import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

const Search = React.lazy(() => import('./SearchLead'));
const New = React.lazy(() => import('./new/NewLead'));
const EditLeadActivity = React.lazy(() => import('./edit/EditLeadActivity'));
const EditLead = React.lazy(() => import('./edit/EditLead'));
const AssignUsers = React.lazy(() => import('./components/AssignUsers'));
const MergeLead = React.lazy(() => import('./merge'));

const Lead = () => {
    const { id } = useParams();
    const { pathname } = useLocation();

    if (id == null) return <Search />;
    if (id === 'new') return <New />;
    if (pathname.toLowerCase().endsWith('/lead')) return <EditLead id={id} />;
    if (pathname.toLowerCase().endsWith('/assign')) return <AssignUsers id={id} />;
    if (pathname.toLowerCase().endsWith('/merge')) return <MergeLead id={id} />;
    return <EditLeadActivity id={id} />;
};

export default Lead;
