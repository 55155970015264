import type { Customer } from './Customer';
import type { Entity } from './Entity';
// import type { ServiceActivity } from './ServiceActivity';
import type { VehicleAdditionalCharge } from './VehicleAdditionalCharge';
import type { VehicleModel } from './VehicleModel';

/** 车辆在库类型 */
export enum VehicleStockType {
    New = 0,
    Used = 1,
    Demo = 2,
    Misc = 3,
    Lease = 4,
    LSE = 5,
    LeaseUsed = 6,
    Fleet = 7,
    SpecialOrder = 8,
    Rental = 9
}

/** 车辆VMS状态 */
export enum VehicleVmsStatus {
    Order = 0,
    Production = 1,
    Invoiced = 2,
    Transit = 3,
    Stock = 4,
    Hold = 5,
    Retired = 6,
    Gone = 7
}

export enum VehicleInputSource {
    Inventory = 0,
    Service = 1
}

/** 二手车修理项目 */
export interface VehicleRepairItem {
    /** 内部维修单编号 */
    repairOrderCode: string;
    /** 维修项目描述 */
    operations: string;
    /** 成本价格 */
    cost: number;
}

/** 车辆输入数据 */
export interface VehicleInput {
    /** 外部系统ID */
    externalId?: string;
    /** 车辆信息的来源 */
    inputSource: VehicleInputSource;
    /** 车辆车型编号 */
    modelCode: string;
    /** 车辆车型年份 */
    modelYear?: string;
    /** 手动输入的车型描述 */
    customModel?: string;
    /** 车辆车架号 */
    vin: string;
    /** 车辆车牌号 */
    licenseNumber?: string;
    /** 仓储编号 */
    stockNo: string;
    /** 在库类型 */
    stockType: VehicleStockType;
    /** 建议零售价 */
    msrp: number;
    /** 车身颜色 */
    bodyColor?: string;
    /** 内饰颜色 */
    interiorColor?: string;
    /** 内饰等级 */
    trimLevel?: string;
    /** 钥匙编号 */
    keyNumber?: string;
    /** 车辆进店日期 */
    receivedDate?: string;
    /** 车辆售出日期 */
    soldDate?: string;
    /** 车辆OEM批发价 */
    wholesalePrice?: number;
    /** 车辆采购发票价 */
    baseInvoicePrice?: number;
    /** 车辆返利金额 */
    holdbackPrice?: number;
    /** 二手车回购成本评估价 */
    grossAcv?: number;
    /** 二手车未还清债务 */
    payoff?: number;
    /** 二手车回购的里程 */
    tradeInMileage?: number;
    /** 二手车售出时里程数 */
    mileage?: number;
    /** 车辆的附加费用清单 */
    additionalCharges: VehicleAdditionalCharge[];
    /** 二手车维修的项目列表 */
    usedRepairs: VehicleRepairItem[];
}

/** 车辆实体数据 */
export interface Vehicle extends Entity, VehicleInput {
    /** 车辆状态 */
    vmsStatus: VehicleVmsStatus;
    /** 车辆初始成本 */
    initialCost: number;
    /** 车辆净成本 */
    netCost: number;
    /** 车型数据 */
    model: VehicleModel;
    /** 关联车主的详细数据 */
    customers?: Customer[];
    // /** 关联的服务过程数据 */
    // serviceActivities?: ServiceActivity[];
}
