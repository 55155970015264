import { Button } from 'antd';
import { compressToEncodedURIComponent } from 'lz-string';
import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
// import Zmage from 'react-zmage';
import styled from 'styled-components';

import { DownloadOutlined } from '@ant-design/icons';

import { AttachmentType } from '../../../models';
import { getAccessToken } from '../../../services/cognito';

interface Props {
    attachmentId: string;
    type: AttachmentType;
}

const Wrapper = styled.div`
    text-align: center;
    & img {
        height: 250px;
    }
`;

const AttachmentShowcase: React.FC<Props> = ({ attachmentId, type }) => {
    const [src, setSrc] = useState<string>();
    const ReactPlayerAny = ReactPlayer as any;
    useEffect(() => {
        (async () => {
            setSrc(`/download?id=${attachmentId}&token=${compressToEncodedURIComponent((await getAccessToken()) ?? '')}`);
        })();
    }, [attachmentId]);

    if (src == null) return null;
    return (
        <>
            {type === AttachmentType.Image ? (
                <Wrapper>{/* <Zmage src={`${src}&inline=1`} alt="" /> */}</Wrapper>
            ) : (
                <ReactPlayerAny width="100%" height="250px" playing={false} controls={true} light={false} url={`${src}&inline=1`} />
            )}
            <Button type="dashed" size="small" style={{ position: 'absolute', top: 10, left: 10, opacity: 0.7, zIndex: 99 }} href={src}>
                <DownloadOutlined />
            </Button>
        </>
    );
};

export default React.memo(AttachmentShowcase);
