import type { TablePaginationConfig } from 'antd';

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useImmer } from 'use-immer';

import { useLazyQuery, DocumentNode } from '@apollo/client';

interface UseSearch<TFilter, TEntity> {
    loading: boolean;
    page: number;
    pageSize: number;
    total: number;
    data: TEntity[];
    pagination: TablePaginationConfig;
    setFilter: <T extends keyof TFilter>(field: T, value: TFilter[T]) => void;
    setSort: (sort: string[]) => void;
    setPage: (page: number) => void;
    setPageSize: (page: number) => void;
    // setParam: <T extends keyof TFilter>(field: T, value: TFilter[T]) => void;
    refresh: () => Promise<void>;
}

interface SearchArguments<TFilter> {
    filter?: TFilter;
    sort?: string[];
    page: number;
}

interface SearchPageArguments {
    page: number;
    pageSize: number;
}

// interface SearchAllArguments<TFilter> {
//     filter?: TFilter;
//     page: number;
// }

export function useSearch<TFilter extends Record<string, any> = any, TEntity = Record<string, any>>(
    document: DocumentNode,
    initialFilter?: TFilter,
    initialSort?: string[],
    initialPageSize = 25,
    initialPageNum = 1
): UseSearch<TFilter, TEntity> {
    const [result, setResult] = useState<[number, any[]]>([0, []]);
    const [args, setArgs] = useImmer<SearchArguments<TFilter>>({ filter: initialFilter, sort: initialSort, page: initialPageNum });
    const [pageArgs, setPageArgs] = useImmer<SearchPageArguments>({ page: initialPageNum, pageSize: initialPageSize });
    // const [allArgs, setAllArgs] = useImmer<SearchAllArguments<TFilter>>({ filter: initialFilter, page: initialPageNum });
    const [fetchData, { called, loading, data, variables, refetch, fetchMore }] = useLazyQuery(document, {
        variables: Object.assign({ filter: {} }, args, pageArgs),
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
        notifyOnNetworkStatusChange: true
    });

    const dataToResult = (data: Record<string, any>) => {
        if (data) {
            const keys = Object.keys(data);
            if (keys.length > 0 && data[keys[0]]) setResult([data[keys[0]].total, data[keys[0]].data]);
        } else setResult([0, []]);
    };

    useEffect(() => {
        dataToResult(data);
    }, [data]);

    useEffect(() => {
        if (called) {
            if (typeof refetch === 'function') refetch(Object.assign({}, variables, args));
            return;
        }
        (async () => {
            const result = await fetchData({ variables: Object.assign({}, variables, args) });
            dataToResult(result.data);
        })();
    }, [called, args, variables, fetchData, refetch]);

    useEffect(() => {
        (async () => {
            if (typeof fetchMore === 'function') {
                // console.log('start');
                // console.log(args);
                // console.log(pageArgs);
                // console.log(Object.assign({}, variables, pageArgs, args));
                // console.log('end');
                const result = await fetchMore({ variables: Object.assign({}, variables, pageArgs, args) });
                dataToResult(result.data);
            }
        })();
    }, [pageArgs, variables, args, fetchMore]);

    const setFilter = useCallback(
        <T extends keyof TFilter>(field: T, value: TFilter[T]) => {
            setArgs(variables => {
                if (variables.filter == null) variables.filter = { [field]: value } as any;
                else (variables.filter as TFilter)[field] = value;
                variables.page = 1;
            });
        },
        [setArgs]
    );

    const setSort = useCallback(
        (sort: string[]) => {
            setArgs(variables => {
                variables.sort = Array.isArray(sort) && sort.length > 0 ? sort : undefined;
            });
        },
        [setArgs]
    );

    const setPage = useCallback(
        (page: number) => {
            setPageArgs(params => {
                params.page = page;
            });
        },
        [setPageArgs]
    );

    const setPageSize = useCallback(
        (pageSize: number) => {
            setPageArgs(params => {
                params.pageSize = pageSize;
            });
        },
        [setPageArgs]
    );

    // const setParam = useCallback(
    //     <T extends keyof TFilter>(field: T, value: TFilter[T], page: number) => {
    //         setAllArgs(variables => {
    //             if (variables.filter == null) variables.filter = { [field]: value } as any;
    //             else (variables.filter as TFilter)[field] = value;
    //             variables.page = page;
    //         });
    //     },
    //     [setAllArgs]
    // );

    const refresh = useCallback(async () => {
        if (typeof refetch === 'function') await refetch();
    }, [refetch]);

    const total = result[0];
    const pagination = useMemo<TablePaginationConfig>(() => {
        const onChange = (page: number, pageSize: number) => {
            setArgs({ filter: args.filter, page });
            setPage(page);
            setPageSize(pageSize);
        };
        return {
            current: args.page,
            pageSize: pageArgs.pageSize,
            total,
            onChange,
            onShowSizeChange: onChange
        };
    }, [args.page, args.filter, pageArgs.pageSize, total, setArgs, setPage, setPageSize]);

    return {
        loading,
        page: args.page,
        pageSize: pageArgs.pageSize,
        total,
        data: result[1],
        pagination,
        setFilter,
        setSort,
        setPage,
        setPageSize,
        // setParam,
        refresh
    };
}
