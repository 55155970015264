import { Form, Input, Modal } from 'antd';
import React, { useEffect, useState } from 'react';

import { SimpleBarAny as SimpleBar } from '../../../components/SimpleBarAny';
import { Vehicle, VehicleInput, VehicleInputSource, VehicleStockType } from '../../../models';
import { WindowContainer } from '../components';
import { VehicleModelSearcher } from '../searchers';

interface Props {
    visible: boolean;
    data?: Readonly<Vehicle>;
    onCancel: () => void;
    onSave: (data: VehicleInput, id?: string, continueAdd?: boolean) => Promise<void>;
}

const emptyData: VehicleInput = {
    inputSource: VehicleInputSource.Service,
    modelCode: '',
    vin: '',
    stockNo: '',
    stockType: VehicleStockType.Used,
    msrp: 0,
    baseInvoicePrice: 0,
    grossAcv: 0,
    payoff: 0,
    tradeInMileage: 0,
    additionalCharges: [],
    usedRepairs: []
};

const ServiceVehicleEditor: React.FC<Props> = props => {
    const id = props.data?.id;
    const initialValues = props.data || emptyData;
    // 表单控制属性
    const [form] = Form.useForm();
    const [isWorking, setIsWorking] = useState(false);
    useEffect(() => {
        if (!props.visible) return;
        form.resetFields();
    }, [form, initialValues, props.visible]);
    const onOk = async () => {
        try {
            setIsWorking(true);
            const valueInput = await form.validateFields();
            valueInput.modelCode = valueInput.model.code;
            delete valueInput.model;
            const values: VehicleInput = Object.assign(
                {
                    inputSource: VehicleInputSource.Service,
                    stockNo: '',
                    stockType: VehicleStockType.Used,
                    msrp: 0,
                    baseInvoicePrice: 0,
                    grossAcv: 0,
                    payoff: 0,
                    tradeInMileage: 0,
                    additionalCharges: [],
                    usedRepairs: []
                },
                valueInput as VehicleInput
            );
            await props.onSave(values, id);
        } catch (err) {
            console.error(err);
        } finally {
            setIsWorking(false);
        }
    };
    const onCancel = () => {
        if (!isWorking) props.onCancel();
    };

    return (
        <Modal
            title={`${id == null ? 'New' : 'Edit'} Vehicle`}
            confirmLoading={isWorking}
            visible={props.visible}
            onCancel={onCancel}
            onOk={onOk}
            width={600}
            className="form"
            okText="Submit"
            cancelButtonProps={{ type: 'link' }}
            maskClosable={false}
            keyboard={false}
            forceRender
        >
            <SimpleBar style={{ maxHeight: 600 }}>
                <WindowContainer>
                    <Form form={form} initialValues={initialValues} labelCol={{ span: 5 }}>
                        <Form.Item name="vin" label="VIN" rules={[{ required: true, whitespace: true, len: 17 }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="model" label="Model" rules={[{ required: true }]}>
                            <VehicleModelSearcher />
                        </Form.Item>
                        <Form.Item name="customModel" label="Custom model">
                            <Input />
                        </Form.Item>
                        <Form.Item name="modelYear" label="Year" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="licenseNumber" label="License #">
                            <Input />
                        </Form.Item>
                        <Form.Item name="bodyColor" label="Body color">
                            <Input />
                        </Form.Item>
                        <Form.Item name="interiorColor" label="Interior">
                            <Input />
                        </Form.Item>
                        <Form.Item name="mileage" label="Mileage">
                            <Input />
                        </Form.Item>
                    </Form>
                </WindowContainer>
            </SimpleBar>
        </Modal>
    );
};

export default React.memo(ServiceVehicleEditor);
