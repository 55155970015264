import { Button, Dropdown, Menu, Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';
import { useContext, useMemo, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Link } from 'react-router-dom';

import { EllipsisOutlined } from '@ant-design/icons';

import { UserContext } from '../../../contexts';
import { LeadContactStatus, LeadProspectLevel, LeadStatus } from '../../crm/lead/models';
import { TaskActivity } from '../models';
import styles from '../styles.module.scss';
import { displayCustomer, insertLink } from '../utils';

interface Props {
    task: TaskActivity;
    index: number;
    handleHistoryVisible: (id: string) => void;
}

const textInnerHtml = (text: string) => {
    return (
        <span
            style={{ wordBreak: 'break-all' }}
            dangerouslySetInnerHTML={{
                __html: insertLink(text)
            }}
        ></span>
    );
};

const grid = 8;

const Task = ({ task, index, handleHistoryVisible }: Props) => {
    const user = useContext(UserContext);
    const { Paragraph } = Typography;
    const editPermission = useMemo(() => user != null && (user.roles.includes('admin') || user.permissions.includes('lead:edit')), [user]);

    const [displayAction, setDisplayAction] = useState<boolean>(false);

    const activity = task.activities[0];
    const lastContactAt = activity?.callContact?.createAt ?? activity?._closeAt ?? activity?._createAt;

    const getItemStyle = (draggableStyle: any, contactStatus?: LeadContactStatus) => {
        const _style = {
            // some basic styles to make the items look a bit nicer
            userSelect: 'none',
            // padding: grid * 2,
            margin: `0 0 ${grid}px 0`,
            border:
                contactStatus != null && contactStatus === LeadContactStatus.Success ? '1px solid #52c41a' : '1px solid rgba(23,43,77,0.2)',
            // change background colour if dragging
            background: displayAction ? 'RGB(248,249,254)' : 'white',
            borderRadius: '3px',
            // styles we need to apply on draggables
            ...draggableStyle
        };
        return _style;
    };

    const getTopHalfStyle = () => {
        return {
            padding: '10px 10px 0 10px'
        };
    };
    const getSecondHalfStyle = () => {
        return {
            padding: '0 10px 10px 10px'
        };
    };

    const menu = (
        <Menu style={{ width: 100, fontSize: '14px' }}>
            <Menu.Item key={0} style={{ padding: 0 }}>
                <Button
                    size="small"
                    type="text"
                    style={{ width: '100%', textAlign: 'left' }}
                    disabled={!editPermission}
                    onClick={() => handleHistoryVisible(task.id)}
                >
                    History
                </Button>
            </Menu.Item>
        </Menu>
    );

    return (
        <>
            <Draggable
                key={task.id}
                draggableId={task.id}
                index={index}
                isDragDisabled={[LeadStatus.Converted].some(o => (task.status & o) === o)}
            >
                {provided => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(provided.draggableProps.style, task.contactStatus)}
                        onMouseOver={() => {
                            setDisplayAction(true);
                        }}
                        onMouseOut={() => {
                            setDisplayAction(false);
                        }}
                    >
                        <div>
                            <div style={getTopHalfStyle()}>
                                <div className={styles.task}>
                                    <div>
                                        <Link style={{ color: '#2a7fff' }} to={`/crm/lead/${task.id}`} target="_blank">
                                            {task.customer != null && displayCustomer(task.customer)}
                                        </Link>
                                    </div>
                                    <div></div>
                                    <div>
                                        {task.customer.contactInfo.address?.city != null && task.customer.contactInfo.address?.city}
                                        {task.customer.contactInfo.address?.city != null &&
                                            task.customer.contactInfo.address?.state != null &&
                                            ', '}
                                        {task.customer.contactInfo.address?.state != null && task.customer.contactInfo.address?.state}
                                    </div>
                                </div>
                                <div className={styles.task}>
                                    <div>{task.source}</div>
                                    <div>{task.prospectLevel != null && LeadProspectLevel[task.prospectLevel]}</div>
                                </div>
                            </div>
                            {lastContactAt != null && (
                                <div style={{ paddingLeft: 8, paddingRight: 8 }}>
                                    <div className={styles.line}></div>
                                </div>
                            )}
                            <div style={getSecondHalfStyle()}>
                                {lastContactAt != null && <div>Called at {dayjs(lastContactAt).format('LT')}</div>}
                                <div style={{ lineHeight: '15px' }}>
                                    {activity?.closeComment != null && activity.closeComment.length > 24 ? (
                                        <Tooltip placement="bottom" className={styles.comment} title={textInnerHtml(activity.closeComment)}>
                                            <span className={styles.summary}>{activity.closeComment}</span>
                                        </Tooltip>
                                    ) : (
                                        <div className={styles.comment}>
                                            {activity?.closeComment != null && textInnerHtml(activity.closeComment)}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div style={{ display: 'none' }}>
                                <Paragraph></Paragraph>
                            </div>
                        </div>
                        <div
                            hidden={!displayAction}
                            style={{
                                position: 'absolute',
                                right: '12px',
                                top: '8px',
                                backgroundColor: 'var(--ds-surface-raised,RGB(236,237,242))',
                                borderRadius: '3px'
                            }}
                        >
                            <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
                                <Button size="small" type="text" icon={<EllipsisOutlined />}></Button>
                            </Dropdown>
                        </div>
                    </div>
                )}
            </Draggable>
        </>
    );
};

export default Task;
