import dayjs from 'dayjs';
import clone from 'lodash/clone';
import { useCallback, useEffect, useState } from 'react';

import { searchData } from '../services';
import { Filters, Paging, SearchResult } from '../types';

const emptyResult = {
    data: [],
    page: 1,
    pageSize: 10,
    total: 0
};

const ISODateRegex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.(\d{3})Z$/;

const processFilters = (filters: Filters) => {
    if (filters == null) return filters;
    const newFilters = clone(filters);
    Object.keys(newFilters).forEach(key => {
        const value = newFilters[key];
        if (
            Array.isArray(value) &&
            value.length === 2 &&
            (value.every(dayjs.isDayjs) || value.every(date => typeof date === 'string' && date.match(ISODateRegex)))
        ) {
            delete newFilters[key];
            ['From', 'To'].forEach((field, i) => {
                newFilters[`${key}${field}`] = dayjs.isDayjs(value[i]) ? value[i].toISOString() : value[i];
            });
        }
    });
    return newFilters;
};

export default function useSearchData<T = any>(
    resource: string,
    filters: Filters,
    paging: Paging,
    includes?: string[]
): [SearchResult<T>, boolean, () => void] {
    const includesFilter = includes != null && Array.isArray(includes) ? includes.join(',') : null;
    const [timestamp, setTimestamp] = useState(Date.now());
    const [isWorking, setIsWorking] = useState(false);
    const [result, setResult] = useState<SearchResult<T>>(emptyResult);
    const reload = useCallback(() => {
        setTimestamp(Date.now());
    }, []);

    useEffect(() => {
        let didCancel = false;
        (async () => {
            setIsWorking(true);
            try {
                const result = await searchData<T>(resource, {
                    filters:
                        includesFilter == null
                            ? processFilters(filters)
                            : Object.assign({}, processFilters(filters), { $includes: includesFilter }),
                    paging
                });
                if (!didCancel) setResult(result || emptyResult);
            } finally {
                setIsWorking(false);
            }
        })();
        return () => {
            didCancel = true;
        };
    }, [resource, filters, paging, includesFilter, timestamp]);

    return [result, isWorking, reload];
}
