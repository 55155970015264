import { Select, Space } from 'antd';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { CustomerName, Loading } from '../../../components';
import { useFilters, usePaging, useSearchData } from '../../../hooks';
import { Dispatch } from '../../../models';

interface Props {
    value?: Dispatch;
    onChange?: (value: Dispatch) => void;
}

const useDispatchSearch = (): [Dispatch[], boolean, (value: string) => void] => {
    const [filters, setFilter] = useFilters();
    const [paging] = usePaging(30);
    const [result, isWorking, reload] = useSearchData<Dispatch>('dispatch', filters, paging);
    const dispatches = useMemo(() => (isWorking ? [] : result.data), [result, isWorking]);
    const setKeyword = useCallback(
        (value: string) => {
            value = value.trim();
            setFilter('$keyword', value === '' ? undefined : value);
        },
        [setFilter]
    );
    useEffect(() => {
        reload();
    }, [reload]);
    return [dispatches, isWorking, setKeyword];
};
const DispatchSearcher: React.FC<Props> = React.forwardRef<any, Props>((props, ref) => {
    const [dispatches, isWorking, setKeyword] = useDispatchSearch();
    const [dispatchCode, setDispatchCode] = useState<string>();
    const onChange = (value: string) => {
        setDispatchCode(value);
        const dispatch = dispatches.find(dispatch => dispatch.code === value);
        if (dispatch != null && typeof props.onChange === 'function') props.onChange(dispatch);
    };
    useMemo(() => {
        setDispatchCode(props.value?.code);
    }, [props.value]);

    return (
        <Select
            ref={ref}
            placeholder="Search dispatch by code,description"
            value={dispatchCode}
            notFoundContent={isWorking ? <Loading text="Searching..." /> : null}
            onSearch={debounce(setKeyword, 500)}
            onChange={onChange}
            defaultActiveFirstOption={false}
            filterOption={false}
            showSearch
        >
            {dispatches.map(dispatch => (
                <Select.Option key={dispatch.code} value={dispatch.code}>
                    <Space>
                        <strong>
                            <CustomerName name={dispatch.code} />
                        </strong>
                        <span>{dispatch.description}</span>
                    </Space>
                </Select.Option>
            ))}
        </Select>
    );
});

DispatchSearcher.displayName = 'DispatchSearcher';

export default React.memo(DispatchSearcher);
