/**
 * 系统中常用的值对象结构
 * @packageDocumentation
 */

import type { DayOfTheWeek, NotifyMethod } from './Enums';

/** 地址信息 */
export interface Address {
    /** 地址所在国家 */
    country?: string;
    /** 地址所在的州或省 */
    state?: string;
    /** 地址所在的城市 */
    city?: string;
    /** 地址所在郡县乡镇 */
    county?: string;
    /** 地址所在街道及门牌号码1 */
    street?: string;
    /** 地址所在街道及门牌号码2 */
    street2?: string;
    /** 地址所在位置的邮编号码 */
    zip?: string;
}

/** 接受联络的方式，可按位组合 */
export enum ContactInfoPreferredMethod {
    Email = 0x1,
    Cell = 0x2,
    Home = 0x4,
    Work = 0x8
}

/** 个人联系方式 */
export interface ContactInfo {
    /** Email地址 */
    email?: string;
    homeEmail?: string;
    workEmail?: string;
    /** 手机号码 */
    cellNumber?: string;
    /** 家庭住址 */
    homeAddress?: Address;
    /** 居住的总月数 */
    homeAddressMonths?: number;
    /** 家里的电话号码 */
    homeNumber?: string;
    /** 公司地址 */
    workAddress?: Address;
    /** 工作的电话号码 */
    workNumber?: string;
    /** 愿意接受联络的日子 */
    preferredDay?: DayOfTheWeek[];
    /** 愿意接受联络的方式 */
    preferredMethod?: ContactInfoPreferredMethod;
}

/** 人名信息 */
export interface PersonName {
    /** 名称前缀 */
    prefix?: string;
    /** 名称后缀 */
    suffix?: string;
    /** 称号 */
    title?: string;
    /** 姓名的名部分 */
    firstName: string;
    /** 姓名的姓部分 */
    lastName: string;
    /** 中间名部分 */
    middleName?: string;
}

export interface Notification {
    method: NotifyMethod;
    email?: string;
    number?: string;
}
