import React from 'react';
import { useParams } from 'react-router-dom';

const Search = React.lazy(() => import('./SearchVehicle'));
const New = React.lazy(() => import('./NewVehicle'));
const Edit = React.lazy(() => import('./EditVehicle'));
const Vehicle = () => {
    const { id } = useParams();
    if (id == null) return <Search />;
    if (id === 'new') return <New />;
    return <Edit id={id} />;
};

export default Vehicle;
