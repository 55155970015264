import { notification, Button, Card, Col, Input, Popconfirm, Row, Space, Table, Tag, Tooltip } from 'antd';
import { ColumnProps, TablePaginationConfig } from 'antd/lib/table';
import { orderBy } from 'lodash';
import { nanoid } from 'nanoid';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useImmer } from 'use-immer';

import { MessageTwoTone } from '@ant-design/icons';
import { useMutation } from '@apollo/client';

import { SimpleBarAny as SimpleBar } from '../../../components/SimpleBarAny';
import { UserContext } from '../../../contexts';
import { useDelete, useFilters, useSearch, useUpdate } from '../../../hooks';
import { LeadProspectLevel } from '../../../models';
import { LeadPriority } from '../../admin/setting/model';
import UserAvatar from '../../components/UserAvatar';
import { insertLink } from '../../crm/board/utils';
import { ReloadButton, TimeCell } from '../../crm/components';
import CallCustomerModal from '../../crm/lead/components/CallCustomerModal';
import CloseLead from '../../crm/lead/components/CloseLead';
import EmailCustomerModal from '../../crm/lead/components/EmailCustomerModal';
import LeadCommentModal from '../../crm/lead/components/LeadCommentModal';
import { getEmail, getPhoneNumber } from '../../crm/lead/components/LeadPanel';
import SmsCustomerModal from '../../crm/lead/components/SmsCustomerModal';
import { ABANDON_LEAD, CLOSE_LEAD, REOPEN_LEAD, SEARCH_LEADS } from '../../crm/lead/graphql';
import { LeadCloseInput, LeadContactStatus, LeadDataObject, LeadStatus, TeamType } from '../../crm/lead/models';
import { buildLedLight } from '../../crm/lead/SearchLead';
import UserSearcherSelect from '../../service/appointment/components/UserSearcherSelect';
import styles from '../styles.module.scss';
import { displayCustomer } from '../utils';

const textInnerHtml = (text: string) => {
    return (
        <span
            style={{ wordBreak: 'break-all' }}
            dangerouslySetInnerHTML={{
                __html: insertLink(text)
            }}
        ></span>
    );
};

const getOpportunity = (lead: LeadDataObject) => {
    if (lead.opportunity != null) return lead.opportunity;
    if (Array.isArray(lead.opportunities) && lead.opportunities.length > 0) return lead.opportunities[0];
};

const getOpportunityVehicle = (lead: LeadDataObject) => {
    if (lead.opportunity != null && typeof lead.opportunity.vehicle != 'string') return lead.opportunity.vehicle;
    if (Array.isArray(lead.opportunities) && lead.opportunities.length > 0)
        return typeof lead.opportunities[0].vehicle != 'string' ? lead.opportunities[0].vehicle : null;
};

const Column = (props: ColumnProps<LeadDataObject>) => <Table.Column<LeadDataObject> {...props} />;

interface Filter {
    _search?: string;
    prospectLevel?: any;
    status?: any;
    _noSearch?: boolean;
    assignee?: string;
    scheduledTimeFrom?: string;
    group?: string;
    createTimeFrom?: string;
    contactStatus?: any;
}

interface Props {
    activeKey: string;
    isSales?: boolean;
    type?: string;
    status?: LeadStatus[];
    leadPriority: LeadPriority;
    setNoContactCount?: (count: number) => void;
}

const LeadContactCard = ({ activeKey, isSales = true, type, status, leadPriority, setNoContactCount }: Props) => {
    //获取当前登录人的信息
    const user = useContext(UserContext);
    // permissions
    const editPermission = useMemo(() => user != null && (user.roles.includes('admin') || user.permissions.includes('lead:edit')), [user]);
    const assignPermission = useMemo(
        () => user != null && (user.roles.includes('admin') || user.permissions.includes('lead:assign')),
        [user]
    );
    const convertPermission = useMemo(
        () => user != null && (user.roles.includes('admin') || user.permissions.includes('lead:convert')),
        [user]
    );
    const closePermission = useMemo(
        () => user != null && (user.roles.includes('admin') || user.permissions.includes('lead:close')),
        [user]
    );
    const reopenPermission = useMemo(
        () => user != null && (user.roles.includes('admin') || user.permissions.includes('lead:reopen')),
        [user]
    );
    const abandonPermission = useMemo(
        () => user != null && (user.roles.includes('admin') || user.permissions.includes('lead:delete')),
        [user]
    );

    const navigate = useNavigate();
    const [leads, setLeads] = useState<LeadDataObject[]>([]);
    const filter: Filter = {
        _noSearch: true,
        contactStatus: type === 'newLeads' ? LeadContactStatus.None : type === 'following' ? LeadContactStatus.Success : undefined,
        status: status,
        assignee: isSales ? user?.id : undefined
        // createTimeFrom: dayjs().subtract(7, 'day').toISOString()
    };
    const defaultSortDescend = type != 'newLeads' && type != 'following' ? '!_updateAt' : '!_createAt';
    const [filters, setStoreFilters] = useFilters(`${type}.filters`, filter);
    const { loading, data, total, setFilter, refresh } = useSearch<Filter>(SEARCH_LEADS, filters, [defaultSortDescend], -1);

    // lead led light priority  red,yellow,green

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedLead, setSelectedLead] = useState<LeadDataObject>();

    const [closeLeadVisible, setCloseLeadVisible] = useState(false);
    const [commentVisible, setCommentVisible] = useState<boolean>(false);
    const [callModalVisible, setCallModalVisible] = useState<boolean>(false);
    const [smsModalVisible, setSmsModalVisible] = useState<boolean>(false);
    const [emailModalVisible, setEmailModalVisible] = useState<boolean>(false);

    const [closeLead] = useUpdate(CLOSE_LEAD);
    const [reopenLead] = useMutation(REOPEN_LEAD);
    const [abandonLead] = useDelete(ABANDON_LEAD);

    useEffect(() => {
        if (activeKey === type) refresh();
    }, [activeKey, refresh, type]);

    useEffect(() => {
        if (data == null) return;

        const newLeads = orderBy(
            data as LeadDataObject[],
            [o => (o.prospectLevel == null ? 99 : o.prospectLevel), o => (o.lastContactAt == null ? 0 : o.lastContactAt), '_createAt'],
            ['asc', 'asc', 'asc']
        );
        setLeads(newLeads);
        if (
            typeof setNoContactCount === 'function' &&
            type == 'newLeads' &&
            filters._search == null &&
            (isSales || filters.assignee == null)
        )
            setNoContactCount(total);
    }, [data, filters._search, filters.assignee, isSales, leadPriority, setNoContactCount, total, type]);

    const onTextKeypress: React.KeyboardEventHandler<HTMLInputElement> = e => {
        if (e.key === 'Enter') setFilter('_search', e.currentTarget.value);
    };

    const onCloseLead = async (id: string, leadCloseInput: LeadCloseInput) => {
        try {
            setIsSubmitting(true);
            if (leadCloseInput) {
                setCloseLeadVisible(false);
                await closeLead(id, leadCloseInput);
            }
        } catch (err) {
            console.error(err);
            notification.error({ duration: 0, message: 'Something went wrong', description: err.message });
        } finally {
            refresh();
            setIsSubmitting(false);
        }
    };

    const onReopenLead = async (id: string) => {
        try {
            setIsSubmitting(true);
            if (id) {
                setCloseLeadVisible(false);
                await reopenLead({ variables: { id } });
            }
        } catch (err) {
            console.error(err);
            notification.error({ duration: 0, message: 'Something went wrong', description: err.message });
        } finally {
            refresh();
            setIsSubmitting(false);
        }
    };

    const onConvertLead = async (id: string) => {
        try {
            setIsSubmitting(true);
            if (id) navigate(`/desking/deal/new?lead=${id}`);
        } catch (err) {
            console.error(err);
            notification.error({ duration: 0, message: 'Something went wrong', description: err.message });
        } finally {
            setIsSubmitting(false);
        }
    };

    const onAbandonLead = async (id: string) => {
        try {
            setIsSubmitting(true);
            if (id) await abandonLead(id);
        } catch (err) {
            console.error(err);
            notification.error({ duration: 0, message: 'Something went wrong', description: err.message });
        } finally {
            refresh();
            setIsSubmitting(false);
        }
    };

    const getColor = (status: any) => {
        let color: string | undefined = undefined;
        switch (status) {
            case LeadStatus.New:
                color = 'cyan';
                break;
            case LeadStatus.Assigned:
                color = 'blue';
                break;
            case LeadStatus.Appointment:
                color = 'purple';
                break;
            case LeadStatus.InProgress:
                color = 'geekblue';
                break;
            case LeadStatus.Converted:
                color = 'green';
                break;
            case LeadStatus.Lost:
                color = 'red';
                break;
            case LeadStatus.Defeated:
                color = 'red';
                break;
        }
        return color;
    };

    const simpleBarRef = useRef<SimpleBar>(null);
    const height = useMemo(() => Math.max(document.documentElement.clientHeight, window.innerHeight ?? 0) - 218, []);

    const setAssignee = (value: string | string[]) => {
        if (Array.isArray(value)) return;
        setStoreFilters('assignee', value);
        setFilter('assignee', value);
    };

    const [pageArgs, setPageArgs] = useImmer({ page: 1, pageSize: 10 });
    const pagination = useMemo<TablePaginationConfig>(() => {
        const onChange = (page: number, pageSize: number) => {
            setPageArgs({
                page,
                pageSize
            });
        };
        return {
            current: pageArgs.page,
            pageSize: pageArgs.pageSize,
            total: leads.length,
            onChange,
            onShowSizeChange: onChange,
            showSizeChanger: true
        };
    }, [leads.length, pageArgs.page, pageArgs.pageSize, setPageArgs]);
    return (
        <Card className={styles.tabcard} bodyStyle={{ paddingTop: 20, paddingBottom: 0 }}>
            <Row gutter={4}>
                <Col span={4}>
                    <Input.Search
                        placeholder="Search by name, description..."
                        onKeyPress={onTextKeypress}
                        onSearch={value => {
                            setStoreFilters('_search', value);
                            setFilter('_search', value);
                        }}
                        allowClear
                    />
                </Col>
                {!isSales && (
                    <Col span={3}>
                        <UserSearcherSelect
                            onChange={setAssignee}
                            placeholder="Select Sales rep"
                            type={TeamType.Sales}
                            value={filters.assignee}
                            disabled={isSales}
                        />
                    </Col>
                )}
                <Col span={3}>
                    <ReloadButton onClick={() => refresh()} disabled={loading} />
                </Col>
            </Row>
            <Row>
                <Card size="small" bordered={false} style={{ marginTop: 8, width: '100%' }} bodyStyle={{ padding: 0 }}>
                    <SimpleBar ref={simpleBarRef} style={{ height }}>
                        <Table
                            rowKey="id"
                            size="small"
                            dataSource={leads}
                            loading={loading || isSubmitting}
                            className={styles.table}
                            pagination={pagination}
                            //onChange={handleTableChange}
                        >
                            <Column
                                width={22}
                                title=""
                                render={(_, record) => {
                                    return buildLedLight(record, leadPriority);
                                }}
                            />
                            <Column
                                width={120}
                                title="Name"
                                key="name"
                                render={(_, record) => {
                                    if (record.customer) {
                                        return (
                                            <Link style={{ color: '#2a7fff' }} to={`crm/lead/${record.id}`}>
                                                {displayCustomer(record.customer)}
                                            </Link>
                                        );
                                    }
                                }}
                            />
                            <Column
                                title="Contact"
                                width={160}
                                ellipsis={true}
                                render={(_, record) => {
                                    if (record.customer) {
                                        const contact: JSX.Element[] = [];
                                        const phoneNumber = getPhoneNumber(record.customer, 'Mobile');
                                        const email = getEmail(record.customer);
                                        if (phoneNumber && phoneNumber !== '')
                                            contact.push(
                                                <span key={'Phone'}>
                                                    <Button
                                                        type="link"
                                                        size="small"
                                                        style={{ padding: 0 }}
                                                        onClick={() => {
                                                            setSelectedLead(record);
                                                            setCallModalVisible(true);
                                                        }}
                                                    >
                                                        {phoneNumber}
                                                    </Button>
                                                    <Button
                                                        type="link"
                                                        size="small"
                                                        style={{ padding: 0, marginLeft: 5 }}
                                                        onClick={() => {
                                                            setSelectedLead(record);
                                                            setSmsModalVisible(true);
                                                        }}
                                                    >
                                                        <MessageTwoTone twoToneColor="#52c41a" />
                                                    </Button>
                                                </span>
                                            );
                                        if (email)
                                            contact.push(
                                                <span key={'Email'}>
                                                    {contact.length >= 1 && <br />}
                                                    <Button
                                                        type="link"
                                                        size="small"
                                                        style={{ padding: 0 }}
                                                        onClick={() => {
                                                            setSelectedLead(record);
                                                            setEmailModalVisible(true);
                                                        }}
                                                    >
                                                        {email}
                                                    </Button>
                                                </span>
                                            );
                                        return contact;
                                    }
                                }}
                            />
                            {(type == 'newLeads' || type == 'following') && (
                                <Column
                                    width={80}
                                    title="Prospect"
                                    render={(_, record) => (record?.prospectLevel != null ? LeadProspectLevel[record.prospectLevel] : null)}
                                />
                            )}
                            {type !== 'lost' && (
                                <Column
                                    width={120}
                                    title="Model"
                                    render={(_, record) => {
                                        return (
                                            <span>
                                                {getOpportunityVehicle(record)?.year} {getOpportunity(record)?.make}{' '}
                                                {getOpportunity(record)?.model} {getOpportunity(record)?.color}
                                            </span>
                                        );
                                    }}
                                />
                            )}
                            <Column
                                width={160}
                                ellipsis={{ showTitle: false }}
                                title="Summary"
                                dataIndex="summary"
                                render={(_, record) => {
                                    return (
                                        <Tooltip title={textInnerHtml(record.summary)} placement="topLeft" overlayStyle={{ maxWidth: 500 }}>
                                            {record.summary}
                                        </Tooltip>
                                    );
                                }}
                            />
                            <Column key="source" width={100} ellipsis={true} title="Source" dataIndex="source" />
                            {!isSales && (
                                <Column
                                    width={60}
                                    align="center"
                                    ellipsis={true}
                                    title="Sales rep"
                                    render={(_, record) => {
                                        if (record.assignee) {
                                            return (
                                                <UserAvatar
                                                    size="small"
                                                    firstName={record.assignee.firstName}
                                                    lastName={record.assignee.lastName}
                                                />
                                            );
                                        }
                                    }}
                                />
                            )}
                            {type == 'lost' && (
                                <>
                                    <Column width={100} ellipsis={true} title="Lost reason" dataIndex="lostReason" />
                                    <Column
                                        title="Status"
                                        width={70}
                                        render={(_, record) => {
                                            const tags = [];
                                            if ((record.status & LeadStatus.New) === LeadStatus.New) tags.push(LeadStatus.New);
                                            if ((record.status & LeadStatus.InProgress) === LeadStatus.InProgress)
                                                tags.push(LeadStatus.InProgress);
                                            if ((record.status & LeadStatus.Appointment) === LeadStatus.Appointment)
                                                tags.push(LeadStatus.Appointment);
                                            if ((record.status & LeadStatus.Converted) === LeadStatus.Converted)
                                                tags.push(LeadStatus.Converted);
                                            if ((record.status & LeadStatus.Lost) === LeadStatus.Lost) tags.push(LeadStatus.Lost);
                                            if ((record.status & LeadStatus.Defeated) === LeadStatus.Defeated)
                                                tags.push(LeadStatus.Defeated);
                                            return (
                                                <>
                                                    {tags.map((tag, i) => (
                                                        <Tag color={getColor(tag)} key={i}>
                                                            {LeadStatus[tag]}
                                                        </Tag>
                                                    ))}
                                                </>
                                            );
                                        }}
                                    />
                                </>
                            )}

                            {type == 'newLeads' || type == 'following' ? (
                                <Column
                                    width={160}
                                    key="_createdAt"
                                    ellipsis={true}
                                    title="Create at"
                                    render={(_, record) =>
                                        record._createAt != null ? <TimeCell time={new Date(record._createAt)} /> : null
                                    }
                                />
                            ) : (
                                <Column
                                    width={160}
                                    key="_updateAt"
                                    ellipsis={true}
                                    title="Last edit"
                                    render={(_, record) =>
                                        record._updateAt != null ? <TimeCell time={new Date(record._updateAt)} /> : null
                                    }
                                />
                            )}

                            <Column
                                width={200}
                                title="Actions"
                                render={(_, record) => (
                                    <Space size={0} wrap>
                                        <Button size="small" type="link">
                                            <Link to={`crm/lead/${record.id}`}>
                                                {![LeadStatus.Converted, LeadStatus.Lost, LeadStatus.Defeated].some(
                                                    status => (record.status & status) === status
                                                ) && editPermission
                                                    ? 'Edit'
                                                    : 'View'}
                                            </Link>
                                        </Button>
                                        {![LeadStatus.Converted].some(status => (record.status & status) === status) &&
                                            assignPermission == true && (
                                                <Button
                                                    size="small"
                                                    type="link"
                                                    onClick={() => {
                                                        const id = nanoid(8);
                                                        sessionStorage.setItem(id, JSON.stringify([record]));
                                                        navigate(`crm/lead/${id}/assign`);
                                                    }}
                                                >
                                                    Assign
                                                </Button>
                                            )}
                                        {![LeadStatus.Converted, LeadStatus.Lost, LeadStatus.Defeated].some(
                                            status => (record.status & status) === status
                                        ) &&
                                            convertPermission == true && (
                                                <Popconfirm
                                                    disabled={isSubmitting}
                                                    placement="right"
                                                    title="Convert this lead?"
                                                    okText="Yes"
                                                    cancelText="No"
                                                    onConfirm={async () => await onConvertLead(record.id)}
                                                >
                                                    <Button size="small" type="link" disabled={isSubmitting || !convertPermission}>
                                                        Convert
                                                    </Button>
                                                </Popconfirm>
                                            )}
                                        {[LeadStatus.Defeated].some(status => (record.status & status) === status) &&
                                            closePermission == true && (
                                                <Button
                                                    size="small"
                                                    type="link"
                                                    disabled={isSubmitting}
                                                    onClick={() => {
                                                        setCloseLeadVisible(true);
                                                        setSelectedLead(record);
                                                    }}
                                                >
                                                    Close
                                                </Button>
                                            )}
                                        {[LeadStatus.Lost].some(status => (record.status & status) === status) && reopenPermission == true && (
                                            <Popconfirm
                                                disabled={isSubmitting}
                                                placement="right"
                                                title="Reopen this lead?"
                                                okText="Yes"
                                                cancelText="No"
                                                onConfirm={async () => await onReopenLead(record.id)}
                                            >
                                                <Button size="small" type="link" disabled={isSubmitting}>
                                                    Reopen
                                                </Button>
                                            </Popconfirm>
                                        )}
                                        {![LeadStatus.Converted, LeadStatus.Lost, LeadStatus.Defeated].some(
                                            status => (record.status & status) === status
                                        ) &&
                                            abandonPermission == true && (
                                                <Popconfirm
                                                    disabled={isSubmitting}
                                                    placement="right"
                                                    title="Abandon this lead?"
                                                    okText="Yes"
                                                    cancelText="No"
                                                    onConfirm={async () => {
                                                        await onAbandonLead(record.id);
                                                        setLeads(leads.filter(d => d.id !== record.id));
                                                    }}
                                                >
                                                    <Button size="small" type="link" disabled={isSubmitting}>
                                                        Abandon
                                                    </Button>
                                                </Popconfirm>
                                            )}
                                        {![LeadStatus.Converted, LeadStatus.Lost, LeadStatus.Defeated].some(
                                            status => (record.status & status) === status
                                        ) &&
                                            editPermission && (
                                                <Button
                                                    size="small"
                                                    type="link"
                                                    disabled={isSubmitting}
                                                    onClick={() => {
                                                        setSelectedLead(record);
                                                        setCommentVisible(true);
                                                    }}
                                                >
                                                    Comment
                                                </Button>
                                            )}
                                    </Space>
                                )}
                            />
                        </Table>
                    </SimpleBar>
                </Card>
            </Row>

            <CloseLead
                id={(selectedLead && selectedLead.id) || ''}
                visible={closeLeadVisible}
                onCancel={() => setCloseLeadVisible(false)}
                onSave={onCloseLead}
            />

            <LeadCommentModal
                lead={selectedLead as LeadDataObject}
                visible={commentVisible}
                onCancel={() => {
                    setCommentVisible(false);
                    refresh();
                }}
            />
            <CallCustomerModal
                lead={selectedLead as LeadDataObject}
                visible={callModalVisible}
                onCancel={() => {
                    setCallModalVisible(false);
                    refresh();
                }}
            />
            <SmsCustomerModal
                lead={selectedLead}
                visible={smsModalVisible}
                onCancel={() => {
                    setSmsModalVisible(false);
                    refresh();
                }}
            />
            <EmailCustomerModal
                lead={selectedLead}
                visible={emailModalVisible}
                onCancel={() => {
                    setEmailModalVisible(false);
                }}
            />
        </Card>
    );
};

export default LeadContactCard;
