import {
    message,
    notification,
    Alert,
    Badge,
    Button,
    Card,
    Checkbox,
    Col,
    DatePicker,
    Dropdown,
    Form,
    Input,
    List,
    Menu,
    Modal,
    Popconfirm,
    Row,
    Space,
    Switch,
    Tooltip
} from 'antd';
import dayjs from 'dayjs';
import { cloneDeep, orderBy } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { DoubleLeftOutlined, DoubleRightOutlined, DownOutlined, PhoneTwoTone, ReloadOutlined } from '@ant-design/icons';

import UserAvatar from '../../../components/UserAvatar';
import { EnumSelect, StatusTag } from '../../components';
import { UserContext } from '../../contexts';
import { useCreate, useGqlGet, useUpdate } from '../../hooks';
import { CLOSE_PHONE_LEAD_ACTIVITY, CREATE_CALL_LOG, CREATE_PHONE_LEAD_ACTIVITY, GET_LEAD } from '../graphql';
import {
    CallResult,
    Customer,
    CustomerType,
    Direction,
    LeadActivity,
    LeadActivityClosePhoneInput,
    LeadActivityCreatePhoneInput,
    LeadContactType,
    LeadDataObject,
    LeadProspectLevel,
    LeadStatus
} from '../models';
import { parsePhoneNumberCustomer, showAuthor } from '../utils';
import EmailCustomerModal from './EmailCustomerModal';
import SmsCustomerModal from './SmsCustomerModal';

export const getPhoneNumber = (customer?: Customer, type?: string) => {
    if (customer?.contactInfo == null) return null;
    if (type == null) return customer.contactInfo.phoneNumber ? parsePhoneNumberCustomer(customer.contactInfo.phoneNumber.value) : null;
    if (!Array.isArray(customer.contactInfo.phoneNumbers)) return null;
    let phoneNumber = customer.contactInfo.phoneNumbers.find(phoneNumber => phoneNumber.description === type);

    if ('Mobile' === type && phoneNumber == null)
        phoneNumber = customer.contactInfo.phoneNumbers.find(
            phoneNumber =>
                phoneNumber.description == null ||
                phoneNumber.description == undefined ||
                !['Mobile', 'Home', 'Work', 'Pager'].includes(phoneNumber.description)
        );

    return phoneNumber ? parsePhoneNumberCustomer(phoneNumber.value) : null;
};

export const displayCustomer = (customer?: Customer) =>
    customer &&
    (customer.name == null
        ? ''
        : customer.type === CustomerType.Person
        ? `${customer.name.lastName ?? ''}, ${customer.name.firstName ?? ''}`
        : customer.name.companyName ?? '');

export const commentDisabled = (editPermission: boolean, leadStatus?: LeadStatus) => {
    if (leadStatus == null) return false;
    if ([LeadStatus.Converted, LeadStatus.Lost, LeadStatus.Defeated].some(status => (leadStatus & status) === status)) return true;
    if (!editPermission) return true;
    return false;
};

interface CallProps {
    lead?: LeadDataObject;
    readOnly: boolean;
    setLead: (lead: LeadDataObject) => void;
    setLeadCall?: (lead: LeadDataObject) => void;
    leadActivity?: LeadActivity;
}

const CallForm = ({ lead, readOnly, setLead, leadActivity, setLeadCall }: CallProps) => {
    const [form] = Form.useForm();
    const [activityFilter, setActivityFilter] = useState<number[]>([]);
    const [activities, setActivities] = useState<LeadActivity[]>([]);

    const [data, loading, refetch] = useGqlGet<LeadDataObject>(GET_LEAD, lead?.id ?? '');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isReload, setIsReload] = useState(false);

    const [closePhoneLeadActivity] = useUpdate(CLOSE_PHONE_LEAD_ACTIVITY);
    const [createPhoneLeadActivity] = useUpdate(CREATE_PHONE_LEAD_ACTIVITY);

    const [selectActivity, setSelectActivity] = useState<LeadActivity>();
    const [smsModalVisible, setSmsModalVisible] = useState<boolean>(false);
    const [emailModalVisible, setEmailModalVisible] = useState(false);

    const [visible, setVisible] = useState(false);
    const DatePickerAny = DatePicker as any;
    const activityFilterData = useCallback((data: LeadDataObject, filter: number[]) => {
        if (data?.activities != null)
            setActivities(
                orderBy(
                    data.activities.filter(o => filter.length === 0 || filter.includes(o.contactType)),
                    a => a.callContact?.createAt ?? a._closeAt ?? a._createAt ?? 0,
                    'desc'
                )
            );
        setActivityFilter(filter);
    }, []);

    useEffect(() => {
        if (data != null) setLead(data);
    }, [data, setLead]);

    useEffect(() => {
        if (lead != null)
            setActivities(orderBy(lead.activities || [], a => a.callContact?.createAt ?? a._closeAt ?? a._createAt ?? 0, 'desc'));
    }, [lead]);

    const onSave = async () => {
        try {
            if (lead == null) return;
            setIsSubmitting(true);
            const { nextContact, prospectLevel, leadDefeated, comment } = await form.validateFields();

            let newLead: LeadDataObject;
            if (leadActivity?.id != null) {
                const input: LeadActivityClosePhoneInput = {
                    id: leadActivity.id,
                    prospectLevel,
                    leadDefeated,
                    comment,
                    nextContact
                };
                newLead = await closePhoneLeadActivity(lead.id, input);
            } else {
                const input: LeadActivityCreatePhoneInput = {
                    prospectLevel,
                    leadDefeated,
                    comment,
                    nextContact
                };
                newLead = await createPhoneLeadActivity(lead.id, input);
            }
            setLead(newLead);
            activityFilterData(newLead, activityFilter);
            if (setLeadCall) setLeadCall(newLead);
            setIsSuccess(true);
            form.resetFields();
        } catch (err) {
            if (err.message === 'calling') message.error('Activity can be closed only if the call session is over', 5);
            else {
                console.error(err);
                notification.error({ duration: 0, message: 'Something went wrong', description: err.message });
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    const reload = async () => {
        try {
            if (lead == null) return;
            setIsReload(true);
            await refetch();
        } catch (err) {
            console.error(err);
            notification.error({ duration: 0, message: 'Something went wrong', description: err.message });
        } finally {
            setIsReload(false);
        }
    };

    const closeForm = (isEdit: boolean) => {
        if (readOnly) return null;
        return isEdit ? (
            <Row>
                <Col span={6}>
                    <Form.Item
                        name="contactType"
                        label="Type"
                        rules={[{ required: true }]}
                        initialValue={LeadContactType[LeadContactType.PhoneCall]}
                        labelCol={{ offset: 0 }}
                        style={{ marginLeft: 42 }}
                    >
                        {LeadContactType[LeadContactType.PhoneCall]}
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name="prospectLevel" label="Prospect level">
                        <EnumSelect enum={LeadProspectLevel} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name="nextContact" label="Next contact">
                        <DatePickerAny format="M/D/YYYY h:mm a" minuteStep={15} showTime={true} style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name="leadDefeated" label="Mark defeated" valuePropName="checked" labelCol={{ span: 10 }}>
                        <Switch />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label="Comment" name="comment" labelCol={{ offset: 0 }} wrapperCol={{ span: 24 }} style={{ marginLeft: 21 }}>
                        <Input.TextArea placeholder="Add a comment..." style={{ width: '95.8%' }} />
                    </Form.Item>
                </Col>
                <Col>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" onClick={() => onSave()} style={{ marginLeft: 95 }} loading={isSubmitting}>
                            Close
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        ) : (
            <Row>
                <Alert message="Call activity completed" type="success" showIcon style={{ fontStyle: 'italic' }} />
            </Row>
        );
    };

    const menuItems =
        Object.keys(LeadContactType).map((key: any) => {
            if (isNaN(Number(key))) return;
            return (
                <Menu.Item key={key}>
                    <Checkbox
                        onChange={e => {
                            let filter = cloneDeep(activityFilter);
                            if (e.target.checked) {
                                filter.push(Number(key));
                            } else {
                                filter = filter.filter(o => o != Number(key));
                            }
                            if (lead) activityFilterData(lead, filter);
                        }}
                    >
                        {Number(key) === LeadContactType.PhoneCall
                            ? '📞 ' + LeadContactType[key]
                            : Number(key) === LeadContactType.Email
                            ? '📧 ' + LeadContactType[key]
                            : Number(key) === LeadContactType.SMS
                            ? '📱 ' + LeadContactType[key]
                            : Number(key) === LeadContactType.Appointment
                            ? '📅 ' + LeadContactType[key]
                            : Number(key) === LeadContactType.Fax
                            ? '📠 ' + LeadContactType[key]
                            : Number(key) === LeadContactType.Letter
                            ? '📮 ' + LeadContactType[key]
                            : '📝 ' + LeadContactType[key]}
                    </Checkbox>
                </Menu.Item>
            );
        }) ?? [];

    const contactTypeFilter = menuItems != null && (
        <Dropdown
            trigger={['click']}
            overlay={<Menu>{menuItems}</Menu>}
            visible={visible}
            onVisibleChange={setVisible}
            placement="topRight"
            getPopupContainer={() => document.getElementById('callModal') || document.body}
        >
            <Button type="text">
                <span>Type</span>
                <Badge offset={[0, -3]} count={activityFilter.length} style={{ backgroundColor: '#2a7fff', marginLeft: 5 }} />
                <DownOutlined />
            </Button>
        </Dropdown>
    );

    return (
        <>
            <Form form={form} labelCol={{ span: 9 }} wrapperCol={{ span: 14 }}>
                {closeForm(!isSuccess)}

                <Row>
                    <Card
                        id="callModal"
                        bodyStyle={{ paddingTop: 5 }}
                        size="small"
                        // title="Contact history"
                        title={
                            <>
                                <Space>
                                    <span>Contact history</span>
                                    <span>
                                        {' '}
                                        {/* <ReloadButton onClick={reload} disabled={loading} loading={isReload} /> */}
                                        <Button onClick={reload} title="Reload data" icon={<ReloadOutlined />} loading={isReload} />
                                    </span>
                                </Space>
                            </>
                        }
                        style={{ width: '100%', border: 0 }}
                        extra={contactTypeFilter}
                    >
                        <List
                            style={{ marginLeft: 10, minHeight: 250 }}
                            itemLayout="horizontal"
                            className="comment-list"
                            loading={loading || isSubmitting || isReload}
                            dataSource={activities}
                            renderItem={item => (
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={
                                            <UserAvatar
                                                size="large"
                                                firstName={item._createBy?.firstName}
                                                lastName={item._createBy?.lastName}
                                                author={item.author}
                                            />
                                        }
                                        title={
                                            <>
                                                <span>{showAuthor(item)}</span>
                                                <span style={{ marginLeft: '5px' }}>
                                                    {item.contactType === LeadContactType.PhoneCall
                                                        ? '📞'
                                                        : item.contactType === LeadContactType.Email
                                                        ? '📧'
                                                        : item.contactType === LeadContactType.SMS
                                                        ? '📱'
                                                        : item.contactType === LeadContactType.Appointment
                                                        ? '📅'
                                                        : item.contactType === LeadContactType.Fax
                                                        ? '📠'
                                                        : item.contactType === LeadContactType.Letter
                                                        ? '📮'
                                                        : '📝'}
                                                </span>
                                                {[LeadContactType.SMS, LeadContactType.PhoneCall, LeadContactType.Email].some(
                                                    o => o === item.contactType
                                                ) && (
                                                    <Tooltip
                                                        title={
                                                            item.direction == null || item.direction === Direction.Outbound
                                                                ? item.contactType === LeadContactType.SMS
                                                                    ? 'Outgoing'
                                                                    : 'Outbound'
                                                                : item.contactType === LeadContactType.SMS
                                                                ? 'Incoming'
                                                                : 'Inbound'
                                                        }
                                                    >
                                                        {item.direction == null || item.direction === Direction.Outbound ? (
                                                            <DoubleRightOutlined />
                                                        ) : (
                                                            <DoubleLeftOutlined />
                                                        )}
                                                    </Tooltip>
                                                )}
                                                <Tooltip
                                                    title={dayjs(
                                                        item?.callContact?.createAt
                                                            ? item.callContact.createAt
                                                            : item._closeAt || item._createAt
                                                    ).format('lll')}
                                                >
                                                    <span
                                                        style={{
                                                            width: 200,
                                                            marginLeft: 5,
                                                            fontSize: '13px',
                                                            color: 'rgba(0, 0, 0, 0.45)'
                                                        }}
                                                    >
                                                        {dayjs(
                                                            item?.callContact?.createAt
                                                                ? item.callContact.createAt
                                                                : item._closeAt || item._createAt
                                                        ).fromNow()}
                                                    </span>
                                                </Tooltip>
                                                {item?.callContact?.result != null && (
                                                    <span style={{ marginLeft: 5 }}>
                                                        <StatusTag
                                                            value={item.callContact.result}
                                                            enum={CallResult}
                                                            colors={['pink', 'green', 'orange', 'cyan', 'purple']}
                                                        />
                                                    </span>
                                                )}
                                                {item.contactType === LeadContactType.Email && item.direction === Direction.Inbound && (
                                                    <Button
                                                        size="small"
                                                        type="link"
                                                        style={{ color: '#2a7fff' }}
                                                        onClick={() => {
                                                            setSelectActivity(item);
                                                            setEmailModalVisible(true);
                                                        }}
                                                    >
                                                        Reply
                                                    </Button>
                                                )}
                                                {item.contactType === LeadContactType.SMS && item.direction === Direction.Inbound && (
                                                    <Button
                                                        size="small"
                                                        type="link"
                                                        style={{ color: '#2a7fff' }}
                                                        onClick={() => {
                                                            setSelectActivity(item);
                                                            setSmsModalVisible(true);
                                                        }}
                                                    >
                                                        Reply
                                                    </Button>
                                                )}
                                            </>
                                        }
                                        description={
                                            item.closeComment === null && item.comment === null ? (
                                                <span style={{ color: 'rgba(0, 0, 0, 0.85)', fontStyle: 'italic' }}>Empty comment</span>
                                            ) : (
                                                <span style={{ whiteSpace: 'pre-wrap', color: 'rgba(0, 0, 0, 0.85)' }}>
                                                    {item.closeComment ? item.closeComment : item.comment}
                                                </span>
                                            )
                                        }
                                    />
                                </List.Item>
                            )}
                        />
                    </Card>
                </Row>
            </Form>

            <SmsCustomerModal
                lead={lead}
                //activity={selectActivity}
                visible={smsModalVisible}
                onCancel={() => {
                    setSmsModalVisible(false);
                }}
                setLeadCall={setLead}
                isReply={true}
            />

            <EmailCustomerModal
                lead={lead}
                activity={selectActivity}
                visible={emailModalVisible}
                onCancel={() => {
                    setEmailModalVisible(false);
                }}
                setLeadCall={setLead}
                isReply={true}
            />
        </>
    );
};

interface Props {
    lead?: LeadDataObject;
    activity?: LeadActivity;
    visible: boolean;
    setLeadCall?: (lead: LeadDataObject) => void;
    onCancel: () => void;
}

const CallCustomerModal = ({ lead: leadDataObject, activity, visible, onCancel, setLeadCall }: Props) => {
    const user = useContext(UserContext);
    const editPermission = useMemo(() => (user?.roles.includes('admin') || user?.permissions.includes('lead:edit')) ?? false, [user]);
    const [readOnly, setReadOnly] = useState(false);

    const [callButton, setCallButton] = useState(false);

    const [lead, setLead] = useState<LeadDataObject>();
    const [leadActivity, setLeadActivity] = useState<LeadActivity>();

    const [createCallLog, loading] = useCreate(CREATE_CALL_LOG);

    useEffect(() => {
        setLead(leadDataObject);
        setLeadActivity(activity);

        if (activity && activity?.callContact?.id) setCallButton(true);
        else setCallButton(false);

        setReadOnly(commentDisabled(editPermission, leadDataObject?.status));
    }, [activity, editPermission, leadDataObject]);

    const onCall = async () => {
        setCallButton(true);
        try {
            if (!leadDataObject?.customer?.contactInfo) return;
            const phone = leadDataObject.customer.contactInfo.phoneNumbers?.find(
                phoneNumber => phoneNumber.description === 'Mobile'
            )?.value;
            if (phone == null) return;

            const result = await createCallLog({ lead: leadDataObject.id, phone, activityId: activity?.id });
            const newLead: LeadDataObject = result.data;
            setLead(newLead);
            setLeadActivity(newLead?.activities?.find(o => o.callContact?.id == result.contactId));
            if (setLeadCall) setLeadCall(newLead);
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <Modal
            title={
                <>
                    {displayCustomer(leadDataObject?.customer)}
                    <span style={{ marginLeft: 10 }}>{getPhoneNumber(leadDataObject?.customer, 'Mobile')}</span>
                    <Popconfirm
                        disabled={loading || callButton || readOnly}
                        placement="right"
                        title={'Call ' + getPhoneNumber(leadDataObject?.customer, 'Mobile') + '?'}
                        okText="Yes"
                        cancelText="No"
                        onConfirm={onCall}
                    >
                        <Button type="default" style={{ marginLeft: 10 }} disabled={callButton || readOnly}>
                            <PhoneTwoTone twoToneColor="#95de64" />
                            {callButton && leadActivity == null ? 'Calling' : 'Call'}
                        </Button>
                    </Popconfirm>
                </>
            }
            visible={visible}
            width={1200}
            onCancel={() => {
                if (!activity) {
                    setLeadActivity(undefined);
                    setCallButton(false);
                }
                onCancel();
            }}
            destroyOnClose={true}
            footer={null}
            // footer={
            //     true ? (
            //         <Popconfirm
            //             disabled={isSubmitting}
            //             placement="right"
            //             title="Discard this call record?"
            //             okText="Yes"
            //             cancelText="No"
            //             onConfirm={onCancel}
            //         >
            //             <Button type="text">Close</Button>
            //         </Popconfirm>
            //     ) : (
            //         <Button type="text" onClick={onCancel}>
            //             Close
            //         </Button>
            //     )
            // }
            maskClosable={false}
            keyboard={false}
            centered
        >
            <div style={{ height: 560, overflowX: 'hidden' }}>
                <CallForm lead={lead} leadActivity={leadActivity} setLeadCall={setLeadCall} setLead={setLead} readOnly={readOnly} />
            </div>
        </Modal>
    );
};

export default CallCustomerModal;
