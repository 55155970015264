import { UserDataObject } from '../../admin/user/models';

export enum TeamType {
    Sales,
    Service,
    Technician
}

export interface Team {
    id: string;
    type: TeamType;
    name: string;
    members: UserDataObject[];
}

export interface TeamDataObject extends Team {
    enabled: boolean;
    notAvailable?: UserDataObject[];
    _updateAt?: number;
}

export type TeamCreateInput = Partial<Pick<TeamDataObject, 'type' | 'name' | 'enabled'>> & {
    members: string[];
};

export type TeamUpdateInput = Partial<Pick<TeamDataObject, 'type' | 'name' | 'enabled'>> & {
    members?: string[];
    notAvailable?: string[];
};

export interface UserTeam {
    id: string;
    user: UserDataObject;
    teams?: TeamDataObject[];
}
