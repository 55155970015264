import { Button, Col, Modal, Row, Space, Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { gql } from '@apollo/client';

import { useSearch } from '../../../hooks';
import UserAvatar from '../../components/UserAvatar';
import { Direction } from '../../crm/lead/models';
import { ReloadButton } from '../components';
import { CustomerHistory, CustomerHistoryType } from './models';
import styles from './styles.module.scss';

export const SEARCH_CUSTOMER_HISTORIES = gql`
    query SearchCustomerHistories($filter: CustomerHistorySearchInput, $sort: [String], $page: Int, $pageSize: Int) {
        searchCustomerHistories(filter: $filter, sort: $sort, page: $page, pageSize: $pageSize) {
            total
            data {
                __typename
                id
                scheduleTime
                type
                comment
                salesperson
                salespersonName {
                    firstName
                    lastName
                }
                customer
                customerName {
                    firstName
                    lastName
                }
                lead
                direction
            }
        }
    }
`;

const Column = (props: ColumnProps<CustomerHistory>) => <Table.Column<CustomerHistory> {...props} />;

interface Filter {
    _search?: string;
    _noSearch?: boolean;
    customer: string;
}

interface Props {
    customer: string;
    module: 'Lead' | 'Customer';
}

const CustomerHistoryList = ({ customer }: Props) => {
    const filter: Filter = {
        _noSearch: true,
        customer: customer
    };

    const { loading, data, pagination, setFilter, refresh } = useSearch<Filter>(SEARCH_CUSTOMER_HISTORIES, filter, ['!scheduleTime']);

    const [customerHistories, setCustomerHistories] = useState<CustomerHistory[]>([]);
    const [selectCustomerHistory, setSelectCustomerHistory] = useState<CustomerHistory>();
    const [selectorEmailVisible, setSelectorEmailVisible] = useState(false);

    useEffect(() => {
        if (data == null) return;
        setCustomerHistories(data as CustomerHistory[]);
    }, [data]);

    useEffect(() => {
        setFilter('customer', customer);
    }, [customer, setFilter]);

    const renderType = (customerHistory: CustomerHistory) => {
        switch (customerHistory.type) {
            case CustomerHistoryType.PhoneCall:
                return `📞 Phone (${Direction[customerHistory.direction ?? Direction.Outbound]})`;
            case CustomerHistoryType.Email:
                return `📧 Email (${Direction[customerHistory.direction ?? Direction.Outbound]})`;
            case CustomerHistoryType.SMS:
                return `📱 SMS (${customerHistory.direction === Direction.Inbound ? 'Incoming' : 'Outgoing'})`;
            case CustomerHistoryType.Appointment:
                return '📅 Appointment';
            case CustomerHistoryType.Fax:
                return '📠 Fax';
            case CustomerHistoryType.Letter:
                return '📮 Letter';
            case CustomerHistoryType.Task:
                return '📝 Task';
            default:
                return '📝 Comment';
        }
    };

    return (
        <>
            <Row gutter={0}>
                <Col span={3}>
                    <ReloadButton onClick={() => refresh()} disabled={loading} />
                </Col>
            </Row>
            <Row>
                <Table
                    rowKey="id"
                    size="small"
                    dataSource={customerHistories}
                    loading={loading}
                    className={styles.table}
                    pagination={pagination}
                >
                    <Column
                        width={130}
                        title="Date"
                        key="date"
                        render={(_, record) => {
                            // (record.scheduleTime != null ? dayjs(record.scheduleTime).format('L') : null);
                            if (record.scheduleTime == null) return;
                            const createAt = dayjs(record.scheduleTime);
                            if (dayjs().year() === createAt.year()) return createAt.format('MM/DD h:mm a');
                            else return createAt.format('MM/DD/YYYY h:mm a');
                        }}
                    />
                    {/* <Column
        width={120}
        title="Time"
        key="time"
        render={(_, record) => (record.scheduleTime != null ? dayjs(record.scheduleTime).format('LT') : null)}
    /> */}
                    {/* <Column
        width={100}
        title="Sales rep"
        key="salesperson"
        render={(_, record) =>
            record.salesperson != null ? `${record.salespersonName.firstName} ${record.salespersonName.lastName}` : null
        }
    /> */}
                    <Column
                        width={80}
                        align="center"
                        ellipsis={true}
                        title="Sales rep"
                        render={(_, record) => {
                            if (record.salespersonName) {
                                return (
                                    <UserAvatar
                                        size="small"
                                        firstName={record.salespersonName.firstName}
                                        lastName={record.salespersonName.lastName}
                                    />
                                );
                            }
                        }}
                    />
                    <Column width={200} title="Type" key="type" render={(_, record) => renderType(record)} />

                    <Column
                        key="comment"
                        ellipsis={true}
                        title="Comment"
                        render={(_, record) => {
                            if (record.type === CustomerHistoryType.Email) {
                                const comment = record.comment ?? '';
                                return comment.length > 50 ? (
                                    <a
                                        style={{ color: '#2a7fff' }}
                                        onClick={() => {
                                            setSelectCustomerHistory(record);
                                            setSelectorEmailVisible(true);
                                        }}
                                    >
                                        <span>{comment.substring(0, comment.indexOf('\n'))}</span>
                                    </a>
                                ) : (
                                    <span>{comment}</span>
                                );
                            } else return record.comment;
                        }}
                    />

                    <Column
                        width={80}
                        title="Actions"
                        render={(_, record) => (
                            <Space size={0} wrap>
                                <Button size="small" type="link">
                                    <Link to={`/crm/lead/${record.lead}`} target="_blank">
                                        View
                                    </Link>
                                </Button>
                            </Space>
                        )}
                    />
                </Table>
                <Modal
                    title={'Mail content'}
                    width={1000}
                    visible={selectorEmailVisible}
                    onCancel={() => setSelectorEmailVisible(false)}
                    footer={null}
                >
                    <div style={{ height: 600, overflowX: 'hidden' }}>
                        <div style={{ whiteSpace: 'pre-wrap' }}>{selectCustomerHistory?.comment}</div>
                    </div>
                </Modal>
            </Row>
        </>
    );
};

export default CustomerHistoryList;
