import { gql } from '@apollo/client';

export const GET_LEAD = gql`
    query GetLead($id: ID!) {
        getLead(id: $id) {
            id
            status
            prospectLevel
            source
            summary
            lostReason
            customer {
                id
                type
                gender
                name {
                    type
                    companyName
                    firstName
                    lastName
                    middleName
                    suffix
                    title
                }
                contactInfo {
                    phoneNumber {
                        value
                        ext
                        description
                        preferred
                    }
                    phoneNumbers {
                        value
                        ext
                        description
                        preferred
                    }
                    email {
                        value
                        ext
                        description
                        preferred
                    }
                    address {
                        state
                        city
                        county
                        street
                    }
                }
                partyIds {
                    type
                    value
                    expirationDate
                    issuingState
                }
            }
            opportunities {
                id
                make
                model
                color
                purchaseTimeframe
                testDrived
                demonstrated
                lookingForUsed
                description
                stockNo
                vin
                vehicle {
                    id
                    vin
                    model {
                        name
                        make
                    }
                    year
                    licenseNumber
                    mileage
                    specs {
                        color
                        bodyStyle
                    }
                }
            }
            tradeIns {
                id
                year
                make
                model
                mileage
                vin
            }
            activities {
                id
                title
                contactType
                automatic
                messageTemplateId
                contactInfoName
                comment
                closeComment
                dueTime
                scheduleTime
                actualTime
                _createAt
                _closeAt
                result
                pickedUp
                afterContactComment
                author
                _createBy {
                    id
                    firstName
                    lastName
                }
                callContact {
                    id
                    result
                    createAt
                    recording {
                        id
                    }
                }
                message {
                    id
                    sendAt
                }
                direction
            }
            assignee {
                id
                firstName
                lastName
                email
            }
            lastContactAt
            contactStatus
            _createAt
            _updateAt
            _closeAt
        }
    }
`;

export const CREATE_LEAD_ACTIVITY = gql`
    mutation CreateLeadActivity($id: ID!, $input: LeadActivityCreateInput!) {
        createLeadActivity(id: $id, input: $input) {
            __typename
            id
            status
            prospectLevel
            source
            summary
            lostReason
            customer {
                id
                type
                gender
                name {
                    type
                    companyName
                    firstName
                    lastName
                    middleName
                    suffix
                    title
                }
                contactInfo {
                    phoneNumber {
                        value
                        ext
                        description
                        preferred
                    }
                    phoneNumbers {
                        value
                        ext
                        description
                        preferred
                    }
                    email {
                        value
                        ext
                        description
                        preferred
                    }
                    address {
                        state
                        city
                        county
                        street
                    }
                }
            }
            opportunities {
                id
                make
                model
                color
                purchaseTimeframe
                testDrived
                demonstrated
                lookingForUsed
                description
                stockNo
                vin
                vehicle {
                    id
                    vin
                    model {
                        name
                        make
                    }
                    year
                    licenseNumber
                    mileage
                    specs {
                        color
                        bodyStyle
                    }
                }
            }
            tradeIns {
                id
                year
                make
                model
                mileage
                vin
            }
            activities {
                id
                title
                contactType
                automatic
                messageTemplateId
                contactInfoName
                comment
                closeComment
                dueTime
                scheduleTime
                actualTime
                _createAt
                _closeAt
                result
                pickedUp
                afterContactComment
                author
                _createBy {
                    id
                    firstName
                    lastName
                }
                callContact {
                    id
                    result
                    createAt
                    recording {
                        id
                    }
                }
                message {
                    id
                    sendAt
                }
                direction
            }
            assignee {
                id
                firstName
                lastName
            }
            lastContactAt
            contactStatus
            _createAt
            _updateAt
            _closeAt
        }
    }
`;

export const CLOSE_LEAD_ACTIVITY = gql`
    mutation CloseLeadActivity($id: ID!, $input: LeadActivityCloseInput!) {
        closeLeadActivity(id: $id, input: $input) {
            __typename
            id
            status
            prospectLevel
            source
            summary
            lostReason
            customer {
                id
                type
                gender
                name {
                    type
                    companyName
                    firstName
                    lastName
                    middleName
                    suffix
                    title
                }
                contactInfo {
                    phoneNumber {
                        value
                        ext
                        description
                        preferred
                    }
                    phoneNumbers {
                        value
                        ext
                        description
                        preferred
                    }
                    email {
                        value
                        ext
                        description
                        preferred
                    }
                    address {
                        state
                        city
                        county
                        street
                    }
                }
            }
            opportunities {
                id
                make
                model
                color
                purchaseTimeframe
                testDrived
                demonstrated
                lookingForUsed
                description
                stockNo
                vin
                vehicle {
                    id
                    vin
                    model {
                        name
                        make
                    }
                    year
                    licenseNumber
                    mileage
                    specs {
                        color
                        bodyStyle
                    }
                }
            }
            tradeIns {
                id
                year
                make
                model
                mileage
                vin
            }
            activities {
                id
                title
                contactType
                automatic
                messageTemplateId
                contactInfoName
                comment
                closeComment
                dueTime
                scheduleTime
                actualTime
                _createAt
                _closeAt
                result
                pickedUp
                afterContactComment
                author
                _createBy {
                    id
                    firstName
                    lastName
                }
                callContact {
                    id
                    result
                    createAt
                    recording {
                        id
                    }
                }
                message {
                    id
                    sendAt
                }
                direction
            }
            assignee {
                id
                firstName
                lastName
            }
            lastContactAt
            contactStatus
            _createAt
            _updateAt
            _closeAt
        }
    }
`;

export const UPDATE_LEAD = gql`
    mutation UpdateLead($id: ID!, $input: LeadUpdateInput!) {
        updateLead(id: $id, input: $input) {
            __typename
            id
            status
            prospectLevel
            source
            summary
            lostReason
            customer {
                id
                type
                gender
                name {
                    type
                    companyName
                    firstName
                    lastName
                    middleName
                    suffix
                    title
                }
                contactInfo {
                    phoneNumber {
                        value
                        ext
                        description
                        preferred
                    }
                    phoneNumbers {
                        value
                        ext
                        description
                        preferred
                    }
                    email {
                        value
                        ext
                        description
                        preferred
                    }
                    address {
                        state
                        city
                        county
                        street
                    }
                }
            }
            opportunities {
                id
                make
                model
                color
                purchaseTimeframe
                testDrived
                demonstrated
                lookingForUsed
                description
                stockNo
                vin
                vehicle {
                    id
                    vin
                    model {
                        name
                        make
                    }
                    year
                    licenseNumber
                    mileage
                    specs {
                        color
                        bodyStyle
                    }
                }
            }
            tradeIns {
                id
                year
                make
                model
                mileage
                vin
            }
            activities {
                id
                title
                contactType
                automatic
                messageTemplateId
                contactInfoName
                comment
                closeComment
                dueTime
                scheduleTime
                actualTime
                _createAt
                _closeAt
                result
                pickedUp
                afterContactComment
                author
                _createBy {
                    id
                    firstName
                    lastName
                }
                callContact {
                    id
                    result
                    createAt
                    recording {
                        id
                    }
                }
                message {
                    id
                    sendAt
                }
                direction
            }
            assignee {
                id
                firstName
                lastName
            }
            lastContactAt
            contactStatus
            _createAt
            _updateAt
            _closeAt
        }
    }
`;

export const UPDATE_CUSTOMER = gql`
    mutation UpdateCustomer($id: ID!, $input: CustomerUpdateInput!) {
        updateCustomer(id: $id, input: $input) {
            __typename
            id
            type
            gender
            name {
                type
                companyName
                firstName
                lastName
                middleName
                suffix
                title
            }
            contactInfo {
                phoneNumber {
                    value
                    ext
                    description
                    preferred
                }
                phoneNumbers {
                    value
                    ext
                    description
                    preferred
                }
                email {
                    value
                    ext
                    description
                    preferred
                }
            }
        }
    }
`;

export const SEARCH_CUSTOMERS = gql`
    query SearchCustomers($filter: CustomerSearchInput, $sort: [String], $page: Int, $pageSize: Int) {
        searchCustomers(filter: $filter, sort: $sort, page: $page, pageSize: $pageSize) {
            total
            data {
                id
                type
                gender
                name {
                    type
                    companyName
                    firstName
                    lastName
                    middleName
                    suffix
                    title
                }
                contactInfo {
                    phoneNumber {
                        value
                        ext
                        description
                        preferred
                    }
                    phoneNumbers {
                        value
                        ext
                        description
                        preferred
                    }
                    email {
                        value
                        ext
                        description
                        preferred
                    }
                }
                employment {
                    employerName
                }
                _dataStatus
                _createAt
                _updateAt
            }
        }
    }
`;

export const SEARCH_LEADS = gql`
    query SearchLeads($filter: LeadSearchInput, $sort: [String], $page: Int, $pageSize: Int) {
        searchLeads(filter: $filter, sort: $sort, page: $page, pageSize: $pageSize) {
            total
            data {
                id
                status
                prospectLevel
                source
                summary
                lostReason
                customer {
                    id
                    type
                    gender
                    name {
                        type
                        companyName
                        firstName
                        lastName
                        middleName
                        suffix
                        title
                    }
                    contactInfo {
                        phoneNumber {
                            value
                            ext
                            description
                            preferred
                        }
                        phoneNumbers {
                            value
                            ext
                            description
                            preferred
                        }
                        email {
                            value
                            ext
                            description
                            preferred
                        }
                        address {
                            state
                        }
                    }
                }
                opportunities {
                    id
                    make
                    model
                    color
                    purchaseTimeframe
                    testDrived
                    demonstrated
                    lookingForUsed
                    description
                    stockNo
                    vin
                    vehicle {
                        id
                        vin
                        model {
                            name
                            make
                        }
                        year
                        licenseNumber
                        mileage
                        specs {
                            color
                            bodyStyle
                        }
                    }
                }
                lastContactAt
                contactStatus
                assignee {
                    id
                    firstName
                    lastName
                }
                _createAt
                _updateAt
                _closeAt
            }
        }
    }
`;

export const CREATE_LEAD = gql`
    mutation CreateLead($input: LeadCreateInput!) {
        createLead(input: $input) {
            __typename
            id
            status
            prospectLevel
            source
            summary
            lostReason
            customer {
                id
                type
                gender
                name {
                    type
                    companyName
                    firstName
                    lastName
                    middleName
                    suffix
                    title
                }
                contactInfo {
                    phoneNumber {
                        value
                        ext
                        description
                        preferred
                    }
                    phoneNumbers {
                        value
                        ext
                        description
                        preferred
                    }
                    email {
                        value
                        ext
                        description
                        preferred
                    }
                    address {
                        state
                        city
                        county
                        street
                    }
                }
            }
            opportunities {
                id
                make
                model
                color
                purchaseTimeframe
                testDrived
                demonstrated
                lookingForUsed
                description
                stockNo
                vin
                vehicle {
                    id
                    vin
                    model {
                        name
                        make
                    }
                    year
                    licenseNumber
                    mileage
                    specs {
                        color
                        bodyStyle
                    }
                }
            }
            tradeIns {
                id
                year
                make
                model
                mileage
                vin
            }
            activities {
                id
                title
                contactType
                automatic
                messageTemplateId
                contactInfoName
                comment
                closeComment
                dueTime
                scheduleTime
                actualTime
                _createAt
                _closeAt
                result
                pickedUp
                afterContactComment
                author
                _createBy {
                    id
                    firstName
                    lastName
                }
                callContact {
                    id
                    result
                    createAt
                    recording {
                        id
                    }
                }
                message {
                    id
                    sendAt
                }
                direction
            }
            assignee {
                id
                firstName
                lastName
            }
            lastContactAt
            contactStatus
            _createAt
            _updateAt
            _closeAt
        }
    }
`;

export const CREATE_LEAD_VALIDATE = gql`
    mutation CreateLeadValidate($input: LeadCreateValidateInput!) {
        createLeadValidate(input: $input) {
            __typename
            id
            status
            prospectLevel
            source
            summary
            lostReason
            customer {
                id
                type
                gender
                name {
                    type
                    companyName
                    firstName
                    lastName
                    middleName
                    suffix
                    title
                }
                contactInfo {
                    phoneNumber {
                        value
                        ext
                        description
                        preferred
                    }
                    phoneNumbers {
                        value
                        ext
                        description
                        preferred
                    }
                    email {
                        value
                        ext
                        description
                        preferred
                    }
                    address {
                        state
                        city
                        county
                        street
                    }
                }
            }
            opportunities {
                id
                make
                model
                color
                purchaseTimeframe
                testDrived
                demonstrated
                lookingForUsed
                description
                stockNo
                vin
                vehicle {
                    id
                    vin
                    model {
                        name
                        make
                    }
                    year
                    licenseNumber
                    mileage
                    specs {
                        color
                        bodyStyle
                    }
                }
            }
            tradeIns {
                id
                year
                make
                model
                mileage
                vin
            }
            activities {
                id
                title
                contactType
                automatic
                messageTemplateId
                contactInfoName
                comment
                closeComment
                dueTime
                scheduleTime
                actualTime
                _createAt
                _closeAt
                result
                pickedUp
                afterContactComment
                author
                _createBy {
                    id
                    firstName
                    lastName
                }
                callContact {
                    id
                    result
                    createAt
                    recording {
                        id
                    }
                }
                message {
                    id
                    sendAt
                }
                direction
            }
            assignee {
                id
                firstName
                lastName
            }
            lastContactAt
            contactStatus
            _createAt
            _updateAt
            _closeAt
        }
    }
`;

export const CLOSE_LEAD = gql`
    mutation CloseLead($id: ID!, $input: LeadCloseInput!) {
        closeLead(id: $id, input: $input) {
            __typename
            id
            status
            prospectLevel
            source
            summary
            lostReason
            customer {
                id
                type
                gender
                name {
                    type
                    companyName
                    firstName
                    lastName
                    middleName
                    suffix
                    title
                }
                contactInfo {
                    phoneNumber {
                        value
                        ext
                        description
                        preferred
                    }
                    phoneNumbers {
                        value
                        ext
                        description
                        preferred
                    }
                    email {
                        value
                        ext
                        description
                        preferred
                    }
                    address {
                        state
                        city
                        county
                        street
                    }
                }
            }
            opportunities {
                id
                make
                model
                color
                purchaseTimeframe
                testDrived
                demonstrated
                lookingForUsed
                description
                stockNo
                vin
                vehicle {
                    id
                    vin
                    model {
                        name
                        make
                    }
                    year
                    licenseNumber
                    mileage
                    specs {
                        color
                        bodyStyle
                    }
                }
            }
            tradeIns {
                id
                year
                make
                model
                mileage
                vin
            }
            activities {
                id
                title
                contactType
                automatic
                messageTemplateId
                contactInfoName
                comment
                closeComment
                dueTime
                scheduleTime
                actualTime
                _createAt
                _closeAt
                result
                pickedUp
                afterContactComment
                author
                _createBy {
                    id
                    firstName
                    lastName
                }
                callContact {
                    id
                    result
                    createAt
                    recording {
                        id
                    }
                }
                message {
                    id
                    sendAt
                }
                direction
            }
            assignee {
                id
                firstName
                lastName
            }
            lastContactAt
            contactStatus
            _createAt
            _updateAt
            _closeAt
        }
    }
`;

export const CONVERT_LEAD = gql`
    mutation ConvertLead($id: ID!) {
        convertLead(id: $id) {
            __typename
            id
            status
            prospectLevel
            source
            summary
            lostReason
            customer {
                id
                type
                gender
                name {
                    type
                    companyName
                    firstName
                    lastName
                    middleName
                    suffix
                    title
                }
                contactInfo {
                    phoneNumber {
                        value
                        ext
                        description
                        preferred
                    }
                    phoneNumbers {
                        value
                        ext
                        description
                        preferred
                    }
                    email {
                        value
                        ext
                        description
                        preferred
                    }
                    address {
                        state
                        city
                        county
                        street
                    }
                }
            }
            opportunities {
                id
                make
                model
                color
                purchaseTimeframe
                testDrived
                demonstrated
                lookingForUsed
                description
                stockNo
                vin
                vehicle {
                    id
                    vin
                    model {
                        name
                        make
                    }
                    year
                    licenseNumber
                    mileage
                    specs {
                        color
                        bodyStyle
                    }
                }
            }
            tradeIns {
                id
                year
                make
                model
                mileage
                vin
            }
            activities {
                id
                title
                contactType
                automatic
                messageTemplateId
                contactInfoName
                comment
                closeComment
                dueTime
                scheduleTime
                actualTime
                _createAt
                _closeAt
                result
                pickedUp
                afterContactComment
                author
                _createBy {
                    id
                    firstName
                    lastName
                }
                callContact {
                    id
                    result
                    createAt
                    recording {
                        id
                    }
                }
                message {
                    id
                    sendAt
                }
                direction
            }
            assignee {
                id
                firstName
                lastName
            }
            lastContactAt
            contactStatus
            _createAt
            _updateAt
            _closeAt
        }
    }
`;

export const REOPEN_LEAD = gql`
    mutation ReopenLead($id: ID!) {
        reopenLead(id: $id) {
            __typename
            id
            status
            prospectLevel
            source
            summary
            lostReason
            customer {
                id
                type
                gender
                name {
                    type
                    companyName
                    firstName
                    lastName
                    middleName
                    suffix
                    title
                }
                contactInfo {
                    phoneNumber {
                        value
                        ext
                        description
                        preferred
                    }
                    phoneNumbers {
                        value
                        ext
                        description
                        preferred
                    }
                    email {
                        value
                        ext
                        description
                        preferred
                    }
                    address {
                        state
                        city
                        county
                        street
                    }
                }
            }
            opportunities {
                id
                make
                model
                color
                purchaseTimeframe
                testDrived
                demonstrated
                lookingForUsed
                description
                stockNo
                vin
                vehicle {
                    id
                    vin
                    model {
                        name
                        make
                    }
                    year
                    licenseNumber
                    mileage
                    specs {
                        color
                        bodyStyle
                    }
                }
            }
            tradeIns {
                id
                year
                make
                model
                mileage
                vin
            }
            activities {
                id
                title
                contactType
                automatic
                messageTemplateId
                contactInfoName
                comment
                closeComment
                dueTime
                scheduleTime
                actualTime
                _createAt
                _closeAt
                result
                pickedUp
                afterContactComment
                author
                _createBy {
                    id
                    firstName
                    lastName
                }
                callContact {
                    id
                    result
                    createAt
                    recording {
                        id
                    }
                }
                message {
                    id
                    sendAt
                }
                direction
            }
            assignee {
                id
                firstName
                lastName
            }
            lastContactAt
            contactStatus
            _createAt
            _updateAt
            _closeAt
        }
    }
`;

export const ASSIGN_LEAD = gql`
    mutation AssignLead($ids: [ID!]!, $input: LeadAssignInput!) {
        assignLead(ids: $ids, input: $input) {
            __typename
            id
            status
            prospectLevel
            source
            summary
            lostReason
            customer {
                id
                type
                gender
                name {
                    type
                    companyName
                    firstName
                    lastName
                    middleName
                    suffix
                    title
                }
                contactInfo {
                    phoneNumber {
                        value
                        ext
                        description
                        preferred
                    }
                    phoneNumbers {
                        value
                        ext
                        description
                        preferred
                    }
                    email {
                        value
                        ext
                        description
                        preferred
                    }
                    address {
                        state
                        city
                        county
                        street
                    }
                }
            }
            opportunities {
                id
                make
                model
                color
                purchaseTimeframe
                testDrived
                demonstrated
                lookingForUsed
                description
                stockNo
                vin
                vehicle {
                    id
                    vin
                    model {
                        name
                        make
                    }
                    year
                    licenseNumber
                    mileage
                    specs {
                        color
                        bodyStyle
                    }
                }
            }
            tradeIns {
                id
                year
                make
                model
                mileage
                vin
            }
            activities {
                id
                title
                contactType
                automatic
                messageTemplateId
                contactInfoName
                comment
                closeComment
                dueTime
                scheduleTime
                actualTime
                _createAt
                _closeAt
                result
                pickedUp
                afterContactComment
                author
                _createBy {
                    id
                    firstName
                    lastName
                }
                callContact {
                    id
                    result
                    createAt
                    recording {
                        id
                    }
                }
                message {
                    id
                    sendAt
                }
                direction
            }
            assignee {
                id
                firstName
                lastName
            }
            lastContactAt
            contactStatus
            _createAt
            _updateAt
            _closeAt
        }
    }
`;

export const SEARCH_USERS = gql`
    query SearchUsers($filter: UserSearchInput, $sort: [String], $page: Int, $pageSize: Int) {
        searchUsers(filter: $filter, sort: $sort, page: $page, pageSize: $pageSize) {
            total
            data {
                id
                firstName
                lastName
                email
                phone
                accountStatus
                lastSignInAt
                roles
                _updateAt
            }
        }
    }
`;

export const SEARCH_TEAMS = gql`
    query SearchTeams($filter: TeamSearchInput, $sort: [String], $page: Int, $pageSize: Int) {
        searchTeams(filter: $filter, sort: $sort, page: $page, pageSize: $pageSize) {
            total
            data {
                id
                type
                name
                enabled
                members {
                    id
                    firstName
                    lastName
                    email
                    phone
                    accountStatus
                    lastSignInAt
                    roles
                    _updateAt
                }
            }
        }
    }
`;

export const ABANDON_LEAD = gql`
    mutation DeleteLead($id: ID!) {
        deleteLead(id: $id)
    }
`;

export const GET_USER = gql`
    query GetUser($id: ID!) {
        getUser(id: $id) {
            id
            dealer
            email
            firstName
            lastName
            phone
            callAgentId
        }
    }
`;

export const CREATE_SMS_LOG = gql`
    mutation CreateSMSLog($input: SMSLogCreateInput!) {
        createSMSLog(input: $input) {
            __typename
            id
            status
            prospectLevel
            source
            summary
            lostReason
            customer {
                id
                type
                gender
                name {
                    type
                    companyName
                    firstName
                    lastName
                    middleName
                    suffix
                    title
                }
                contactInfo {
                    phoneNumber {
                        value
                        ext
                        description
                        preferred
                    }
                    phoneNumbers {
                        value
                        ext
                        description
                        preferred
                    }
                    email {
                        value
                        ext
                        description
                        preferred
                    }
                    address {
                        state
                        city
                        county
                        street
                    }
                }
            }
            opportunities {
                id
                make
                model
                color
                purchaseTimeframe
                testDrived
                demonstrated
                lookingForUsed
                description
                stockNo
                vin
                vehicle {
                    id
                    vin
                    model {
                        name
                        make
                    }
                    year
                    licenseNumber
                    mileage
                    specs {
                        color
                        bodyStyle
                    }
                }
            }
            tradeIns {
                id
                year
                make
                model
                mileage
                vin
            }
            activities {
                id
                title
                contactType
                automatic
                messageTemplateId
                contactInfoName
                comment
                closeComment
                dueTime
                scheduleTime
                actualTime
                _createAt
                _closeAt
                result
                pickedUp
                afterContactComment
                author
                _createBy {
                    id
                    firstName
                    lastName
                }
                callContact {
                    id
                    result
                    createAt
                    recording {
                        id
                    }
                }
                message {
                    id
                    sendAt
                }
                direction
            }
            assignee {
                id
                firstName
                lastName
            }
            lastContactAt
            contactStatus
            _createAt
            _updateAt
            _closeAt
        }
    }
`;

export const CREATE_CALL_LOG = gql`
    mutation CreateCallLog($input: CallLogCreateInput!) {
        createCallLog(input: $input) {
            contactId
            data {
                __typename
                __typename
                id
                status
                prospectLevel
                source
                summary
                lostReason
                customer {
                    id
                    type
                    gender
                    name {
                        type
                        companyName
                        firstName
                        lastName
                        middleName
                        suffix
                        title
                    }
                    contactInfo {
                        phoneNumber {
                            value
                            ext
                            description
                            preferred
                        }
                        phoneNumbers {
                            value
                            ext
                            description
                            preferred
                        }
                        email {
                            value
                            ext
                            description
                            preferred
                        }
                        address {
                            state
                            city
                            county
                            street
                        }
                    }
                }
                opportunities {
                    id
                    make
                    model
                    color
                    purchaseTimeframe
                    testDrived
                    demonstrated
                    lookingForUsed
                    description
                    stockNo
                    vin
                    vehicle {
                        id
                        vin
                        model {
                            name
                            make
                        }
                        year
                        licenseNumber
                        mileage
                        specs {
                            color
                            bodyStyle
                        }
                    }
                }
                tradeIns {
                    id
                    year
                    make
                    model
                    mileage
                    vin
                }
                activities {
                    id
                    title
                    contactType
                    automatic
                    messageTemplateId
                    contactInfoName
                    comment
                    closeComment
                    dueTime
                    scheduleTime
                    actualTime
                    _createAt
                    _closeAt
                    result
                    pickedUp
                    afterContactComment
                    author
                    _createBy {
                        id
                        firstName
                        lastName
                    }
                    callContact {
                        id
                        result
                        createAt
                        recording {
                            id
                        }
                    }
                    message {
                        id
                        sendAt
                    }
                    direction
                }
                assignee {
                    id
                    firstName
                    lastName
                }
                lastContactAt
                contactStatus
                _createAt
                _updateAt
                _closeAt
            }
        }
    }
`;

export const CLOSE_PHONE_LEAD_ACTIVITY = gql`
    mutation ClosePhoneLeadActivity($id: ID!, $input: LeadActivityClosePhoneInput!) {
        closePhoneLeadActivity(id: $id, input: $input) {
            __typename
            id
            status
            prospectLevel
            source
            summary
            lostReason
            customer {
                id
                type
                gender
                name {
                    type
                    companyName
                    firstName
                    lastName
                    middleName
                    suffix
                    title
                }
                contactInfo {
                    phoneNumber {
                        value
                        ext
                        description
                        preferred
                    }
                    phoneNumbers {
                        value
                        ext
                        description
                        preferred
                    }
                    email {
                        value
                        ext
                        description
                        preferred
                    }
                    address {
                        state
                        city
                        county
                        street
                    }
                }
            }
            opportunities {
                id
                make
                model
                color
                purchaseTimeframe
                testDrived
                demonstrated
                lookingForUsed
                description
                stockNo
                vin
                vehicle {
                    id
                    vin
                    model {
                        name
                        make
                    }
                    year
                    licenseNumber
                    mileage
                    specs {
                        color
                        bodyStyle
                    }
                }
            }
            tradeIns {
                id
                year
                make
                model
                mileage
                vin
            }
            activities {
                id
                title
                contactType
                automatic
                messageTemplateId
                contactInfoName
                comment
                closeComment
                dueTime
                scheduleTime
                actualTime
                _createAt
                _closeAt
                result
                pickedUp
                afterContactComment
                author
                _createBy {
                    id
                    firstName
                    lastName
                }
                callContact {
                    id
                    result
                    createAt
                    recording {
                        id
                    }
                }
                message {
                    id
                    sendAt
                }
                direction
            }
            assignee {
                id
                firstName
                lastName
            }
            lastContactAt
            contactStatus
            _createAt
            _updateAt
            _closeAt
        }
    }
`;

export const CREATE_PHONE_LEAD_ACTIVITY = gql`
    mutation CreatePhoneLeadActivity($id: ID!, $input: LeadActivityCreatePhoneInput!) {
        createPhoneLeadActivity(id: $id, input: $input) {
            __typename
            id
            status
            prospectLevel
            source
            summary
            lostReason
            customer {
                id
                type
                gender
                name {
                    type
                    companyName
                    firstName
                    lastName
                    middleName
                    suffix
                    title
                }
                contactInfo {
                    phoneNumber {
                        value
                        ext
                        description
                        preferred
                    }
                    phoneNumbers {
                        value
                        ext
                        description
                        preferred
                    }
                    email {
                        value
                        ext
                        description
                        preferred
                    }
                    address {
                        state
                        city
                        county
                        street
                    }
                }
            }
            opportunities {
                id
                make
                model
                color
                purchaseTimeframe
                testDrived
                demonstrated
                lookingForUsed
                description
                stockNo
                vin
                vehicle {
                    id
                    vin
                    model {
                        name
                        make
                    }
                    year
                    licenseNumber
                    mileage
                    specs {
                        color
                        bodyStyle
                    }
                }
            }
            tradeIns {
                id
                year
                make
                model
                mileage
                vin
            }
            activities {
                id
                title
                contactType
                automatic
                messageTemplateId
                contactInfoName
                comment
                closeComment
                dueTime
                scheduleTime
                actualTime
                _createAt
                _closeAt
                result
                pickedUp
                afterContactComment
                author
                _createBy {
                    id
                    firstName
                    lastName
                }
                callContact {
                    id
                    result
                    createAt
                    recording {
                        id
                    }
                }
                message {
                    id
                    sendAt
                }
                direction
            }
            assignee {
                id
                firstName
                lastName
            }
            lastContactAt
            contactStatus
            _createAt
            _updateAt
            _closeAt
        }
    }
`;

export const CREATE_EMAIL_LOG = gql`
    mutation CreateEmailLog($input: EmailLogCreateInput!) {
        createEmailLog(input: $input) {
            __typename
            id
            status
            prospectLevel
            source
            summary
            lostReason
            customer {
                id
                type
                gender
                name {
                    type
                    companyName
                    firstName
                    lastName
                    middleName
                    suffix
                    title
                }
                contactInfo {
                    phoneNumber {
                        value
                        ext
                        description
                        preferred
                    }
                    phoneNumbers {
                        value
                        ext
                        description
                        preferred
                    }
                    email {
                        value
                        ext
                        description
                        preferred
                    }
                    address {
                        state
                        city
                        county
                        street
                    }
                }
            }
            opportunities {
                id
                make
                model
                color
                purchaseTimeframe
                testDrived
                demonstrated
                lookingForUsed
                description
                stockNo
                vin
                vehicle {
                    id
                    vin
                    model {
                        name
                        make
                    }
                    year
                    licenseNumber
                    mileage
                    specs {
                        color
                        bodyStyle
                    }
                }
            }
            tradeIns {
                id
                year
                make
                model
                mileage
                vin
            }
            activities {
                id
                title
                contactType
                automatic
                messageTemplateId
                contactInfoName
                comment
                closeComment
                dueTime
                scheduleTime
                actualTime
                _createAt
                _closeAt
                result
                pickedUp
                afterContactComment
                author
                _createBy {
                    id
                    firstName
                    lastName
                }
                callContact {
                    id
                    result
                    createAt
                    recording {
                        id
                    }
                }
                message {
                    id
                    sendAt
                }
                direction
            }
            assignee {
                id
                firstName
                lastName
            }
            lastContactAt
            contactStatus
            _createAt
            _updateAt
            _closeAt
        }
    }
`;
