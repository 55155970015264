import dayjs from 'dayjs';
import { parsePhoneNumber, CountryCode } from 'libphonenumber-js';
import { isEmpty } from 'lodash';

import { LeadActivity, User } from './models';

export const getDayjs = (value: string | undefined) => {
    if (value == null || value == undefined) return null; // dayjs('1970-01-01')
    if (Number.isNaN(Number(value))) {
        return dayjs(value);
    } else {
        return dayjs(Number(value));
    }
};

const stringToColor = (string: string) => {
    let hash = 0;
    let i;
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) hash = string.charCodeAt(i) + ((hash << 5) - hash);
    let color = '#';
    const colors: number[] = [];
    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        const v = `00${value.toString(16)}`.substr(-2);
        colors.push(parseInt(v, 16));
        color += v;
    }
    /* eslint-enable no-bitwise */
    const contrast = (Math.round(colors[0] * 299) + Math.round(colors[1] * 587) + Math.round(colors[2] * 114)) / 1000;
    return [color, contrast >= 150 ? '#111' : '#eee'];
};

export const getAvatarStyle = (user: User) => {
    if (!user) return {};
    const [backgroundColor, color] = stringToColor(user.firstName + user.lastName);

    return {
        color: color,
        backgroundColor: backgroundColor
    };
};

export const insertLink = (value: string) => {
    const urlReg = /(http:\/\/|ftp:\/\/|https:\/\/|www.)[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?/g;
    return value.replace(urlReg, url => {
        if (url.match('http://') || url.match('ftp://') || url.match('https://'))
            return `<a href="${url}" style="color:#2a7fff"  target="_blank">${url}</a>`;
        else return `<a href=\"http:\/\/${url}\" style="color:#2a7fff" target="_blank">${url}</a>`;
    });
};

export const parsePhoneNumberCustomer = (value: string, country: CountryCode = 'US') => {
    try {
        const phoneNumber = parsePhoneNumber(value, country).formatNational();
        return phoneNumber;
    } catch (error) {
        console.error(error);
        return value;
    }
};

export const showAuthor = (activity: LeadActivity) => {
    if (activity.author != null && !isEmpty(activity.author)) return activity.author;
    return activity._createBy ? `${activity._createBy.firstName} ${activity._createBy.lastName}` : '';
};

export const clearNullDeep = (obj: any) => {
    if (Array.isArray(obj)) {
        obj.forEach((item: any) => {
            if (!item || !(typeof item === 'object')) return;
            const keys = Object.keys(item);
            for (const key of keys) {
                const val = item[key];
                if (typeof val === 'undefined' || (typeof val === 'object' && val === null)) {
                    delete item[key];
                } else if (typeof val === 'object') {
                    clearNullDeep(item[key]);
                    // if (Object.keys(item[key]).length === 0) delete item[key];
                }
            }
        });
    } else {
        const keys = Object.keys(obj);
        for (const key of keys) {
            const val = obj[key];
            if (typeof val === 'undefined' || (typeof val === 'object' && val === null)) {
                delete obj[key];
            } else if (typeof val === 'object') {
                clearNullDeep(obj[key]);
                // if (Object.keys(obj[key]).length === 0) delete obj[key];
            }
        }
    }
};
