export enum TaskReminderType {
    Task,
    Reminder
}

export enum TaskReminderBusinessType {
    Lead,
    ServiceAppointment,
    RepairOrder
}

export enum TaskReminderStatus {
    InProgress,
    Completed
}

export interface TaskReminder {
    id: string;
    type: TaskReminderType;
    title: string;
    scheduleTime: number;
    allDay: boolean;
    year: number;
    month: number;
    week: number;
    date: number;
    description?: string;
    businessType?: TaskReminderBusinessType;
    businessId?: string;
    businessTitle?: string;
}

export interface TaskReminderObject extends TaskReminder {
    status: TaskReminderStatus;
    user: string;
    _closeAt?: number;
}

export interface TaskReminderCreateInput {
    type: TaskReminderType;
    title: string;
    scheduleTime: number;
    allDay: boolean;
    timezone: string;
    description?: string;
}
