import { Form, Input, Modal, Select } from 'antd';
import { Store } from 'antd/lib/form/interface';
import React, { useEffect, useState } from 'react';

import { CustomerName } from '../../../components';
import { Employee, EmployeeInput } from '../../../models';
import { WindowContainer } from '../components';

const { Option } = Select;
interface Props {
    visible: boolean;
    data: Employee;
    onCancel: () => void;
    onSave: (data: EmployeeInput, id: string) => Promise<void>;
}
const EmployeesAddCount: React.FC<Props> = props => {
    const id = props.data.id;
    const hasCellNumber = typeof props.data.contactInfo?.cellNumber === 'string';
    const deleteString = props.data.contactInfo?.cellNumber?.replace(/^\+86/, '');
    const phoneNumber = typeof deleteString === 'string' ? deleteString : '';
    //areaCode 国际区号
    const initialValues: EmployeeInput & { areaCode: string } = {
        name: props.data.name,
        userName: props.data.userName || '',
        contactInfo: {
            cellNumber: phoneNumber,
            email: props.data.contactInfo?.email || ''
        },
        areaCode: hasCellNumber && props.data.contactInfo?.cellNumber?.match(/^\+86/) ? '+86' : '+1'
    };
    const [isWorking, setIsWorking] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        if (props.visible) {
            form.resetFields();
        }
    }, [form, props.visible]);

    const onCancel = () => {
        if (!isWorking) props.onCancel();
    };
    const onOk = async () => {
        try {
            setIsWorking(true);
            const values = (await form.validateFields()) as EmployeeInput & { areaCode: string };
            const newData: EmployeeInput = {
                name: props.data.name,
                userName: values.userName,
                contactInfo: {
                    cellNumber: values.areaCode + values.contactInfo?.cellNumber,
                    email: values.contactInfo?.email
                }
            };
            await props.onSave(newData, id);
        } catch (err) {
            console.error(err);
        } finally {
            setIsWorking(false);
        }
    };
    //字段值更新
    const onValuesChange = (changedValues: Store) => {
        if (changedValues.areaCode) form.setFieldsValue({ contactInfo: { cellNumber: '' } });
    };
    const prefixSelector = (
        <Form.Item name={'areaCode'} noStyle>
            <Select
                style={{
                    width: 70
                }}
            >
                {/* <Option value="+86">+86</Option> */}
                <Option value="+1">+1</Option>
            </Select>
        </Form.Item>
    );
    return (
        <Modal
            title={'Add Account'}
            confirmLoading={isWorking}
            visible={props.visible}
            onCancel={onCancel}
            onOk={onOk}
            width={500}
            className="form"
            okText="Submit"
            cancelButtonProps={{ type: 'link' }}
            maskClosable={false}
            forceRender
        >
            <WindowContainer>
                <Form form={form} initialValues={initialValues} labelCol={{ span: 6 }} onValuesChange={onValuesChange}>
                    <Form.Item label="Name">
                        <CustomerName name={props.data.name} />
                    </Form.Item>
                    <Form.Item name="userName" label="User name" rules={[{ required: true, whitespace: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name={['contactInfo', 'cellNumber']}
                        label="Cell number"
                        rules={[
                            {
                                required: true
                            },
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (value) {
                                        if (getFieldValue('areaCode') === '+86') {
                                            if (!/^1[3456789]\d{9}$/.test(value)) {
                                                return Promise.reject('Incorrect number format.eq. 18812345678');
                                            }
                                            return Promise.resolve();
                                        } else if (getFieldValue('areaCode') === '+1') {
                                            if (!/^[2-9]\d{2}[2-9](?!11)\d{6}$/.test(value)) {
                                                return Promise.reject('Incorrect number format.eq. 9664445555');
                                            }
                                            return Promise.resolve();
                                        }
                                    } else {
                                        return Promise.reject('Please input your phone number!');
                                    }
                                }
                            })
                        ]}
                    >
                        <Input
                            addonBefore={prefixSelector}
                            style={{
                                width: '100%'
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        name={['contactInfo', 'email']}
                        label="Email"
                        rules={[{ required: true, type: 'email' }]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </WindowContainer>
        </Modal>
    );
};

export default React.memo(EmployeesAddCount);
