import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import React from 'react';

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

interface TimeCellProps {
    time: number | string | Date | dayjs.Dayjs;
}

const TimeCell: React.FC<TimeCellProps> = props => {
    const time = dayjs(props.time);
    return (
        <div style={{ lineHeight: '120%' }}>
            {time.format('lll')}
            <br />
            <span style={{ fontSize: '85%', fontStyle: 'italic' }}>{time.fromNow()}</span>
        </div>
    );
};

export default React.memo(TimeCell);
