/* eslint-disable @typescript-eslint/no-unused-vars */
import type { Employee } from '../models';

import { request, sessionStore } from '../services';
import { SearchResult } from '../types';

//清空缓存权限
export const clearPermission = () => {
    sessionStore.remove('user.permissions');
};

const EMPTY_PERMISSIONS: string[] = [];

//设置并返回权限
export const setPermissions = async (
    userName: string,
    setUserPermissions: (data: string[]) => void,
    setCurrentEmployee?: (data: Employee) => void
) => {
    try {
        // const userPermissions = sessionStore.get('user.permissions');
        // let permissions = null;
        // const employees = await request<Employee, SearchResult<Employee>>('get', 'employee', [], { userName });
        // if (employees != null && Array.isArray(employees.data) && employees.data.length > 0) {
        //     permissions =
        //         Array.isArray(employees.data[0].roles) &&
        //         employees.data[0].roles.length > 0 &&
        //         employees.data[0].roles.map(item => item.permissions).flat(2);
        //     if (setCurrentEmployee) setCurrentEmployee(employees.data[0]);
        // }
        // if (userPermissions == null) {
        //     if (permissions != null) sessionStore.set('user.permissions', permissions);
        // } else {
        //     if (Array.isArray(permissions) && userPermissions.sort().toString() !== permissions.sort().toString()) {
        //         sessionStore.set('user.permissions', permissions);
        //     } else {
        //         permissions = userPermissions;
        //     }
        // }
        // setUserPermissions([permissions]);
        // return permissions;
        setUserPermissions(EMPTY_PERMISSIONS);
        return EMPTY_PERMISSIONS;
    } catch (err) {
        console.error(err);
    }
};
