import { Button, Popconfirm } from 'antd';
import React from 'react';

import { CloseOutlined } from '@ant-design/icons';

const ClearButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
    <Popconfirm placement="right" title="Reset all filters?" okText="Yes" cancelText="No" onConfirm={onClick}>
        <Button className="reload" title="Reset filters" icon={<CloseOutlined />} />
    </Popconfirm>
);

export default React.memo(ClearButton);
