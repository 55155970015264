import type { Employee, EmployeeInput, Role } from '../../../models';

import { Form, Modal, Select } from 'antd';
import { produce } from 'immer';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { CustomerName } from '../../../components';
import { useFilters, usePaging, useSearchData } from '../../../hooks';
import { clearPermission } from '../../utils';
import { WindowContainer } from '../components';

interface Props {
    visible: boolean;
    data: Employee;
    onCancel: () => void;
    onSave: (data: EmployeeInput, id: string) => Promise<void>;
}

const EmployeesAddRole: React.FC<Props> = React.forwardRef<any, Props>(({ data, visible, onCancel, onSave }, ref) => {
    const [filters] = useFilters();
    const [paging] = usePaging(0);
    const [rolesData] = useSearchData<Role>('roles', filters, paging);
    const id: string = data.id;
    const [isWorking, setIsWorking] = useState(false);
    const [employeeEdit, setEmployeeEdit] = useState<EmployeeInput>(Object);
    useEffect(() => {
        if (Array.isArray(data.roleIds) && data.roleIds.length > 0)
            setEmployeeEdit(employeeData => {
                if (employeeData == null) return employeeData;
                return produce<EmployeeInput, EmployeeInput>(employeeData, draft => {
                    draft.name = data.name;
                    draft.roleIds = data.roleIds;
                });
            });
        else
            setEmployeeEdit(employeeData => {
                if (employeeData == null) return employeeData;
                return produce<EmployeeInput, EmployeeInput>(employeeData, draft => {
                    draft.name = data.name;
                    draft.roleIds = [];
                });
            });
    }, [data]);
    const onCancelFn = () => {
        if (!isWorking) onCancel();
    };
    const onOk = useCallback(async () => {
        try {
            setIsWorking(true);
            await onSave(employeeEdit, id);
        } catch (err) {
            console.error(err);
        } finally {
            setIsWorking(false);
            clearPermission();
        }
    }, [employeeEdit, id, onSave]);
    const handleChange = (value: string[]) => {
        setEmployeeEdit(employeeData => {
            if (employeeData == null) return employeeData;
            return produce<EmployeeInput, EmployeeInput>(employeeData, draft => {
                draft.roleIds = value;
            });
        });
    };
    const selectRole = useMemo(() => {
        return (
            <Select
                mode="multiple"
                style={{ width: '100%' }}
                ref={ref}
                value={employeeEdit.roleIds || []}
                placeholder="Please select"
                onChange={handleChange}
            >
                {rolesData.data.map((item: Role) => (
                    <Select.Option key={item.name} value={item.id}>
                        {item.name}
                    </Select.Option>
                ))}
            </Select>
        );
    }, [rolesData, ref, employeeEdit]);

    return (
        <Modal
            title={'Edit'}
            confirmLoading={isWorking}
            visible={visible}
            onCancel={onCancelFn}
            onOk={onOk}
            width={500}
            className="form"
            okText="Submit"
            cancelButtonProps={{ type: 'link' }}
            maskClosable={false}
            forceRender
        >
            <WindowContainer>
                <Form labelCol={{ span: 6 }}>
                    <Form.Item label="Name">
                        <CustomerName name={data.name} />
                    </Form.Item>
                    <Form.Item label="Roles">{selectRole}</Form.Item>
                </Form>
            </WindowContainer>
        </Modal>
    );
});

EmployeesAddRole.displayName = 'EmployeesAddRole';

export default React.memo(EmployeesAddRole);
