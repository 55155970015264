import * as React from 'react';

import DatePicker from './DatePicker';

import type { PickerTimeProps } from 'antd/es/date-picker/generatePicker';
import type { Dayjs } from 'dayjs';

export type TimePickerProps = Omit<PickerTimeProps<Dayjs>, 'picker'>;

const TimePicker = React.forwardRef<any, TimePickerProps>((props, ref) => {
    const DatePickerAny = DatePicker as any;
    return <DatePickerAny {...props} picker="time" mode={undefined} ref={ref} />;
});

TimePicker.displayName = 'TimePicker';

export default TimePicker;
