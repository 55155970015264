import React from 'react';

const Main = React.lazy(() => import('./Main'));

const LeadContactPlanner= () => {

    return <Main />;
};

export default LeadContactPlanner;
