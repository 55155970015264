import { Button, Col, Collapse, Descriptions, Form, Input, Modal, Radio, Row, Switch, Tag } from 'antd';
import { FormInstance } from 'antd/lib/form';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';

import { CarOutlined } from '@ant-design/icons';

import { DatePicker, EnumCheckboxGroup, EnumSelect } from '../../../components';
import { SimpleBarAny as SimpleBar } from '../../../components/SimpleBarAny';
import { Address, ContactInfoPreferredMethod, CustomerCategory, CustomerType, DayOfTheWeek } from '../../../models';
import { Customer, CustomerInput } from '../../customers/types';
import { WindowContainer } from '../components';
import AddressEditor from './AddressEditor';

interface Props {
    visible: boolean;
    data?: Readonly<Customer>;
    category: number;
    onCancel: () => void;
    onSave: (data: CustomerInput, id?: string) => Promise<void>;
    //creditID?: string;
}

const emptyData: CustomerInput = {
    category: CustomerCategory.Potential,
    type: CustomerType.Individual,
    name: {
        firstName: '',
        lastName: ''
    },
    contactInfo: {
        preferredMethod: ContactInfoPreferredMethod.Cell | ContactInfoPreferredMethod.Email
    }
};

const preferredDayItem = (
    <Form.Item label="Preferred day" shouldUpdate>
        {({ getFieldValue, setFieldsValue }) => {
            const preferredDay = getFieldValue(['contactInfo', 'preferredDay']);
            const isAny = !Array.isArray(preferredDay);
            return (
                <Row gutter={8} style={{ height: 34 }}>
                    <Col span={4}>
                        <Radio
                            style={{ marginTop: 5 }}
                            checked={isAny}
                            onClick={() =>
                                setFieldsValue({
                                    contactInfo: { preferredDay: undefined }
                                })
                            }
                        >
                            Any
                        </Radio>
                    </Col>
                    <Col span={2}>
                        <Radio
                            style={{ marginTop: 5 }}
                            checked={!isAny}
                            onClick={() =>
                                setFieldsValue({
                                    contactInfo: { preferredDay: [] }
                                })
                            }
                        />
                    </Col>
                    <Col span={18}>
                        <Form.Item name={['contactInfo', 'preferredDay']}>
                            <EnumSelect
                                disabled={isAny}
                                placeholder="Select preferred contact days"
                                enum={DayOfTheWeek}
                                mode="multiple"
                                maxTagCount={4}
                                tagRender={({ closable, onClose, label }) => (
                                    <Tag closable={closable} onClose={onClose}>
                                        {typeof label === 'string' ? label.substr(0, 3) : label}
                                    </Tag>
                                )}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            );
        }}
    </Form.Item>
);
//车辆信息

const CustomerForm: React.FC<Props> = props => {
    const id = props.data?.id;
    const initialValues = props.data || emptyData;
    // 表单控制属性
    const [isWorking, setIsWorking] = useState(false);
    const [form] = Form.useForm();
    const onOk = async () => {
        try {
            setIsWorking(true);
            const values = (await form.validateFields()) as CustomerInput;
            values.dob = form.getFieldValue('dob');
            // if (props.creditID) {
            //     if (
            //         !(
            //             values.name &&
            //             values.ssn &&
            //             values.contactInfo &&
            //             values.contactInfo.homeAddress &&
            //             values.contactInfo.homeAddress.street &&
            //             values.contactInfo.homeAddress.city &&
            //             values.contactInfo.homeAddress.state &&
            //             values.contactInfo.homeAddress.zip
            //         )
            //     ) {
            //         message.error('To view credit, you need to fill in your name, home address, and ssn');
            //         return;
            //     }
            // }
            values.category = props.category;
            //console.log(JSON.stringify(values, null, 2));
            await props.onSave(values, id);
        } catch (err) {
            console.error(err);
        } finally {
            setIsWorking(false);
        }
    };
    const onCancel = () => {
        if (!isWorking) props.onCancel();
    };
    useEffect(() => {
        if (props.visible) form.resetFields();
        if (props.data?.employment) setHasEmployment(true);
        if (!props.visible) setHasEmployment(false);
    }, [form, initialValues, props.visible, props.data]);

    // 地址编辑对话框
    const [addrEditorVisible, setAddrEditorVisible] = useState(false);
    const [addressData, setAddressData] = useState<Address>();
    const [addressPath, setAddressPath] = useState<string | string[]>();
    // eslint-disable-next-line react/display-name
    const createAddressRenderer = (path: string | string[]) => (form: FormInstance) => {
        const address = form.getFieldValue(path);
        return (
            <Form.Item name={path} noStyle>
                <Button
                    type="link"
                    size="small"
                    style={{ padding: 0 }}
                    onClick={() => {
                        setAddressPath(path);
                        setAddressData(address);
                        setAddrEditorVisible(true);
                    }}
                >
                    Edit
                </Button>
            </Form.Item>
        );
    };
    const onAddressChange = (address: Address) => {
        if (typeof addressPath === 'string') form.setFieldsValue({ [addressPath]: address });
        else if (Array.isArray(addressPath)) {
            let value: any;
            addressPath.reverse().forEach((path, i) => {
                if (i === 0) value = { [path]: address };
                else value = { [path]: value };
            });
            if (value != null) form.setFieldsValue(value);
        }
        setAddrEditorVisible(false);
    };

    const onFieldsChange = (changedFields: any[]) => {
        const typeChanged = changedFields.some(field => field.name.length === 1 && field.name[0] === 'type');
        const nameChanged = changedFields.some(field => field.name.length === 1 && field.name[0] === 'name');
        if (typeChanged && !nameChanged) {
            const type = changedFields.find(field => field.name.length === 1 && field.name[0] === 'type').value;
            if (type === CustomerType.Individual && typeof form.getFieldValue(['name', 'firstName']) !== 'string')
                form.setFieldsValue({ name: { firstName: '', lastName: '' } });
            else if (type === CustomerType.Company && typeof form.getFieldValue('name') !== 'string') form.setFieldsValue({ name: '' });
        }
    };

    const companyItems = (
        <Row gutter={4}>
            <Col span={24}>
                <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    name={['contactInfo', 'cellNumber']}
                    label="Phone"
                    labelCol={{ span: 10 }}
                    rules={[{ required: true }, { pattern: /^[2-9]\d{2}[2-9](?!11)\d{6}$/, message: 'Incorrect number format.' }]}
                >
                    <Input />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item name={['contactInfo', 'workNumber']} label="Alt. phone" labelCol={{ span: 10 }}>
                    <Input />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    rules={[{ required: true, type: 'email' }]}
                    name={['contactInfo', 'email']}
                    label="Email"
                    labelCol={{ span: 10 }}
                >
                    <Input />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    label="Address"
                    labelCol={{ span: 10 }}
                    shouldUpdate={(prev, next) => prev.contactInfo.workAddress !== next.contactInfo.workAddress}
                >
                    {createAddressRenderer(['contactInfo', 'workAddress'])}
                </Form.Item>
            </Col>
        </Row>
    );

    const individualItems = (
        <Row gutter={4}>
            <Col span={12}>
                <Form.Item
                    name={['contactInfo', 'cellNumber']}
                    label="Cell number"
                    labelCol={{ span: 10 }}
                    rules={[{ required: true }, { pattern: /^[2-9]\d{2}[2-9](?!11)\d{6}$/, message: 'Incorrect number format.' }]}
                >
                    <Input />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    rules={[{ required: true, type: 'email' }]}
                    name={['contactInfo', 'email']}
                    label="Email"
                    labelCol={{ span: 10 }}
                >
                    <Input />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    name={['name', 'firstName']}
                    label="First name"
                    rules={[{ required: true, whitespace: true }]}
                    labelCol={{ span: 10 }}
                >
                    <Input />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    name={['name', 'lastName']}
                    label="Last name"
                    rules={[{ required: true, whitespace: true }]}
                    labelCol={{ span: 10 }}
                >
                    <Input />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item name={['name', 'middleName']} label="Mid. name" labelCol={{ span: 10 }}>
                    <Input />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item name={['name', 'title']} label="Title" labelCol={{ span: 10 }}>
                    <Input />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item name={['name', 'prefix']} label="Prefix" labelCol={{ span: 10 }}>
                    <Input />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item name={['name', 'suffix']} label="Suffix" labelCol={{ span: 10 }}>
                    <Input />
                </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item name="gender" label="Gender">
                    <Radio.Group>
                        <Radio value="M">Male</Radio>
                        <Radio value="F">Female</Radio>
                        <Radio value="X">Other</Radio>
                    </Radio.Group>
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item label="Birth date" labelCol={{ span: 10 }} shouldUpdate>
                    {({ getFieldValue, setFieldsValue }) => (
                        <DatePicker
                            format="MM/DD/YYYY"
                            value={getFieldValue('dob') ? dayjs(getFieldValue('dob')) : undefined}
                            onChange={(date: any) => {
                                setFieldsValue({
                                    dob: date
                                });
                            }}
                        />
                    )}
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    name="ssn"
                    label="SSN"
                    labelCol={{ span: 10 }}
                    rules={[
                        {
                            pattern: /^(?!000)(?!666)[0-8][0-9]{2}[ -](?!00)[0-9]{2}[ -](?!0000)[0-9]{4}$/,
                            message: 'Invalid SSN format.'
                        }
                    ]}
                >
                    <Input />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item name={['contactInfo', 'homeNumber']} label="Home phone" labelCol={{ span: 10 }}>
                    <Input />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    label="Home address"
                    labelCol={{ span: 10 }}
                    shouldUpdate={(prev, next) => prev.contactInfo.homeAddress !== next.contactInfo.homeAddress}
                >
                    {createAddressRenderer(['contactInfo', 'homeAddress'])}
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item name={['contactInfo', 'workNumber']} label="Work phone" labelCol={{ span: 10 }}>
                    <Input />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    label="Work address"
                    labelCol={{ span: 10 }}
                    shouldUpdate={(prev, next) => prev.contactInfo.workAddress !== next.contactInfo.workAddress}
                >
                    {createAddressRenderer(['contactInfo', 'workAddress'])}
                </Form.Item>
            </Col>
        </Row>
    );

    const [hasEmployment, setHasEmployment] = useState(false);
    const employmentItems = (
        <Row gutter={4}>
            <Col span={12}>
                <Form.Item rules={[{ required: true }]} name={['employment', 'employerName']} label="Name" labelCol={{ span: 10 }}>
                    <Input />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item name={['employment', 'occupation']} label="Occupation" labelCol={{ span: 10 }}>
                    <Input />
                </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item
                    label="Address"
                    labelCol={{ span: 5 }}
                    shouldUpdate={(prev, next) => prev.employment?.address !== next.employment?.address}
                >
                    {createAddressRenderer(['employment', 'address'])}
                </Form.Item>
            </Col>
        </Row>
    );
    //车辆信息
    const VehicleItems = useMemo(() => {
        if (!props.data || !props.data.vehicles || props.data.vehicles.length < 1) return;
        return props.data.vehicles.map(item => (
            <Collapse.Panel
                header={
                    <div>
                        <CarOutlined /> Vin: {item.vin}
                    </div>
                }
                key={item.id}
            >
                <Descriptions column={2}>
                    <Descriptions.Item label="VIN">{item.vin}</Descriptions.Item>
                    <Descriptions.Item label="Mileage">{item.mileage}</Descriptions.Item>
                    <Descriptions.Item label="Year">{item.modelYear}</Descriptions.Item>
                    <Descriptions.Item label="Make">
                        <strong>{`${item.model.makeName}`}</strong>
                    </Descriptions.Item>
                    <Descriptions.Item label="Model">
                        <strong>{`${item.model.fullName}`}</strong>
                    </Descriptions.Item>
                    <Descriptions.Item label="License #">{item.licenseNumber}</Descriptions.Item>
                    <Descriptions.Item label="Body color">{item.bodyColor}</Descriptions.Item>
                    <Descriptions.Item label="Interior">{item.interiorColor}</Descriptions.Item>
                </Descriptions>
            </Collapse.Panel>
        ));
    }, [props.data]);
    return (
        <Modal
            title={`${id == null ? 'New' : 'Edit'} Customer`}
            confirmLoading={isWorking}
            visible={props.visible}
            onCancel={onCancel}
            onOk={onOk}
            width={600}
            className="form"
            okText="Submit"
            cancelButtonProps={{ type: 'link' }}
            maskClosable={false}
            forceRender
        >
            <SimpleBar style={{ maxHeight: 600 }}>
                <WindowContainer>
                    <Form form={form} initialValues={initialValues} labelCol={{ span: 5 }} onFieldsChange={onFieldsChange}>
                        <Form.Item name="type" label="Type" rules={[{ required: true }]}>
                            <EnumSelect enum={CustomerType} />
                        </Form.Item>
                        {/* <Row>
                            <Col span={20}>
                                <Form.Item name="type" label="Type" rules={[{ required: true }]} labelCol={{ span: 6 }}>
                                    <EnumSelect enum={CustomerType} />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Button type="link" onClick={() => setCreditVisible(true)} disabled={id == null}>
                                    Credit
                                </Button>
                                <CreditPage visible={creditVisible} onCancel={() => setCreditVisible(false)} />
                            </Col>
                        </Row> */}
                        <Form.Item noStyle shouldUpdate={(prev, current) => prev.type !== current.type}>
                            {({ getFieldValue }) => (getFieldValue('type') === CustomerType.Company ? companyItems : individualItems)}
                        </Form.Item>
                        {preferredDayItem}
                        <Form.Item noStyle shouldUpdate={(prev, current) => prev.type !== current.type}>
                            {({ getFieldValue }) =>
                                getFieldValue('type') === CustomerType.Individual ? (
                                    <Form.Item name={['contactInfo', 'preferredMethod']} label="Preferred methods" labelCol={{ span: 6 }}>
                                        <EnumCheckboxGroup enum={ContactInfoPreferredMethod} flag />
                                    </Form.Item>
                                ) : null
                            }
                        </Form.Item>
                        <Form.Item noStyle shouldUpdate={(prev, current) => prev.type !== current.type}>
                            {({ getFieldValue }) =>
                                getFieldValue('type') === CustomerType.Individual ? (
                                    <>
                                        <Form.Item label="Employment" labelCol={{ span: 6 }}>
                                            <Switch
                                                checked={hasEmployment}
                                                onChange={val => {
                                                    setHasEmployment(val);
                                                }}
                                            />
                                        </Form.Item>
                                        {hasEmployment ? employmentItems : null}
                                    </>
                                ) : null
                            }
                        </Form.Item>
                    </Form>
                    {id ? <Collapse bordered={false}>{VehicleItems}</Collapse> : null}
                    <AddressEditor
                        visible={addrEditorVisible}
                        onCancel={() => setAddrEditorVisible(false)}
                        data={addressData}
                        onChange={onAddressChange}
                    />
                </WindowContainer>
            </SimpleBar>
        </Modal>
    );
};

export default React.memo(CustomerForm);
