import { Avatar, Dropdown, Menu, Modal } from 'antd';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import { LockOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';

import { UserContext } from '../contexts';
import { sessionStore } from '../services';
import { signOut } from '../services/cognito';
import logo from './logo.png';
import Password from './Password';

const AvatarBox = styled.div`
    cursor: pointer;
    float: right;
    padding: 0 20px;
    /* width: 360px; */
`;

const UserName = styled.div`
    line-height: 18px;
    margin-top: 17px;
    & > div:first-child {
        font-size: 110%;
    }
    & > div:last-child {
        font-size: 80%;
    }
`;

// const onClick = ({ key }) => {
//     switch (key) {
//         case 'signOut':
//             Modal.confirm({
//                 title: t('message.signOutConfirm'),
//                 okText: t('message.yes'),
//                 cancelText: t('message.no'),
//                 onOk() {
//                     if (onSignOut) onSignOut();
//                 },
//             });
//             break;
//     }
// };

const Header: React.FC = () => {
    const user = useContext(UserContext);
    const [PasswordVisible, setPasswordVisible] = useState(false);
    const overlay = (
        <Menu
            onClick={({ key }) => {
                switch (key) {
                    case 'signOut':
                        Modal.confirm({
                            title: 'Are you sure to log out?',
                            async onOk() {
                                await signOut();
                                sessionStore.remove(`user.${user?.id}`);
                                location.reload();
                            }
                        });
                        break;
                    case 'password':
                        setPasswordVisible(true);
                        break;
                }
            }}
        >
            {/* <Menu.Item key="preferences">
                <ControlOutlined /> Preferences
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="avatar">
                <SettingOutlined /> Set avatar
            </Menu.Item>
            <Menu.Divider /> */}
            <Menu.Item key="password">
                <LockOutlined /> Change Password
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="signOut">
                <LogoutOutlined /> Sign-out
            </Menu.Item>
        </Menu>
    );

    //聊天室相关
    // const [topNoticeDyan] = useState<
    //     { channelName: string; sender: string; message: string; type: string; at?: boolean }[]
    // >([]);
    // const [invitedChannel, setInvitedChannel] = useState<string>('');
    // const noticeList = (
    //     <Menu>
    //         {/* {topNoticeDyan.length ? (
    //             topNoticeDyan.map((item, index) => {
    //                 if (item.type === 'newMessage')
    //                     return (
    //                         <Menu.Item
    //                             style={{
    //                                 borderBottom: index === topNoticeDyan.length - 1 ? 'none' : '1px solid #f0f0f0',
    //                                 whiteSpace: 'normal'
    //                             }}
    //                             key={index}
    //                             onClick={e => {
    //                                 setShowChat(true);
    //                                 setChannelName(item.channelName);
    //                                 setTopNoticeDyan(topNoticeDyan.filter(item => item.channelName !== e.key));
    //                             }}
    //                         >
    //                             <p style={{ fontWeight: 500, marginBottom: 0 }}>{item.channelName}</p>
    //                             <span style={{ color: '#40a9ff', border: 'none', marginRight: '6px' }}>
    //                                 {item.sender}:
    //                             </span>
    //                             {item.message}
    //                         </Menu.Item>
    //                     );
    //                 else
    //                     return (
    //                         <Menu.Item
    //                             key={index}
    //                             onClick={e => {
    //                                 setTopNoticeDyan(topNoticeDyan.filter(item => item.channelName !== e.key));
    //                             }}
    //                         >
    //                             {item.message}
    //                         </Menu.Item>
    //                     );
    //             })
    //         ) : (
    //             <Menu.Item key={0}>{`No reminder`}</Menu.Item>
    //         )} */}
    //     </Menu>
    // );
    // const [, setShowChat] = useState(false);
    // const [, setChannelName] = useState('');
    const name = user
        ? `${user.firstName} ${typeof user.lastName === 'string' && user.lastName.length > 0 ? user.lastName[0].toUpperCase() + '.' : ''}`
        : '';

    //修改头像
    const [userPicture] = useState<string>(user?.picture ?? '');
    // const [, setAvatarVisible] = useState(false);
    // const onAvatarSave = async (data: EmployeeInput, id: string) => {
    //     const result = await updateEmployee(id, data);
    //     if (result) setUserPicture(result.avatar ?? '');
    //     setAvatarVisible(false);
    // };

    return (
        <div>
            <img src={logo} alt="Lexus" style={{ verticalAlign: 'top', marginTop: 8, userSelect: 'none' }} />
            <span style={{ color: '#eee', fontSize: '180%', marginLeft: 40, fontWeight: 'bold', textShadow: '1px 1px 3px black' }}>
                {user?.dealerName ?? ''}
            </span>
            <AvatarBox>
                <Dropdown overlay={overlay}>
                    <div>
                        <Avatar src={userPicture} style={{ backgroundColor: '#303030', marginRight: '6px' }}>
                            <UserOutlined />
                        </Avatar>
                        <UserName style={{ color: 'white', display: 'inline-block' }}>
                            <div>{name}</div>
                            <div></div>
                        </UserName>
                    </div>
                </Dropdown>
                {/* <Row gutter={4}>
                    <Col span={4}></Col>
                    <Col span={4}></Col>
                    <Col span={4}></Col>
                    <Col span={12}>
                        <Dropdown overlay={overlay}>
                            <Row>
                                <Col span={8} style={{ textAlign: 'center' }}>
                                    <Avatar style={{ backgroundColor: '#303030' }}>
                                        <UserOutlined />
                                    </Avatar>
                                </Col>
                                <Col span={16} style={{ color: 'white' }}>
                                    <UserName>
                                        <div>{name}</div>
                                        <div>Demonstrator</div>
                                    </UserName>
                                </Col>
                            </Row>
                        </Dropdown>
                    </Col>
                </Row> */}
            </AvatarBox>
            {/* <div style={{ float: 'right' }}>
                <Dropdown overlay={noticeList} placement="bottomRight">
                    <Badge
                        count={
                            topNoticeDyan.filter(t => t.at).length
                                ? topNoticeDyan.filter(t => t.at).length
                                : topNoticeDyan.length
                        }
                        dot={!topNoticeDyan.filter(t => t.at).length}
                    >
                        <Button
                            type="link"
                            onClick={() => {
                                return;
                            }}
                            size="small"
                            icon={<BellOutlined style={{ color: '#52c41a', fontSize: '1em' }} />}
                        />
                    </Badge>
                </Dropdown>
            </div> */}
            {/* <HeaderChat
                visible={showChat}
                setVisible={setShowChat}
                channelName={channelName}
                setTopNoticeDyan={setTopNoticeDyan}
                topNoticeDyan={topNoticeDyan}
                invitedChannel={invitedChannel}
                setInvitedChannel={setInvitedChannel}
            />
            <SettingAvatar visible={avatarVisible} onCancel={() => setAvatarVisible(false)} onSave={onAvatarSave} /> */}
            <Password visible={PasswordVisible} user={user ?? undefined} onClose={() => setPasswordVisible(false)} />
        </div>
    );
};

export default Header;
