import { Form, Input, InputNumber, Modal, Switch } from 'antd';
import React, { useEffect, useState } from 'react';

import { VehicleAdditionalCharge, VehicleAdditionalChargeInput } from '../../../models';
import { WindowContainer } from '../components';

interface Props {
    visible: boolean;
    data?: Readonly<VehicleAdditionalCharge>;
    onCancel: () => void;
    onSave: (data: VehicleAdditionalChargeInput, id?: string) => Promise<void>;
}

const emptyData: VehicleAdditionalChargeInput = {
    code: '',
    cost: 0,
    isDefault: false
};

const AdditionalChargeEditor: React.FC<Props> = props => {
    const id = props.data?.id;
    const initialValues = props.data || emptyData;

    const [isWorking, setIsWorking] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        if (props.visible) form.resetFields();
    }, [form, initialValues, props.visible]);

    const onCancel = () => {
        if (!isWorking) props.onCancel();
    };
    const onOk = async () => {
        try {
            setIsWorking(true);
            const values = (await form.validateFields()) as VehicleAdditionalChargeInput;
            await props.onSave(values, id);
        } catch (err) {
            console.error(err);
        } finally {
            setIsWorking(false);
        }
    };

    return (
        <Modal
            title={`${id == null ? 'New' : 'Edit'} Additional Charge`}
            confirmLoading={isWorking}
            visible={props.visible}
            onCancel={onCancel}
            onOk={onOk}
            width={500}
            className="form"
            okText="Submit"
            cancelButtonProps={{ type: 'link' }}
            maskClosable={false}
            forceRender
        >
            <WindowContainer>
                <Form form={form} initialValues={initialValues} labelCol={{ span: 6 }}>
                    <Form.Item name="code" label="Code" rules={[{ required: true, whitespace: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="cost" label="Cost" rules={[{ type: 'number', required: true, min: 0 }]}>
                        <InputNumber style={{ width: 120 }} />
                    </Form.Item>
                    <Form.Item name="description" label="Description">
                        <Input />
                    </Form.Item>
                    <Form.Item name="isDefault" label="Default" valuePropName="checked">
                        <Switch />
                    </Form.Item>
                    <Form.Item name="notes" label="Notes">
                        <Input.TextArea rows={3} />
                    </Form.Item>
                </Form>
            </WindowContainer>
        </Modal>
    );
};

export default React.memo(AdditionalChargeEditor);
