import { PageHeader as Header } from 'antd';
import React, { useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import useResizeObserver from 'use-resize-observer';

import { SimpleBarAny as SimpleBar } from '../components/SimpleBarAny';

interface PageProps {
    title: string;
}

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const PageHeader = styled(Header)`
    background-color: #2e2e2e;
    padding-bottom: 24px;

    .ant-page-header-heading-title {
        color: #fefefe;
        font-size: 22px;
        font-weight: normal;
    }
`;

const PageContent = styled.div`
    flex-grow: 1;
    max-height: 100%;
`;

const Page = (props: React.PropsWithChildren<PageProps>) => {
    const simpleBarRef = useRef<SimpleBar>(null);
    const { ref: headerRef, height: headerHeight = 0 } = useResizeObserver();
    const { ref: contentRef, height: contentHeight = 0 } = useResizeObserver();
    const height = useMemo(
        () => Math.max(document.documentElement.clientHeight, window.innerHeight ?? 0) - 64 - headerHeight + contentHeight * 0,
        [headerHeight, contentHeight]
    );

    useEffect(() => {
        simpleBarRef.current?.recalculate();
    }, [height]);

    const actions: React.ReactNode[] = [];
    const header: React.ReactNode[] = [];
    const content: React.ReactNode[] = [];
    React.Children.forEach(props.children, child => {
        const type = (child as any).type;
        if (typeof type === 'function') {
            const name = type.displayName ?? type.name;
            if (name === 'PageAction') actions.push(child);
            else if (name === 'PageHeader') header.push(child);
            else if (name === 'PageContent') content.push(child);
        }
    });

    return (
        <PageContainer>
            <div ref={headerRef}>
                <PageHeader title={props.title} extra={actions}>
                    {header}
                </PageHeader>
            </div>
            <PageContent ref={contentRef}>
                <SimpleBar ref={simpleBarRef} style={{ height }}>
                    {content}
                </SimpleBar>
            </PageContent>
        </PageContainer>
    );
};

Page.Action = function PageAction({ children }: { children: React.ReactNode }) {
    return <>{children}</>;
} as React.FC;
Page.Action.displayName = 'PageAction';

Page.Header = function PageHeader({ children }: { children: React.ReactNode }) {
    return <>{children}</>;
} as React.FC;
Page.Header.displayName = 'PageHeader';

Page.Content = function PageContent({ children }: { children: React.ReactNode }) {
    return <div style={{ margin: '10px 20px' }}>{children}</div>;
} as React.FC;
Page.Content.displayName = 'PageContent';

export default Page;
