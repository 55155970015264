import { Avatar, Button, Popover, Space, Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';

import { EditOutlined } from '@ant-design/icons';

import { Customer, LeadContactType, LeadDataObject } from '../models';
import styles from '../styles.module.scss';
import { getAvatarStyle, insertLink } from '../utils';
import { parsePhoneNumberCustom } from './CustomerCard';

interface Props {
    lead: LeadDataObject;
    editPermission: boolean;
    handleCommentVisible: (id: string) => void;
    lastComment?: string;
}

export const getPhoneNumber = (customer: Customer, type?: string) => {
    if (customer?.contactInfo == null) return null;
    if (type == null) return customer.contactInfo.phoneNumber ? parsePhoneNumberCustom(customer.contactInfo.phoneNumber.value) : null;
    if (!Array.isArray(customer.contactInfo.phoneNumbers)) return null;
    let phoneNumber = customer.contactInfo.phoneNumbers.find(phoneNumber => phoneNumber.description === type);

    if ('Mobile' === type && phoneNumber == null)
        phoneNumber = customer.contactInfo.phoneNumbers.find(
            phoneNumber =>
                phoneNumber.description == null ||
                phoneNumber.description == undefined ||
                !['Mobile', 'Home', 'Work', 'Pager'].includes(phoneNumber.description)
        );

    return phoneNumber ? parsePhoneNumberCustom(phoneNumber.value) : null;
};

export const getEmail = (customer: Customer) => {
    return customer?.contactInfo?.email?.value ? customer.contactInfo.email.value : null;
};

export const getAddress = (customer: Customer) => {
    if (customer?.contactInfo?.address == null) return '';
    const address = customer?.contactInfo?.address;
    const addresses: string[] = [];
    if (address.mailing || address.street) addresses.push(address.mailing ?? address.street ?? '');
    if (address.zip) addresses.push(address.zip);
    if (address.state && address.city) addresses.push(`${address.city}, ${address.state}`);
    else if (address.city) addresses.push(address.city);
    else if (address.state) addresses.push(address.state);
    if (address.country) addresses.push(address.country);
    return addresses.join(' ');
};

const textInnerHtml = (text: string) => {
    return (
        <span
            style={{ wordBreak: 'break-all' }}
            dangerouslySetInnerHTML={{
                __html: insertLink(text)
            }}
        ></span>
    );
};

// const displayVehicle = (vehicle?: VehicleDataObject) => {
//     if (vehicle?.model == null) return null;

//     const result: string[] = [];
//     if (vehicle.year != null) result.push(vehicle.year.toString());
//     if (typeof vehicle.model.make === 'string') result.push(vehicle.model.make);
//     if (typeof vehicle.model.name === 'string') result.push(vehicle.model.name);
//     return result.join(' ');
// };

const LeadPanel = ({ lead, editPermission, handleCommentVisible, lastComment }: Props) => {
    const { Paragraph } = Typography;
    const customer = lead.customer;

    const getOpportunity = (lead: LeadDataObject) => {
        if (lead.opportunity != null) return lead.opportunity;
        if (Array.isArray(lead.opportunities) && lead.opportunities.length > 0) return lead.opportunities[0];
    };

    const getOpportunityVehicle = (lead: LeadDataObject) => {
        if (lead.opportunity != null && typeof lead.opportunity.vehicle != 'string') return lead.opportunity.vehicle;
        if (Array.isArray(lead.opportunities) && lead.opportunities.length > 0)
            return typeof lead.opportunities[0].vehicle != 'string' ? lead.opportunities[0].vehicle : null;
    };

    const getPhone = () => {
        const phone: JSX.Element[] = [];
        const mobilePhone = getPhoneNumber(customer, 'Mobile');
        const workPhone = getPhoneNumber(customer, 'Work');
        const homePhone = getPhoneNumber(customer, 'Home');

        if (mobilePhone) phone.push(<span key={'Mobile'}>{mobilePhone} (Mobile)</span>);

        if (workPhone)
            phone.push(
                <span key={'Work'}>
                    , {workPhone} (Work) <br />
                </span>
            );
        if (homePhone) phone.push(<span key={'Home'}> {homePhone} (Home)</span>);

        return phone;
    };
    const title = () => {
        const commentActivity = getLashCommentActivity();
        if (commentActivity == null) return null;
        return (
            <Space align="center" size={5}>
                <span>
                    <Avatar style={getAvatarStyle(commentActivity._createBy)} size="small">
                        {commentActivity._createBy
                            ? commentActivity._createBy?.firstName?.substring(0, 1) + commentActivity._createBy?.lastName?.substring(0, 1)
                            : ''}
                    </Avatar>
                </span>
                <span>
                    {commentActivity._createBy ? `${commentActivity._createBy.firstName} ${commentActivity._createBy.lastName}` : ''}
                </span>
                <span>
                    {commentActivity.contactType === LeadContactType.PhoneCall
                        ? '📞'
                        : commentActivity.contactType === LeadContactType.Email
                        ? '📧'
                        : commentActivity.contactType === LeadContactType.SMS
                        ? '📱'
                        : commentActivity.contactType === LeadContactType.Appointment
                        ? '📅'
                        : commentActivity.contactType === LeadContactType.Fax
                        ? '📠'
                        : commentActivity.contactType === LeadContactType.Letter
                        ? '📮'
                        : '📝'}
                </span>
                <Tooltip title={dayjs(commentActivity._createAt).format('lll')}>
                    <span
                        style={{
                            fontSize: '13px',
                            color: 'rgba(0, 0, 0, 0.45)'
                        }}
                    >
                        {dayjs(commentActivity._createAt).fromNow()}
                    </span>
                </Tooltip>
            </Space>
        );
    };

    const getLashCommentActivity = () => {
        if (!Array.isArray(lead.activities)) return null;
        let activityList = lead.activities.filter(act => act.comment);
        if (!Array.isArray(activityList) || activityList.length == 0) return null;
        activityList = activityList.sort(function (a, b) {
            return dayjs(b._createAt)?.valueOf() - dayjs(a._createAt)?.valueOf();
        });
        return activityList[0];
    };

    const getLashComment = () => {
        const result = getLashCommentActivity()?.comment;
        let _lastComment: string[] = [];
        if (lastComment != null) _lastComment = lastComment.split('#Lead#');
        if (_lastComment.length > 1 && _lastComment[0] === lead.id) return _lastComment[1];
        return textInnerHtml(result ?? '');
    };

    const onCommentClick = () => {
        // if ([LeadStatus.Converted, LeadStatus.Lost, LeadStatus.Defeated].some(status => (lead.status & status) === status)) return;
        // if (!editPermission) return;
        if (typeof handleCommentVisible === 'function') handleCommentVisible(lead.id);
    };

    // const commentDisabled = () => {
    //     if ([LeadStatus.Converted, LeadStatus.Lost, LeadStatus.Defeated].some(status => (lead.status & status) === status)) return true;
    //     if (!editPermission) return true;
    //     return false;
    // };

    // const LashComment = () => {
    //     const comment = getLashComment();
    //     if ([LeadStatus.Converted, LeadStatus.Lost, LeadStatus.Defeated].some(status => (lead.status & status) === status))
    //         return <>{comment}</>;
    //     if (!editPermission) return <>{comment}</>;
    //     return (
    //         <Link to={''} style={{ color: '#2a7fff' }}>
    //             {comment}
    //         </Link>
    //     );
    // };

    return (
        <div key={lead.id}>
            <div className={styles.leadGrid}>
                <div className={styles.box}>
                    <span className={styles.label}>
                        Phone #<span className={styles.colon}>:</span>
                    </span>
                    <div className={styles.content}>{getPhone()}</div>
                </div>
                <div className={styles.box}>
                    <span className={styles.label}>
                        Comments<span className={styles.colon}>:</span>
                    </span>
                    <div className={styles.content}>
                        <div style={{ display: 'flex' }}>
                            <div style={{ maxWidth: '90%' }}>
                                <Popover overlayInnerStyle={{ maxWidth: 850 }} content={getLashComment()} title={title} placement="topLeft">
                                    <Paragraph ellipsis={{ rows: 2, expandable: false }}>{getLashComment()}</Paragraph>
                                </Popover>
                            </div>
                            <Button
                                style={{ lineHeight: 0 }}
                                disabled={!editPermission}
                                size="small"
                                type="link"
                                onClick={() => onCommentClick()}
                            >
                                <EditOutlined style={{ color: '#2a7fff' }} />
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={styles.box}>
                    <span className={styles.label}>
                        Test drived<span className={styles.colon}>:</span>
                    </span>
                    <div className={styles.content}>{getOpportunity(lead)?.testDrived ? 'Yes' : 'No'}</div>
                </div>
                <div className={styles.box}>
                    <span className={styles.label}>
                        Model<span className={styles.colon}>:</span>
                    </span>
                    <div className={styles.content}>
                        {getOpportunityVehicle(lead)?.year} {getOpportunity(lead)?.make} {getOpportunity(lead)?.model}{' '}
                        {getOpportunity(lead)?.color}
                    </div>
                </div>
                <div className={styles.box}>
                    <span className={styles.label}>
                        Summary<span className={styles.colon}>:</span>
                    </span>
                    <div className={styles.content}>
                        {lead.summary && lead.summary.length > 150 ? (
                            <Tooltip title={textInnerHtml(lead.summary)} placement="bottom" overlayStyle={{ maxWidth: 500 }}>
                                <Paragraph style={{ maxWidth: '98%' }} ellipsis={{ rows: 2, expandable: false }}>
                                    {lead.summary}
                                </Paragraph>
                            </Tooltip>
                        ) : (
                            <Paragraph ellipsis={{ rows: 2, expandable: false }}>{textInnerHtml(lead.summary)}</Paragraph>
                        )}
                    </div>
                </div>
                <div className={styles.box}>
                    <span className={styles.label}>
                        Demonstrated<span className={styles.colon}>:</span>
                    </span>
                    <div className={styles.content}>{getOpportunity(lead)?.demonstrated ? 'Yes' : 'No'}</div>
                </div>
                <div className={styles.box}>
                    <span className={styles.label}>
                        Vehicle<span className={styles.colon}>:</span>
                    </span>
                    <div className={styles.content}>
                        <a
                            style={{ color: '#2a7fff' }}
                            href={`/vehicle/inventory/${getOpportunityVehicle(lead)?.id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {getOpportunity(lead)?.stockNo} {getOpportunity(lead)?.vin}
                        </a>
                    </div>
                </div>
                <div className={styles.box}>
                    {lead.lostReason && lead.lostReason.length > 90 ? (
                        <>
                            <div className={styles.label} style={{ width: '90px' }}>
                                {'Lost reason'}
                                <span className={styles.colon}>:</span>
                            </div>
                            <div className={styles.content} style={{ marginLeft: -5 }}>
                                <Tooltip title={textInnerHtml(lead.lostReason)} placement="bottom" overlayStyle={{ maxWidth: 500 }}>
                                    <Paragraph style={{ maxWidth: '98%' }} ellipsis={{ rows: 1, expandable: false }}>
                                        {lead.lostReason}
                                    </Paragraph>
                                </Tooltip>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={styles.label}>
                                {'Lost reason'}
                                <span className={styles.colon}>:</span>
                            </div>
                            <div className={styles.content}>
                                <Paragraph ellipsis={{ rows: 1, expandable: false }}>{textInnerHtml(lead.lostReason ?? '')} </Paragraph>
                            </div>
                        </>
                    )}
                </div>
                <div className={styles.box}></div>
            </div>
        </div>
    );
};

export default LeadPanel;
