import type { ColumnProps, TablePaginationConfig } from 'antd/lib/table';
import { message, notification, Button, Col, Input, Modal, Popconfirm, Row, Space, Table, Tag, Tooltip } from 'antd';
import { FilterValue, TableCurrentDataSource } from 'antd/lib/table/interface';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { orderBy, uniq } from 'lodash';
import { nanoid } from 'nanoid';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import { MergeCellsOutlined, MessageTwoTone, PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';

import { sessionStore } from '../../../services/store';
import { LeadPriority, Setting } from '../../admin/setting/model';
import { initLeadPriority, leadPriorityInitData, SEARCH_SETTING } from '../../admin/setting/SettingEdit';
import UserAvatar from '../../components/UserAvatar';
import CustomerHistoryList from '../../desking/customer/CustomerHistoryList';
import UserSearcherSelect from '../../service/appointment/components/UserSearcherSelect';
import { ClearButton, DatePicker, EnumSelect, Page, ReloadButton, TimeCell } from '../components';
import { UserContext } from '../contexts';
import { useDelete, useFilters, useUpdate } from '../hooks';
import CallCustomerModal from './components/CallCustomerModal';
import CloseLead from './components/CloseLead';
import EmailCustomerModal from './components/EmailCustomerModal';
import LeadCommentModal from './components/LeadCommentModal';
import LeadPanel, { getPhoneNumber } from './components/LeadPanel';
import SmsCustomerModal from './components/SmsCustomerModal';
import { ABANDON_LEAD, CLOSE_LEAD, REOPEN_LEAD, SEARCH_LEADS } from './graphql';
import LedGreen from './images/led_green.png';
import LedRed from './images/led_red.png';
import LedYellow from './images/led_yellow.png';
import {
    Customer,
    CustomerName,
    CustomerType,
    LeadCloseInput,
    LeadContactStatus,
    LeadDataObject,
    LeadProspectLevel,
    LeadStatus,
    TeamType
} from './models';
import styles from './styles.module.scss';
import { useSearch } from './useSearch';
import { insertLink } from './utils';

interface Filter {
    _search?: string;
    prospectLevel?: any;
    status?: any;
    _noSearch?: boolean;
    assignee?: string;
    scheduledTimeFrom?: string;
    scheduledTimeTo?: string;
    createTimeFrom?: string;
    createTimeTo?: string;
}
dayjs.extend(relativeTime);
const Column = (props: ColumnProps<LeadDataObject>) => <Table.Column<LeadDataObject> {...props} />;

const textInnerHtml = (text: string) => {
    return (
        <span
            style={{ wordBreak: 'break-all' }}
            dangerouslySetInnerHTML={{
                __html: insertLink(text)
            }}
        ></span>
    );
};

const buildLedImage = (led: string, lastDay: dayjs.Dayjs | null, initContactSpanMinutes: number, createAt?: dayjs.Dayjs) => {
    let dueCreateAt = null;
    let isInitialcontact = false;
    if (createAt != null) {
        dueCreateAt = createAt.add(initContactSpanMinutes, 'minute');
        isInitialcontact = dayjs().diff(createAt, 'minute', true) < initContactSpanMinutes;
    }
    return (
        <Tooltip
            title={
                lastDay != null ? (
                    <>
                        {`Last contact ${lastDay.fromNow()}`}
                        <br />
                        {`at ${lastDay.format('M/D h:mm a')} `}
                    </>
                ) : dueCreateAt != null && !isInitialcontact ? (
                    <>
                        {`First contact is due by ${dueCreateAt?.format('M/D h:mm a')}`}
                        <br />
                        {`${dueCreateAt?.fromNow()}`}
                    </>
                ) : (
                    <>
                        {`First contact is due by ${dueCreateAt?.format('M/D h:mm a')}`}
                        <br />
                        {`in ${initContactSpanMinutes - dayjs().diff(createAt, 'minute')} minutes`}
                    </>
                )
            }
        >
            <img src={led} width={15} />
        </Tooltip>
    );
};

export const buildLedLight = (lead: LeadDataObject, leadPriority: LeadPriority) => {
    const leadFrom = leadPriority.from;
    const initContactSpanMinutes = leadPriority.minutes;

    if ([LeadStatus.Converted, LeadStatus.Lost, LeadStatus.Defeated].some(status => (lead.status & status) === status)) return;
    if (lead.contactStatus == null) return;
    if (!(lead.source && leadFrom && leadFrom.trim().toLowerCase().split('\n').includes(lead.source.toLowerCase()))) return;

    const lastContactedAt = lead.lastContactAt != null ? dayjs(lead.lastContactAt) : null;

    // const createDiffHours = dayjs().diff(lead._createAt, 'hour', true);
    const createDiffDays = dayjs().diff(lead._createAt, 'day', true);
    const toady = dayjs(dayjs().format('L')); // MM/DD/YYYY
    const lastContacted = dayjs(lastContactedAt?.format('L'));

    /** test */
    // const toady = dayjs(dayjs().add(1, 'day').format('L')); // MM/DD/YYYY
    // const toady = dayjs(dayjs().subtract(1, 'day').format('L')); // MM/DD/YYYY
    // const lastContacted = dayjs(dayjs().add(1, 'day').format('L'));

    // console.log('========================');
    // console.log(`${lead.customer.name.firstName}  ${lead.customer.name.companyName}`);
    // console.log(`createDiffHours=${createDiffHours},createDiffDays=${createDiffDays}`);
    // console.log(`toady=${toady.format('L')},lastContacted=${lastContacted.format('L')},diff=${toady.diff(lastContacted, 'day')}`);
    // console.log(dayjs('08/27/2022').diff(lead._createAt, 'hour', true));
    /** test */
    const toadyLastContactedDiff = toady.diff(lastContacted, 'day');
    if (dayjs().diff(lead._createAt, 'minute', true) < initContactSpanMinutes) {
        // 15 分钟有联系过则 green
        if (lead.contactStatus === LeadContactStatus.Success) return buildLedImage(LedGreen, lastContactedAt, initContactSpanMinutes);
        return buildLedImage(LedYellow, lastContactedAt, initContactSpanMinutes, dayjs(lead._createAt));
    }

    //
    /**
     * Leads older than [  ] days, follow up interval: [  ] days
     * **/

    if (
        leadPriority.followupIntervals != null &&
        Array.isArray(leadPriority.followupIntervals) &&
        leadPriority.followupIntervals?.length > 0
    ) {
        // console.log(`followupIntervals=${JSON.stringify(leadPriority?.followupIntervals)}`);
        let followupIntervals = leadPriority?.followupIntervals.filter(
            followupInterval => followupInterval.leadsOlderThan < createDiffDays
        );
        if (followupIntervals.length > 0) {
            followupIntervals = orderBy(followupIntervals, ['leadsOlderThan'], ['desc']);
            // console.log(followupIntervals[0]);
            const followupInterval = followupIntervals[0].followupInterval ?? leadPriority.defaultFollowupInterval;
            // console.log(`followupInterval=${followupInterval}`);
            //** 第 X 天联系过 变为 绿灯   <= 是每隔 X 天  < 是每 X 天 */
            if (lead.contactStatus === LeadContactStatus.Success && lastContactedAt != null && toadyLastContactedDiff < followupInterval)
                return buildLedImage(LedGreen, lastContactedAt, initContactSpanMinutes);
            if (lastContactedAt == null && lead.contactStatus === LeadContactStatus.None)
                return buildLedImage(LedRed, lastContactedAt, initContactSpanMinutes, dayjs(lead._createAt));
            return buildLedImage(LedYellow, lastContactedAt, initContactSpanMinutes);
        }
    }

    /** test */
    // console.log(`defaultFollowupInterval=${leadPriority.defaultFollowupInterval}`);
    /** test */

    /**
     * Default lead follow-up interval: [  ] days
     * **/
    if (
        lead.contactStatus === LeadContactStatus.Success &&
        lastContactedAt != null &&
        toadyLastContactedDiff < leadPriority.defaultFollowupInterval
    )
        return buildLedImage(LedGreen, lastContactedAt, initContactSpanMinutes);
    if (lastContactedAt == null && lead.contactStatus === LeadContactStatus.None)
        return buildLedImage(LedRed, lastContactedAt, initContactSpanMinutes, dayjs(lead._createAt));
    return buildLedImage(LedYellow, lastContactedAt, initContactSpanMinutes);
};

const SearchLead = () => {
    const user = useContext(UserContext);

    const isSales = useMemo(
        () => user != null && !user.roles.includes('admin') && !user.roles.includes('salesmanager') && user.pages.includes('crm/lead'),
        [user]
    );

    const pageStoreKey = 'lead.management.page';
    const [filters, setStoreFilters, onReset] = useFilters('lead.filters', { _noSearch: true }); //, assignee: isSales ? user?.id : undefined
    const [pageNum] = useState(sessionStore.get(pageStoreKey) || 1);
    const navigate = useNavigate();
    const defaultSortDescend = '!_createAt';
    const defaultSortAscend = '_createAt';
    const { loading, page, data, pagination, setFilter, setSort, refresh } = useSearch<Filter>(
        SEARCH_LEADS,
        isSales ? Object.assign({}, filters, { assignee: user?.id }) : filters,
        [defaultSortDescend],
        25,
        pageNum
    );
    const { data: settings } = useSearch<any, Setting>(SEARCH_SETTING, { _noSearch: true, name: 'leadpriority' }, [], -1);

    const [closeLead] = useUpdate(CLOSE_LEAD);
    const [reopenLead] = useMutation(REOPEN_LEAD);
    const [abandonLead] = useDelete(ABANDON_LEAD);

    const [leads, setLeads] = useState<LeadDataObject[]>([]);

    // const [convertLead] = useMutation(CONVERT_LEAD);
    const [closeLeadVisible, setCloseLeadVisible] = useState(false);
    const [selectedLead, setSelectedLead] = useState<LeadDataObject>();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedLeadIds, setSelectedLeadIds] = useState<string[]>([]);
    const [selectedLeads, setSelectedLeads] = useState<any[]>([]);

    const [commentVisible, setCommentVisible] = useState<boolean>(false);
    const [lastComment, setLastComment] = useState<string>();
    // lead led light priority  red,yellow,green
    const [leadPriority, setLeadPriority] = useState<LeadPriority>(leadPriorityInitData);

    const [callModalVisible, setCallModalVisible] = useState<boolean>(false);
    const [smsModalVisible, setSmsModalVisible] = useState<boolean>(false);
    const [emailModalVisible, setEmailModalVisible] = useState<boolean>(false);

    //customer History
    const [historyModalVisible, setHistoryModalVisible] = useState<boolean>(false);

    const [searchText, setSearchText] = useState<string>();

    useEffect(() => {
        if (data == null || settings == null) return;
        sessionStore.get(pageStoreKey, page) && sessionStore.remove(pageStoreKey);
        setLeads(data as LeadDataObject[]);
        setSearchText(filters._search);
        initLeadPriorityParam(settings);
    }, [data, filters._search, page, settings]);

    const initLeadPriorityParam = (settings: Setting[]) => {
        const leadPriorityParam = initLeadPriority(settings);
        if (leadPriorityParam == null) return;
        setLeadPriority(leadPriorityParam);
    };

    const onTextKeypress: React.KeyboardEventHandler<HTMLInputElement> = e => {
        if (e.key === 'Enter') {
            const value = e.currentTarget.value === '' ? undefined : e.currentTarget.value;
            setStoreFilters('_search', value);
            setFilter('_search', value);
        }
    };

    const onTextChange = (e: any) => {
        setSearchText(e.target.value);
        if (e.target.value === '') {
            // input is cleared.
            setStoreFilters('_search', undefined);
        }
        if (e.type === 'click') {
            // clear icon is clicked
            setStoreFilters('_search', undefined);
            setFilter('_search', undefined);
        }
    };

    const resetFilters = () => {
        onReset();
        //
        setFilter('_search', undefined);
        setFilter('status', undefined);
        setFilter('prospectLevel', undefined);
        if (!isSales) setFilter('assignee', undefined);
        // setParam('assignee', undefined);
        setFilter('scheduledTimeFrom', undefined);
        setFilter('scheduledTimeTo', undefined);

        setFilter('createTimeFrom', undefined);
        setFilter('createTimeTo', undefined);
        setSearchText(undefined);
        // refresh();
    };

    // permissions
    const createPermission = useMemo(
        () => user != null && (user.roles.includes('admin') || user.permissions.includes('lead:create')),
        [user]
    );
    const editPermission = useMemo(() => user != null && (user.roles.includes('admin') || user.permissions.includes('lead:edit')), [user]);
    const assignPermission = useMemo(
        () => user != null && (user.roles.includes('admin') || user.permissions.includes('lead:assign')),
        [user]
    );
    const convertPermission = useMemo(
        () => user != null && (user.roles.includes('admin') || user.permissions.includes('lead:convert')),
        [user]
    );
    const closePermission = useMemo(
        () => user != null && (user.roles.includes('admin') || user.permissions.includes('lead:close')),
        [user]
    );
    const reopenPermission = useMemo(
        () => user != null && (user.roles.includes('admin') || user.permissions.includes('lead:reopen')),
        [user]
    );
    const abandonPermission = useMemo(
        () => user != null && (user.roles.includes('admin') || user.permissions.includes('lead:delete')),
        [user]
    );

    // 查询面板
    const today = dayjs();
    const tomorrow = today.add(1, 'day');

    const onCloseLead = async (id: string, leadCloseInput: LeadCloseInput) => {
        try {
            setIsSubmitting(true);
            if (leadCloseInput) {
                setCloseLeadVisible(false);
                await closeLead(id, leadCloseInput);
                if (!leadCloseInput.lost && leadCloseInput.createDeal) {
                    selectedLead?.customer.id && navigate(`/desking/deal/new?lead=${selectedLead?.id}`);
                } else {
                    navigate('./.');
                }
            }
        } catch (err) {
            console.error(err);
            notification.error({ duration: 0, message: 'Something went wrong', description: err.message });
        } finally {
            setIsSubmitting(false);
        }
    };

    const onReopenLead = async (id: string) => {
        try {
            setIsSubmitting(true);
            if (id) {
                setCloseLeadVisible(false);
                await reopenLead({ variables: { id } });
                navigate('./.');
            }
        } catch (err) {
            console.error(err);
            notification.error({ duration: 0, message: 'Something went wrong', description: err.message });
        } finally {
            setIsSubmitting(false);
        }
    };

    const onConvertLead = async (id: string) => {
        try {
            setIsSubmitting(true);
            if (id) {
                // await convertLead({ variables: { id } });
                navigate(`/desking/deal/new?lead=${id}`);
            }
        } catch (err) {
            console.error(err);
            notification.error({ duration: 0, message: 'Something went wrong', description: err.message });
        } finally {
            setIsSubmitting(false);
        }
    };

    const onAbandonLead = async (id: string) => {
        try {
            setIsSubmitting(true);
            if (id) {
                await abandonLead(id);
            }
        } catch (err) {
            console.error(err);
            notification.error({ duration: 0, message: 'Something went wrong', description: err.message });
        } finally {
            setIsSubmitting(false);
        }
    };

    const setAssignee = (value: string | string[]) => {
        if (Array.isArray(value)) return;
        setStoreFilters('assignee', value);
        // setPage(1);
        setFilter('assignee', value);
        // setParam('assignee', value);
    };

    const getColor = (status: any) => {
        let color: string | undefined = undefined;
        switch (status) {
            case LeadStatus.New:
                color = 'cyan';
                break;
            case LeadStatus.Assigned:
                color = 'blue';
                break;
            case LeadStatus.Appointment:
                color = 'purple';
                break;
            case LeadStatus.InProgress:
                color = 'geekblue';
                break;
            case LeadStatus.Converted:
                color = 'green';
                break;
            case LeadStatus.Lost:
                color = 'red';
                break;
            case LeadStatus.Defeated:
                color = 'red';
                break;
        }
        return color;
    };

    const displayCustomerName = (name: CustomerName) => (
        <span>
            {name == null
                ? ''
                : name.type === CustomerType.Person
                ? `${name.lastName ?? ''}, ${name.firstName ?? ''}`
                : name.companyName ?? ''}
        </span>
    );

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: any,
        extra: TableCurrentDataSource<LeadDataObject>
    ) => {
        if (extra.action === 'sort') {
            switch (sorter.columnKey) {
                case '_createdAt':
                    if (sorter.order === 'ascend') setSort([defaultSortAscend]);
                    if (sorter.order === 'descend') setSort([defaultSortDescend]);
                    break;
                case 'prospectLevel':
                    if (sorter.order === 'ascend') setSort(['prospectLevel']);
                    if (sorter.order === 'descend') setSort(['!prospectLevel']);
                    if (sorter.order === undefined) setSort([defaultSortDescend]);
                    break;
                case 'source':
                    if (sorter.order === 'ascend') setSort(['source']);
                    if (sorter.order === 'descend') setSort(['!source']);
                    if (sorter.order === undefined) setSort([defaultSortDescend]);
                    break;
            }
        }
    };

    const getTimeFromTo = (timeFrom: string, timeTo: string) => {
        if (timeFrom == null || timeTo == null) return [];
        return [dayjs(timeFrom), dayjs(timeTo).subtract(1, 'day')];
    };

    const table = (
        <Table
            rowKey="id"
            size="small"
            dataSource={leads}
            pagination={pagination}
            loading={loading || isSubmitting}
            className={styles.table}
            rowSelection={{
                type: 'checkbox',
                selectedRowKeys: selectedLeadIds,
                preserveSelectedRowKeys: true,
                onChange: (selectedRowKeys: any[], selectedRows: any[]) => {
                    setSelectedLeadIds([...selectedRowKeys]);
                    setSelectedLeads([...selectedRows]);
                },
                getCheckboxProps: record => ({
                    disabled: [LeadStatus.Converted].some(status => (record.status & status) === status) ? true : false
                })
            }}
            expandable={{
                expandRowByClick: true,
                expandedRowRender: record => (
                    <LeadPanel
                        lead={record}
                        lastComment={lastComment}
                        editPermission={editPermission}
                        handleCommentVisible={() => {
                            setSelectedLead(record);
                            setLastComment(undefined);
                            setCommentVisible(true);
                        }}
                    />
                )
            }}
            onChange={handleTableChange}
        >
            <Column
                width={22}
                title=""
                render={(_, record) => {
                    return buildLedLight(record, leadPriority);
                }}
            />

            <Column
                width={160}
                title="Name"
                key="name"
                render={(_, record) => {
                    if (record.customer) {
                        return (
                            <Link style={{ color: '#2a7fff' }} to={record.id}>
                                {displayCustomerName(record.customer.name)}
                            </Link>
                        );
                    }
                }}
            />
            <Column
                title="Contact"
                width={160}
                ellipsis={true}
                render={(_, record) => {
                    if (record.customer) {
                        const contact: JSX.Element[] = [];
                        const phoneNumber = getPhoneNumber(record.customer, 'Mobile');
                        const email = record.customer.contactInfo.email?.value;
                        if (phoneNumber && phoneNumber !== '')
                            contact.push(
                                <span key={'Phone'}>
                                    <Button
                                        type="link"
                                        size="small"
                                        style={{ padding: 0 }}
                                        onClick={() => {
                                            setSelectedLead(record);
                                            setCallModalVisible(true);
                                        }}
                                    >
                                        {phoneNumber}
                                    </Button>
                                    <Button
                                        type="link"
                                        size="small"
                                        style={{ padding: 0, marginLeft: 5 }}
                                        onClick={() => {
                                            setSelectedLead(record);
                                            setSmsModalVisible(true);
                                        }}
                                    >
                                        <MessageTwoTone twoToneColor="#52c41a" />
                                    </Button>
                                </span>
                            );
                        if (email)
                            contact.push(
                                <span key={'Email'}>
                                    {contact.length >= 1 && <br />}
                                    <Button
                                        type="link"
                                        size="small"
                                        style={{ padding: 0 }}
                                        onClick={() => {
                                            setSelectedLead(record);
                                            setEmailModalVisible(true);
                                        }}
                                    >
                                        {email}
                                    </Button>
                                </span>
                            );
                        return contact;
                    }
                }}
            />
            <Column
                sorter={true}
                key="prospectLevel"
                title="Prospect"
                width={80}
                render={(_, record) => (typeof record?.prospectLevel === 'number' && LeadProspectLevel[record?.prospectLevel]) || null}
            />

            <Column sorter={true} key="source" width={100} ellipsis={true} title="Source" dataIndex="source" />
            <Column
                width={140}
                ellipsis={{ showTitle: false }}
                title="Summary"
                dataIndex="summary"
                render={(_, record) => {
                    return (
                        <Tooltip title={textInnerHtml(record.summary)} placement="topLeft" overlayStyle={{ maxWidth: 500 }}>
                            {record.summary}
                        </Tooltip>
                    );
                }}
            />
            <Column
                width={80}
                align="center"
                ellipsis={true}
                title="Sales rep"
                render={(_, record) => {
                    if (record.assignee) {
                        return <UserAvatar size="small" firstName={record.assignee.firstName} lastName={record.assignee.lastName} />;
                    }
                }}
            />

            <Column
                title="Status"
                width={180}
                render={(_, record) => {
                    const tags = [];
                    if ((record.status & LeadStatus.New) === LeadStatus.New) tags.push(LeadStatus.New);
                    if ((record.status & LeadStatus.Assigned) === LeadStatus.Assigned) tags.push(LeadStatus.Assigned);
                    if ((record.status & LeadStatus.InProgress) === LeadStatus.InProgress) tags.push(LeadStatus.InProgress);
                    if ((record.status & LeadStatus.Appointment) === LeadStatus.Appointment) tags.push(LeadStatus.Appointment);
                    if ((record.status & LeadStatus.Converted) === LeadStatus.Converted) tags.push(LeadStatus.Converted);
                    if ((record.status & LeadStatus.Lost) === LeadStatus.Lost) tags.push(LeadStatus.Lost);
                    if ((record.status & LeadStatus.Defeated) === LeadStatus.Defeated) tags.push(LeadStatus.Defeated);
                    return (
                        <>
                            {tags.map((tag, i) => (
                                <Tag color={getColor(tag)} key={i}>
                                    {LeadStatus[tag]}
                                </Tag>
                            ))}
                        </>
                    );
                }}
            />
            <Column
                width={180}
                sorter={true}
                key="_createdAt"
                defaultSortOrder="descend"
                showSorterTooltip={true}
                // sortOrder={sortOrderCreate}
                sortDirections={['descend', 'ascend', 'descend']}
                ellipsis={true}
                title="Create at"
                render={(_, record) => (record._createAt != null ? <TimeCell time={new Date(record._createAt)} /> : null)}
            />
            <Column
                width={200}
                title="Actions"
                render={(_, record) => (
                    <Space size={0} wrap>
                        <Button
                            size="small"
                            type="link"
                            onClick={() => {
                                if (page > 1) sessionStore.set(pageStoreKey, page);
                                navigate(record.id);
                            }}
                        >
                            {![LeadStatus.Converted, LeadStatus.Lost, LeadStatus.Defeated].some(
                                status => (record.status & status) === status
                            ) && editPermission
                                ? 'Edit'
                                : 'View'}
                        </Button>
                        {![LeadStatus.Converted].some(status => (record.status & status) === status) && (
                            <Button
                                size="small"
                                type="link"
                                disabled={!assignPermission}
                                onClick={() => {
                                    const id = nanoid(8);
                                    sessionStorage.setItem(id, JSON.stringify([record]));
                                    if (page > 1) sessionStore.set(pageStoreKey, page);
                                    navigate(`${id}/assign`);
                                }}
                            >
                                Assign
                            </Button>
                        )}
                        {![LeadStatus.Converted, LeadStatus.Lost, LeadStatus.Defeated].some(
                            status => (record.status & status) === status
                        ) && (
                            <Popconfirm
                                disabled={isSubmitting || !convertPermission}
                                placement="right"
                                title="Convert this lead?"
                                okText="Yes"
                                cancelText="No"
                                onConfirm={async () => await onConvertLead(record.id)}
                            >
                                <Button size="small" type="link" disabled={isSubmitting || !convertPermission}>
                                    Convert
                                </Button>
                            </Popconfirm>
                        )}
                        {[LeadStatus.Defeated].some(status => (record.status & status) === status) && (
                            <Button
                                size="small"
                                type="link"
                                disabled={isSubmitting || !closePermission}
                                onClick={() => {
                                    setCloseLeadVisible(true);
                                    setSelectedLead(record);
                                }}
                            >
                                Close
                            </Button>
                        )}
                        {[LeadStatus.Lost].some(status => (record.status & status) === status) && (
                            <Popconfirm
                                disabled={isSubmitting || !reopenPermission}
                                placement="right"
                                title="Reopen this lead?"
                                okText="Yes"
                                cancelText="No"
                                onConfirm={async () => await onReopenLead(record.id)}
                            >
                                <Button size="small" type="link" disabled={isSubmitting || !reopenPermission}>
                                    Reopen
                                </Button>
                            </Popconfirm>
                        )}
                        {![LeadStatus.Converted, LeadStatus.Lost, LeadStatus.Defeated].some(
                            status => (record.status & status) === status
                        ) && (
                            <Popconfirm
                                disabled={isSubmitting || !abandonPermission}
                                placement="right"
                                title="Abandon this lead?"
                                okText="Yes"
                                cancelText="No"
                                onConfirm={async () => {
                                    await onAbandonLead(record.id);
                                    setLeads(leads.filter(d => d.id !== record.id));
                                }}
                            >
                                <Button size="small" type="link" disabled={isSubmitting || !abandonPermission}>
                                    Abandon
                                </Button>
                            </Popconfirm>
                        )}
                        {![LeadStatus.Converted, LeadStatus.Lost, LeadStatus.Defeated].some(
                            status => (record.status & status) === status
                        ) && (
                            <Button
                                size="small"
                                type="link"
                                disabled={isSubmitting || !editPermission}
                                onClick={() => {
                                    setSelectedLead(record);
                                    setCommentVisible(true);
                                }}
                            >
                                Comment
                            </Button>
                        )}
                        <Button
                            size="small"
                            type="link"
                            onClick={() => {
                                setSelectedLead(record);
                                setHistoryModalVisible(true);
                            }}
                        >
                            History
                        </Button>
                    </Space>
                )}
            />
        </Table>
    );

    const getName = (customer?: Customer) =>
        customer &&
        (customer.name == null
            ? ''
            : customer.type === CustomerType.Person
            ? `${customer.name.lastName ?? ''}, ${customer.name.firstName ?? ''}`
            : customer.name.companyName ?? '');

    return (
        <Page title="Lead Management">
            <Page.Action>
                <Button type="primary" icon={<PlusOutlined />} onClick={() => navigate('new')} disabled={!createPermission}>
                    New
                </Button>
                <Button
                    type="primary"
                    onClick={() => {
                        const id = nanoid(8);
                        sessionStorage.setItem(id, JSON.stringify(selectedLeads));
                        navigate(`${id}/assign`);
                    }}
                    disabled={selectedLeads.length == 0 || !assignPermission}
                >
                    Assign
                </Button>
                <Button
                    type="primary"
                    icon={<MergeCellsOutlined />}
                    onClick={() => {
                        if (uniq(selectedLeads.map(o => o.customer.type)).length > 1) {
                            message.info('Only customers with same type can be merged.');
                            return;
                        }
                        const id = nanoid(8);
                        sessionStorage.setItem(id, JSON.stringify(selectedLeads));
                        navigate(`${id}/merge`);
                    }}
                    disabled={selectedLeads.length != 2}
                >
                    Merge
                </Button>
            </Page.Action>
            <Page.Header>
                <Row gutter={4}>
                    <Col span={4}>
                        <Input
                            placeholder="Search by name, description..."
                            onKeyPress={onTextKeypress}
                            onChange={onTextChange}
                            value={searchText}
                            allowClear
                        />
                    </Col>
                    <Col span={3}>
                        <EnumSelect
                            enum={LeadStatus}
                            placeholder="Status"
                            style={{ width: '100%' }}
                            value={filters.status}
                            onChange={value => {
                                setStoreFilters('status', value);
                                setFilter('status', value);
                            }}
                            allowClear
                        />
                    </Col>
                    <Col span={3}>
                        <EnumSelect
                            enum={LeadProspectLevel}
                            placeholder="Prospect level"
                            style={{ width: '100%' }}
                            value={filters.prospectLevel}
                            onChange={value => {
                                setStoreFilters('prospectLevel', value);
                                setFilter('prospectLevel', value);
                            }}
                            allowClear
                        />
                    </Col>
                    <Col span={3}>
                        <UserSearcherSelect
                            onChange={setAssignee}
                            placeholder="Select Sales rep"
                            type={TeamType.Sales}
                            value={isSales ? user?.id : filters.assignee}
                            name={isSales ? `${user?.firstName} ${user?.lastName}` : undefined}
                            disabled={isSales}
                        />
                    </Col>
                    <Col span={5}>
                        <DatePicker.RangePicker
                            style={{ width: '100%' }}
                            placeholder={['Create from', 'Create to']}
                            format="MM/DD/YYYY"
                            ranges={{
                                Today: [today, today],
                                Tomorrow: [tomorrow, tomorrow],
                                'Next 3 days': [tomorrow, today.add(4, 'day')],
                                'Next 7 days': [tomorrow, today.add(8, 'day')]
                            }}
                            value={(filters.createTimeFrom && getTimeFromTo(filters.createTimeFrom, filters.createTimeTo)) || []}
                            onChange={(dates: any) => {
                                if (dates != null && Array.isArray(dates)) {
                                    const startISO = dates[0] && dates[0].startOf('date').toISOString();
                                    const endISO = dates[1] && dates[1].add(1, 'day').startOf('date').toISOString();
                                    if (startISO != null) {
                                        setStoreFilters('createTimeFrom', startISO);
                                        setFilter('createTimeFrom', startISO);
                                    }
                                    if (endISO != null) {
                                        setStoreFilters('createTimeTo', endISO);
                                        setFilter('createTimeTo', endISO);
                                    }
                                } else {
                                    setStoreFilters('createTimeFrom', undefined);
                                    setFilter('createTimeFrom', undefined);
                                    setStoreFilters('createTimeTo', undefined);
                                    setFilter('createTimeTo', undefined);
                                }
                            }}
                            allowClear
                            inputReadOnly
                        />
                    </Col>

                    <Col span={3}>
                        <ReloadButton
                            onClick={() => {
                                setLastComment(undefined);
                                refresh();
                            }}
                            disabled={loading}
                        />
                        <ClearButton onClick={resetFilters} />
                    </Col>
                </Row>
            </Page.Header>
            <Page.Content>
                {table}

                <LeadCommentModal
                    lead={selectedLead}
                    visible={commentVisible}
                    onCancel={() => setCommentVisible(false)}
                    onComment={comment => setLastComment(comment)}
                />
                <CloseLead
                    id={(selectedLead && selectedLead.id) || ''}
                    visible={closeLeadVisible}
                    onCancel={() => setCloseLeadVisible(false)}
                    onSave={onCloseLead}
                />

                <CallCustomerModal
                    lead={selectedLead as LeadDataObject}
                    visible={callModalVisible}
                    onCancel={() => {
                        setCallModalVisible(false);
                    }}
                />
                <SmsCustomerModal
                    lead={selectedLead}
                    visible={smsModalVisible}
                    onCancel={() => {
                        setSmsModalVisible(false);
                    }}
                />
                <EmailCustomerModal
                    lead={selectedLead}
                    visible={emailModalVisible}
                    onCancel={() => {
                        setEmailModalVisible(false);
                    }}
                />

                <Modal
                    title={`Customer History ${getName(selectedLead?.customer)}`}
                    visible={historyModalVisible}
                    width={1200}
                    footer={null}
                    onCancel={() => {
                        setHistoryModalVisible(false);
                    }}
                    destroyOnClose={true}
                    keyboard={false}
                    centered
                >
                    <div style={{ height: 560, overflowX: 'hidden' }}>
                        <CustomerHistoryList customer={selectedLead?.customer?.id ?? ''} module="Lead" />
                    </div>
                </Modal>
            </Page.Content>
        </Page>
    );
};

export default SearchLead;
