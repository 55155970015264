import { message, notification, Button, Card, Col, Divider, Form, Input, InputNumber, Row, Select, Space, Spin, Tabs } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { isEmpty, isNil, omit, pickBy } from 'lodash';
import { useEffect, useState } from 'react';

import { MinusCircleOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';

import { SEARCH_USERS } from '../../shop/team/graphql';
import { TeamDataObject, TeamType } from '../../shop/team/models';
import { Page } from '../components';
import { useSearch } from '../hooks';
import { AccountStatus, UserDataObject } from '../user/models';
import { LeadPriority, Setting, SettingType, SettingUpdateInput } from './model';
import styles from './styles.module.scss';

export const SEARCH_SETTING = gql`
    query SearchSettings($filter: SettingSearchInput, $sort: [String], $page: Int, $pageSize: Int) {
        searchSettings(filter: $filter, sort: $sort, page: $page, pageSize: $pageSize) {
            total
            data {
                id
                type
                name
                value
            }
        }
    }
`;

export const UPDATE_SETTING = gql`
    mutation UpdateSetting($input: SettingUpdateInput!) {
        updateSetting(input: $input) {
            id
            type
            name
            value
        }
    }
`;

export const SEARCH_TEAMS = gql`
    query SearchTeams($filter: TeamSearchInput) {
        searchTeams(filter: $filter, page: 1, pageSize: 50) {
            total
            data {
                id
                type
                name
                enabled
                members {
                    id
                    firstName
                    lastName
                    email
                    accountStatus
                }
                _updateAt
            }
        }
    }
`;

export const leadPriorityInitData: LeadPriority = {
    from: '',
    minutes: 15,
    defaultFollowupInterval: 1,
    followupIntervals: [{ leadsOlderThan: 7, followupInterval: 3 }]
};

// init lead priority
export const initLeadPriority = (settings: Setting[]) => {
    if (Array.isArray(settings) && settings.length === 0) return leadPriorityInitData;
    const leadPriorityData = settings.find(setting => setting.name === 'leadpriority');
    if (leadPriorityData == null) return leadPriorityInitData;
    let leadPriorityParam = JSON.parse(leadPriorityData.value);
    leadPriorityParam = pickBy(leadPriorityParam, v => !isNil(v) && !isEmpty(v));
    if (Array.isArray(leadPriorityParam?.followupIntervals) && leadPriorityParam?.followupIntervals.length === 0)
        leadPriorityParam = omit(leadPriorityParam, 'followupIntervals');
    const leadPriority = leadPriorityParam != null ? Object.assign({}, leadPriorityInitData, leadPriorityParam) : leadPriorityInitData;
    return leadPriority;
};

const SettingEdit = () => {
    const [form] = Form.useForm();
    const { loading: userLoading, data: teams } = useSearch<any, TeamDataObject>(
        SEARCH_TEAMS,
        { _noSearch: true, type: TeamType.Sales },
        [],
        -1
    );
    const { data: userDataObjects } = useSearch<any, UserDataObject>(SEARCH_USERS, { _noSearch: true }, undefined, -1);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [updateSetting] = useMutation(UPDATE_SETTING);
    const { loading, data } = useSearch<any, Setting>(SEARCH_SETTING, { _noSearch: true }, [], -1);
    const [settings, setSettings] = useState<Setting[]>([]);
    const [users, setUsers] = useState<UserDataObject[]>([]);
    //const [selectStates, setSelectStates] = useState<any[]>([]);

    const [leadPriorityData, setLeadPriorityData] = useState<LeadPriority>(leadPriorityInitData);

    useEffect(() => {
        if (data == null) return;
        const autoassignment = JSON.parse(data.find(setting => setting.name === 'autoassignment')?.value || '[]');
        const assignblacklist = JSON.parse(data.find(setting => setting.name === 'assignblacklist')?.value || '[]');
        for (const item of autoassignment) if (typeof item.to === 'string') item.to = [item.to];
        // init lead priority
        const leadPriority = initLeadPriority(data);
        form.setFieldsValue({
            autoassignment,
            assignblacklist,
            leadpriority: leadPriority,
            //statetax: JSON.parse(data.find(setting => setting.name === 'statetax')?.value || '[]'),
            documentFee: data.find(setting => setting.name === 'documentFee')?.value || 0,
            bankProcessingFee: data.find(setting => setting.name === 'bankProcessingFee')?.value || 0,
            dmvFee: data.find(setting => setting.name === 'dmvFee')?.value || 0,
            tireFee: data.find(setting => setting.name === 'tireFee')?.value || 0
            // plannerwarning: data.find(setting => setting.name === 'plannerwarning')?.value
        });
        setSettings(data);
        setLeadPriorityData(leadPriority);
        // }

        let teamUsers: UserDataObject[] = [];
        if (teams && teams.length > 0) {
            const newUsers = teams.flatMap(o => o.members).map(o => JSON.stringify(o));
            const usersSet = new Set(newUsers);
            teamUsers = Array.from(usersSet).map(o => JSON.parse(o));
        }

        if (userDataObjects && userDataObjects.length > 0) {
            const autoassignments = JSON.parse(data.find(setting => setting.name === 'autoassignment')?.value || '[]');
            const userIds = autoassignments.map((o: any) => o.to);

            const userObject = userDataObjects.filter(o => userIds.includes(o.id));
            userObject.forEach(o => {
                if (!teamUsers.some(r => r.id === o.id)) teamUsers.push(o);
            });
        }
        setUsers(teamUsers);

        //setSelectStates(States);
    }, [data, form, teams, userDataObjects]);

    const onSave = async () => {
        const { autoassignment, leadpriority, assignblacklist } = await form.validateFields();
        // const statetax = form.getFieldValue('statetax');
        const documentFee = form.getFieldValue('documentFee');
        const bankProcessingFee = form.getFieldValue('bankProcessingFee');
        const dmvFee = form.getFieldValue('dmvFee');
        const tireFee = form.getFieldValue('tireFee');

        // leadPriority
        let leadPriority = leadpriority as LeadPriority;
        leadPriority = Object.assign({}, leadpriority, {
            followupIntervals: leadPriority.followupIntervals?.filter(f => f.leadsOlderThan != null && f.followupInterval != null) ?? []
        });

        const groupAutoassignment = new Set(autoassignment.map((o: any) => o.from));
        if (autoassignment.length !== groupAutoassignment.size) {
            message.error('Duplicate autoassignment lead form');
            return;
        }

        const items = [
            {
                id: settings.find(setting => setting.name === 'assignblacklist')?.id,
                name: 'assignblacklist',
                value: JSON.stringify(assignblacklist)
            },
            {
                id: settings.find(setting => setting.name === 'autoassignment')?.id,
                name: 'autoassignment',
                value: JSON.stringify(autoassignment)
            },
            {
                id: settings.find(setting => setting.name === 'leadpriority')?.id,
                name: 'leadpriority',
                value: JSON.stringify(leadPriority)
            },
            // {
            //     id: settings.find(setting => setting.name === 'statetax')?.id,
            //     name: 'statetax',
            //     value: JSON.stringify(statetax)
            // },
            {
                id: settings.find(setting => setting.name === 'documentFee')?.id,
                name: 'documentFee',
                value: documentFee
            },
            {
                id: settings.find(setting => setting.name === 'bankProcessingFee')?.id,
                name: 'bankProcessingFee',
                value: bankProcessingFee
            },
            {
                id: settings.find(setting => setting.name === 'dmvFee')?.id,
                name: 'dmvFee',
                value: dmvFee
            },
            {
                id: settings.find(setting => setting.name === 'tireFee')?.id,
                name: 'tireFee',
                value: tireFee
            }
            // {
            //     id: data.find(setting => setting.name === 'plannerwarning')?.id,
            //     name: 'plannerwarning',
            //     value: plannerwarning
            // }
        ];

        const input: SettingUpdateInput = {
            type: SettingType.Dealer,
            items: items
        };

        try {
            setIsSubmitting(true);
            const result = await updateSetting({ variables: { input: input } });
            if (result?.data != null) setSettings(result.data[Object.keys(result.data)[0]]);
            notification.success({ message: 'Setting updated successfully' });
        } catch (err) {
            console.error(err);
            notification.error({ duration: 0, message: 'Something went wrong', description: err.message });
        } finally {
            setIsSubmitting(false);
        }
    };

    const assignToUserName = (user: UserDataObject) => {
        if (user.accountStatus !== AccountStatus.Confirmed)
            return (
                <span style={{ color: 'rgba(0, 0, 0, 0.25)' }}>
                    {user.firstName} {user.lastName} (Blocked)
                </span>
            );
        return `${user.firstName} ${user.lastName}`;
    };

    users.sort((a, b) => a.firstName.localeCompare(b.firstName));

    const selectUser = (
        <Select mode="multiple">
            {users
                .filter(
                    o =>
                        o.accountStatus !== AccountStatus.Blocked ||
                        (o.accountStatus === AccountStatus.Blocked && form.getFieldValue('assignblacklist').includes(o.id))
                )
                .map(user => {
                    return (
                        <Select.Option key={user.id} value={user.id} disabled={user.accountStatus !== AccountStatus.Confirmed}>
                            {assignToUserName(user)}
                        </Select.Option>
                    );
                })}
        </Select>
    );

    const onValuesChange = () => {
        setLeadPriorityData(Object.assign({}, leadPriorityData, form.getFieldValue('leadpriority')));
    };

    return (
        <Page title="System Settings">
            <Page.Action>
                <Space>
                    <Button
                        type="primary"
                        icon={<SaveOutlined />}
                        onClick={onSave}
                        disabled={loading || isSubmitting || userLoading}
                        loading={isSubmitting}
                    >
                        Save
                    </Button>
                </Space>
            </Page.Action>
            <Page.Content>
                <Form form={form} onValuesChange={onValuesChange}>
                    <Spin spinning={loading || isSubmitting || userLoading}>
                        <Tabs type="card" className={styles.tab}>
                            <Tabs.TabPane tab="Lead" key="lead">
                                <Card className={styles.tabcard} bodyStyle={{ padding: '10px 24px 10px 24px' }}>
                                    <Row>
                                        <Col span={18}>
                                            <Divider style={{ marginTop: 0 }} orientation="left">
                                                Auto Assignment
                                            </Divider>
                                            <Row gutter={8}>
                                                <Col span={11}>
                                                    <Form.Item name="assignblacklist" label="Don't assign to" style={{ marginLeft: 20 }}>
                                                        {selectUser}
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <Form.List name="autoassignment">
                                                {(fields, { add, remove }) => {
                                                    return (
                                                        <div style={{ maxHeight: '300px', overflowX: 'hidden' }}>
                                                            {fields.map(({ key, name, ...restField }) => (
                                                                <Row key={key} gutter={8}>
                                                                    <Col span={11}>
                                                                        <Form.Item
                                                                            {...restField}
                                                                            label="Lead from"
                                                                            name={[name, 'from']}
                                                                            style={{ marginLeft: 52 }}
                                                                        >
                                                                            <Input />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col span={11}>
                                                                        <Form.Item {...restField} label="Assign to" name={[name, 'to']}>
                                                                            {selectUser}
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col span={2}>
                                                                        <Form.Item>
                                                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                            ))}
                                                            <Row>
                                                                <Col span={7}></Col>
                                                                <Col span={8}>
                                                                    <Form.Item style={{ textAlign: 'center' }}>
                                                                        <Button
                                                                            type="dashed"
                                                                            onClick={() => add()}
                                                                            icon={<PlusOutlined />}
                                                                            style={{ marginLeft: 52 }}
                                                                        >
                                                                            Add new rule
                                                                        </Button>
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    );
                                                }}
                                            </Form.List>
                                        </Col>
                                        {/*<Col span={1}></Col>
                                         <Col span={11}>
                                            <Divider style={{ marginTop: 0 }} orientation="left">
                                                Warning Value Of Planner
                                            </Divider>
                                            <Form.Item
                                                label="Warning value"
                                                name="plannerwarning"
                                                rules={[{ required: true }]}
                                                trigger="onValueChange"
                                            >
                                                <InputPercent style={{ width: 170 }} />
                                            </Form.Item>
                                        </Col> */}
                                    </Row>
                                    <Row>
                                        <Col span={18}>
                                            <Divider style={{ marginTop: 0 }} orientation="left">
                                                Contact Settings
                                            </Divider>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={18}>
                                            <Space size={0}>
                                                <Form.Item
                                                    label="Sources applied to"
                                                    name={['leadpriority', 'from']}
                                                    wrapperCol={{ xl: 15 }}
                                                    help={'One source per line'}
                                                    style={{ marginBottom: '8px', width: 800 }}
                                                    labelCol={{ xs: { span: 7 }, xxl: { span: 7 } }}
                                                >
                                                    <TextArea autoSize={{ minRows: 3, maxRows: 5 }}></TextArea>
                                                </Form.Item>
                                            </Space>
                                        </Col>
                                        <Col span={6}></Col>
                                    </Row>

                                    <Row>
                                        <Col span={18}>
                                            <Divider style={{ marginTop: 0 }} orientation="left"></Divider>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col span={18}>
                                            <Space size={0}>
                                                <Form.Item
                                                    label="Initial contact span"
                                                    name={['leadpriority', 'minutes']}
                                                    labelCol={{ xs: { span: 14 }, xxl: { span: 14 } }}
                                                    style={{ width: 400 }}
                                                >
                                                    <InputNumber
                                                        addonAfter={
                                                            form.getFieldValue('leadpriority')?.minutes === 1 ? 'minute' : 'minutes'
                                                        }
                                                        min={1}
                                                        style={{ width: 135 }}
                                                    />
                                                </Form.Item>
                                                {/* <Form.Item
                                                    label="Initial contact span"
                                                    name={['leadpriority', 'minutes']}
                                                    labelCol={{ xs: { span: 14 }, xxl: { span: 14 } }}
                                                    style={{ width: 400, marginLeft: -100 }}
                                                >
                                                    <InputNumber
                                                        addonAfter={
                                                            form.getFieldValue('leadpriority')?.minutes === 1 ? 'minute' : 'minutes'
                                                        }
                                                        min={1}
                                                        style={{ width: 135 }}
                                                    />
                                                </Form.Item> */}
                                            </Space>
                                        </Col>
                                        <Col span={6}></Col>
                                    </Row>

                                    <Row>
                                        <Col span={12}>
                                            <Space size={0}>
                                                <Form.Item
                                                    label="Default lead follow-up interval"
                                                    name={['leadpriority', 'defaultFollowupInterval']}
                                                    labelCol={{ xs: { span: 14 }, xxl: { span: 14 } }}
                                                    style={{ width: 400 }}
                                                >
                                                    <InputNumber
                                                        addonAfter={
                                                            form.getFieldValue('leadpriority')?.defaultFollowupInterval === 1
                                                                ? 'day'
                                                                : 'days'
                                                        }
                                                        min={1}
                                                        style={{ width: 115 }}
                                                    />
                                                </Form.Item>
                                                {/* <Form.Item
                                                    label="Default lead follow-up interval"
                                                    name={['leadpriority', 'defaultFollowupInterval']}
                                                    labelCol={{ xs: { span: 14 }, xxl: { span: 14 } }}
                                                    style={{ width: 400 }}
                                                >
                                                    <InputNumber
                                                        addonAfter={
                                                            form.getFieldValue('leadpriority')?.defaultFollowupInterval === 1
                                                                ? 'day'
                                                                : 'days'
                                                        }
                                                        min={1}
                                                        style={{ width: 115 }}
                                                    />
                                                </Form.Item> */}
                                            </Space>
                                        </Col>
                                        <Col span={6}></Col>
                                    </Row>
                                    <Row>
                                        <Col span={18}>
                                            <Divider style={{ marginTop: 0 }} orientation="left"></Divider>
                                        </Col>
                                    </Row>

                                    {leadPriorityData && (
                                        <Form.List name={['leadpriority', 'followupIntervals']}>
                                            {(fields, { add, remove }) => {
                                                return (
                                                    <div>
                                                        {fields.map(({ key, name, ...restField }) => (
                                                            <Row key={key}>
                                                                <Col span={18}>
                                                                    <Space size={0}>
                                                                        <Form.Item
                                                                            {...restField}
                                                                            label="Leads older than"
                                                                            name={[name, 'leadsOlderThan']}
                                                                            labelCol={{ xs: { span: 14 }, xxl: { span: 14 } }}
                                                                            style={{ width: 400 }}
                                                                        >
                                                                            <InputNumber
                                                                                addonAfter={
                                                                                    form.getFieldValue('leadpriority')?.followupIntervals[
                                                                                        name
                                                                                    ]?.leadsOlderThan === 1
                                                                                        ? 'day'
                                                                                        : 'days'
                                                                                }
                                                                                min={1}
                                                                                style={{ width: 115 }}
                                                                            />
                                                                        </Form.Item>
                                                                        <Form.Item
                                                                            {...restField}
                                                                            label="follow-up interval"
                                                                            name={[name, 'followupInterval']}
                                                                            style={{ marginLeft: -30 }}
                                                                        >
                                                                            <InputNumber
                                                                                addonAfter={
                                                                                    form.getFieldValue('leadpriority')?.followupIntervals[
                                                                                        name
                                                                                    ]?.followupInterval === 1
                                                                                        ? 'day'
                                                                                        : 'days'
                                                                                }
                                                                                min={1}
                                                                                style={{ width: 115 }}
                                                                            />
                                                                        </Form.Item>
                                                                        <Form.Item style={{ marginLeft: 8 }}>
                                                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                                                        </Form.Item>
                                                                    </Space>
                                                                </Col>
                                                                <Col span={6}></Col>
                                                            </Row>
                                                        ))}
                                                        <Row>
                                                            <Col span={18}>
                                                                <Form.Item
                                                                    labelCol={{ xs: { span: 14 }, xxl: { span: 14 } }}
                                                                    wrapperCol={{ xs: { offset: 14 }, xxl: { offset: 14 } }}
                                                                    style={{ width: 400 }}
                                                                >
                                                                    <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                                                                        Add new rule
                                                                    </Button>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={6}></Col>
                                                        </Row>
                                                    </div>
                                                );
                                            }}
                                        </Form.List>
                                    )}
                                </Card>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Deal" key="deal">
                                <Card className={styles.tabcard} bodyStyle={{ padding: '10px 24px 10px 24px' }}>
                                    <Row>
                                        {/* <Col span={11}>
                                            <Divider style={{ marginTop: 0 }} orientation="left">
                                                State Tax
                                            </Divider>
                                            <Form.List name="statetax">
                                                {(fields, { add, remove }) => {
                                                    return (
                                                        <div style={{ maxHeight: '500px', overflowX: 'hidden' }}>
                                                            {fields.map(({ key, name, ...restField }) => (
                                                                <Row key={key} gutter={8}>
                                                                    <Col span={2}></Col>
                                                                    <Col span={12}>
                                                                        <Form.Item {...restField} label="State" name={[name, 'state']}>
                                                                            <StateSelect
                                                                                showSearch
                                                                                selectStates={selectStates.filter(
                                                                                    o =>
                                                                                        !form
                                                                                            .getFieldValue('statetax')
                                                                                            .some((r: any) => r.state === o.value) ||
                                                                                        o.value ===
                                                                                            form.getFieldValue('statetax')[name]?.state
                                                                                )}
                                                                                onChange={() => {
                                                                                    const newSelectStates = States.filter(
                                                                                        o =>
                                                                                            !form
                                                                                                .getFieldValue('statetax')
                                                                                                .some((r: any) => r.state === o.value) ||
                                                                                            o.value ===
                                                                                                form.getFieldValue('statetax')[name]?.state
                                                                                    );
                                                                                    setSelectStates(newSelectStates);
                                                                                }}
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col span={8}>
                                                                        <Form.Item
                                                                            {...restField}
                                                                            label="Rate"
                                                                            name={[name, 'rate']}
                                                                            trigger="onValueChange"
                                                                        >
                                                                            <InputPercent />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col span={2}>
                                                                        <Form.Item>
                                                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                            ))}
                                                            <Row>
                                                                <Col span={8}></Col>
                                                                <Col span={8}>
                                                                    <Form.Item>
                                                                        <Button
                                                                            type="dashed"
                                                                            onClick={() => add({ state: '', rate: '' })}
                                                                            icon={<PlusOutlined />}
                                                                        >
                                                                            Add another state
                                                                        </Button>
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    );
                                                }}
                                            </Form.List>
                                        </Col>
                                        <Col span={1}></Col> */}
                                        <Col span={11}>
                                            <Divider style={{ marginTop: 0 }} orientation="left">
                                                Fee Defaults
                                            </Divider>
                                            <Row>
                                                <Col span={12}>
                                                    <Form.Item label="Processing fee" name="documentFee" labelCol={{ span: 9 }}>
                                                        <Input type="number" prefix="$" min={0} />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item label="Bank fee" name="bankProcessingFee" labelCol={{ span: 9 }}>
                                                        <Input type="number" prefix="$" min={0} />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item label="Tag fee" name="dmvFee" labelCol={{ span: 9 }}>
                                                        <Input type="number" prefix="$" min={0} />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item label="State tire" name="tireFee" labelCol={{ span: 9 }}>
                                                        <Input type="number" prefix="$" min={0} />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Card>
                            </Tabs.TabPane>
                        </Tabs>
                    </Spin>
                </Form>
            </Page.Content>
        </Page>
    );
};

export default SettingEdit;
