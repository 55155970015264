import { Col, Form, Modal, Radio, Row, Table } from 'antd';
import currency from 'currency.js';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';

import { FormInput } from '../../../components';
import { useFilters, usePaging, useSearchData } from '../../../hooks';
import { Container, MainContainer, WindowContainer } from '../components';

import type { TableRowSelection } from 'antd/lib/table/interface';
import type { VehicleAdditionalCharge } from '../../../models';
interface Props {
    visible: boolean;
    single?: boolean;
    data?: VehicleAdditionalCharge[];
    onCancel: () => void;
    onChange?: (data: VehicleAdditionalCharge[]) => void;
}

const AdditionalChargeSelector: React.FC<Props> = ({ visible, single, data, onChange, onCancel }) => {
    const [filters, setFilter] = useFilters();
    const [paging, pagination, setTotal] = usePaging(30, true);
    const [result, isWorking] = useSearchData<VehicleAdditionalCharge>('vehicleAdditionalCharge', filters, paging);
    useEffect(() => {
        setTotal(result.total);
    }, [setTotal, result.total]);
    // 选择项
    const [selectedRows, setSelectedRows] = useState<VehicleAdditionalCharge[]>([]);
    // 筛选出非库存的项
    const [newDatas, setnewDatas] = useState<VehicleAdditionalCharge[]>([]);
    const rowSelection = useMemo(
        (): TableRowSelection<VehicleAdditionalCharge> => ({
            columnWidth: 40,
            type: single === true ? 'radio' : 'checkbox',
            selectedRowKeys: selectedRows.map(charge => charge.code),
            onChange: (_: any, selectedRows: VehicleAdditionalCharge[]) => setSelectedRows(selectedRows)
        }),
        [selectedRows, single]
    );
    // 本地过滤
    const [dataFilter, setDataFilter] = useState<'all' | 'default' | 'selected'>('all');
    const filteredData = useMemo(() => {
        switch (dataFilter) {
            case 'default':
                return result.data.filter(entry => entry.isDefault === true);
            case 'selected':
                const codes = selectedRows.map(entry => entry.code);
                return result.data.filter(entry => codes.indexOf(entry.code) > -1);
        }
        return result.data;
    }, [dataFilter, result.data, selectedRows]);

    // data属性更改后自动更新选择项目
    useEffect(() => {
        const selectData: VehicleAdditionalCharge[] =
            data != null && Array.isArray(data) ? _.intersectionBy(result.data, data, 'code') : [];
        data && setnewDatas(_.differenceBy(data, selectData, 'code'));
        setSelectedRows(selectData);
    }, [data, result.data, visible]);

    return (
        <Modal
            title="Select Charge Item"
            confirmLoading={isWorking}
            visible={visible}
            onCancel={onCancel}
            onOk={() => {
                if (typeof onChange === 'function') {
                    if (selectedRows.length === 0) {
                        onChange(newDatas);
                        return;
                    }
                    //选择后的总项
                    const allData: VehicleAdditionalCharge[] = newDatas.concat(selectedRows);
                    if (data) {
                        onChange(_.intersectionBy(data, allData, 'code').concat(_.differenceBy(allData, data, 'code')));
                    }
                }
            }}
            width={800}
            className="form"
            cancelButtonProps={{ type: 'link' }}
            style={{ top: 200 }}
        >
            <WindowContainer>
                <Container>
                    <Row gutter={8}>
                        <Col span={10}>
                            <FormInput
                                placeholder="Search charges..."
                                allowClear
                                onEnterPress={setFilter}
                                data={filters}
                                field="$keyword"
                            />
                        </Col>
                        <Col span={14}>
                            <Form.Item label="Filter" labelCol={{ span: 4 }}>
                                <Radio.Group
                                    value={dataFilter}
                                    buttonStyle="solid"
                                    onChange={e => setDataFilter(e.target.value)}
                                >
                                    <Radio.Button value="all">All</Radio.Button>
                                    <Radio.Button value="default">Default</Radio.Button>
                                    <Radio.Button value="selected">Selected</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                </Container>
                <MainContainer style={{ margin: 0 }}>
                    <Table
                        rowKey="code"
                        size="small"
                        dataSource={filteredData}
                        pagination={pagination}
                        rowSelection={rowSelection}
                        scroll={{ y: 200 }}
                        loading={isWorking}
                    >
                        <Table.Column title="Code" dataIndex="code" />
                        <Table.Column title="Description" dataIndex="description" />
                        <Table.Column<VehicleAdditionalCharge>
                            title="Cost"
                            render={(_, record) => currency(record.cost).format()}
                        />
                    </Table>
                </MainContainer>
            </WindowContainer>
        </Modal>
    );
};

AdditionalChargeSelector.displayName = 'AdditionalChargeSelector';

export default React.memo(AdditionalChargeSelector);
