import {
    notification,
    Badge,
    Button,
    Card,
    Checkbox,
    Col,
    Dropdown,
    Form,
    Input,
    List,
    Menu,
    Modal,
    Row,
    Space,
    Switch,
    Tooltip
} from 'antd';
import dayjs from 'dayjs';
import { cloneDeep, orderBy } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { DoubleLeftOutlined, DoubleRightOutlined, DownOutlined, ReloadOutlined } from '@ant-design/icons';

import UserAvatar from '../../../components/UserAvatar';
import { StatusTag } from '../../components';
import { UserContext } from '../../contexts';
import { useGqlGet, useUpdate } from '../../hooks';
import { CREATE_LEAD_ACTIVITY, GET_LEAD } from '../graphql';
import {
    CallResult,
    Customer,
    Direction,
    LeadActivity,
    LeadActivityCreateInput,
    LeadContactType,
    LeadDataObject,
    LeadStatus
} from '../models';
import { parsePhoneNumberCustomer, showAuthor } from '../utils';
import { displayCustomer } from './CallCustomerModal';
import EmailCustomerModal from './EmailCustomerModal';
import SmsCustomerModal from './SmsCustomerModal';

export const getPhoneNumber = (customer?: Customer, type?: string) => {
    if (customer?.contactInfo == null) return null;
    if (type == null) return customer.contactInfo.phoneNumber ? parsePhoneNumberCustomer(customer.contactInfo.phoneNumber.value) : null;
    if (!Array.isArray(customer.contactInfo.phoneNumbers)) return null;
    let phoneNumber = customer.contactInfo.phoneNumbers.find(phoneNumber => phoneNumber.description === type);

    if ('Mobile' === type && phoneNumber == null)
        phoneNumber = customer.contactInfo.phoneNumbers.find(
            phoneNumber =>
                phoneNumber.description == null ||
                phoneNumber.description == undefined ||
                !['Mobile', 'Home', 'Work', 'Pager'].includes(phoneNumber.description)
        );

    return phoneNumber ? parsePhoneNumberCustomer(phoneNumber.value) : null;
};

export const commentDisabled = (editPermission: boolean, leadStatus?: LeadStatus) => {
    if (leadStatus == null) return false;
    if ([LeadStatus.Converted, LeadStatus.Lost, LeadStatus.Defeated].some(status => (leadStatus & status) === status)) return true;
    if (!editPermission) return true;
    return false;
};

interface CommentProps {
    lead: LeadDataObject;
    readOnly: boolean;
    onComment?: (comment: string) => void;
}
const CommentForm = ({ lead: leadObject, readOnly, onComment }: CommentProps) => {
    const [form] = Form.useForm();
    const [lead, setLead] = useState<LeadDataObject>(Object);
    const [activityFilter, setActivityFilter] = useState<number[]>([]);
    const [activities, setActivities] = useState<LeadActivity[]>([]);
    const [data, loading, refetch] = useGqlGet<LeadDataObject>(GET_LEAD, leadObject.id);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isReload, setIsReload] = useState(false);

    const [createLeadActivity] = useUpdate(CREATE_LEAD_ACTIVITY);

    const [selectActivity, setSelectActivity] = useState<LeadActivity>();
    const [smsModalVisible, setSmsModalVisible] = useState<boolean>(false);
    const [emailModalVisible, setEmailModalVisible] = useState(false);

    const [visible, setVisible] = useState(false);
    const activityFilterData = useCallback((data: LeadDataObject, filter: number[]) => {
        if (data?.activities != null)
            setActivities(
                orderBy(
                    data.activities.filter(o => filter.length === 0 || filter.includes(o.contactType)),
                    a => a.callContact?.createAt ?? a._closeAt ?? a._createAt ?? 0,
                    'desc'
                )
            );
        setActivityFilter(filter);
    }, []);

    useEffect(() => {
        if (data) {
            setLead(data);
            setActivities(orderBy(data.activities || [], a => a.callContact?.createAt ?? a._closeAt ?? a._createAt ?? 0, 'desc'));
        }
    }, [data]);

    const onSaveActivity = async () => {
        let input: LeadActivityCreateInput;
        const { comment, afterContactComment } = await form.validateFields();
        try {
            setIsSubmitting(true);
            input = {
                comment: comment,
                contactType: LeadContactType.Comment,
                afterContactComment,
                title: ''
            };

            const newLead: LeadDataObject = await createLeadActivity(lead?.id || '', input);
            setLead(newLead);
            activityFilterData(newLead, activityFilter);
            if (typeof onComment === 'function') onComment(lead?.id + '#Lead#' + comment);
        } catch (err) {
            console.error(err);
            notification.error({ duration: 0, message: 'Something went wrong', description: err.message });
        } finally {
            form.resetFields();
            setIsSubmitting(false);
        }
    };

    const reload = async () => {
        try {
            if (lead == null) return;
            setIsReload(true);
            await refetch();
        } catch (err) {
            console.error(err);
            notification.error({ duration: 0, message: 'Something went wrong', description: err.message });
        } finally {
            setIsReload(false);
        }
    };

    const menuItems =
        Object.keys(LeadContactType).map((key: any) => {
            if (isNaN(Number(key))) return;
            return (
                <Menu.Item key={key}>
                    <Checkbox
                        onChange={e => {
                            let filter = cloneDeep(activityFilter);
                            if (e.target.checked) {
                                filter.push(Number(key));
                            } else {
                                filter = filter.filter(o => o != Number(key));
                            }
                            activityFilterData(lead, filter);
                        }}
                    >
                        {Number(key) === LeadContactType.PhoneCall
                            ? '📞 ' + LeadContactType[key]
                            : Number(key) === LeadContactType.Email
                            ? '📧 ' + LeadContactType[key]
                            : Number(key) === LeadContactType.SMS
                            ? '📱 ' + LeadContactType[key]
                            : Number(key) === LeadContactType.Appointment
                            ? '📅 ' + LeadContactType[key]
                            : Number(key) === LeadContactType.Fax
                            ? '📠 ' + LeadContactType[key]
                            : Number(key) === LeadContactType.Letter
                            ? '📮 ' + LeadContactType[key]
                            : '📝 ' + LeadContactType[key]}
                    </Checkbox>
                </Menu.Item>
            );
        }) || [];

    const contactTypeFilter = menuItems && (
        <Dropdown
            trigger={['click']}
            overlay={<Menu>{menuItems}</Menu>}
            visible={visible}
            onVisibleChange={setVisible}
            placement="topRight"
            getPopupContainer={() => document.getElementById('commentModal') || document.body}
        >
            <Button type="text">
                <span>Type</span>
                <Badge offset={[0, -3]} count={activityFilter.length} style={{ backgroundColor: '#2a7fff', marginLeft: 5 }} />
                <DownOutlined />
            </Button>
        </Dropdown>
    );

    return (
        <>
            <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }}>
                {!readOnly && (
                    <>
                        <Row gutter={8}>
                            <Col span={8}>
                                <Form.Item
                                    name="contactType"
                                    label="Type"
                                    rules={[{ required: true }]}
                                    initialValue={LeadContactType[LeadContactType.Comment]}
                                >
                                    {LeadContactType[LeadContactType.Comment]}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name="afterContactComment"
                                    label="Already called in other places"
                                    valuePropName="checked"
                                    initialValue={false}
                                    labelCol={{ span: 14 }}
                                >
                                    <Switch />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={8}>
                            <Col span={24}>
                                <Form.Item label="Comment" name="comment" labelCol={{ span: 2 }} wrapperCol={{ span: 22 }}>
                                    <Input.TextArea placeholder="Add a comment..." style={{ width: '96.8%' }} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item wrapperCol={{ offset: 2 }}>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        onClick={() => onSaveActivity()}
                                        // style={{ marginLeft: 127 }}
                                        loading={isSubmitting}
                                    >
                                        Save
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                )}

                <Row>
                    <Card
                        id="commentModal"
                        bodyStyle={{ paddingTop: 5 }}
                        size="small"
                        // title="Contact history"
                        title={
                            <>
                                <Space>
                                    <span>Contact history</span>
                                    <span>
                                        <Button onClick={reload} title="Reload data" icon={<ReloadOutlined />} loading={isReload} />
                                    </span>
                                </Space>
                            </>
                        }
                        style={{ width: '100%', border: 0 }}
                        extra={contactTypeFilter}
                    >
                        <List
                            style={{ marginLeft: 10, minHeight: 250 }}
                            itemLayout="horizontal"
                            className="comment-list"
                            loading={loading || isSubmitting || isReload}
                            dataSource={activities}
                            renderItem={item => (
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={
                                            // <Avatar style={getAvatarStyle(item._createBy)} src="" size="large">
                                            //     {item._createBy
                                            //         ? item._createBy?.firstName?.substring(0, 1) + item._createBy?.lastName?.substring(0, 1)
                                            //         : ''}
                                            // </Avatar>
                                            <UserAvatar
                                                size="large"
                                                firstName={item._createBy?.firstName}
                                                lastName={item._createBy?.lastName}
                                                author={item.author}
                                            />
                                        }
                                        title={
                                            <>
                                                <span>{showAuthor(item)}</span>
                                                <span style={{ marginLeft: '5px' }}>
                                                    {item.contactType === LeadContactType.PhoneCall
                                                        ? '📞'
                                                        : item.contactType === LeadContactType.Email
                                                        ? '📧'
                                                        : item.contactType === LeadContactType.SMS
                                                        ? '📱'
                                                        : item.contactType === LeadContactType.Appointment
                                                        ? '📅'
                                                        : item.contactType === LeadContactType.Fax
                                                        ? '📠'
                                                        : item.contactType === LeadContactType.Letter
                                                        ? '📮'
                                                        : '📝'}
                                                </span>
                                                {[LeadContactType.SMS, LeadContactType.PhoneCall, LeadContactType.Email].some(
                                                    o => o === item.contactType
                                                ) && (
                                                    <Tooltip
                                                        title={
                                                            item.direction == null || item.direction === Direction.Outbound
                                                                ? item.contactType === LeadContactType.SMS
                                                                    ? 'Outgoing'
                                                                    : 'Outbound'
                                                                : item.contactType === LeadContactType.SMS
                                                                ? 'Incoming'
                                                                : 'Inbound'
                                                        }
                                                    >
                                                        {item.direction == null || item.direction === Direction.Outbound ? (
                                                            <DoubleRightOutlined />
                                                        ) : (
                                                            <DoubleLeftOutlined />
                                                        )}
                                                    </Tooltip>
                                                )}
                                                <Tooltip
                                                    title={dayjs(
                                                        item?.callContact?.createAt
                                                            ? item.callContact.createAt
                                                            : item._closeAt || item._createAt
                                                    ).format('lll')}
                                                >
                                                    <span
                                                        style={{
                                                            marginLeft: 5,
                                                            fontSize: '13px',
                                                            color: 'rgba(0, 0, 0, 0.45)'
                                                        }}
                                                    >
                                                        {dayjs(
                                                            item?.callContact?.createAt
                                                                ? item.callContact.createAt
                                                                : item._closeAt || item._createAt
                                                        ).fromNow()}
                                                    </span>
                                                </Tooltip>
                                                {item?.callContact?.result != null && (
                                                    <span style={{ marginLeft: 5 }}>
                                                        <StatusTag
                                                            value={item.callContact.result}
                                                            enum={CallResult}
                                                            colors={['pink', 'green', 'orange', 'cyan', 'purple']}
                                                        />
                                                    </span>
                                                )}
                                                {item.contactType === LeadContactType.Email && item.direction === Direction.Inbound && (
                                                    <Button
                                                        size="small"
                                                        type="link"
                                                        style={{ color: '#2a7fff' }}
                                                        onClick={() => {
                                                            setSelectActivity(item);
                                                            setEmailModalVisible(true);
                                                        }}
                                                    >
                                                        Reply
                                                    </Button>
                                                )}
                                                {item.contactType === LeadContactType.SMS && item.direction === Direction.Inbound && (
                                                    <Button
                                                        size="small"
                                                        type="link"
                                                        style={{ color: '#2a7fff' }}
                                                        onClick={() => {
                                                            setSelectActivity(item);
                                                            setSmsModalVisible(true);
                                                        }}
                                                    >
                                                        Reply
                                                    </Button>
                                                )}
                                            </>
                                        }
                                        description={
                                            item.closeComment === null && item.comment === null ? (
                                                <span style={{ color: 'rgba(0, 0, 0, 0.85)', fontStyle: 'italic' }}>Empty comment</span>
                                            ) : (
                                                <span style={{ whiteSpace: 'pre-wrap', color: 'rgba(0, 0, 0, 0.85)' }}>
                                                    {item.closeComment ? item.closeComment : item.comment}
                                                </span>
                                            )
                                        }
                                    />
                                </List.Item>
                            )}
                        />
                    </Card>
                </Row>
            </Form>

            <SmsCustomerModal
                lead={lead}
                //activity={selectActivity}
                visible={smsModalVisible}
                onCancel={() => {
                    setSmsModalVisible(false);
                }}
                setLeadCall={setLead}
                isReply={true}
            />
            <EmailCustomerModal
                lead={lead}
                activity={selectActivity}
                visible={emailModalVisible}
                onCancel={() => {
                    setEmailModalVisible(false);
                }}
                setLeadCall={setLead}
                isReply={true}
            />
        </>
    );
};

interface Props {
    lead?: LeadDataObject;
    visible: boolean;
    onCancel: () => void;
    onComment?: (comment: string) => void;
}

const LeadCommentModal = ({ lead, visible, onCancel, onComment }: Props) => {
    const user = useContext(UserContext);
    const editPermission = useMemo(() => user != null && (user.roles.includes('admin') || user.permissions.includes('lead:edit')), [user]);

    const [comment, setComment] = useState<string>();
    return (
        <Modal
            title={
                <>
                    {displayCustomer(lead?.customer)}
                    <span style={{ marginLeft: 10 }}>{getPhoneNumber(lead?.customer, 'Mobile')}</span>
                </>
            }
            visible={visible}
            width={1200}
            footer={null}
            onCancel={() => {
                onCancel();
                if (comment != null && typeof onComment === 'function') onComment(comment);
            }}
            destroyOnClose={true}
            maskClosable={false}
            keyboard={false}
            centered
        >
            <div style={{ height: 560, overflowX: 'hidden' }}>
                {lead && <CommentForm lead={lead} onComment={setComment} readOnly={commentDisabled(editPermission, lead?.status)} />}
            </div>
        </Modal>
    );
};

export default LeadCommentModal;
