import {
    notification,
    Alert,
    Badge,
    Button,
    Card,
    Checkbox,
    Col,
    Dropdown,
    Form,
    Input,
    List,
    Menu,
    Modal,
    Row,
    Space,
    Tooltip
} from 'antd';
import dayjs from 'dayjs';
import { cloneDeep, orderBy } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { DoubleLeftOutlined, DoubleRightOutlined, DownOutlined, ReloadOutlined } from '@ant-design/icons';

import UserAvatar from '../../../components/UserAvatar';
import { StatusTag } from '../../components';
import { UserContext } from '../../contexts';
import { useCreate, useGqlGet } from '../../hooks';
import { CREATE_EMAIL_LOG, GET_LEAD } from '../graphql';
import { CallResult, Customer, Direction, LeadActivity, LeadContactType, LeadDataObject, LeadStatus } from '../models';
import { parsePhoneNumberCustomer, showAuthor } from '../utils';
import { displayCustomer } from './CallCustomerModal';
import SmsCustomerModal from './SmsCustomerModal';

export const getPhoneNumber = (customer?: Customer, type?: string) => {
    if (customer?.contactInfo == null) return null;
    if (type == null) return customer.contactInfo.phoneNumber ? parsePhoneNumberCustomer(customer.contactInfo.phoneNumber.value) : null;
    if (!Array.isArray(customer.contactInfo.phoneNumbers)) return null;
    let phoneNumber = customer.contactInfo.phoneNumbers.find(phoneNumber => phoneNumber.description === type);

    if ('Mobile' === type && phoneNumber == null)
        phoneNumber = customer.contactInfo.phoneNumbers.find(
            phoneNumber =>
                phoneNumber.description == null ||
                phoneNumber.description == undefined ||
                !['Mobile', 'Home', 'Work', 'Pager'].includes(phoneNumber.description)
        );

    return phoneNumber ? parsePhoneNumberCustomer(phoneNumber.value) : null;
};

export const commentDisabled = (editPermission: boolean, leadStatus?: LeadStatus) => {
    if (leadStatus == null) return false;
    if ([LeadStatus.Converted, LeadStatus.Lost, LeadStatus.Defeated].some(status => (leadStatus & status) === status)) return true;
    if (!editPermission) return true;
    return false;
};

interface CallProps {
    lead?: LeadDataObject;
    readOnly: boolean;
    setLead: (lead: LeadDataObject) => void;
    setLeadCall?: (lead: LeadDataObject) => void;
    leadActivity?: LeadActivity;
    isReply?: boolean;
}

const EmailForm = ({ lead, readOnly, setLead, setLeadCall, leadActivity, isReply }: CallProps) => {
    const [form] = Form.useForm();
    const [activityFilter, setActivityFilter] = useState<number[]>([]);
    const [activities, setActivities] = useState<LeadActivity[]>([]);

    const [data, loading, refetch] = useGqlGet<LeadDataObject>(GET_LEAD, lead?.id ?? '');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isReload, setIsReload] = useState(false);

    const [createEmailLog] = useCreate(CREATE_EMAIL_LOG);

    const [selectActivity, setSelectActivity] = useState<LeadActivity>();
    const [smsModalVisible, setSmsModalVisible] = useState<boolean>(false);
    const [emailModalVisible, setEmailModalVisible] = useState(false);

    const [visible, setVisible] = useState(false);
    const activityFilterData = useCallback((data: LeadDataObject, filter: number[]) => {
        if (data?.activities != null)
            setActivities(
                orderBy(
                    data.activities.filter(o => filter.length === 0 || filter.includes(o.contactType)),
                    a => a.callContact?.createAt ?? a._closeAt ?? a._createAt ?? 0,
                    'desc'
                )
            );
        setActivityFilter(filter);
    }, []);

    useEffect(() => {
        if (data != null) setLead(data);
    }, [data, setLead]);

    useEffect(() => {
        if (lead != null)
            setActivities(orderBy(lead.activities || [], a => a.callContact?.createAt ?? a._closeAt ?? a._createAt ?? 0, 'desc'));
    }, [lead]);

    const onSave = async () => {
        try {
            if (lead == null) return;
            setIsSubmitting(true);
            const { subject, message } = await form.validateFields();

            const input = {
                lead: lead.id,
                email: lead.customer.contactInfo.email?.value,
                subject: subject,
                message: message,
                activityId: leadActivity?.id
            };
            const newLead: LeadDataObject = await createEmailLog(input);

            setLead(newLead);
            activityFilterData(newLead, activityFilter);
            if (setLeadCall) setLeadCall(newLead);
            setIsSuccess(true);
            form.resetFields();
        } catch (err) {
            if (err?.errorFields && Array.isArray(err.errorFields)) {
            } else {
                notification.error({ duration: 0, message: 'Something went wrong', description: err.message });
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    const reload = async () => {
        try {
            if (lead == null) return;
            setIsReload(true);
            await refetch();
        } catch (err) {
            console.error(err);
            notification.error({ duration: 0, message: 'Something went wrong', description: err.message });
        } finally {
            setIsReload(false);
        }
    };

    const closeForm = (isEdit: boolean) => {
        if (readOnly) return null;
        return isEdit ? (
            <Row>
                <Col span={24}>
                    <Form.Item label="Type" required={true} style={{ marginBottom: 10 }}>
                        {LeadContactType[LeadContactType.Email]}
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label="To">{lead != null ? lead?.customer?.contactInfo?.email?.value : undefined}</Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name="subject" label="Subject" rules={[{ required: true }]}>
                        <Input width="100%" />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name="message" label="Message" rules={[{ required: true }]}>
                        <Input.TextArea rows={4} />
                    </Form.Item>
                </Col>
                <Col span={2}></Col>
                <Col>
                    <Form.Item labelCol={{ offset: -3 }}>
                        <Button type="primary" htmlType="submit" onClick={() => onSave()} loading={isSubmitting}>
                            Send
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        ) : (
            <Row>
                <Alert message="Email sent successfully" type="success" showIcon style={{ fontStyle: 'italic' }} />
            </Row>
        );
    };

    const menuItems =
        Object.keys(LeadContactType).map((key: any) => {
            if (isNaN(Number(key))) return;
            return (
                <Menu.Item key={key}>
                    <Checkbox
                        onChange={e => {
                            let filter = cloneDeep(activityFilter);
                            if (e.target.checked) {
                                filter.push(Number(key));
                            } else {
                                filter = filter.filter(o => o != Number(key));
                            }
                            if (lead) activityFilterData(lead, filter);
                        }}
                    >
                        {Number(key) === LeadContactType.PhoneCall
                            ? '📞 ' + LeadContactType[key]
                            : Number(key) === LeadContactType.Email
                            ? '📧 ' + LeadContactType[key]
                            : Number(key) === LeadContactType.SMS
                            ? '📱 ' + LeadContactType[key]
                            : Number(key) === LeadContactType.Appointment
                            ? '📅 ' + LeadContactType[key]
                            : Number(key) === LeadContactType.Fax
                            ? '📠 ' + LeadContactType[key]
                            : Number(key) === LeadContactType.Letter
                            ? '📮 ' + LeadContactType[key]
                            : '📝 ' + LeadContactType[key]}
                    </Checkbox>
                </Menu.Item>
            );
        }) ?? [];

    const contactTypeFilter = menuItems != null && (
        <Dropdown
            trigger={['click']}
            overlay={<Menu>{menuItems}</Menu>}
            visible={visible}
            onVisibleChange={setVisible}
            placement="topRight"
            getPopupContainer={() => document.getElementById('callModal') || document.body}
        >
            <Button type="text">
                <span>Type</span>
                <Badge offset={[0, -3]} count={activityFilter.length} style={{ backgroundColor: '#2a7fff', marginLeft: 5 }} />
                <DownOutlined />
            </Button>
        </Dropdown>
    );

    return (
        <>
            <Form form={form} labelCol={{ span: 2 }} wrapperCol={{ span: 21 }}>
                {closeForm(!isSuccess)}
                {!isReply && (
                    <Row>
                        <Card
                            id="callModal"
                            bodyStyle={{ paddingTop: 5 }}
                            size="small"
                            title={
                                <>
                                    <Space>
                                        <span>Contact history</span>
                                        <span>
                                            <Button onClick={reload} title="Reload data" icon={<ReloadOutlined />} loading={isReload} />
                                        </span>
                                    </Space>
                                </>
                            }
                            style={{ width: '100%', border: 0 }}
                            extra={contactTypeFilter}
                        >
                            <List
                                style={{ marginLeft: 10, minHeight: 250 }}
                                itemLayout="horizontal"
                                className="comment-list"
                                loading={loading || isSubmitting || isReload}
                                dataSource={activities}
                                renderItem={item => (
                                    <List.Item>
                                        <List.Item.Meta
                                            avatar={
                                                <UserAvatar
                                                    size="large"
                                                    firstName={item._createBy?.firstName}
                                                    lastName={item._createBy?.lastName}
                                                    author={item.author}
                                                />
                                            }
                                            title={
                                                <>
                                                    <span>{showAuthor(item)}</span>
                                                    <span style={{ marginLeft: '5px' }}>
                                                        {item.contactType === LeadContactType.PhoneCall
                                                            ? '📞'
                                                            : item.contactType === LeadContactType.Email
                                                            ? '📧'
                                                            : item.contactType === LeadContactType.SMS
                                                            ? '📱'
                                                            : item.contactType === LeadContactType.Appointment
                                                            ? '📅'
                                                            : item.contactType === LeadContactType.Fax
                                                            ? '📠'
                                                            : item.contactType === LeadContactType.Letter
                                                            ? '📮'
                                                            : '📝'}
                                                    </span>
                                                    {[LeadContactType.SMS, LeadContactType.PhoneCall, LeadContactType.Email].some(
                                                        o => o === item.contactType
                                                    ) && (
                                                        <Tooltip
                                                            title={
                                                                item.direction == null || item.direction === Direction.Outbound
                                                                    ? item.contactType === LeadContactType.SMS
                                                                        ? 'Outgoing'
                                                                        : 'Outbound'
                                                                    : item.contactType === LeadContactType.SMS
                                                                    ? 'Incoming'
                                                                    : 'Inbound'
                                                            }
                                                        >
                                                            {item.direction == null || item.direction === Direction.Outbound ? (
                                                                <DoubleRightOutlined />
                                                            ) : (
                                                                <DoubleLeftOutlined />
                                                            )}
                                                        </Tooltip>
                                                    )}
                                                    <Tooltip
                                                        title={dayjs(
                                                            item?.callContact?.createAt
                                                                ? item.callContact.createAt
                                                                : item._closeAt || item._createAt
                                                        ).format('lll')}
                                                    >
                                                        <span
                                                            style={{
                                                                width: 200,
                                                                marginLeft: 5,
                                                                fontSize: '13px',
                                                                color: 'rgba(0, 0, 0, 0.45)'
                                                            }}
                                                        >
                                                            {dayjs(
                                                                item?.callContact?.createAt
                                                                    ? item.callContact.createAt
                                                                    : item._closeAt || item._createAt
                                                            ).fromNow()}
                                                        </span>
                                                    </Tooltip>
                                                    {item?.callContact?.result != null && (
                                                        <span style={{ marginLeft: 5 }}>
                                                            <StatusTag
                                                                value={item.callContact.result}
                                                                enum={CallResult}
                                                                colors={['pink', 'green', 'orange', 'cyan', 'purple']}
                                                            />
                                                        </span>
                                                    )}
                                                    {item.contactType === LeadContactType.Email && item.direction === Direction.Inbound && (
                                                        <Button
                                                            size="small"
                                                            type="link"
                                                            style={{ color: '#2a7fff' }}
                                                            onClick={() => {
                                                                setSelectActivity(item);
                                                                setEmailModalVisible(true);
                                                            }}
                                                        >
                                                            Reply
                                                        </Button>
                                                    )}
                                                    {item.contactType === LeadContactType.SMS && item.direction === Direction.Inbound && (
                                                        <Button
                                                            size="small"
                                                            type="link"
                                                            style={{ color: '#2a7fff' }}
                                                            onClick={() => {
                                                                setSelectActivity(item);
                                                                setSmsModalVisible(true);
                                                            }}
                                                        >
                                                            Reply
                                                        </Button>
                                                    )}
                                                </>
                                            }
                                            description={
                                                item.closeComment === null && item.comment === null ? (
                                                    <span style={{ color: 'rgba(0, 0, 0, 0.85)', fontStyle: 'italic' }}>Empty comment</span>
                                                ) : (
                                                    <span style={{ whiteSpace: 'pre-wrap', color: 'rgba(0, 0, 0, 0.85)' }}>
                                                        {item.closeComment ? item.closeComment : item.comment}
                                                    </span>
                                                )
                                            }
                                        />
                                    </List.Item>
                                )}
                            />
                        </Card>
                    </Row>
                )}
            </Form>

            <SmsCustomerModal
                lead={lead}
                //activity={selectActivity}
                visible={smsModalVisible}
                onCancel={() => {
                    setSmsModalVisible(false);
                }}
                setLeadCall={setLead}
                isReply={true}
            />
            <EmailCustomerModal
                lead={lead}
                activity={selectActivity}
                visible={emailModalVisible}
                onCancel={() => {
                    setEmailModalVisible(false);
                }}
                setLeadCall={setLead}
                isReply={true}
            />
        </>
    );
};

interface Props {
    lead?: LeadDataObject;
    activity?: LeadActivity;
    visible: boolean;
    setLeadCall?: (lead: LeadDataObject) => void;
    onCancel: () => void;
    isReply?: boolean;
}

const EmailCustomerModal = ({ lead: leadDataObject, activity, visible, onCancel, setLeadCall, isReply }: Props) => {
    const user = useContext(UserContext);
    const editPermission = useMemo(() => (user?.roles.includes('admin') || user?.permissions.includes('lead:edit')) ?? false, [user]);
    const [readOnly, setReadOnly] = useState(false);

    const [lead, setLead] = useState<LeadDataObject>();
    const [leadActivity, setLeadActivity] = useState<LeadActivity>();

    useEffect(() => {
        setLead(leadDataObject);
        setLeadActivity(activity);

        setReadOnly(commentDisabled(editPermission, leadDataObject?.status));
    }, [activity, editPermission, leadDataObject]);

    return (
        <Modal
            title={leadDataObject != null && displayCustomer(leadDataObject.customer)}
            visible={visible}
            width={1200}
            onCancel={() => {
                if (!activity) {
                    setLeadActivity(undefined);
                }
                onCancel();
            }}
            destroyOnClose={true}
            footer={null}
            maskClosable={false}
            keyboard={false}
            centered
        >
            <div style={{ height: isReply ? 350 : 560, overflowX: 'hidden' }}>
                <EmailForm
                    lead={lead}
                    leadActivity={leadActivity}
                    setLeadCall={setLeadCall}
                    setLead={setLead}
                    readOnly={readOnly}
                    isReply={isReply}
                />
            </div>
        </Modal>
    );
};

export default EmailCustomerModal;
