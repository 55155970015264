import React from 'react';
import { useParams } from 'react-router-dom';

const Search = React.lazy(() => import('./SearchOperation'));
const New = React.lazy(() => import('./NewServiceOperation'));
const Edit = React.lazy(() => import('./EditServiceOperation'));

const ServiceOperation = () => {
    const { id } = useParams();
    if (id == null) return <Search />;
    if (id.toLowerCase() === 'new') return <New />;
    return <Edit id={id} />;
};

export default ServiceOperation;
