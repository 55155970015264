import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

const Search = React.lazy(() => import('./SearchUser'));
const New = React.lazy(() => import('./NewUser'));
const Edit = React.lazy(() => import('./EditUser'));
const Assign = React.lazy(() => import('./AssignRoles'));

const User = () => {
    const { id } = useParams();
    const { pathname } = useLocation();

    if (id == null) return <Search />;
    if (id.toLowerCase() === 'new') return <New />;
    if (pathname.toLowerCase().endsWith('/assign')) return <Assign id={id} />;
    return <Edit id={id} />;
};

export default User;
