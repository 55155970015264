import { Layout } from 'antd';
import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import useResizeObserver from 'use-resize-observer';

import { Loading } from '../components';
import { SimpleBarAny as SimpleBar } from '../components/SimpleBarAny';
import background from './background.png';
import ShellHeader from './Header';
import ShellMenu from './Menu';

const ShellContainer = styled(Layout)`
    background-image: url(${background}) !important;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100vh;
    & header,
    & aside,
    & section {
        background-color: transparent;
    }
`;

const LayoutHeader = styled(Layout.Header)`
    overflow: hidden;
`;

const LayoutSider = styled(Layout.Sider)`
    overflow: hidden;
`;

const LayoutContent = styled(Layout.Content)`
    padding: 0;
    background-color: #f5f5f5;
`;

const Main = () => {
    const { ref, height = 1 } = useResizeObserver();
    return (
        <ShellContainer>
            <LayoutHeader id="shell-header">
                <ShellHeader />
            </LayoutHeader>
            <Layout>
                <LayoutSider ref={ref} width={256}>
                    <SimpleBar style={{ height }}>
                        <ShellMenu />
                    </SimpleBar>
                </LayoutSider>
                <LayoutContent>
                    <React.Suspense fallback={<Loading text="Just a second..." fullscreen />}>
                        <Outlet />
                    </React.Suspense>
                </LayoutContent>
            </Layout>
        </ShellContainer>
    );
};

export default Main;
