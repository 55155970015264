import type { User } from '../../admin/user/models';

import { VehicleDataObject } from '../../vehicles/inventory/models';
import { CustomerDataObject } from '../customer/models';
import { LiveForm } from './formTemplates/models';

export enum DealStatus {
    New = 0,
    Closed = 1,
    Invalid = 2,
    Abandoned = -1
}

export enum DealType {
    Lease = 0,
    Purchase = 1
}

export enum DealAdditionalCategory {
    Accessory = 0,
    Warranty = 1,
    Extra = 2
}

export enum DealAdditionalProfitTo {
    Gross = 0,
    Finance = 1
}

export interface FinancialInstitute {
    code: string;
    bankName: string;
    lease: {
        acquisitionFee: string;
        sellRateType: string;
    };
}

export interface DealLeaseSinglePay {
    payment: string;
    monthlyBase: string;
    monthlyTax: string;
    monthlyPay: string;
    customerCash: string;
    salesTax: string;
    sellRate: string;
}

export interface DealLeaseSecurityDeposit {
    monthlyDeposit: string;
    months: number;
    sellRate: string;
}

export interface DealPaymentOption {
    terms: number;
    rate: string;
}

export interface DealLease {
    instituteCode: string;
    term: number;
    buyRate: string;
    sellRate: string;
    milesPerYear: number;
    residualFactor: string;
    mfgRebate: string;
    customerCash: string;
    acquisitionFee: string;
    acquisitionFeeFinanced: boolean;
    participation: string;
    reserve: string;
    flatReserve: string;
    paymentOptions: DealPaymentOption[];

    residual: string;
    capitalCostReduction: string;
    adjustedCapitalCost: string;
    salesTax: string;
    monthlyBase: string;
    monthlyPay: string;
    monthlyTax: string;
    totalPay: string;
    grossProfit: string;
    netProfit: string;

    // dealerFunds: string;
    // driveOff: string;
    // isSinglePay: boolean;
    // securityDepositMonths: number;
    // singlePay?: DealLeaseSinglePay;
    // securityDeposit?: DealLeaseSecurityDeposit;
}

export interface DealRetail {
    instituteCode: string;
    term: number;
    buyApr: string;
    sellApr: string;
    mfgRebate: string;
    cashDeposit: string;
    reserve: string;
    flatReserve: string;
    participation: string;
    bankProcessingFee: string;
    paymentOptions: DealPaymentOption[];

    totalCashDown: string;
    amountFinanced: string;
    stateTax: string;
    monthlyPay: string;
    totalPay: string;
    grossProfit: string;
    netProfit: string;
}

export interface DealAdditional {
    id: string;
    category: DealAdditionalCategory;
    code: string;
    description?: string;
    cost: string;
    retail: string;
    residual?: string;
    profitTo?: DealAdditionalProfitTo;
    tax?: string;
    taxable: boolean;
    financed: boolean;
    addToTax?: boolean;
    serviceVisible: boolean;
    notes?: string;
}

export interface DealTradeIn {
    vin: string;
    actualValue: string;
    tradeValue: string;
    mileage: number;
    make?: string;
    model?: string;
    year?: string;
    licensePlate?: string;
    dealPayoff?: string;
    tradeEquity?: string;
    tradeUA?: string;

    // useAsCapReduction?: boolean;
    // isLeaseReturn?: boolean;
    // cylinders?: string;
    // bodyType?: string;
    // vehicleInfo?: string;
    // accidents?: boolean;
    // damages?: boolean;
    // majorProblems?: boolean;
    // adjustment?: string;
    // deductions?: string;
}

export interface Deal {
    id: string;
    type: DealType;
    date: string;
    retailPrice?: string;
    cashSalePrice: string;
    state?: string;
    tax: string;
    countyTax?: string;
    cityTax?: string;
    dmvFee: string;
    dmvFeeFinanced: boolean;
    tireFee: string;
    tireFeeFinanced: boolean;
    documentFee: string;
    documentFeeFinanced: boolean;
    firstPayIn: number;
    incentive: string;
    lease?: DealLease;
    retail?: DealRetail;
    additionals?: DealAdditional[];
    tradeIns?: DealTradeIn[];
    customer: CustomerDataObject;
    vehicle: VehicleDataObject;
    salesPerson: User;
    status: DealStatus;
    code: string;
    accessoryTotal: string;
    accessoryNonFinancedTotal: string;
    accessoryTaxTotal: string;
    accessoryNonTaxTotal: string;
    accessoryResidual: string;
    accessoryGrossProfit: string;
    accessoryFinancedProfit: string;
    warrantyTotal: string;
    warrantyProfitTotal: string;
    tradeTotal: string;
    tradeUATotal: string;
    tradeBalance: string;
    grossProfit: string;
    netProfit: string;

    forms?: LiveForm[];
}

export interface DealDataObject extends Deal {
    cdkId?: string;
    _dataStatus: number;
    _createAt?: number;
    _updateAt?: number;
}

export type DealCreateInput = Omit<Deal, 'id' | 'customer' | 'vehicle'> & {
    customer: string;
    vehicle: string;
    lead?: string;
};

export type DealUpdateInput = Omit<Deal, 'id' | 'customer' | 'vehicle' | 'salesPerson'> & {
    salesPerson: string;
};
