import { Form, Modal } from 'antd';
import React, { useEffect } from 'react';

import { EnumSelect } from '../../../components';
import { SimpleBarAny as SimpleBar } from '../../../components/SimpleBarAny';
import { DealType, VehicleInputSource, VehicleVmsStatus } from '../../../models';
import { DealInput } from '../../deals/types';
import { WindowContainer } from '../components';
import { CustomerSearcher, VehicleSearcher } from '../searchers';

interface Props {
    visible: boolean;
    isSubmitting: boolean;
    onCancel: () => void;
    onSave: (data: DealInput) => Promise<void>;
}

const emptyData: DealInput = {
    type: DealType.Lease,
    date: new Date().toISOString(),
    cashSalePrice: 0,
    tax: 0.0725,
    dmvFee: 560,
    dmvFeeFinanced: false,
    tireFee: 8.75,
    tireFeeFinanced: false,
    documentFee: 85,
    documentFeeFinanced: true,
    firstPayIn: 45,
    incentive: 0,
    reserve: 0,
    lease: {
        term: 36,
        buyRate: 0.00015,
        sellRate: 0.00115,
        milesPerYear: 10000,
        residualFactor: 0.58,
        mfgRebate: 0,
        customerCash: 0,
        acquisitionFee: 795,
        acquisitionFeeFinanced: false,
        participation: 0.7,
        flatReserve: 200,
        isSinglePay: false,
        securityDepositMonths: 0
    },
    retail: {
        term: 24,
        buyApr: 0.049,
        sellApr: 0.059,
        participation: 0.75,
        mfgRebate: 0,
        cashDeposit: 0,
        flatReserve: 200,
        bankProcessingFee: 0
    },
    additionals: [],
    tradeIns: []
};

const NewDealWindow: React.FC<Props> = props => {
    // 表单控制属性
    const [form] = Form.useForm();
    const onOk = async () => {
        try {
            const values = (await form.validateFields()) as DealInput;
            const data = Object.assign({}, emptyData, values);
            //console.log(JSON.stringify(data, null, 2));
            await props.onSave(data);
        } catch (err) {
            console.error(err);
        }
    };
    const onCancel = () => {
        if (!props.isSubmitting) props.onCancel();
    };
    useEffect(() => {
        if (props.visible) form.resetFields();
    }, [form, props.visible]);

    return (
        <Modal
            title="New Deal"
            confirmLoading={props.isSubmitting}
            visible={props.visible}
            onCancel={onCancel}
            onOk={onOk}
            width={700}
            className="form"
            okText="Submit"
            cancelButtonProps={{ type: 'link' }}
            maskClosable={false}
            //forceRender
        >
            <SimpleBar style={{ maxHeight: 600 }}>
                <WindowContainer>
                    <Form form={form} initialValues={emptyData} labelCol={{ span: 5 }}>
                        <Form.Item name="type" label="Type" rules={[{ required: true }]}>
                            <EnumSelect enum={DealType} />
                        </Form.Item>
                        <Form.Item name="customer" label="Customer" rules={[{ required: true }]}>
                            <CustomerSearcher />
                        </Form.Item>
                        <Form.Item name="vehicle" label="Vehicle" rules={[{ required: true }]}>
                            <VehicleSearcher vmsStatus={VehicleVmsStatus.Stock} inputSource={VehicleInputSource.Inventory} />
                        </Form.Item>
                    </Form>
                </WindowContainer>
            </SimpleBar>
        </Modal>
    );
};

export default React.memo(NewDealWindow);
