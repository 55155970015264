import { notification, Button, Modal, Popconfirm, Row, Space, Table, Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { orderBy } from 'lodash';
import { nanoid } from 'nanoid';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import { MessageTwoTone } from '@ant-design/icons';
import { useMutation } from '@apollo/client';

import { UserContext } from '../../../contexts';
import { useDelete, useSearch, useUpdate } from '../../../hooks';
import { LeadProspectLevel } from '../../../models';
import { LeadPriority } from '../../admin/setting/model';
import { insertLink } from '../../crm/board/utils';
import { TimeCell } from '../../crm/components';
import CallCustomerModal from '../../crm/lead/components/CallCustomerModal';
import CloseLead from '../../crm/lead/components/CloseLead';
import EmailCustomerModal from '../../crm/lead/components/EmailCustomerModal';
import LeadCommentModal from '../../crm/lead/components/LeadCommentModal';
import { getEmail, getPhoneNumber } from '../../crm/lead/components/LeadPanel';
import SmsCustomerModal from '../../crm/lead/components/SmsCustomerModal';
import { ABANDON_LEAD, CLOSE_LEAD, REOPEN_LEAD, SEARCH_LEADS } from '../../crm/lead/graphql';
import { LeadCloseInput, LeadContactStatus, LeadDataObject, LeadStatus } from '../../crm/lead/models';
import { buildLedLight } from '../../crm/lead/SearchLead';
import styles from '../styles.module.scss';
import { displayCustomer } from '../utils';

interface Filter {
    _search?: string;
    prospectLevel?: any;
    status?: any;
    _noSearch?: boolean;
    assignee?: string;
    scheduledTimeFrom?: string;
    group?: string;
    createTimeFrom?: string;
    contactStatus?: any;
}

interface LeadProps {
    assignee?: string;
    leadPriority: LeadPriority;
}

const textInnerHtml = (text: string) => {
    return (
        <span
            style={{ wordBreak: 'break-all' }}
            dangerouslySetInnerHTML={{
                __html: insertLink(text)
            }}
        ></span>
    );
};

const getOpportunity = (lead: LeadDataObject) => {
    if (lead.opportunity != null) return lead.opportunity;
    if (Array.isArray(lead.opportunities) && lead.opportunities.length > 0) return lead.opportunities[0];
};

const getOpportunityVehicle = (lead: LeadDataObject) => {
    if (lead.opportunity != null && typeof lead.opportunity.vehicle != 'string') return lead.opportunity.vehicle;
    if (Array.isArray(lead.opportunities) && lead.opportunities.length > 0)
        return typeof lead.opportunities[0].vehicle != 'string' ? lead.opportunities[0].vehicle : null;
};

const Column = (props: ColumnProps<LeadDataObject>) => <Table.Column<LeadDataObject> {...props} />;

const SalesLeadList = ({ assignee, leadPriority }: LeadProps) => {
    //获取当前登录人的信息
    const user = useContext(UserContext);
    // permissions
    const editPermission = useMemo(() => user != null && (user.roles.includes('admin') || user.permissions.includes('lead:edit')), [user]);
    const assignPermission = useMemo(
        () => user != null && (user.roles.includes('admin') || user.permissions.includes('lead:assign')),
        [user]
    );
    const convertPermission = useMemo(
        () => user != null && (user.roles.includes('admin') || user.permissions.includes('lead:convert')),
        [user]
    );
    const closePermission = useMemo(
        () => user != null && (user.roles.includes('admin') || user.permissions.includes('lead:close')),
        [user]
    );
    const reopenPermission = useMemo(
        () => user != null && (user.roles.includes('admin') || user.permissions.includes('lead:reopen')),
        [user]
    );
    const abandonPermission = useMemo(
        () => user != null && (user.roles.includes('admin') || user.permissions.includes('lead:delete')),
        [user]
    );

    const navigate = useNavigate();
    const [leads, setLeads] = useState<LeadDataObject[]>([]);
    const filter: Filter = {
        _noSearch: true,
        contactStatus: [LeadContactStatus.None, LeadContactStatus.Success],
        status: [LeadStatus.New, LeadStatus.Appointment, LeadStatus.InProgress],
        assignee: assignee != null ? assignee : undefined
    };
    const { loading, data, refresh } = useSearch<Filter, LeadDataObject>(SEARCH_LEADS, filter, [], -1);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedLead, setSelectedLead] = useState<LeadDataObject>();

    const [closeLeadVisible, setCloseLeadVisible] = useState(false);
    const [commentVisible, setCommentVisible] = useState<boolean>(false);
    const [callModalVisible, setCallModalVisible] = useState<boolean>(false);
    const [smsModalVisible, setSmsModalVisible] = useState<boolean>(false);
    const [emailModalVisible, setEmailModalVisible] = useState<boolean>(false);

    const [closeLead] = useUpdate(CLOSE_LEAD);
    const [reopenLead] = useMutation(REOPEN_LEAD);
    const [abandonLead] = useDelete(ABANDON_LEAD);

    useEffect(() => {
        if (data == null || data.length == 0) return;

        const newLeads = orderBy(
            data as LeadDataObject[],
            [o => (o.prospectLevel == null ? 99 : o.prospectLevel), o => (o.lastContactAt == null ? 0 : o.lastContactAt), '_createAt'],
            ['asc', 'asc', 'asc']
        );
        setLeads(newLeads);
    }, [data]);

    const onCloseLead = async (id: string, leadCloseInput: LeadCloseInput) => {
        try {
            setIsSubmitting(true);
            if (leadCloseInput) {
                setCloseLeadVisible(false);
                await closeLead(id, leadCloseInput);
            }
        } catch (err) {
            console.error(err);
            notification.error({ duration: 0, message: 'Something went wrong', description: err.message });
        } finally {
            refresh();
            setIsSubmitting(false);
        }
    };

    const onReopenLead = async (id: string) => {
        try {
            setIsSubmitting(true);
            if (id) {
                setCloseLeadVisible(false);
                await reopenLead({ variables: { id } });
            }
        } catch (err) {
            console.error(err);
            notification.error({ duration: 0, message: 'Something went wrong', description: err.message });
        } finally {
            refresh();
            setIsSubmitting(false);
        }
    };

    const onConvertLead = async (id: string) => {
        try {
            setIsSubmitting(true);
            if (id) navigate(`/desking/deal/new?lead=${id}`);
        } catch (err) {
            console.error(err);
            notification.error({ duration: 0, message: 'Something went wrong', description: err.message });
        } finally {
            setIsSubmitting(false);
        }
    };

    const onAbandonLead = async (id: string) => {
        try {
            setIsSubmitting(true);
            if (id) await abandonLead(id);
        } catch (err) {
            console.error(err);
            notification.error({ duration: 0, message: 'Something went wrong', description: err.message });
        } finally {
            refresh();
            setIsSubmitting(false);
        }
    };

    return (
        <Row>
            <Table
                rowKey="id"
                size="small"
                dataSource={leads}
                loading={loading || isSubmitting}
                className={styles.table}
                pagination={false}
            >
                <Column
                    width={22}
                    title=""
                    render={(_, record) => {
                        return buildLedLight(record, leadPriority);
                    }}
                />
                <Column
                    width={120}
                    title="Name"
                    key="name"
                    render={(_, record) => {
                        if (record.customer) {
                            return (
                                <Link style={{ color: '#2a7fff' }} to={`crm/lead/${record.id}`}>
                                    {displayCustomer(record.customer)}
                                </Link>
                            );
                        }
                    }}
                />
                <Column
                    title="Contact"
                    width={160}
                    ellipsis={true}
                    render={(_, record) => {
                        if (record.customer) {
                            const contact: JSX.Element[] = [];
                            const phoneNumber = getPhoneNumber(record.customer, 'Mobile');
                            const email = getEmail(record.customer);
                            if (phoneNumber && phoneNumber !== '')
                                contact.push(
                                    <span key={'Phone'}>
                                        <Button
                                            type="link"
                                            size="small"
                                            style={{ padding: 0 }}
                                            onClick={() => {
                                                setSelectedLead(record);
                                                setCallModalVisible(true);
                                            }}
                                        >
                                            {phoneNumber}
                                        </Button>
                                        <Button
                                            type="link"
                                            size="small"
                                            style={{ padding: 0, marginLeft: 5 }}
                                            onClick={() => {
                                                setSelectedLead(record);
                                                setSmsModalVisible(true);
                                            }}
                                        >
                                            <MessageTwoTone twoToneColor="#52c41a" />
                                        </Button>
                                    </span>
                                );
                            if (email)
                                contact.push(
                                    <span key={'Email'}>
                                        {contact.length >= 1 && <br />}
                                        <Button
                                            type="link"
                                            size="small"
                                            style={{ padding: 0 }}
                                            onClick={() => {
                                                setSelectedLead(record);
                                                setEmailModalVisible(true);
                                            }}
                                        >
                                            {email}
                                        </Button>
                                    </span>
                                );
                            return contact;
                        }
                    }}
                />
                <Column
                    width={80}
                    title="Prospect"
                    render={(_, record) => (record?.prospectLevel != null ? LeadProspectLevel[record.prospectLevel] : null)}
                />
                <Column
                    width={120}
                    title="Model"
                    render={(_, record) => {
                        return (
                            <span>
                                {getOpportunityVehicle(record)?.year} {getOpportunity(record)?.make} {getOpportunity(record)?.model}{' '}
                                {getOpportunity(record)?.color}
                            </span>
                        );
                    }}
                />
                <Column
                    width={160}
                    ellipsis={{ showTitle: false }}
                    title="Summary"
                    dataIndex="summary"
                    render={(_, record) => {
                        return (
                            <Tooltip title={textInnerHtml(record.summary)} placement="topLeft" overlayStyle={{ maxWidth: 500 }}>
                                {record.summary}
                            </Tooltip>
                        );
                    }}
                />
                <Column key="source" width={100} ellipsis={true} title="Source" dataIndex="source" />
                <Column
                    width={160}
                    key="_createdAt"
                    ellipsis={true}
                    title="Create at"
                    render={(_, record) => (record._createAt != null ? <TimeCell time={new Date(record._createAt)} /> : null)}
                />
                <Column
                    width={200}
                    title="Actions"
                    render={(_, record) => (
                        <Space size={0} wrap>
                            <Button size="small" type="link">
                                <Link to={`crm/lead/${record.id}`}>
                                    {![LeadStatus.Converted, LeadStatus.Lost, LeadStatus.Defeated].some(
                                        status => (record.status & status) === status
                                    ) && editPermission
                                        ? 'Edit'
                                        : 'View'}
                                </Link>
                            </Button>
                            {![LeadStatus.Converted].some(status => (record.status & status) === status) && assignPermission == true && (
                                <Button
                                    size="small"
                                    type="link"
                                    onClick={() => {
                                        const id = nanoid(8);
                                        sessionStorage.setItem(id, JSON.stringify([record]));
                                        navigate(`crm/lead/${id}/assign`);
                                    }}
                                >
                                    Assign
                                </Button>
                            )}
                            {![LeadStatus.Converted, LeadStatus.Lost, LeadStatus.Defeated].some(
                                status => (record.status & status) === status
                            ) &&
                                convertPermission == true && (
                                    <Popconfirm
                                        disabled={isSubmitting}
                                        placement="right"
                                        title="Convert this lead?"
                                        okText="Yes"
                                        cancelText="No"
                                        onConfirm={async () => await onConvertLead(record.id)}
                                    >
                                        <Button size="small" type="link" disabled={isSubmitting || !convertPermission}>
                                            Convert
                                        </Button>
                                    </Popconfirm>
                                )}
                            {[LeadStatus.Defeated].some(status => (record.status & status) === status) && closePermission == true && (
                                <Button
                                    size="small"
                                    type="link"
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        setCloseLeadVisible(true);
                                        setSelectedLead(record);
                                    }}
                                >
                                    Close
                                </Button>
                            )}
                            {[LeadStatus.Lost].some(status => (record.status & status) === status) && reopenPermission == true && (
                                <Popconfirm
                                    disabled={isSubmitting}
                                    placement="right"
                                    title="Reopen this lead?"
                                    okText="Yes"
                                    cancelText="No"
                                    onConfirm={async () => await onReopenLead(record.id)}
                                >
                                    <Button size="small" type="link" disabled={isSubmitting}>
                                        Reopen
                                    </Button>
                                </Popconfirm>
                            )}
                            {![LeadStatus.Converted, LeadStatus.Lost, LeadStatus.Defeated].some(
                                status => (record.status & status) === status
                            ) &&
                                abandonPermission == true && (
                                    <Popconfirm
                                        disabled={isSubmitting}
                                        placement="right"
                                        title="Abandon this lead?"
                                        okText="Yes"
                                        cancelText="No"
                                        onConfirm={async () => {
                                            await onAbandonLead(record.id);
                                            setLeads(leads.filter(d => d.id !== record.id));
                                        }}
                                    >
                                        <Button size="small" type="link" disabled={isSubmitting}>
                                            Abandon
                                        </Button>
                                    </Popconfirm>
                                )}
                            {![LeadStatus.Converted, LeadStatus.Lost, LeadStatus.Defeated].some(
                                status => (record.status & status) === status
                            ) &&
                                editPermission && (
                                    <Button
                                        size="small"
                                        type="link"
                                        disabled={isSubmitting}
                                        onClick={() => {
                                            setSelectedLead(record);
                                            setCommentVisible(true);
                                        }}
                                    >
                                        Comment
                                    </Button>
                                )}
                        </Space>
                    )}
                />
            </Table>
            <CloseLead
                id={(selectedLead && selectedLead.id) || ''}
                visible={closeLeadVisible}
                onCancel={() => setCloseLeadVisible(false)}
                onSave={onCloseLead}
            />

            <LeadCommentModal
                lead={selectedLead as LeadDataObject}
                visible={commentVisible}
                onCancel={() => {
                    setCommentVisible(false);
                    refresh();
                }}
            />
            <CallCustomerModal
                lead={selectedLead as LeadDataObject}
                visible={callModalVisible}
                onCancel={() => {
                    setCallModalVisible(false);
                    refresh();
                }}
            />
            <SmsCustomerModal
                lead={selectedLead}
                visible={smsModalVisible}
                onCancel={() => {
                    setSmsModalVisible(false);
                    refresh();
                }}
            />
            <EmailCustomerModal
                lead={selectedLead}
                visible={emailModalVisible}
                onCancel={() => {
                    setEmailModalVisible(false);
                }}
            />
        </Row>
    );
};

interface Props {
    visible: boolean;
    assignee?: string;
    assigneeName?: string;
    leadPriority: LeadPriority;
    onCancel: () => void;
}

const SalesLeadsModal = ({ assignee, assigneeName, visible, leadPriority, onCancel }: Props) => {
    return (
        <Modal
            title={`Leads (Sales rep: ${assigneeName})`}
            visible={visible}
            width={1200}
            footer={null}
            onCancel={() => onCancel()}
            destroyOnClose={true}
            keyboard={false}
            centered
        >
            <div style={{ height: 560, overflowX: 'hidden' }}>
                {assignee != null && <SalesLeadList assignee={assignee} leadPriority={leadPriority} />}
            </div>
        </Modal>
    );
};

export default SalesLeadsModal;
