import { Badge, Button, Card, Checkbox, Dropdown, List, Menu, Modal, Row, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { cloneDeep, isEmpty, orderBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import { DoubleLeftOutlined, DoubleRightOutlined, DownOutlined } from '@ant-design/icons';

import { useGqlGet } from '../../../hooks';
import UserAvatar from '../../components/UserAvatar';
import { MainContainer, StatusTag, WindowContainer } from '../../crm/components';
import { GET_LEAD } from '../../crm/lead/graphql';
import { CallResult, Direction, LeadActivity, LeadContactType, LeadDataObject } from '../../crm/lead/models';

export const showAuthor = (activity: LeadActivity) => {
    if (activity.author != null && !isEmpty(activity.author)) return activity.author;
    return activity._createBy ? `${activity._createBy.firstName} ${activity._createBy.lastName}` : '';
};

interface HistoryProps {
    leadId: string;
}
const HistoryList = ({ leadId }: HistoryProps) => {
    const [lead, setLead] = useState<LeadDataObject>(Object);
    const [activityFilter, setActivityFilter] = useState<number[]>([]);
    const [activities, setActivities] = useState<LeadActivity[]>([]);
    const [data, loading] = useGqlGet<LeadDataObject>(GET_LEAD, leadId);

    const [visible, setVisible] = useState(false);
    const activityFilterData = useCallback((data: LeadDataObject, filter: number[]) => {
        if (data?.activities != null)
            setActivities(
                orderBy(
                    data.activities.filter(o => filter.length === 0 || filter.includes(o.contactType)),
                    a => a.callContact?.createAt ?? a._closeAt ?? a._createAt ?? 0,
                    'desc'
                )
            );
        setActivityFilter(filter);
    }, []);

    useEffect(() => {
        if (data) {
            setLead(data);
            setActivities(orderBy(data.activities || [], a => a.callContact?.createAt ?? a._closeAt ?? a._createAt ?? 0, 'desc'));
        }
    }, [data]);

    const menuItems =
        Object.keys(LeadContactType).map((key: any) => {
            if (isNaN(Number(key))) return;
            return (
                <Menu.Item key={key}>
                    <Checkbox
                        onChange={e => {
                            let filter = cloneDeep(activityFilter);
                            if (e.target.checked) {
                                filter.push(Number(key));
                            } else {
                                filter = filter.filter(o => o != Number(key));
                            }
                            activityFilterData(lead, filter);
                        }}
                    >
                        {Number(key) === LeadContactType.PhoneCall
                            ? '📞 ' + LeadContactType[key]
                            : Number(key) === LeadContactType.Email
                            ? '📧 ' + LeadContactType[key]
                            : Number(key) === LeadContactType.SMS
                            ? '📱 ' + LeadContactType[key]
                            : Number(key) === LeadContactType.Appointment
                            ? '📅 ' + LeadContactType[key]
                            : Number(key) === LeadContactType.Fax
                            ? '📠 ' + LeadContactType[key]
                            : Number(key) === LeadContactType.Letter
                            ? '📮 ' + LeadContactType[key]
                            : '📝 ' + LeadContactType[key]}
                    </Checkbox>
                </Menu.Item>
            );
        }) || [];

    const contactTypeFilter = menuItems && (
        <Dropdown
            trigger={['click']}
            overlay={<Menu>{menuItems}</Menu>}
            visible={visible}
            onVisibleChange={setVisible}
            placement="topRight"
            getPopupContainer={() => document.getElementById('commentModal') || document.body}
        >
            <Button type="text">
                <span>Type</span>
                <Badge offset={[0, -3]} count={activityFilter.length} style={{ backgroundColor: '#2a7fff', marginLeft: 5 }} />
                <DownOutlined />
            </Button>
        </Dropdown>
    );

    return (
        <Row>
            <Card
                id="commentModal"
                bodyStyle={{ paddingTop: 5 }}
                size="small"
                style={{ width: '100%', border: 0 }}
                extra={contactTypeFilter}
            >
                <List
                    style={{ marginLeft: 10, minHeight: 250 }}
                    itemLayout="horizontal"
                    className="comment-list"
                    loading={loading}
                    dataSource={activities}
                    renderItem={item => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={
                                    <UserAvatar
                                        size="large"
                                        firstName={item._createBy?.firstName}
                                        lastName={item._createBy?.lastName}
                                        author={item.author}
                                    />
                                }
                                title={
                                    <>
                                        <span>{showAuthor(item)}</span>
                                        <span style={{ marginLeft: '5px' }}>
                                            {item.contactType === LeadContactType.PhoneCall
                                                ? '📞'
                                                : item.contactType === LeadContactType.Email
                                                ? '📧'
                                                : item.contactType === LeadContactType.SMS
                                                ? '📱'
                                                : item.contactType === LeadContactType.Appointment
                                                ? '📅'
                                                : item.contactType === LeadContactType.Fax
                                                ? '📠'
                                                : item.contactType === LeadContactType.Letter
                                                ? '📮'
                                                : '📝'}
                                        </span>
                                        {[LeadContactType.SMS, LeadContactType.PhoneCall].some(o => o === item.contactType) && (
                                            <Tooltip
                                                title={
                                                    item.direction == null || item.direction === Direction.Outbound
                                                        ? item.contactType === LeadContactType.SMS
                                                            ? 'Outgoing'
                                                            : 'Outbound'
                                                        : item.contactType === LeadContactType.SMS
                                                        ? 'Incoming'
                                                        : 'Inbound'
                                                }
                                            >
                                                {item.direction == null || item.direction === Direction.Outbound ? (
                                                    <DoubleRightOutlined />
                                                ) : (
                                                    <DoubleLeftOutlined />
                                                )}
                                            </Tooltip>
                                        )}
                                        <Tooltip
                                            title={dayjs(
                                                item?.callContact?.createAt ? item.callContact.createAt : item._closeAt || item._createAt
                                            ).format('lll')}
                                        >
                                            <span
                                                style={{
                                                    marginLeft: 5,
                                                    fontSize: '13px',
                                                    color: 'rgba(0, 0, 0, 0.45)'
                                                }}
                                            >
                                                {dayjs(
                                                    item?.callContact?.createAt
                                                        ? item.callContact.createAt
                                                        : item._closeAt || item._createAt
                                                ).fromNow()}
                                            </span>
                                        </Tooltip>
                                        {item?.callContact?.result != null && (
                                            <span style={{ marginLeft: 5 }}>
                                                <StatusTag
                                                    value={item.callContact.result}
                                                    enum={CallResult}
                                                    colors={['pink', 'green', 'orange', 'cyan', 'purple']}
                                                />
                                            </span>
                                        )}
                                    </>
                                }
                                description={
                                    item.closeComment === null && item.comment === null ? (
                                        <span style={{ color: 'rgba(0, 0, 0, 0.85)', fontStyle: 'italic' }}>Empty comment</span>
                                    ) : (
                                        <span style={{ whiteSpace: 'pre-wrap', color: 'rgba(0, 0, 0, 0.85)' }}>
                                            {item.closeComment ? item.closeComment : item.comment}
                                        </span>
                                    )
                                }
                            />
                        </List.Item>
                    )}
                />
            </Card>
        </Row>
    );
};

interface Props {
    id: string;
    visible: boolean;
    onCancel: () => void;
}

const LeadHistoryModal = ({ id, visible, onCancel }: Props) => {
    return (
        <Modal
            title="Contact history"
            visible={visible}
            width={1000}
            className="form"
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ style: { display: 'none' } }}
            onCancel={() => onCancel()}
            destroyOnClose={true}
            centered
        >
            <WindowContainer>
                <MainContainer style={{ margin: 0, height: 500 }}>
                    <HistoryList leadId={id} />
                </MainContainer>
            </WindowContainer>
        </Modal>
    );
};

export default LeadHistoryModal;
