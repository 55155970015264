import { Input } from 'antd';
import { InputProps } from 'antd/lib/input';
import { omit } from 'lodash';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';

export interface FormInputProps {
    field: string | string[];
    data: { [key: string]: any };
    onEnterPress?: (field: string | string[], value: string) => void;
}

const FormInput: React.FC<Omit<InputProps, 'value'> & FormInputProps> = props => {
    const [value, setValue] = useState(get(props.data, props.field));
    const onChange: React.ChangeEventHandler<HTMLInputElement> = e => {
        setValue(e.currentTarget.value);
        if (typeof props.onChange === 'function') props.onChange(e);
    };
    const onKeyPress: React.KeyboardEventHandler<HTMLInputElement> = e => {
        if (typeof props.onKeyPress === 'function') props.onKeyPress(e);
        if (e.key === 'Enter' && typeof props.onEnterPress === 'function')
            props.onEnterPress(props.field, e.currentTarget.value);
    };

    useEffect(() => {
        setValue(get(props.data, props.field));
    }, [props.data, props.field]);

    const inputProps = omit(props, 'field', 'data', 'onEnterPress', 'onChange', 'onKeyPress');

    return <Input {...inputProps} value={value} onChange={onChange} onKeyPress={onKeyPress} />;
};

export default React.memo(FormInput);
