import { TablePaginationConfig } from 'antd/lib/table';
import produce from 'immer';
import { useCallback, useEffect, useState } from 'react';

import { Paging } from '../types';

const DEFAULT_PAGE = 1;
const DEFAULT_PAGESIZE = 10;

export default function usePaging(
    pageSize?: number,
    hideOnSinglePage?: boolean
): [Paging, TablePaginationConfig, (total: number) => void] {
    const [paging, setPaging] = useState<Paging>({
        page: DEFAULT_PAGE,
        pageSize
    });
    const [pagination, setPagination] = useState<TablePaginationConfig>({
        total: 0,
        defaultCurrent: DEFAULT_PAGE,
        defaultPageSize: DEFAULT_PAGESIZE,
        hideOnSinglePage: hideOnSinglePage === true,
        showSizeChanger: false,
        disabled: typeof pageSize === 'number' && pageSize <= 0,
        onChange: (page: number, pageSize?: number) =>
            setPaging(paging =>
                produce(paging, draft => {
                    draft.page = page;
                    if (typeof pageSize === 'number' && typeof paging.pageSize === 'number' && paging.pageSize > 0)
                        draft.pageSize = pageSize;
                })
            )
    });

    const setTotal = useCallback((total: number) => {
        setPagination(pagination =>
            produce(pagination, draft => {
                draft.total = total;
                const maxPage = Math.ceil(total / (draft.pageSize ?? paging.pageSize ?? 10));
                if (typeof draft.current === 'number' && draft.current > maxPage) draft.current = 1;
            })
        );
    }, [paging.pageSize]);

    useEffect(() => {
        setPagination(pagination =>
            produce(pagination, draft => {
                draft.current = paging.page;
                draft.pageSize =
                    typeof paging.pageSize === 'number' && paging.pageSize > 0 ? paging.pageSize : draft.pageSize;
            })
        );
    }, [paging.page, paging.pageSize]);

    return [paging, pagination, setTotal];
}
