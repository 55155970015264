import { Card, Col, Row, Spin } from 'antd';
import dayjs from 'dayjs';
import { orderBy } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { DraggableLocation, DragDropContext, DropResult } from 'react-beautiful-dnd';

import { gql, useMutation } from '@apollo/client';

import { SimpleBarAny as SimpleBar } from '../../../components/SimpleBarAny';
import { UserContext } from '../../../contexts';
import { useSearch } from '../../../hooks';
import { ReloadButton } from '../../admin/components';
import { Setting } from '../../admin/setting/model';
import { SEARCH_SETTING } from '../../admin/setting/SettingEdit';
import { AccountStatus } from '../../admin/user/models';
import TeamSearcher from '../../crm/board/TeamSearcher';
import { ASSIGN_LEAD } from '../../crm/lead/graphql';
import { LeadAssignInput, LeadDataObject, LeadStatus, TeamType, User, UserDataObject } from '../../crm/lead/models';
import { TeamDataObject } from '../../shop/team/models';
import { Group, TaskActivity } from '../models';
import styles from '../styles.module.scss';
import TaskList from './TaskList';

export const SEARCH_LEADS = gql`
    query SearchLeads($filter: LeadSearchInput, $sort: [String], $page: Int, $pageSize: Int) {
        searchLeads(filter: $filter, sort: $sort, page: $page, pageSize: $pageSize) {
            total
            data {
                id
                status
                prospectLevel
                source
                summary
                customer {
                    id
                    type
                    gender
                    name {
                        type
                        companyName
                        firstName
                        lastName
                        middleName
                    }
                    contactInfo {
                        phoneNumber {
                            value
                            ext
                            description
                            preferred
                        }
                        phoneNumbers {
                            value
                            ext
                            description
                            preferred
                        }
                        email {
                            value
                            ext
                            description
                            preferred
                        }
                        address {
                            state
                            city
                        }
                    }
                }
                activities {
                    pickedUp
                    afterContactComment
                    closeComment
                    callContact {
                        createAt
                    }
                    _closeAt
                }
                lastContactAt
                contactStatus
                assignee {
                    id
                    firstName
                    lastName
                }
                _createAt
                _updateAt
            }
        }
    }
`;

interface Filter {
    status?: any;
    _noSearch?: boolean;
    todayDateFrom?: string;
    assignees?: string[];
}

const LeadAssignBoard = () => {
    const user = useContext(UserContext);
    const leadFilter: Filter = {
        _noSearch: true,
        status: [LeadStatus.New, LeadStatus.Appointment, LeadStatus.InProgress],
        todayDateFrom: dayjs().startOf('date').toISOString()
    };
    const { loading, data, setFilter, refresh } = useSearch<Filter, LeadDataObject>(SEARCH_LEADS, leadFilter, [], -1);
    const { data: settings } = useSearch<any, Setting>(SEARCH_SETTING, { _noSearch: true, name: 'plannerwarning' }, [], -1);

    const [assignLead] = useMutation(ASSIGN_LEAD);

    const [teams, setTeams] = useState<TeamDataObject[]>();

    const [groups, setGroups] = useState<Group[]>([]);
    const [reload, setReload] = useState<boolean>(false);
    const [assigned, setAssigned] = useState<boolean>(false);

    const [users, setUsers] = useState<User[]>();

    const buildTasks = useCallback((leads: LeadDataObject[]) => {
        const tasks: TaskActivity[] = [];
        leads.forEach(lead => {
            tasks.push({
                id: lead.id,
                status: lead.status,
                source: lead.source,
                summary: lead.summary,
                prospectLevel: lead.prospectLevel,
                loading: false,
                contactStatus: lead.contactStatus,
                lastContactAt: lead.lastContactAt,
                createAt: lead._createAt || 0,
                customer: lead.customer,
                activities: orderBy(
                    lead.activities?.filter(o => o.pickedUp == true || o.afterContactComment == true) || [],
                    a => a.callContact?.createAt ?? a._closeAt ?? a._createAt ?? 0,
                    'desc'
                )
            });
        });

        const tasksSort = orderBy(
            tasks,
            ['contactStatus', lead => (lead.status === LeadStatus.Converted ? 0x50 : lead.status), 'createAt'],
            ['asc', 'asc', 'asc']
        );
        return tasksSort;
    }, []);

    /**
     * 没打过电话的业务条件应该是“没有PhoneCall类型且已完成的Activity”
     * 打过电话的业务条件是“有PhoneCall类型且已完成的Activity”，
     */
    const buildGroups = useCallback(
        (data: LeadDataObject[]) => {
            const _groups: Group[] = [];
            if (users == undefined) return;

            //unassigned Leads
            const unassignedLeads = data.filter(item => item.assignee == null);

            if (Array.isArray(unassignedLeads) && unassignedLeads.length > 0) {
                const unassigneTasks: TaskActivity[] = buildTasks(unassignedLeads);
                if (Array.isArray(unassigneTasks) && unassigneTasks.length > 0) {
                    _groups.push({
                        id: 'UnassignedId',
                        title: 'Unassigned',
                        tasks: unassigneTasks
                    });
                }
            }

            users.forEach(user => {
                const leads = data.filter(item => item.assignee?.id == user.id);
                const tasks: TaskActivity[] = buildTasks(leads);
                /**
                 * 默认 useSearch查询数据时排除，手工 拖拽后的数据不排序
                 */
                // if (!assigned) {
                //     setAssigned(false);
                //     tasks = tasks.sort(function (a, b) {
                //         return dayjs(a.scheduleTime).valueOf() - dayjs(b.scheduleTime).valueOf();
                //     });
                // }

                _groups.push({
                    id: user.id ?? '',
                    title: user.firstName + ' ' + user.lastName,
                    tasks: tasks
                });
            });
            setGroups(_groups);
        },
        [buildTasks, users]
    );

    useEffect(() => {
        if (data == null) return;
        if (!assigned) {
            buildGroups(data);
        }

        setReload(false);
    }, [assigned, buildGroups, data, reload]); // , reload

    const handlerTeam = useCallback(() => {
        if (teams == null || (Array.isArray(teams) && teams.length == 0)) return;
        const _users: UserDataObject[] = [];
        teams.filter(team => {
            team.members.map(member => {
                const _members = _users.filter(user => user.id == member.id);
                if (_members.length == 0) {
                    _users.push(member);
                }
            });
        });
        setUsers(_users.filter(u => u.accountStatus === AccountStatus.Confirmed).sort((a, b) => a.firstName.localeCompare(b.firstName)));
    }, [teams]);

    useEffect(() => {
        handlerTeam();
    }, [teams, handlerTeam]);

    const onTeamCallBack = (teams: TeamDataObject[]) => {
        if (teams == null || (Array.isArray(teams) && teams.length == 0)) return;
        setTeams(teams);
    };

    const onChangeSelect = (value: TeamDataObject | null) => {
        if (value == null) {
            setFilter('assignees', undefined);
            handlerTeam();
        } else {
            setFilter(
                'assignees',
                value.members.map(member => member.id)
            );
            const _users: User[] = [];
            value.members.filter(member => {
                _users.push(member);
            });
            setUsers(_users.sort((a, b) => a.firstName.localeCompare(b.firstName)));
        }
    };

    const reorder = (sourceGroup: Group, startIndex: number, endIndex: number) => {
        if (startIndex === endIndex) return;
        const _tasks = sourceGroup.tasks;
        const [removed] = _tasks.splice(startIndex, 1);
        _tasks.splice(endIndex, 0, removed);
        sourceGroup.tasks = _tasks;
        return sourceGroup;
    };

    /**
     * Moves an item from one list to another list.
     */
    const move = (source: Group, destination: Group, droppableSource: DraggableLocation, droppableDestination: DraggableLocation) => {
        const _sTasks = source.tasks;
        const _dTasks = destination.tasks;
        const [removed] = _sTasks.splice(droppableSource.index, 1);

        _dTasks.splice(droppableDestination.index, 0, removed);

        source.tasks = _sTasks;
        destination.tasks = _dTasks;

        return { source, destination, removed };
    };

    const onDragEnd = async (result: DropResult) => {
        const { source, destination } = result;
        // dropped outside the list
        if (!destination) return;
        // Unassigned no drag
        if (destination.droppableId === 'UnassignedId') return;

        const sInd = source.droppableId;
        const dInd = destination.droppableId;

        const sourceGroup = (groups.filter(g => g.id == sInd) ?? [null])[0];
        const destinationGroup = (groups.filter(g => g.id == dInd) ?? [null])[0];
        if (sourceGroup == null || destinationGroup == null) return;

        if (sInd === dInd) {
            reorder(sourceGroup, source.index, destination.index);
        } else {
            const result = move(sourceGroup, destinationGroup, source, destination);

            /**
             * Assign lead to user
             */
            const leadAssignInput: LeadAssignInput = {
                targetType: 'person',
                targetIds: [destinationGroup.id]
            };

            const variables = { variables: { ids: [result.removed.id], input: leadAssignInput } };

            // 调用 assign API时 组件禁用
            //result.removed.loading = true;
            /**
             * bug
             * 连续快速拖拽存在bug ,假设从A到B， 被拖拽的数据会在下一个assign没有完成时 从B回到A，然后在assign完成后在从A到B，造成A的卡片数据上下跳动，
             * 虽然最终数据是正常的，但是卡片上下跳动的体验奇怪，不是我们想要的效果
             * 目前思考解决是 move 后，把当前拖拽的数据从 groups 中移除 ? 待修复验证
             */
            assignLead(variables).then((data: any) => {
                if (data.data.assignLead.length > 0) {
                    setAssigned(true);
                    setGroups(groups);
                }
            });
        }
    };

    const simpleBarRef = useRef<SimpleBar>(null);
    const height = useMemo(() => Math.max(document.documentElement.clientHeight, window.innerHeight ?? 0) - 184, []);
    return (
        <Card className={styles.tabcard} bodyStyle={{ paddingTop: 20, paddingBottom: 0 }}>
            <Row gutter={4}>
                <Col span={5}>
                    <TeamSearcher type={TeamType.Sales} onChange={onChangeSelect} members={user?.id} onCallBack={onTeamCallBack} />
                </Col>
                <Col span={3}>
                    <ReloadButton
                        onClick={() => {
                            setReload(true);
                            setAssigned(false);
                            refresh();
                        }}
                        disabled={loading}
                    />
                </Col>
            </Row>
            <Spin spinning={loading}>
                <SimpleBar ref={simpleBarRef} style={{ height }}>
                    <Row>
                        <Col span={24}>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                {groups != null && (
                                    <DragDropContext onDragEnd={onDragEnd}>
                                        <TaskList groups={groups} settings={settings || []} />
                                    </DragDropContext>
                                )}
                            </div>
                        </Col>
                    </Row>
                </SimpleBar>
            </Spin>
        </Card>
    );
};

export default LeadAssignBoard;
