import { notification, Button, Card, Col, Modal, Popconfirm, Row, Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import dayjs from 'dayjs';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { DeleteOutlined } from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';

import { SimpleBarAny as SimpleBar } from '../../../components/SimpleBarAny';
import { UserContext } from '../../../contexts';
import { useSearch } from '../../../hooks';
import { ReloadButton } from '../../crm/components';
import EmailCustomerModal from '../../crm/lead/components/EmailCustomerModal';
import SmsCustomerModal from '../../crm/lead/components/SmsCustomerModal';
import { Direction, TeamType } from '../../crm/lead/models';
import UserSearcherSelect from '../../service/appointment/components/UserSearcherSelect';
import { InboxDataObject, InboxType } from '../models';
import styles from '../styles.module.scss';
import { displayCustomer } from '../utils';

const SEARCH_INBOX = gql`
    query SearchInBoxViews($filter: InBoxViewSearchInput, $sort: [String], $page: Int, $pageSize: Int) {
        searchInBoxViews(filter: $filter, sort: $sort, page: $page, pageSize: $pageSize) {
            total
            data {
                id
                type
                message
                lead {
                    id
                    customer {
                        id
                        type
                        name {
                            companyName
                            firstName
                            lastName
                        }
                        contactInfo {
                            email {
                                value
                            }
                        }
                    }
                    opportunities {
                        make
                        model
                        color
                        vehicle {
                            year
                        }
                    }
                }
                _createAt
            }
        }
    }
`;

export const DELETE_SMSLOG = gql`
    mutation DeleteSMSLog($ids: [ID!]!) {
        deleteSMSLog(ids: $ids)
    }
`;

export const DELETE_EMAILLOG = gql`
    mutation DeleteEmailLog($ids: [ID!]!) {
        deleteEmailLog(ids: $ids)
    }
`;

const Column = (props: ColumnProps<InboxDataObject>) => <Table.Column<InboxDataObject> {...props} />;

interface Filter {
    _search?: string;
    _noSearch?: boolean;
    salesperson?: string;
    addressees?: string[];
    direction?: Direction;
}

const InboxCard = () => {
    //获取当前登录人的信息
    const user = useContext(UserContext);
    const isSalesManager = useMemo(() => user != null && (user.roles.includes('admin') || user.roles.includes('salesmanager')), [user]);

    const [inboxs, setInBoxs] = useState<InboxDataObject[]>([]);
    const filter: Filter = {
        _noSearch: true,
        salesperson: user?.id,
        direction: Direction.Inbound
    };
    const defaultSortDescend = '!_createAt';
    const { loading, data, pagination, setFilter, refresh } = useSearch<Filter>(SEARCH_INBOX, filter, [defaultSortDescend]);

    const [selectedInBoxIds, setSelectedInBoxIds] = useState<string[]>([]);
    const [selectedInBoxs, setSelectedInBoxs] = useState<InboxDataObject[]>([]);

    const [selectedInBox, setSelectedInBox] = useState<InboxDataObject>();
    const [smsModalVisible, setSmsModalVisible] = useState<boolean>(false);
    const [emailModalVisible, setEmailModalVisible] = useState(false);
    const [selectorInBoxPreviewVisible, setSelectorInBoxPreviewVisible] = useState(false);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [deleteSMSLog] = useMutation(DELETE_SMSLOG);
    const [deleteEmailLog] = useMutation(DELETE_EMAILLOG);

    useEffect(() => {
        if (data == null) return;
        setInBoxs(data as InboxDataObject[]);
    }, [data]);

    const onDelete = async () => {
        try {
            setIsSubmitting(true);
            const eamilLogIds = selectedInBoxs.filter(o => o.type === InboxType.Email).map(o => o.id);
            const smsLogIds = selectedInBoxs.filter(o => o.type === InboxType.SMS).map(o => o.id);
            if (eamilLogIds.length > 0) await deleteEmailLog({ variables: { ids: eamilLogIds } });
            if (smsLogIds.length > 0) await deleteSMSLog({ variables: { ids: smsLogIds } });
            refresh();
        } catch (err) {
            console.error(err);
            notification.error({ duration: 0, message: 'Something went wrong', description: err.message });
        } finally {
            setIsSubmitting(false);
        }
    };

    const simpleBarRef = useRef<SimpleBar>(null);
    const height = useMemo(() => Math.max(document.documentElement.clientHeight, window.innerHeight ?? 0) - 218, []);
    return (
        <Card className={styles.tabcard} bodyStyle={{ paddingTop: 20, paddingBottom: 0 }}>
            <Row gutter={4}>
                {isSalesManager && (
                    <Col span={3}>
                        <UserSearcherSelect
                            onChange={value => {
                                if (typeof value === 'string') setFilter('salesperson', value);
                            }}
                            placeholder="Select Sales rep"
                            type={TeamType.Sales}
                            value={filter.salesperson}
                            name={`${user?.firstName} ${user?.lastName}`}
                        />
                    </Col>
                )}
                <Col span={3}>
                    <ReloadButton onClick={() => refresh()} disabled={loading} />
                </Col>
                <Col span={isSalesManager ? 18 : 21}>
                    <div style={{ display: 'flex', justifyContent: 'right' }}>
                        <div>
                            <Popconfirm
                                disabled={selectedInBoxs.length == 0}
                                placement="right"
                                title="Remove selected data?"
                                okText="Yes"
                                cancelText="No"
                                onConfirm={onDelete}
                            >
                                <Button type="primary" icon={<DeleteOutlined />} disabled={selectedInBoxs.length == 0}>
                                    Remove
                                </Button>
                            </Popconfirm>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Card size="small" bordered={false} style={{ marginTop: 8, width: '100%' }} bodyStyle={{ padding: 0 }}>
                    <SimpleBar ref={simpleBarRef} style={{ height }}>
                        <Table
                            rowKey="id"
                            size="small"
                            dataSource={inboxs}
                            pagination={pagination}
                            loading={loading || isSubmitting}
                            className={styles.table}
                            rowSelection={{
                                type: 'checkbox',
                                selectedRowKeys: selectedInBoxIds,
                                preserveSelectedRowKeys: true,
                                onChange: (selectedRowKeys: any[], selectedRows: any[]) => {
                                    setSelectedInBoxIds([...selectedRowKeys]);
                                    setSelectedInBoxs([...selectedRows]);
                                }
                            }}
                        >
                            <Column
                                title="Date"
                                width={150}
                                render={(_, record) => {
                                    if (record._createAt == null) return;
                                    const createAt = dayjs(record._createAt);
                                    if (dayjs().year() === createAt.year()) return createAt.format('MM/DD h:mm a');
                                    else return createAt.format('MM/DD/YYYY h:mm a');
                                }}
                            />
                            <Column
                                title="Customer"
                                width={150}
                                render={(_, record) =>
                                    record.lead != null && (
                                        <Button size="small" type="link" style={{ padding: 0 }}>
                                            <Link to={`crm/lead/${record.lead.id}`} target="_blank">
                                                {displayCustomer(record.lead.customer)}
                                            </Link>
                                        </Button>
                                    )
                                }
                            />

                            <Column
                                title="Model"
                                width={150}
                                render={(_, record) => {
                                    if (
                                        record.lead != null &&
                                        Array.isArray(record.lead.opportunities) &&
                                        record.lead.opportunities.length > 0
                                    ) {
                                        const opportunity = record.lead.opportunities[0];
                                        return (
                                            <span>
                                                {typeof opportunity.vehicle != 'string' && opportunity.vehicle?.year} {opportunity.make}{' '}
                                                {opportunity.model}
                                            </span>
                                        );
                                    }
                                }}
                            />
                            <Column
                                title="Type"
                                width={80}
                                render={(_, record) => {
                                    if (record.type === InboxType.Email) return '📧 Email';
                                    if (record.type === InboxType.SMS) return '📱 SMS';
                                }}
                            />
                            <Column
                                title="Preview"
                                render={(_, record) => {
                                    if (record.type === InboxType.Email)
                                        return (
                                            <a
                                                style={{ color: '#2a7fff' }}
                                                onClick={() => {
                                                    setSelectedInBox(record);
                                                    setSelectorInBoxPreviewVisible(true);
                                                }}
                                            >
                                                <span>{record.message.substring(0, record.message.indexOf('\n'))}</span>
                                            </a>
                                        );
                                    else return <span>{record.message}</span>;
                                }}
                            />
                            <Column
                                width={140}
                                title="Actions"
                                align="center"
                                render={(_, record) => (
                                    <Button
                                        size="small"
                                        type="link"
                                        onClick={() => {
                                            setSelectedInBox(record);
                                            if (record.type === InboxType.Email) setEmailModalVisible(true);
                                            else setSmsModalVisible(true);
                                        }}
                                    >
                                        Reply
                                    </Button>
                                )}
                            />
                        </Table>
                    </SimpleBar>
                </Card>
            </Row>

            <Modal
                title={
                    selectedInBox?.message != null
                        ? selectedInBox.message.substring(0, selectedInBox.message.indexOf('\n'))
                        : 'Mail content'
                }
                width={1000}
                visible={selectorInBoxPreviewVisible}
                onCancel={() => setSelectorInBoxPreviewVisible(false)}
                footer={null}
            >
                <div style={{ height: 600, overflowX: 'hidden' }}>
                    <div style={{ whiteSpace: 'pre-wrap' }}>
                        {selectedInBox?.message != null &&
                            selectedInBox.message.substring(selectedInBox.message.indexOf('\n') + 1, selectedInBox.message.length - 1)}
                    </div>
                </div>
            </Modal>
            <SmsCustomerModal
                lead={selectedInBox?.lead}
                visible={smsModalVisible}
                onCancel={() => {
                    setSmsModalVisible(false);
                }}
            />

            <EmailCustomerModal
                lead={selectedInBox?.lead}
                visible={emailModalVisible}
                onCancel={() => {
                    setEmailModalVisible(false);
                }}
            />
        </Card>
    );
};

export default InboxCard;
