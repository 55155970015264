import { notification, Button, Card, Col, Progress, Row, Space, Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { FilterValue, TableCurrentDataSource, TablePaginationConfig } from 'antd/lib/table/interface';
import dayjs from 'dayjs';
import { orderBy } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';

import { gql } from '@apollo/client';

import { SimpleBarAny as SimpleBar } from '../../../components/SimpleBarAny';
import { useSearch } from '../../../hooks';
import { LeadPriority } from '../../admin/setting/model';
import { ReloadButton } from '../../crm/components';
import { SEARCH_TEAMS } from '../../crm/lead/graphql';
import { LeadContactStatus, LeadDataObject, LeadStatus, TeamType, UserDataObject } from '../../crm/lead/models';
import { TeamDataObject } from '../../shop/team/models';
import styles from '../styles.module.scss';
import SalesLeadsModal from './SalesLeadsModal';

interface Filter {
    _search?: string;
    prospectLevel?: any;
    status?: any;
    _noSearch?: boolean;
    assignee?: string;
    scheduledTimeFrom?: string;
    group?: string;
    createTimeFrom?: string;
    contactStatus?: any;
}

interface Props {
    activeKey: string;
    type?: string;
    leadPriority: LeadPriority;
}

interface LeadStatistics {
    salesId: string;
    salesName: string;
    taskTotal: number;
    taskCompleted: number;
    progress: number;
}

const Column = (props: ColumnProps<LeadStatistics>) => <Table.Column<LeadStatistics> {...props} />;

const SEARCH_LEADS = gql`
    query SearchLeads($filter: LeadSearchInput, $sort: [String], $page: Int, $pageSize: Int) {
        searchLeads(filter: $filter, sort: $sort, page: $page, pageSize: $pageSize) {
            total
            data {
                id
                status
                lastContactAt
                contactStatus
                assignee {
                    id
                    firstName
                    lastName
                }
                _createAt
            }
        }
    }
`;

const FollowUpStatistics = ({ activeKey, type, leadPriority }: Props) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [statisticsData, setStatisticsData] = useState<LeadStatistics[]>([]);
    const filter: Filter = {
        _noSearch: true,
        contactStatus: [LeadContactStatus.None, LeadContactStatus.Success],
        status: [LeadStatus.New, LeadStatus.Appointment, LeadStatus.InProgress],
        assignee: undefined
    };
    const { loading, data, refresh } = useSearch<Filter, LeadDataObject>(SEARCH_LEADS, filter, [], -1);
    const [leadDetailVisible, setLeadDetailVisible] = useState(false);
    const [selectSales, setSelectSales] = useState<LeadStatistics>();

    const { loading: userLoading, data: teams } = useSearch<any, TeamDataObject>(
        SEARCH_TEAMS,
        { _noSearch: true, type: TeamType.Sales },
        [],
        -1
    );

    const buildLedLight = (lead: LeadDataObject, leadPriority: LeadPriority) => {
        //const leadFrom = leadPriority.from;
        const initContactSpanMinutes = leadPriority.minutes;

        if ([LeadStatus.Converted, LeadStatus.Lost, LeadStatus.Defeated].some(status => (lead.status & status) === status)) return;
        if (lead.contactStatus == null) return;
        //if (!(lead.source && leadFrom && leadFrom.trim().toLowerCase().split('\n').includes(lead.source.toLowerCase()))) return;

        const lastContactedAt = lead.lastContactAt != null ? dayjs(lead.lastContactAt) : null;

        const createDiffDays = dayjs().diff(lead._createAt, 'day', true);
        const toady = dayjs(dayjs().format('L')); // MM/DD/YYYY
        const lastContacted = dayjs(lastContactedAt?.format('L'));

        const toadyLastContactedDiff = toady.diff(lastContacted, 'day');
        // 15 分钟有联系过则 green
        if (dayjs().diff(lead._createAt, 'minute', true) < initContactSpanMinutes) {
            if (lead.contactStatus === LeadContactStatus.Success) return true;
        }

        /**
         * Leads older than [  ] days, follow up interval: [  ] days
         * **/
        if (
            leadPriority.followupIntervals != null &&
            Array.isArray(leadPriority.followupIntervals) &&
            leadPriority.followupIntervals?.length > 0
        ) {
            let followupIntervals = leadPriority?.followupIntervals.filter(
                followupInterval => followupInterval.leadsOlderThan < createDiffDays
            );
            if (followupIntervals.length > 0) {
                followupIntervals = orderBy(followupIntervals, ['leadsOlderThan'], ['desc']);
                const followupInterval = followupIntervals[0].followupInterval ?? leadPriority.defaultFollowupInterval;
                //** 第 X 天联系过 变为 绿灯   <= 是每隔 X 天  < 是每 X 天 */
                if (
                    lead.contactStatus === LeadContactStatus.Success &&
                    lastContactedAt != null &&
                    toadyLastContactedDiff < followupInterval
                )
                    return true;
            }
        }

        /**
         * Default lead follow-up interval: [  ] days
         * **/
        if (
            lead.contactStatus === LeadContactStatus.Success &&
            lastContactedAt != null &&
            toadyLastContactedDiff < leadPriority.defaultFollowupInterval
        )
            return true;
    };

    useEffect(() => {
        if (activeKey === type) refresh();
    }, [activeKey, refresh, type]);

    useEffect(() => {
        try {
            setIsSubmitting(true);

            let teamUsers: UserDataObject[] = [];
            if (teams && teams.length > 0) {
                const newUsers = teams.flatMap(o => o.members).map(o => JSON.stringify(o));
                const usersSet = new Set(newUsers);
                teamUsers = Array.from(usersSet).map(o => JSON.parse(o));
            }

            if (data == null) return;
            if (leadPriority == null) return;
            const newStatisticsData: LeadStatistics[] = [];
            teamUsers.forEach(sale => {
                const taskTotal = data.filter(r => r.assignee?.id == sale.id).length;
                const taskCompleted = data.filter(r => r.assignee?.id == sale.id && buildLedLight(r, leadPriority) == true).length;
                newStatisticsData.push({
                    salesId: sale.id,
                    salesName: `${sale.firstName} ${sale.lastName}`,
                    taskTotal: taskTotal,
                    taskCompleted: taskCompleted,
                    progress: taskTotal == 0 ? 100 : Number(((taskCompleted / taskTotal) * 100).toFixed(0))
                });
            });

            const newStatisticsDatailOrder = orderBy(newStatisticsData, ['progress', 'taskTotal'], ['asc', 'desc']);
            setStatisticsData(newStatisticsDatailOrder);
        } catch (error) {
            notification.error({ duration: 0, message: 'Something went wrong', description: error.message });
        } finally {
            setIsSubmitting(false);
        }
    }, [data, leadPriority, teams]);

    const simpleBarRef = useRef<SimpleBar>(null);
    const height = useMemo(() => Math.max(document.documentElement.clientHeight, window.innerHeight ?? 0) - 208, []);

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: any,
        extra: TableCurrentDataSource<LeadStatistics>
    ) => {
        if (extra.action === 'sort') {
            const tableDefaultOrder = orderBy(statisticsData, ['progress', 'taskTotal'], ['asc', 'desc']);
            switch (sorter.columnKey) {
                case 'salesName':
                    if (sorter.order === 'ascend') setStatisticsData(orderBy(statisticsData, ['salesName'], ['asc']));
                    if (sorter.order === 'descend') setStatisticsData(orderBy(statisticsData, ['salesName'], ['desc']));

                    if (sorter.order === undefined) setStatisticsData(tableDefaultOrder);
                    break;
                case 'progress':
                    if (sorter.order === 'ascend') setStatisticsData(tableDefaultOrder);
                    if (sorter.order === 'descend') {
                        const newStatisticsDatailOrder = orderBy(statisticsData, ['progress', 'taskTotal'], ['desc', 'desc']);
                        setStatisticsData(newStatisticsDatailOrder);
                    }
                    if (sorter.order === undefined) setStatisticsData(tableDefaultOrder);
                    break;
            }
        }
    };

    return (
        <Card className={styles.tabcard} bodyStyle={{ paddingTop: 20, paddingBottom: 0 }}>
            <Row gutter={4}>
                <Col span={3}>
                    <ReloadButton onClick={() => refresh()} disabled={loading || isSubmitting || userLoading} />
                </Col>
            </Row>
            <Row>
                <Card size="small" bordered={false} style={{ marginTop: 8, width: '100%' }} bodyStyle={{ padding: 0 }}>
                    <SimpleBar ref={simpleBarRef} style={{ height }}>
                        <Table
                            rowKey="salesId"
                            size="small"
                            dataSource={statisticsData}
                            loading={loading || isSubmitting || userLoading}
                            className={styles.table}
                            pagination={false}
                            onChange={handleTableChange}
                        >
                            <Column width={120} title="Sales rep" dataIndex="salesName" key="salesName" sorter={true} />
                            <Column width={120} title="Task total" dataIndex="taskTotal" />
                            <Column width={120} title="Task completed" dataIndex="taskCompleted" />
                            <Column
                                width={120}
                                title="Progress"
                                key="progress"
                                sorter={true}
                                defaultSortOrder="ascend"
                                render={(_, record) => {
                                    return (
                                        <Progress
                                            type="circle"
                                            width={50}
                                            percent={record.progress}
                                            strokeColor={record.progress >= 80 ? '#389e0d' : '#cf1322'}
                                            format={percent => (
                                                <span style={(percent ?? 0) >= 80 ? { color: '#389e0d' } : { color: '#cf1322' }}>
                                                    {percent}%
                                                </span>
                                            )}
                                        />
                                    );
                                }}
                            />{' '}
                            <Column
                                width={100}
                                title="Actions"
                                render={(_, record) => (
                                    <Space size={0} wrap>
                                        {record.taskTotal > 0 && (
                                            <Button
                                                size="small"
                                                type="link"
                                                onClick={() => {
                                                    setSelectSales(record);
                                                    setLeadDetailVisible(true);
                                                }}
                                            >
                                                Details
                                            </Button>
                                        )}
                                    </Space>
                                )}
                            />
                        </Table>
                    </SimpleBar>
                </Card>
            </Row>
            <SalesLeadsModal
                assignee={selectSales?.salesId}
                assigneeName={selectSales?.salesName}
                visible={leadDetailVisible}
                onCancel={() => setLeadDetailVisible(false)}
                leadPriority={leadPriority}
            />
        </Card>
    );
};

export default FollowUpStatistics;
