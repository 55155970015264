export enum SettingType {
    Dealer,
    User
}

export interface Setting {
    id: string;
    owner: string;
    type: SettingType;
    name: string;
    value: string;
}

export type SettingUpdateInput = {
    type: SettingType;
    items: SettingItemInput[];
};

export interface SettingItemInput {
    id?: string;
    type?: SettingType;
    name: string;
    value: string;
}

// export interface SettingItem {
//     id?: string;
//     type?: SettingType;
//     name: string;
//     value: SettingItemValue[];
// }

// export interface SettingItemValue {
//     from: string;
//     to: string;
// }

interface FollowupInterval {
    leadsOlderThan: number;
    followupInterval: number;
}

export interface LeadPriority {
    from: string;
    minutes: number;
    defaultFollowupInterval: number;
    followupIntervals?: FollowupInterval[];
}
