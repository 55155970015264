import { Button, Empty } from 'antd';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.section`
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: center;
    gap: 1rem;
`;

const NotFound = () => {
    const navigate = useNavigate();
    return (
        <Container>
            <Empty description="Page not found, or you don't have the permission to access it." />
            <Button style={{ width: 100 }} onClick={() => navigate(-1)}>
                Go Back
            </Button>
        </Container>
    );
};

export default NotFound;
