import { message, Button, Col, Form, Input, InputNumber, Modal, Row, Table } from 'antd';
import currency from 'currency.js';
import React, { useEffect, useState } from 'react';

import { CloseOutlined, DownOutlined, EditOutlined } from '@ant-design/icons';

import { EnumSelect } from '../../../components';
import { SimpleBarAny as SimpleBar } from '../../../components/SimpleBarAny';
import { Vehicle, VehicleAdditionalCharge, VehicleInput, VehicleInputSource, VehicleStockType } from '../../../models';
import VehicleChargeEditor from '../../vehicles/VehicleChargeEditor';
import { WindowContainer } from '../components';
import { VehicleModelSearcher } from '../searchers';
import { AdditionalChargeSelector } from '../selectors';

interface Props {
    visible: boolean;
    data?: Readonly<Vehicle>;
    onCancel: () => void;
    onSave: (data: VehicleInput, id?: string, continueAdd?: boolean) => Promise<void>;
}

const emptyData: VehicleInput = {
    modelCode: '',
    vin: '',
    stockNo: '',
    stockType: VehicleStockType.New,
    inputSource: VehicleInputSource.Inventory,
    msrp: 0,
    baseInvoicePrice: 0,
    grossAcv: 0,
    payoff: 0,
    tradeInMileage: 0,
    additionalCharges: [],
    usedRepairs: []
};

const VehicleFormNew: React.FC = () => (
    <Row>
        <Col span={12}>
            <Form.Item
                name="msrp"
                label="MSRP"
                rules={[{ type: 'number', required: true, min: 0 }]}
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 12 }}
            >
                <InputNumber step={100} style={{ width: '100%' }} />
            </Form.Item>
        </Col>
        <Col span={12}>
            <Form.Item
                name="baseInvoicePrice"
                label="Invoice price"
                rules={[{ type: 'number', required: true, min: 0 }]}
                labelCol={{ span: 12 }}
            >
                <InputNumber step={100} style={{ width: '100%' }} />
            </Form.Item>
        </Col>
        <Col span={12}>
            <Form.Item name="holdbackPrice" label="Holdback" rules={[{ type: 'number' }]} labelCol={{ span: 10 }} wrapperCol={{ span: 12 }}>
                <InputNumber step={100} style={{ width: '100%' }} min={0} />
            </Form.Item>
        </Col>
        <Col span={12}>
            <Form.Item name="wholesalePrice" label="Wholesale price" rules={[{ type: 'number' }]} labelCol={{ span: 12 }}>
                <InputNumber step={100} style={{ width: '100%' }} min={0} />
            </Form.Item>
        </Col>
    </Row>
);

const VehicleFormUsed: React.FC = () => (
    <Row>
        <Col span={12}>
            <Form.Item
                name="grossAcv"
                label="ACV"
                rules={[{ type: 'number', required: true }]}
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 12 }}
            >
                <InputNumber step={100} style={{ width: '100%' }} min={0} />
            </Form.Item>
        </Col>
        <Col span={12}>
            <Form.Item name="payoff" label="Payoff" rules={[{ type: 'number' }]} labelCol={{ span: 12 }}>
                <InputNumber step={100} style={{ width: '100%' }} min={0} />
            </Form.Item>
        </Col>
        <Col span={12}>
            <Form.Item name="tradeInMileage" label="Mileage" rules={[{ type: 'number' }]} labelCol={{ span: 10 }} wrapperCol={{ span: 12 }}>
                <InputNumber step={100} style={{ width: '100%' }} min={0} />
            </Form.Item>
        </Col>
    </Row>
);

const VehicleFormExtended: React.FC = () => (
    <Row gutter={8}>
        <Col span={24}>
            <Form.Item name="bodyColor" label="Body color">
                <Input />
            </Form.Item>
        </Col>
        <Col span={24}>
            <Form.Item name="interiorColor" label="Interior">
                <Input />
            </Form.Item>
        </Col>
        <Col span={24}>
            <Form.Item name="trimLevel" label="Trim level">
                <Input />
            </Form.Item>
        </Col>
        <Col span={15}>
            <Form.Item name="licenseNumber" label="License #" labelCol={{ span: 8 }}>
                <Input />
            </Form.Item>
        </Col>
        <Col span={9}>
            <Form.Item name="keyNumber" label="Key #" labelCol={{ span: 8 }}>
                <Input />
            </Form.Item>
        </Col>
    </Row>
);
// const EditableCell = ({ children, ...restProps }: { children: any }) => {
//     return <td {...restProps}>{children}</td>;
// };
const VehicleEditor: React.FC<Props> = props => {
    const id = props.data?.id;
    const initialValues = props.data || emptyData;
    // 表单控制属性
    const [form] = Form.useForm();
    const [isWorking, setIsWorking] = useState(false);
    const [showAll, setShowAll] = useState(false);

    useEffect(() => {
        if (!props.visible) return;
        form.resetFields();
        setChargeList(Array.from(form.getFieldValue('additionalCharges')).concat());
        setShowAll(false);
    }, [form, initialValues, props.visible]);
    const onOk = async () => {
        try {
            setIsWorking(true);
            const values = (await form.validateFields()) as VehicleInput;
            // 处理数据
            if (Array.isArray(values.additionalCharges))
                values.additionalCharges = chargeList.map(charge => ({
                    code: charge.code,
                    description: charge.description,
                    cost: charge.cost,
                    notes: charge.notes
                })) as any;
            values.modelCode = (values as any).model.code;
            delete (values as any).model;
            values.inputSource = 0;
            // values.msrp = values.msrp ?? 0;
            //console.log(JSON.stringify(values, null, 2));
            await props.onSave(values, id);
        } catch (err) {
            console.error(err);
        } finally {
            setIsWorking(false);
        }
    };
    const onCancel = () => {
        if (!isWorking) props.onCancel();
    };
    // AdditionalCharges选择对话框
    const [selectorVisible, setSelectorVisible] = useState(false);
    const onFieldsChange = (changedFields: any[]) => {
        const field = changedFields.find(
            field => Array.isArray(field.name) && field.name.length === 1 && field.name[0] === 'additionalCharges'
        );
        if (field != null) setSelectorVisible(false);
    };
    //添加库里没有的additional chargeItems
    const [chargeList, setChargeList] = useState<any[]>([]);
    const [manuallyVisible, setManuallyVisible] = useState(false);
    const [manuallyData, setManuallyData] = useState<any>();
    const edit = (record: any) => {
        setManuallyVisible(true);
        setManuallyData(record);
    };
    const newCharge = () => {
        setManuallyVisible(true);
        setManuallyData(undefined);
    };

    const deleteItem = (code: string) => {
        setChargeList(chargeList.filter(item => item.code !== code));
    };

    const save = (data: any, code: string) => {
        const index = chargeList.findIndex(item => data.code === item.code);
        if (index > -1) {
            if (manuallyData?.code !== data.code) message.error('Code can not repeat');
            else {
                chargeList[index] = data;
                setManuallyVisible(false);
            }
            return;
        }
        chargeList.push(data);
        if (code && manuallyData?.code !== data.code) {
            setChargeList(chargeList.filter(item => item.code !== code));
        }
        setManuallyVisible(false);
    };

    const chargeTable = () => {
        return (
            <Form.Item name="additionalCharges" label="Add. charges">
                <Table
                    rowKey="code"
                    size="small"
                    dataSource={chargeList}
                    pagination={false}
                    key={new Date().toString()}
                    summary={charges => (
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0}>
                                <Button type="link" size="small" onClick={() => setSelectorVisible(true)}>
                                    Select items
                                </Button>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1} colSpan={2}>
                                Total: {charges.reduce((current, items) => current.add(items.cost), currency(0)).format()}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={2}>
                                <Button type="link" size="small" onClick={newCharge}>
                                    Add new
                                </Button>
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    )}
                >
                    <Table.Column title="Code" dataIndex="code" />
                    <Table.Column title="Cost" dataIndex="cost" />
                    <Table.Column title="Description" dataIndex="description" />
                    <Table.Column
                        title="Action"
                        render={(_: any, record: VehicleAdditionalCharge) => {
                            return (
                                <>
                                    <Button size="small" type="link" onClick={() => edit(record)}>
                                        <EditOutlined />
                                    </Button>
                                    <Button size="small" type="link" onClick={() => deleteItem(record.code)}>
                                        <CloseOutlined />
                                    </Button>
                                </>
                            );
                        }}
                    />
                </Table>
            </Form.Item>
        );
    };

    return (
        <Modal
            title={`${id == null ? 'New' : 'Edit'} Inventory`}
            confirmLoading={isWorking}
            visible={props.visible}
            onCancel={onCancel}
            onOk={onOk}
            width={600}
            className="form"
            okText="Submit"
            cancelButtonProps={{ type: 'link' }}
            maskClosable={false}
            keyboard={false}
            forceRender
        >
            <SimpleBar style={{ maxHeight: 600 }}>
                <WindowContainer>
                    <Form form={form} initialValues={initialValues} labelCol={{ span: 5 }} onFieldsChange={onFieldsChange}>
                        <Form.Item name="stockNo" label="Stock #" rules={[{ required: true, whitespace: true }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="vin" label="VIN" rules={[{ required: true, whitespace: true, len: 17 }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="model" label="Model" rules={[{ required: true }]}>
                            <VehicleModelSearcher />
                        </Form.Item>
                        <Form.Item name="modelYear" label="Year" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                        {/* <Form.Item noStyle shouldUpdate={(prev, next) => prev.model !== next.model}>
                            {({ getFieldValue }) => {
                                const model = getFieldValue('model') as VehicleModel;
                                console.log(model);
                                return model != null && Array.isArray(model.years) && model.years.length > 0 ? (
                                    <Form.Item name="modelYear" label="Year" rules={[{ required: true }]}>
                                        <Radio.Group>
                                            {model.years.map(year => (
                                                <Radio key={year} value={year}>
                                                    {year}
                                                </Radio>
                                            ))}
                                        </Radio.Group>
                                    </Form.Item>
                                ) : null;
                            }}
                        </Form.Item> */}

                        <Form.Item name="stockType" label="Stock type" rules={[{ type: 'number', required: true }]}>
                            <EnumSelect enum={VehicleStockType} />
                        </Form.Item>
                        <Form.Item noStyle shouldUpdate={(prev, current) => prev.stockType !== current.stockType}>
                            {({ getFieldValue }) => {
                                const stockType = getFieldValue('stockType') as VehicleStockType;
                                return stockType === VehicleStockType.Used || stockType === VehicleStockType.LeaseUsed ? (
                                    <VehicleFormUsed />
                                ) : (
                                    <VehicleFormNew />
                                );
                            }}
                        </Form.Item>
                        <Row>
                            <Col span={24} style={{ textAlign: 'right' }}>
                                <AdditionalChargeSelector
                                    visible={selectorVisible}
                                    onCancel={() => setSelectorVisible(false)}
                                    data={chargeList}
                                    onChange={(data: VehicleAdditionalCharge[]) => {
                                        setChargeList(data);
                                        setSelectorVisible(false);
                                    }}
                                />
                                {chargeTable()}
                            </Col>
                        </Row>
                        {showAll ? (
                            <VehicleFormExtended />
                        ) : (
                            <Row>
                                <Col span={24} style={{ textAlign: 'right' }}>
                                    <Button type="link" onClick={() => setShowAll(true)}>
                                        More
                                        <DownOutlined />
                                    </Button>
                                </Col>
                            </Row>
                        )}
                    </Form>
                    <VehicleChargeEditor
                        visible={manuallyVisible}
                        data={manuallyData}
                        onCancel={() => setManuallyVisible(false)}
                        onSave={save}
                    />
                </WindowContainer>
            </SimpleBar>
        </Modal>
    );
};

export default React.memo(VehicleEditor);
