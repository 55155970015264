import React from 'react';
import { useParams } from 'react-router-dom';
const Search = React.lazy(() => import('./SearchVehicleAdditional'));
const New = React.lazy(() => import('./NewVehicleAdditional'));
const Edit = React.lazy(() => import('./EditVehicleAdditional'));
const VehicleAdditional = () => {
    const { id } = useParams();
    if (id == null) return <Search />;
    if (id === 'new') return <New />;
    return <Edit id={id} />;
};

export default VehicleAdditional;
