import dayjs from 'dayjs';

import { Customer, CustomerType } from '../crm/lead/models';

export const hasPermissions = (user: any, name: any) => {
    if (user == null) return false;
    const isAdmin = user.roles.includes('admin');
    return isAdmin || user.permissions.includes(name);
};

export const displayCustomer = (customer?: Customer) =>
    customer &&
    (customer.name == null
        ? ''
        : customer.type === CustomerType.Person
        ? `${customer.name.lastName ?? ''}, ${customer.name.firstName ?? ''}`
        : customer.name.companyName ?? '');

export const insertLink = (value: string) => {
    const urlReg = /(http:\/\/|ftp:\/\/|https:\/\/|www.)[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?/g;
    return value.replace(urlReg, url => {
        if (url.match('http://') || url.match('ftp://') || url.match('https://'))
            return `<a href="${url}" style="color:#2a7fff"  target="_blank">${url}</a>`;
        else return `<a href=\"http:\/\/${url}\" style="color:#2a7fff" target="_blank">${url}</a>`;
    });
};

export const getDayjs = (value: string | undefined) => {
    if (value == null || value == undefined) return null; // dayjs('1970-01-01')
    if (Number.isNaN(Number(value))) {
        return dayjs(value);
    } else {
        return dayjs(Number(value));
    }
};
