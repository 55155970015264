import { Badge, Tabs } from 'antd';
import { useContext, useEffect, useMemo, useState } from 'react';

import { UserContext } from '../../contexts';
import { useSearch } from '../../hooks';
import Page from '../../pages/Page';
import { LeadPriority, Setting } from '../admin/setting/model';
import { initLeadPriority, leadPriorityInitData, SEARCH_SETTING } from '../admin/setting/SettingEdit';
import { LeadStatus } from '../crm/lead/models';
import MyCalendar from './calendar/MyCalendar';
import AdviserLeadChart from './components/AdviserLeadChart';
import CloseLeadCard from './components/CloseLeadCard';
// import CustomerBirthdaysCard from './components/CustomerBirthdaysCard';
import DealCard from './components/DealCard';
import FollowUpStatistics from './components/FollowUpStatistics';
import InboxCard from './components/InboxCard';
import LeadAssignBoard from './components/LeadAssignBoard';
import LeadContactCard from './components/LeadContactCard';
import styles from './styles.module.scss';

const Home = () => {
    const user = useContext(UserContext);
    const isSalesManager = useMemo(() => user != null && (user.roles.includes('admin') || user.roles.includes('salesmanager')), [user]);
    const isSales = useMemo(
        () => user != null && !user.roles.includes('admin') && !user.roles.includes('salesmanager') && user.pages.includes('crm/lead'),
        [user]
    );
    const [contactCount, setContactCount] = useState<number>(0);

    const [activeKey, setActiveKey] = useState<string>('calendar');

    const { data: settings } = useSearch<any, Setting>(SEARCH_SETTING, { _noSearch: true, name: 'leadpriority' }, [], -1);
    const [leadPriority, setLeadPriority] = useState<LeadPriority>(leadPriorityInitData);
    useEffect(() => {
        setLeadPriority(initLeadPriority(settings));
    }, [settings]);

    return (
        <Page.Content>
            {isSalesManager && (
                <Tabs type="card" className={styles.tab} activeKey={activeKey} onChange={key => setActiveKey(key)}>
                    <Tabs.TabPane tab="Calendar" key="calendar">
                        <MyCalendar activeKey={activeKey} userId={user?.id as string} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Contact tracker" key="contactTracker">
                        <LeadAssignBoard />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Daily Follow-ups" key="follow-up">
                        <FollowUpStatistics activeKey={activeKey} type="follow-up" leadPriority={leadPriority} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Inbox" key="inbox">
                        <InboxCard />
                    </Tabs.TabPane>
                    <Tabs.TabPane
                        tab={
                            <Badge offset={[10, 2]} count={contactCount} overflowCount={99} color="#fadb14" style={{ zIndex: 100 }}>
                                New leads
                            </Badge>
                        }
                        key="newLeads"
                    >
                        <LeadContactCard
                            activeKey={activeKey}
                            type="newLeads"
                            status={[LeadStatus.New, LeadStatus.Appointment, LeadStatus.InProgress]}
                            setNoContactCount={setContactCount}
                            leadPriority={leadPriority}
                            isSales={false}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Day Planner" key="following">
                        <LeadContactCard
                            activeKey={activeKey}
                            type="following"
                            status={[LeadStatus.New, LeadStatus.Appointment, LeadStatus.InProgress]}
                            leadPriority={leadPriority}
                            isSales={false}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Converted" key="converted">
                        <CloseLeadCard
                            activeKey={activeKey}
                            type="converted"
                            status={[LeadStatus.Converted]}
                            leadPriority={leadPriority}
                            isSales={false}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Pencils" key="pencils">
                        <DealCard />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Lost" key="lost">
                        <CloseLeadCard
                            activeKey={activeKey}
                            type="lost"
                            status={[LeadStatus.Lost, LeadStatus.Defeated]}
                            isSales={false}
                            leadPriority={leadPriority}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Statistics" key="statistics">
                        <AdviserLeadChart />
                    </Tabs.TabPane>
                </Tabs>
            )}
            {isSales && !isSalesManager && (
                <Tabs type="card" className={styles.tab} activeKey={activeKey} onChange={key => setActiveKey(key)}>
                    <Tabs.TabPane tab="Calendar" key="calendar">
                        <MyCalendar activeKey={activeKey} userId={user?.id as string} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Inbox" key="inbox">
                        <InboxCard />
                    </Tabs.TabPane>
                    <Tabs.TabPane
                        tab={
                            <Badge offset={[10, 2]} count={contactCount} overflowCount={99} color="#fadb14" style={{ zIndex: 100 }}>
                                New leads
                            </Badge>
                        }
                        key="newLeads"
                    >
                        <LeadContactCard
                            activeKey={activeKey}
                            type="newLeads"
                            status={[LeadStatus.New, LeadStatus.Appointment, LeadStatus.InProgress]}
                            setNoContactCount={setContactCount}
                            leadPriority={leadPriority}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Day Planner" key="following">
                        <LeadContactCard
                            activeKey={activeKey}
                            type="following"
                            status={[LeadStatus.New, LeadStatus.Appointment, LeadStatus.InProgress]}
                            leadPriority={leadPriority}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Converted" key="converted">
                        <CloseLeadCard activeKey={activeKey} type="converted" status={[LeadStatus.Converted]} leadPriority={leadPriority} />
                    </Tabs.TabPane>
                    {/* <Tabs.TabPane tab="Birthdays" key="birthdays">
                        <CustomerBirthdaysCard />
                    </Tabs.TabPane> */}
                    <Tabs.TabPane tab="Lost" key="lost">
                        <CloseLeadCard
                            activeKey={activeKey}
                            type="lost"
                            status={[LeadStatus.Lost, LeadStatus.Defeated]}
                            leadPriority={leadPriority}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Statistics" key="statistics">
                        <AdviserLeadChart />
                    </Tabs.TabPane>
                </Tabs>
            )}
        </Page.Content>
    );

    // const isSalesManager = useMemo(() => user != null && (user.roles.includes('admin') || user.roles.includes('salesmanager')), [user]);

    // const isServiceManager = useMemo(() => user != null && (user.roles.includes('admin') || user.roles.includes('servicemanager')), [user]);

    // const isSales = useMemo(() => user != null && (user.roles.includes('admin') || user.pages.includes('crm/lead')), [user]);

    // const isService = useMemo(() => user != null && (user.roles.includes('admin') || user.pages.includes('service/appointment')), [user]);

    // // //选中某一天
    // const [today, setToday] = useState<dayjs.Dayjs>(dayjs());
    // const [dailyDateReport, setDailyDateReport] = useState<dayjs.Dayjs>(dayjs());
    // const [weeklyDateReport, setWeeklyDateReport] = useState<dayjs.Dayjs>(dayjs());
    // const [monthlyDateReport, setMonthlyDateReport] = useState<dayjs.Dayjs>(dayjs());
    // // Lead Report
    // const daySpan = 30,
    //     weekSpan = 20,
    //     monthSpan = 20;
    // const daily = 'daily',
    //     weekly = 'weekly',
    //     monthly = 'monthly';

    // const [reportType, setReportType] = useState<string>(daily);
    // // const [yearMonths, setYearMonths] = useState<any>();
    // const [activeKey, setActiveKey] = useState<string>('myLead');

    // const [timelineDatas, setTimelineDatas] = useState<TimelineData[]>([]);
    // const [leadStatistics, setLeadStatistics] = useState<LeadStatistics[]>([]);
    // const [serviceStatistics, setServiceStatistics] = useState<ServiceStatistics[]>([]);
    // const [selectAdvisor, setSelectAdvisor] = useState<string>();

    // const [searchLeadReports, { loading: leadReportsLoading, called, refetch: refetchLeadReports }] = useLazyQuery(SEARCH_LEAD_REPORTS, {
    //     fetchPolicy: 'network-only',
    //     nextFetchPolicy: 'network-only',
    //     notifyOnNetworkStatusChange: true
    // });

    // const [searchRepairReports, { loading: repairReportsLoading, called: repairCalled, refetch: refetchRepairReports }] = useLazyQuery(
    //     SEARCH_REPAIRORDER_REPORTS,
    //     {
    //         fetchPolicy: 'network-only',
    //         nextFetchPolicy: 'network-only',
    //         notifyOnNetworkStatusChange: true
    //     }
    // );

    // const [leadFilter, setLeadFilter] = useState<any>({
    //     _noSearch: true,
    //     assignee: user?.id,
    //     scheduledTimeFrom: today.startOf('date').toISOString(),
    //     scheduledTimeTo: today.add(1, 'day').startOf('date').toISOString()
    // });

    // const { data: leadData, loading: leadLoading } = useQuery(SEARCH_LEADS, {
    //     variables: { filter: leadFilter, page: 1, pageSize: -1 },
    //     fetchPolicy: 'network-only',
    //     nextFetchPolicy: 'network-only',
    //     notifyOnNetworkStatusChange: true
    // });

    // const [appointmentFilter, setAppointmentFilter] = useState<any>({
    //     _noSearch: true,
    //     serviceAdvisor: user?.id,
    //     scheduledTimeFrom: today.startOf('date').valueOf(),
    //     scheduledTimeTo: today.add(1, 'day').startOf('date').valueOf()
    // });

    // const { data: appointmentData, loading: appointmentLoading } = useQuery(SEARCH_APPOINTMENTS, {
    //     variables: {
    //         filter: appointmentFilter,
    //         page: 1,
    //         pageSize: -1
    //     },
    //     fetchPolicy: 'network-only',
    //     nextFetchPolicy: 'network-only',
    //     notifyOnNetworkStatusChange: true
    // });

    // const getYearMonths = useCallback(
    //     (day: dayjs.Dayjs): any => {
    //         if (day == null) return;
    //         // console.log(dayjs(day).week());
    //         // console.log(dayjs(day).week(18).format('L'));
    //         // console.log(dayjs(day).week(18).startOf('week').format('L'));
    //         // console.log(dayjs(day).week(18).endOf('week').format('L'));
    //         // console.log(dayjs(day).weekYear());
    //         const years = [];
    //         const months = [];
    //         const weeks = [];
    //         const results: dayjs.Dayjs[] = [];
    //         if (reportType === daily) {
    //             for (let i = 0; i < daySpan; i++) {
    //                 const d = dayjs(day).subtract(i, 'day');
    //                 years.push(d.year());
    //                 months.push(d.month() + 1);
    //                 results.push(d);
    //             }
    //         } else if (reportType === weekly) {
    //             for (let i = 0; i < weekSpan; i++) {
    //                 const d = dayjs(day).subtract(i, 'week');
    //                 years.push(d.year());
    //                 months.push(d.month() + 1);
    //                 weeks.push(d.week());
    //                 results.push(d);
    //             }
    //         } else if (reportType === monthly) {
    //             for (let i = 0; i < monthSpan; i++) {
    //                 const d = dayjs(day).subtract(i, 'month');
    //                 years.push(d.year());
    //                 months.push(d.month() + 1);
    //                 results.push(d);
    //             }
    //         }
    //         return { years: uniq(years), months: uniq(months), weeks: uniq(weeks), results };
    //     },
    //     [reportType]
    // );

    // const simpleBarRef = useRef<SimpleBar>(null);

    // useEffect(() => {
    //     const leads = (leadData?.searchLeads?.data ?? []) as LeadDataObject[];
    //     const appointments = (appointmentData?.searchServiceAppointments?.data ?? []) as ServiceAppointmentDataObject[];
    //     // console.log('leads', leads);
    //     // console.log('appointments', appointments);
    //     setTimelineDatas([]);
    //     const result: TimelineData[] = [];
    //     Array.isArray(leads) &&
    //         leads.forEach(lead => {
    //             lead.activities
    //                 .filter(l => l.result == null && l.scheduleTime != null && l.contactType != LeadContactType.Comment)
    //                 .forEach(activity => {
    //                     if(![LeadStatus.Converted, LeadStatus.Lost, LeadStatus.Defeated].some(status => (lead.status & status) === status)){
    //                         if (
    //                             today.year() === getDayjs(activity.scheduleTime)?.year() &&
    //                             today.month() === getDayjs(activity.scheduleTime)?.month() &&
    //                             today.date() === getDayjs(activity.scheduleTime)?.date()
    //                         ) {
    //                             const timelineData: TimelineData = {
    //                                 id: lead.id,
    //                                 type: BusinessType.Lead,
    //                                 scheduleTime: getDayjs(activity.scheduleTime)?.toISOString() ?? '',
    //                                 time: getDayjs(activity.scheduleTime)?.unix() ?? 0,
    //                                 title: `${displayCustomer(lead.customer)} (${lead.source})` ?? '',
    //                                 content: activity.comment ?? '',
    //                                 contactType: activity.contactType
    //                             };

    //                             result.push(timelineData);
    //                         }
    //                     }

    //                 });
    //         });

    //     Array.isArray(appointments) &&
    //         appointments
    //             .filter(a =>
    //                 [ServiceAppointmentStatus.New, ServiceAppointmentStatus.Confirmed].some(status => status === a.appointmentStatus)
    //             )
    //             .forEach(appointment => {
    //                 const timelineData: TimelineData = {
    //                     id: appointment.id,
    //                     type: BusinessType.Appointment,
    //                     scheduleTime: dayjs(appointment.scheduledTime).toISOString() ?? '',
    //                     time: dayjs(appointment.scheduledTime).unix(),
    //                     title: `${displayCustomer(appointment.customer)}`,
    //                     content: appointment.notes ?? ''
    //                 };

    //                 result.push(timelineData);
    //             });
    //     setTimelineDatas(orderBy(result, ['time'], ['asc']));
    // }, [appointmentData, leadData, today]);

    // useEffect(() => {
    //     // leadRefetch();
    //     setAppointmentFilter({
    //         _noSearch: true,
    //         serviceAdvisor: user?.id,
    //         scheduledTimeFrom: today.startOf('date').valueOf(),
    //         scheduledTimeTo: today.add(1, 'day').startOf('date').valueOf()
    //     });

    //     setLeadFilter({
    //         _noSearch: true,
    //         assignee: user?.id,
    //         scheduledTimeFrom: today.startOf('date').toISOString(),
    //         scheduledTimeTo: today.add(1, 'day').startOf('date').toISOString()
    //     });
    // }, [today, user?.id]);

    // //用来将普通数字转为序号形式的方法
    // // const ordinal = (n: number) => {
    // //     const s = ['th', 'st', 'nd', 'rd'];
    // //     const v = n % 100;
    // //     return n + (s[(v - 20) % 10] || s[v] || s[0]);
    // // };

    // // RepairOrder Report
    // // eslint-disable-next-line react-hooks/exhaustive-deps

    // const buildServiceStatistics = useCallback(
    //     async (_yearMonths: any, userId: any, reportType: any) => {
    //         const _serviceStatistics: ServiceStatistics[] = [];
    //         const variables = {
    //             filter: {
    //                 _noSearch: true,
    //                 type: reportType,
    //                 serviceAdvisor: userId
    //             }
    //         };

    //         if (reportType === daily) {
    //             variables.filter = Object.assign({}, variables.filter, { year: _yearMonths.years, month: _yearMonths.months });
    //         } else if (reportType === weekly) {
    //             variables.filter = Object.assign({}, variables.filter, { year: _yearMonths.years, week: _yearMonths.weeks });
    //         } else if (reportType === monthly) {
    //             variables.filter = Object.assign({}, variables.filter, { year: _yearMonths.years, month: _yearMonths.months });
    //         }

    //         const results = _yearMonths?.results as dayjs.Dayjs[];
    //         const result = repairCalled ? await refetchRepairReports(variables) : await searchRepairReports({ variables });
    //         const repairOrders = (result.data?.searchRepairOrderReports?.data ?? []) as RepairOrderReportDataObject[];
    //         results.forEach(item => {
    //             let result: RepairOrderReportDataObject[] = [];
    //             let label = '';
    //             if (reportType === daily) {
    //                 result = repairOrders.filter(l => l.year === item.year() && l.month === item.month() + 1 && l.date === item.date());
    //                 label = item.format('L');
    //             } else if (reportType === weekly) {
    //                 result = repairOrders.filter(l => l.year === item.year() && l.week == item.week());
    //                 // label = `${ordinal(item.week())} week of ${item.year()}`;
    //                 label = item.format('L');
    //             } else if (reportType === monthly) {
    //                 result = repairOrders.filter(l => l.year === item.year() && l.month === item.month() + 1);
    //                 label = item.format('MMM/YYYY');
    //             }

    //             _serviceStatistics.push({
    //                 timeLabel: label,
    //                 time: item,
    //                 appointments: result.length > 0 ? result[0].appointments ?? 0 : 0,
    //                 repairOrders: result.length > 0 ? result[0].repairOrders ?? 0 : 0,
    //                 appointmentInRepairOrders: result.length > 0 ? result[0].appedRepairOrders ?? 0 : 0
    //             });
    //         });
    //         setServiceStatistics(_serviceStatistics);
    //     },
    //     [refetchRepairReports, repairCalled, searchRepairReports]
    // );

    // // Lead Report

    // const buildLeadStatistics = useCallback(
    //     async (_yearMonths: any, userId: any, reportType: any) => {
    //         const _leadStatistics: LeadStatistics[] = [];
    //         const variables = {
    //             filter: {
    //                 _noSearch: true,
    //                 type: reportType,
    //                 assignee: userId
    //             }
    //         };

    //         if (reportType === daily) {
    //             variables.filter = Object.assign({}, variables.filter, { year: _yearMonths.years, month: _yearMonths.months });
    //         } else if (reportType === weekly) {
    //             variables.filter = Object.assign({}, variables.filter, { year: _yearMonths.years, week: _yearMonths.weeks });
    //         } else if (reportType === monthly) {
    //             variables.filter = Object.assign({}, variables.filter, { year: _yearMonths.years, month: _yearMonths.months });
    //         }

    //         // console.log(variables);
    //         const results = _yearMonths?.results as dayjs.Dayjs[];
    //         const result = called ? await refetchLeadReports(variables) : await searchLeadReports({ variables });
    //         const leads = (result.data?.searchLeadReports?.data ?? []) as LeadReportDataObject[];
    //         results.forEach(item => {
    //             let result: LeadReportDataObject[] = [];
    //             let label = '';
    //             if (reportType === daily) {
    //                 result = leads.filter(l => l.year === item.year() && l.month === item.month() + 1 && l.date === item.date());
    //                 label = item.format('L');
    //             } else if (reportType === weekly) {
    //                 result = leads.filter(l => l.year === item.year() && l.week === item.week());
    //                 // label = `${ordinal(item.week())} week of ${item.year()}`;
    //                 label = item.format('L');
    //             } else if (reportType === monthly) {
    //                 result = leads.filter(l => l.year === item.year() && l.month === item.month() + 1);
    //                 label = item.format('MMM/YYYY');
    //             }

    //             _leadStatistics.push({
    //                 timeLabel: label,
    //                 time: item,
    //                 new: result.length > 0 ? result[0].new ?? 0 : 0,
    //                 close: result.length > 0 ? result[0].closed ?? 0 : 0,
    //                 won: result.length > 0 ? result[0].won ?? 0 : 0,
    //                 assigned: result.length > 0 ? result[0].assigned ?? 0 : 0,
    //                 contacted: result.length > 0 ? result[0].contacted ?? 0 : 0
    //             });
    //         });
    //         setLeadStatistics(_leadStatistics);
    //     },
    //     [called, refetchLeadReports, searchLeadReports]
    // );

    // const getDateReport = () => {
    //     if (reportType === daily) {
    //         return dailyDateReport;
    //     } else if (reportType === weekly) {
    //         return weeklyDateReport;
    //     } else if (reportType === monthly) {
    //         return monthlyDateReport;
    //     }
    //     return dailyDateReport;
    // };

    // // eslint-disable-line react-hooks/exhaustive-deps
    // useEffect(() => {
    //     // console.log(getDateReport().format('L'));
    //     const _yearMonths = getYearMonths(getDateReport());
    //     // console.log(_yearMonths);
    //     let userId = undefined;

    //     if (['myLead', 'myAppointment'].includes(activeKey)) userId = user?.id;

    //     if (['allLead', 'allAppointment'].includes(activeKey) && selectAdvisor != null) userId = selectAdvisor;

    //     if (isSalesManager || isSales) {
    //         ['myLead', 'allLead'].includes(activeKey) && buildLeadStatistics(_yearMonths, userId, reportType);
    //     }

    //     if (isServiceManager || isService) {
    //         ['myAppointment', 'allAppointment'].includes(activeKey) && buildServiceStatistics(_yearMonths, userId, reportType);
    //     }
    // }, [activeKey, getYearMonths, isSales, isSalesManager, dailyDateReport, weeklyDateReport, monthlyDateReport, user?.id, selectAdvisor]); // eslint-disable-line react-hooks/exhaustive-deps

    // const userSelectOnChange = (value: string | string[]) => {
    //     if (Array.isArray(value)) return;
    //     setSelectAdvisor(value);
    // };

    // const onTabChange = (activeKey: string) => {
    //     setActiveKey(activeKey);
    //     setSelectAdvisor(undefined);
    // };

    // const previous = () => {
    //     if (reportType === daily) {
    //         const previousDay = dailyDateReport.subtract(daySpan, 'day');
    //         setDailyDateReport(previousDay);
    //     } else if (reportType === weekly) {
    //         const previousDay = weeklyDateReport.subtract(weekSpan, 'week');
    //         setWeeklyDateReport(previousDay);
    //     } else if (reportType === monthly) {
    //         const previousDay = monthlyDateReport.subtract(monthSpan, 'month');
    //         setMonthlyDateReport(previousDay);
    //     }
    // };
    // const next = () => {
    //     if (reportType === daily) {
    //         const nextDay = dailyDateReport.add(daySpan, 'day');
    //         setDailyDateReport(nextDay);
    //     } else if (reportType === weekly) {
    //         const nextDay = weeklyDateReport.add(weekSpan, 'week');
    //         setWeeklyDateReport(nextDay);
    //     } else if (reportType === monthly) {
    //         const previousDay = monthlyDateReport.add(monthSpan, 'month');
    //         setMonthlyDateReport(previousDay);
    //     }
    // };

    // const checkDisabled = () => {
    //     if (reportType === daily) {
    //         return dailyDateReport.format('L') === dayjs().format('L');
    //     } else if (reportType === weekly) {
    //         return weeklyDateReport.format('L') === dayjs().format('L');
    //     } else if (reportType === monthly) {
    //         return monthlyDateReport.format('L') === dayjs().format('L');
    //     }
    // };

    // const tabBarExtraContent = (
    //     <Space>
    //         <Radio.Group value={reportType} onChange={e => setReportType(e.target.value)}>
    //             <Radio value={daily}>Daily</Radio>
    //             <Radio value={weekly}>Weekly</Radio>
    //             <Radio value={monthly}>Monthly</Radio>
    //         </Radio.Group>
    //         {(isSalesManager) && ['allLead'].includes(activeKey) && (
    //             <div style={{ width: 200 }}>
    //                 <UserSearcherSelect onChange={userSelectOnChange} showSearch={true} type={TeamType.Sales}/>
    //             </div>
    //         )}
    //         {(isServiceManager) && ['allAppointment'].includes(activeKey) && (
    //             <div style={{ width: 200 }}>
    //                 <UserSearcherSelect onChange={userSelectOnChange} showSearch={true} type={TeamType.Service}/>
    //             </div>
    //         )}
    //         <Button type="primary" icon={<ArrowLeftOutlined />} onClick={previous}></Button>
    //         <Button type="primary" icon={<ArrowRightOutlined />} onClick={next} disabled={checkDisabled()}></Button>
    //     </Space>
    // );

    // return (
    //     <Page title={'Home'}>
    //         <Page.Action></Page.Action>
    //         <Page.Content>
    //             <Card size="small">
    //                 <Row>
    //                     <Col span={16}>
    //                         <Calendar fullscreen={false} onSelect={value => setToday(value)} />
    //                         {/* <Button onClick={()=>setToday(dayjs)}>test</Button> */}
    //                     </Col>
    //                     <Col span={8}>
    //                         <Spin spinning={leadLoading || appointmentLoading}>
    //                             <SimpleBar ref={simpleBarRef} style={{ height: 300 }}>
    //                                 <Timeline style={{ marginLeft: 16, marginTop: 16 }}>
    //                                     {timelineDatas && timelineDatas.length == 0 ? (
    //                                         <Empty />
    //                                     ) : (
    //                                         timelineDatas.map(timelineData => (
    //                                             <Timeline.Item
    //                                                 color={timelineData.type === BusinessType.Lead ? 'green' : '#096dd9'}
    //                                                 key={timelineData.time}
    //                                             >
    //                                                 <div>
    //                                                     <p>
    //                                                         {' '}
    //                                                         <strong>
    //                                                             {timelineData.type === BusinessType.Lead ? (
    //                                                                 <Tag color={'green'}>Lead</Tag>
    //                                                             ) : (
    //                                                                 <Tag color={'blue'}>Appointment</Tag>
    //                                                             )}

    //                                                             {getDayjs(timelineData.scheduleTime)?.format('h:mm A')}
    //                                                             {'                      '}

    //                                                             <Link
    //                                                                 style={{ color: '#3CB371' }}
    //                                                                 key={timelineData.time}
    //                                                                 to={
    //                                                                     timelineData.type == BusinessType.Lead
    //                                                                         ? `/crm/lead/${timelineData.id}`
    //                                                                         : `/service/appointment/${timelineData.id}`
    //                                                                 }
    //                                                                 target="_blank"
    //                                                             >
    //                                                                 {timelineData.title}{' '}
    //                                                             </Link>
    //                                                         </strong>
    //                                                         {`${
    //                                                             timelineData.contactType === LeadContactType.PhoneCall
    //                                                                 ? '📞       '
    //                                                                 : timelineData.contactType === LeadContactType.Email
    //                                                                 ? '📧       '
    //                                                                 : timelineData.contactType === LeadContactType.SMS
    //                                                                 ? '📱'
    //                                                                 : timelineData.contactType === LeadContactType.Appointment
    //                                                                 ? '📅       '
    //                                                                 : timelineData.contactType === LeadContactType.Fax
    //                                                                 ? '📠       '
    //                                                                 : timelineData.contactType === LeadContactType.Letter
    //                                                                 ? '📮       '
    //                                                                 : timelineData.contactType === LeadContactType.Task
    //                                                                 ? '📝       '
    //                                                                 : ''
    //                                                         }${timelineData.content}`}{' '}
    //                                                     </p>
    //                                                 </div>
    //                                             </Timeline.Item>
    //                                         ))
    //                                     )}
    //                                 </Timeline>
    //                             </SimpleBar>
    //                         </Spin>
    //                     </Col>
    //                 </Row>
    //             </Card>
    //             {/* <DualAxesChartA/> */}
    //             <Card style={{ minHeight: 500, marginTop: 16 }}>
    //                 <Spin spinning={leadReportsLoading || repairReportsLoading}>
    //                     <Tabs onChange={onTabChange} type="card" tabBarExtraContent={tabBarExtraContent} activeKey={activeKey}>
    //                         {(isSalesManager || isSales) && (
    //                             <Tabs.TabPane tab="My Leads" key="myLead">
    //                                 <Card>
    //                                     <HomeChart type={BusinessType.Lead} leadStatisticss={leadStatistics} reportType={reportType} />
    //                                 </Card>
    //                             </Tabs.TabPane>
    //                         )}

    //                         {isSalesManager && (
    //                             <Tabs.TabPane tab="All Leads" key="allLead">
    //                                 <Card>
    //                                     <HomeChart type={BusinessType.Lead} leadStatisticss={leadStatistics} reportType={reportType} />
    //                                 </Card>
    //                             </Tabs.TabPane>
    //                         )}

    //                         {(isServiceManager || isService) && (
    //                             <Tabs.TabPane tab="My Appointments" key="myAppointment">
    //                                 <Card>
    //                                     <HomeChart
    //                                         type={BusinessType.Appointment}
    //                                         serviceStatistics={serviceStatistics}
    //                                         reportType={reportType}
    //                                     />
    //                                 </Card>
    //                             </Tabs.TabPane>
    //                         )}

    //                         {isServiceManager && (
    //                             <Tabs.TabPane tab="All Appointments" key="allAppointment">
    //                                 <Card>
    //                                     <HomeChart
    //                                         type={BusinessType.Appointment}
    //                                         serviceStatistics={serviceStatistics}
    //                                         reportType={reportType}
    //                                     />
    //                                 </Card>
    //                             </Tabs.TabPane>
    //                         )}
    //                     </Tabs>
    //                 </Spin>
    //             </Card>
    //         </Page.Content>
    //     </Page>
    // );
};

export default Home;
