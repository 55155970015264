import dayjs from 'dayjs';

import { Customer, CustomerType, User } from '../lead/models';

const stringToColor = (string: string) => {
    let hash = 0;
    let i;
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) hash = string.charCodeAt(i) + ((hash << 5) - hash);
    let color = '#';
    const colors: number[] = [];
    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        const v = `00${value.toString(16)}`.substr(-2);
        colors.push(parseInt(v, 16));
        color += v;
    }
    /* eslint-enable no-bitwise */
    const contrast = (Math.round(colors[0] * 299) + Math.round(colors[1] * 587) + Math.round(colors[2] * 114)) / 1000;
    return [color, contrast >= 150 ? '#111' : '#eee'];
};

export const getAvatarStyle = (user: User) => {
    if (!user) return {};
    const [backgroundColor, color] = stringToColor(user.firstName + user.lastName);
    return {
        color: color,
        backgroundColor: backgroundColor
    };
};

export const getDayjs = (value: string | undefined) => {
    if (value == null || value == undefined) return null; // dayjs('1970-01-01')
    if (Number.isNaN(Number(value))) {
        return dayjs(value);
    } else {
        return dayjs(Number(value));
    }
};

export const displayCustomer = (customer?: Customer) =>
    customer &&
    (customer.name == null
        ? ''
        : customer.type === CustomerType.Person
        ? `${customer.name.lastName ?? ''}, ${customer.name.firstName ?? ''}`
        : customer.name.companyName ?? '');

export const insertLink = (value: string) => {
    const urlReg = /(http:\/\/|ftp:\/\/|https:\/\/|www.)[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?/g;
    return value.replace(urlReg, url => {
        if (url.match('http://') || url.match('ftp://') || url.match('https://'))
            return `<a href="${url}" style="color:#2a7fff"  target="_blank">${url}</a>`;
        else return `<a href=\"http:\/\/${url}\" style="color:#2a7fff" target="_blank">${url}</a>`;
    });
};
