import { Address } from '../../crm/lead/models';

export enum AccountStatus {
    Confirmed,
    ForceChangePassword,
    Blocked = -1
}

export interface UserPermission {
    page: string;
    permissions: string[];
}

export interface User {
    id: string;
    dealer: string;
    /** User's email address, also is the sign in username. */
    email: string;
    /** User's first name (given name). */
    firstName: string;
    /** User's last name (family name). */
    lastName: string;
    /** User's telephone number, must be stored in E.164 standard. */
    phone?: string;
    /** User's picture address. */
    picture?: string;
}

export type UserCreateInput = Partial<Pick<User, 'dealer' | 'email' | 'firstName' | 'lastName' | 'phone'>> & {
    password?: string;
};
export type UserUpdateInput = UserCreateInput;
export type UserRoleAssignInput = {
    roles: string[];
};

export interface UserDataObject extends User {
    /** User account status */
    accountStatus: AccountStatus;
    /** Dealers the user belongs to */
    dealers: string[];
    /** User roles */
    roles: string[];
    /** Pages to access, populated from roles */
    pages: string[];
    /** Access permissions, populated from roles */
    permissions: string[];
    /** User last sign in time, in unix time. */
    lastSignInAt?: number;
    /** User last sign in ip address. */
    lastSignInIp?: string;
    _dataStatus: number;
    _createAt?: number;
    _updateAt?: number;
}

export enum DealerStatus {
    Normal = 0
}

export interface Dealer {
    id: string;
    status: DealerStatus;
    name: string;
    shortName: string;
    contactNumber: string;
    timezone: string;
    address?: Address;
    cdk?: {
        dealerId: string;
        invCompany?: number;
        lastUpdate: { [key: string]: string };
    };
    callcenter?: {
        id: string;
        url: string;
        number: string;
    };
}
