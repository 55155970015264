import type { Entity } from './Entity';

import type { Address, ContactInfo, PersonName } from './ValueObjects';
import type { Vehicle } from './Vehicle';

/** 客户类型 */
export enum CustomerType {
    /** 个人客户 */
    Individual = 0,
    /** 企业客户 */
    Company = 1
}

/** 客户分类 */
export enum CustomerCategory {
    /** 潜在客户 */
    Potential = 0,
    /** 保有客户 */
    Retention = 1
}

export type FICO = {
    equifax: number;
    experian: number;
    transUnion: number;
};

/** 客户受雇信息 */
export interface CustomerEmployment {
    /** 雇主名称 */
    employerName: string;
    /** 职业 */
    occupation?: string;
    /** 雇主地址 */
    address?: Address;
}

/** 客户输入数据 */
export interface CustomerInput {
    /** 外部系统ID */
    externalId?: string;
    /** 客户类型 */
    type: CustomerType;
    /** 客户姓名，其类型取决于客户类型 */
    name: string | PersonName;
    /** 个人客户性别 */
    gender?: 'M' | 'F' | 'X';
    /** 个人客户生日 */
    dob?: string;
    /** 个人客户社会保障号 */
    ssn?: string;
    /** 客户的联系方式 */
    contactInfo?: ContactInfo;
    /** 受雇信息 */
    employment?: CustomerEmployment;
    /** FICO分数 */
    ficoScores?: FICO;
    /** 客户分类 */
    category: CustomerCategory;
    driversLicense?: string;
}

/** 客户实体数据 */
export interface Customer extends Entity, CustomerInput {
    // /** 关联的销售过程数据 */
    // saleActivities?: SaleActivity[] | string[];
    /** 客户名下关联车辆 */
    vehicles?: Vehicle[];
}
