import type { PersonName } from '../models';

import React from 'react';

interface Props {
    name: string | PersonName;
}

const CustomerName: React.FC<Props> = ({ name }) => (
    <span>{typeof name === 'object' ? `${name.lastName}, ${name.firstName}` : name}</span>
);

export default React.memo(CustomerName);
