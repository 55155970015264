import { Col, Form, Input, InputNumber, Modal, Row, Switch } from 'antd';
import React, { useEffect, useState } from 'react';

import { EnumSelect } from '../../../components';
import { SimpleBarAny as SimpleBar } from '../../../components/SimpleBarAny';
import { ContractType, ContractTypeCode, DealAdditionalProfitTo, VehicleWarranty, VehicleWarrantyInput } from '../../../models';
import { WindowContainer } from '../components';

interface Props {
    visible: boolean;
    data?: Readonly<VehicleWarranty>;
    onCancel: () => void;
    onSave: (data: VehicleWarrantyInput, id?: string) => Promise<void>;
}

const emptyData: VehicleWarrantyInput = {
    code: '',
    cost: 0,
    retail: 0,
    profitTo: DealAdditionalProfitTo.Gross,
    taxable: false,
    financed: false,
    serviceVisible: false
};

const VehicleWarrantyEditor: React.FC<Props> = props => {
    const id = props.data?.id;
    const initialValues = props.data || emptyData;

    const [isWorking, setIsWorking] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        if (props.visible) form.resetFields();
    }, [form, initialValues, props.visible]);

    const onCancel = () => {
        if (!isWorking) props.onCancel();
    };
    const onOk = async () => {
        try {
            setIsWorking(true);
            const values = (await form.validateFields()) as VehicleWarrantyInput;
            if (values.contractType === ContractType.TexasGap) values.texasGapFlag = true;

            await props.onSave(values, id);
        } catch (err) {
            console.error(err);
        } finally {
            setIsWorking(false);
        }
    };
    return (
        <Modal
            title={`${id == null ? 'New' : 'Edit'} Warranty`}
            confirmLoading={isWorking}
            visible={props.visible}
            onCancel={onCancel}
            onOk={onOk}
            width={600}
            className="form"
            okText="Submit"
            cancelButtonProps={{ type: 'link' }}
            maskClosable={false}
            forceRender
        >
            <SimpleBar style={{ maxHeight: 600 }}>
                <WindowContainer>
                    <Form form={form} initialValues={initialValues} labelCol={{ span: 8 }}>
                        <Form.Item name="code" label="Code" rules={[{ required: true, whitespace: true }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="description" label="Description">
                            <Input />
                        </Form.Item>
                        <Row gutter={4}>
                            <Col span={12}>
                                <Form.Item
                                    name="cost"
                                    label="Cost"
                                    rules={[{ type: 'number', required: true, min: 0 }]}
                                    labelCol={{ span: 16 }}
                                >
                                    <InputNumber style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="retail"
                                    label="Retail"
                                    rules={[{ type: 'number', required: true, min: 0 }]}
                                    labelCol={{ span: 16 }}
                                >
                                    <InputNumber style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="profitTo" label="Profit to" rules={[{ required: true }]}>
                                    <EnumSelect enum={DealAdditionalProfitTo} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="taxable" label="Taxable" valuePropName="checked" labelCol={{ span: 16 }}>
                                    <Switch />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="financed" label="Financed" valuePropName="checked" labelCol={{ span: 16 }}>
                                    <Switch />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="contractCompanyName" label="Contract company">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="contractType" label="Contract type">
                                    <EnumSelect enum={ContractType} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="contractTypeCode" label="Contract type code">
                                    <EnumSelect enum={ContractTypeCode} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="contractDeductible" label="Contract deductible">
                                    <InputNumber style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Form.Item name="contractRate" label="Contract rate">
                                    <InputNumber style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="contractRateTable" label="Contract rate table">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="contractTermMileage" label="Contract term mileage">
                                    <InputNumber style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="term" label="Term">
                                    <InputNumber style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="totalContractAmount" label="Total contract amount">
                                    <InputNumber style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item name="notes" label="Notes">
                            <Input.TextArea rows={3} />
                        </Form.Item>
                    </Form>
                </WindowContainer>
            </SimpleBar>
        </Modal>
    );
};

export default React.memo(VehicleWarrantyEditor);
