import { Button, Card, Col, Input, Popconfirm, Row, Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { gql } from '@apollo/client';

import { SimpleBarAny as SimpleBar } from '../../../components/SimpleBarAny';
import { UserContext } from '../../../contexts';
import { useDelete, useSearch } from '../../../hooks';
import UserAvatar from '../../components/UserAvatar';
import { ReloadButton, StatusTag, TimeCell } from '../../crm/components';
import { CustomerDataObject, CustomerType } from '../../desking/customer/models';
import { DealDataObject, DealStatus, DealType } from '../../desking/deal/models';
import { VehicleDataObject } from '../../vehicles/inventory/models';
import styles from '../styles.module.scss';

const SEARCH_DEALS = gql`
    query SearchDeals($filter: DealSearchInput, $sort: [String], $page: Int, $pageSize: Int) {
        searchDeals(filter: $filter, sort: $sort, page: $page, pageSize: $pageSize) {
            total
            data {
                id
                status
                code
                type
                customer {
                    type
                    name {
                        type
                        firstName
                        lastName
                        companyName
                    }
                }
                vehicle {
                    stockNo
                    model {
                        make
                        name
                    }
                    year
                    vin
                }
                salesPerson {
                    firstName
                    lastName
                }
                _createAt
            }
        }
    }
`;

const CLOSE_DEAL = gql`
    mutation CloseDeal($id: ID!) {
        closeDeal(id: $id) {
            __typename
            id
            status
            code
            type
            customer {
                type
                name {
                    type
                    firstName
                    lastName
                    companyName
                }
            }
            vehicle {
                stockNo
                model {
                    make
                    name
                }
                year
                vin
            }
            salesPerson {
                firstName
                lastName
            }
            _updateAt
        }
    }
`;

const ABANDON_DEAL = gql`
    mutation DeleteDeal($id: ID!) {
        deleteDeal(id: $id)
    }
`;

const displayCustomer = (customer?: CustomerDataObject) =>
    customer &&
    (customer.name == null
        ? ''
        : customer.type === CustomerType.Person
        ? `${customer.name.lastName ?? ''}, ${customer.name.firstName ?? ''}`
        : customer.name.companyName ?? '');

const displayVehicle = (vehicle?: VehicleDataObject) => {
    if (vehicle?.model == null) return null;

    const result: string[] = [];
    if (vehicle.year != null) result.push(vehicle.year.toString());
    if (typeof vehicle.model.make === 'string') result.push(vehicle.model.make);
    if (typeof vehicle.model.name === 'string') result.push(vehicle.model.name);
    return result.join(' ');
};

const Column = (props: ColumnProps<DealDataObject>) => <Table.Column<DealDataObject> {...props} />;

interface Filter {
    _search?: string;
    _noSearch?: boolean;
    status?: DealStatus;
    createTimeFrom?: string;
}

const DealCard = () => {
    //获取当前登录人的信息
    const user = useContext(UserContext);
    const isAdmin = useMemo(() => user != null && user.roles.includes('admin'), [user]);
    const canEdit = useMemo(() => isAdmin || (user != null && user.permissions.includes('deal:edit')), [user, isAdmin]);
    const canClose = useMemo(() => isAdmin || (user != null && user.permissions.includes('deal:close')), [user, isAdmin]);
    const canAbandon = useMemo(() => isAdmin || (user != null && user.permissions.includes('deal:delete')), [user, isAdmin]);

    const [deals, setDeals] = useState<DealDataObject[]>([]);
    const filter: Filter = {
        _noSearch: true,
        status: DealStatus.New
        // createTimeFrom: dayjs().subtract(7, 'day').toISOString()
    };
    const defaultSortDescend = '!_createAt';
    const { loading, data, pagination, setFilter, refresh } = useSearch<Filter>(SEARCH_DEALS, filter, [defaultSortDescend]);

    const [abandonDeal, isAbandoning] = useDelete(ABANDON_DEAL);
    const [closeDeal, isClosing] = useDelete(CLOSE_DEAL);

    useEffect(() => {
        if (data == null) return;
        setDeals(data as DealDataObject[]);
    }, [data]);

    const onTextKeypress: React.KeyboardEventHandler<HTMLInputElement> = e => {
        if (e.key === 'Enter') setFilter('_search', e.currentTarget.value);
    };

    const simpleBarRef = useRef<SimpleBar>(null);
    const height = useMemo(() => Math.max(document.documentElement.clientHeight, window.innerHeight ?? 0) - 218, []);
    return (
        <Card className={styles.tabcard} bodyStyle={{ paddingTop: 20, paddingBottom: 0 }}>
            <Row gutter={4}>
                <Col span={4}>
                    <Input.Search
                        placeholder="Search by name, description..."
                        onKeyPress={onTextKeypress}
                        onSearch={value => setFilter('_search', value)}
                        allowClear
                    />
                </Col>
                <Col span={3}>
                    <ReloadButton onClick={() => refresh()} disabled={loading} />
                </Col>
            </Row>
            <Row>
                <Card size="small" bordered={false} style={{ marginTop: 8, width: '100%' }} bodyStyle={{ padding: 0 }}>
                    <SimpleBar ref={simpleBarRef} style={{ height }}>
                        <Table
                            rowKey="id"
                            size="small"
                            dataSource={deals}
                            pagination={{ ...pagination, ...{ showSizeChanger: true } }}
                            loading={loading}
                            className={styles.table}
                            //onChange={handleTableChange}
                        >
                            <Column
                                title="Deal #"
                                width={200}
                                dataIndex="code"
                                render={(_, record) => (
                                    <Button size="small" type="link">
                                        <Link to={`desking/deal/${record.id}`}>{record.code}</Link>
                                    </Button>
                                )}
                            />
                            <Column title="Type" width={100} render={(_, record) => DealType[record.type]} />
                            <Column title="Customer" width={200} render={(_, record) => displayCustomer(record.customer)} />
                            <Column title="Vehicle" width={100} dataIndex={['vehicle', 'stockNo']} />
                            <Column title="Model" width={300} render={(_, record) => displayVehicle(record.vehicle)} />
                            <Column title="VIN" dataIndex={['vehicle', 'vin']} />
                            <Column
                                title="Status"
                                render={(_, record) => (
                                    <StatusTag value={record.status} enum={DealStatus} colors={['red', 'blue', 'green']} />
                                )}
                            />
                            <Column
                                title="Sales rep"
                                width={80}
                                align="center"
                                render={(_, record) =>
                                    record.salesPerson ? (
                                        <UserAvatar
                                            size="small"
                                            firstName={record.salesPerson.firstName}
                                            lastName={record.salesPerson.lastName}
                                        />
                                    ) : null
                                }
                            />
                            <Column
                                width={200}
                                title="Create at"
                                render={(_, record) => (record._createAt != null ? <TimeCell time={new Date(record._createAt)} /> : null)}
                            />
                            <Column
                                width={140}
                                title="Actions"
                                align="center"
                                render={(_, record) => (
                                    <>
                                        <Button size="small" type="link">
                                            <Link to={`desking/deal/${record.id}`}>
                                                {record.status === DealStatus.New && canEdit ? 'Edit' : 'View'}
                                            </Link>
                                        </Button>
                                        {record.status === DealStatus.New ? (
                                            <Popconfirm
                                                title="Close this deal?"
                                                okText="Yes"
                                                placement="left"
                                                disabled={!canClose}
                                                onConfirm={async () => {
                                                    await closeDeal(record.id);
                                                    const deal = data.find(d => d.id === record.id);
                                                    if (deal) deal.status = DealStatus.Closed;
                                                }}
                                            >
                                                <Button size="small" type="link" disabled={isClosing || !canClose}>
                                                    Close
                                                </Button>
                                            </Popconfirm>
                                        ) : null}
                                        {record.status === DealStatus.New && (
                                            <Popconfirm
                                                disabled={isAbandoning || !canAbandon}
                                                placement="right"
                                                title="Abandon this deal?"
                                                okText="Yes"
                                                cancelText="No"
                                                onConfirm={async () => {
                                                    await abandonDeal(record.id);
                                                    const deal = data.find(d => d.id === record.id);
                                                    if (deal) deal.status = DealStatus.Abandoned;
                                                }}
                                            >
                                                <Button size="small" type="link" disabled={isAbandoning || !canAbandon}>
                                                    Abandon
                                                </Button>
                                            </Popconfirm>
                                        )}
                                    </>
                                )}
                            />
                        </Table>
                    </SimpleBar>
                </Card>
            </Row>
        </Card>
    );
};

export default DealCard;
