import { Employee, Entity, LeadActivity, LeadOpportunity, LeadStatus, LeadTradeIn } from '../../models';
import { Customer, CustomerInput } from '../customers/types';

export enum LeadSourceType {
    Unknown = 0,
    WalkIn = 1,
    Website = 2,
    CallCenter = 3,
    Dealer = 4,
    DigitalAd = 5,
    Exhibition = 6,
    App = 7
}

/** 线索输入数据 */
export interface LeadInput {
    /** 客户数据 */
    customerInput: CustomerInput;
    /** 线索来源类型 */
    sourceType: LeadSourceType;
    /** 线索详情 */
    sourceDetails?: string;
    /** 分配人id */
    assignedSalespersonId?: string;
    /** 原因 */
    reason?: string;
    /** 潜在机会 */
    opportunity?: LeadOpportunity;
    /** 二手车 */
    tradeIn?: LeadTradeIn[];
    /** 跟进活动 */
    activities?: LeadActivity[];
}

export interface Lead extends Entity, LeadInput {
    /** 线索状态 */
    leadStatus: LeadStatus;
    /** 分配员工 */
    assignedSalesperson?: Employee;
    /** 分配时间 */
    assignedTime?: string;
    /** 客户 */
    customer: Customer;
}
