import React from 'react';
import { useParams } from 'react-router-dom';

const Search = React.lazy(() => import('./SearchServicePackage'));
const New = React.lazy(() => import('./NewServicePackage'));
const Edit = React.lazy(() => import('./EditServicePackage'));
const ServicePackage = () => {
    const { id } = useParams();
    if (id == null) return <Search />;
    if (id === 'new') return <New />;
    return <Edit id={id} />;
};

export default ServicePackage;
