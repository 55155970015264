import { Direction } from '../../crm/lead/models';

export enum CustomerType {
    Person = 0,
    Business = 1
}

export interface CustomerName {
    type: CustomerType;
    companyName?: string;
    firstName?: string;
    lastName?: string;
    middleName?: string;
    suffix?: string;
    title?: string;
}

export interface CustomerEmployment {
    employerName?: string;
    occupation?: string;
}

export interface CustomerInsurance {
    agentName?: string;
    agentPhone?: string;
    companyName?: string;
    policyNo?: string;
    other?: string;
}

export interface CustomerPartyId {
    type: 'NationalID' | 'DriversLicense';
    value: string;
    expirationDate?: string;
    issuingState?: string;
}

/** 一周的星期几 */
export enum DayOfTheWeek {
    /** 星期日 */
    Sunday = 0,
    /** 星期一 */
    Monday = 1,
    /** 星期二 */
    Tuesday = 2,
    /** 星期三 */
    Wednesday = 3,
    /** 星期四 */
    Thursday = 4,
    /** 星期五 */
    Friday = 5,
    /** 星期六 */
    Saturday = 6
}

export interface Customer {
    groupId?: string;
    type: CustomerType;
    name: CustomerName;
    names?: CustomerName[];
    gender?: 'M' | 'F';
    dob?: string;
    isSalesCustomer?: boolean;
    isServiceCustomer?: boolean;
    assigningPartyId?: CustomerPartyId;
    partyIds?: CustomerPartyId[];
    contactInfo?: ContactInfo;
    employment?: CustomerEmployment;
    insurance?: CustomerInsurance;
}

export interface ContactInfoValue {
    value: string;
    ext?: string;
    description?: 'Mobile' | 'Home' | 'Work' | 'Pager';
    preferred?: boolean;
}

export interface Address {
    country?: string;
    state?: string;
    city?: string;
    county?: string;
    street?: string;
    mailing?: string;
    zip?: string;
}

export enum DayOfWeek {
    Sunday = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6
}

export interface ContactInfo {
    phoneNumber: ContactInfoValue;
    phoneNumbers?: ContactInfoValue[];
    email?: ContactInfoValue;
    emails?: ContactInfoValue[];
    fax?: ContactInfoValue;
    address?: Address;
    preferredContactDay?: DayOfWeek[];
    preferredContactHour?: number[];
    addresses?: { description?: string; address: Address }[];
    doNotContact?: boolean;
}

export type CustomerCreateInput = Customer;
export type CustomerUpdateInput = Customer;

export interface CustomerDataObject extends Customer {
    id: string;
}

export enum CustomerHistoryType {
    Task,
    Appointment,
    PhoneCall,
    Email,
    SMS,
    Fax,
    Letter,
    Comment
}

export interface CustomerHistory {
    id: string;
    dealer: string;
    scheduleTime: number;
    type: CustomerHistoryType;
    comment: string;
    salesperson: string;
    salespersonName: { firstName: string; lastName: string };
    customer: string;
    customerName: { firstName: string; lastName: string };
    lead?: string;
    direction?: Direction;
}
