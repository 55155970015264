import { VehicleDataObject } from '../../vehicles/inventory/models';

export enum LeadStatus {
    New = 0x1,
    Assigned = 0x2,
    Appointment = 0x4,
    InProgress = 0x8,
    Converted = 0x10,
    Lost = 0x20,
    Defeated = 0x40
}

export enum LeadProspectLevel {
    High,
    Neutral,
    Low
}

export enum LeadPurchaseTimeframe {
    Immediate,
    ThisMonth,
    ThisQuarter,
    NextQuarter,
    ThisYear
}

export enum LeadContactTypeTask {
    Task = 0,
    Appointment = 1,
    PhoneCall = 2,
    Fax = 5,
    Letter = 6
}

export enum LeadContactType {
    Task = 0,
    Appointment = 1,
    PhoneCall = 2,
    Email = 3,
    SMS = 4,
    Fax = 5,
    Letter = 6,
    Comment = 7
}

export enum LeadActivityResult {
    Completed,
    Abandoned
}

export enum Direction {
    Inbound,
    Outbound
}

export enum LeadContactStatus {
    None,
    Failure,
    Success
}

export interface LeadOpportunity {
    id?: string;
    make?: string;
    model?: string;
    color?: string;
    purchaseTimeframe?: LeadPurchaseTimeframe;
    testDrived?: boolean;
    demonstrated?: boolean;
    lookingForUsed?: boolean;
    description?: string;
    vin?: string;
    stockNo?: string;
    vehicle?: string | VehicleDataObject;
}

export interface LeadTradeIn {
    id?: string;
    year?: number;
    make?: string;
    model?: string;
    mileage?: number;
    vin?: string;
}

export interface LeadActivity {
    id?: string;
    title: string;
    contactType: LeadContactType;
    automatic?: boolean;
    messageTemplateId?: string;
    contactInfoName?: string;
    comment?: string;
    closeComment?: string;
    dueTime?: string;
    scheduleTime?: string;
    actualTime?: string;
    result?: LeadActivityResult;
    pickedUp?: boolean;
    afterContactComment?: boolean;
    remindMe?: boolean;
    callContact?: CallLog;
    message?: SMSLog;
    direction?: Direction;
    customer?: string;
    author: string;
    _createAt: number;
    _closeAt?: number;
    _createBy: User;
    _closeBy: User;
}
export interface LeadActivityCreateInput {
    title: string;
    contactType: LeadContactType;
    comment?: string;
    dueTime?: string;
    scheduleTime?: string;
    automatic?: boolean;
    messageTemplateId?: string;
    contactInfoName?: string;
    afterContactComment?: boolean;
    remindMe?: boolean;
    timezone?: string;
}

export interface LeadActivityCloseInput {
    id: string;
    result: LeadActivityResult;
    prospectLevel: LeadProspectLevel;
    actualTime?: string;
    comment?: string;
    leadDefeated?: boolean;
    pickedUp?: boolean;
}

export interface LeadCloseInput {
    lost?: boolean;
    reason?: string;
    createDeal?: boolean;
}

export interface Lead {
    id: string;
    source: string;
    summary: string;
    customerId: string;
    opportunities: LeadOpportunity[];
    tradeIns?: LeadTradeIn[];
    opportunity?: LeadOpportunity; // only web
}

export interface CreateLeadActivityInput {
    id?: string;
    title: string;
    contactType: LeadContactType;
    automatic?: boolean;
    messageTemplateId?: string;
    contactInfoName?: string;
    comment?: string;
    closeComment?: string;
    dueTime?: string;
    scheduleTime?: string;
    actualTime?: string;
    result?: LeadActivityResult;
    pickedUp?: boolean;
    afterContactComment?: boolean;
    callContact?: string;
    message?: SMSLog;
    direction?: Direction;
    customer?: string;
    author: string;
    _createAt: number;
    _closeAt?: number;
    _createBy?: string;
    _closeBy?: string;
}

export type LeadCreateInput = Partial<
    Pick<LeadDataObject, 'source' | 'summary' | 'customerId' | 'customer' | 'opportunities' | 'tradeIns'>
> & {
    assignee?: string;
    activities?: CreateLeadActivityInput[];
};
export type LeadUpdateInput = Partial<Pick<Lead, 'source' | 'summary' | 'opportunities' | 'tradeIns'>>;
export type LeadAssignInput = {
    targetType: string;
    targetIds: string[];
};

export interface LeadDataObject extends Lead {
    status: LeadStatus;
    customer: Customer;
    cdkId?: string;
    prospectLevel?: LeadProspectLevel;
    activities: LeadActivity[];
    assignee?: User;
    assignees?: string[];
    associatedDealId?: string;
    lostReason?: string;
    lastContactAt?: number;
    contactStatus?: LeadContactStatus;
    _dataStatus: number;
    _owner: string;
    _createAt?: number;
    _updateAt?: number;
    _closeAt?: number;
}

// customer
export enum CustomerType {
    Person = 0,
    Business = 1
}

export type FICO = {
    equifax: number;
    experian: number;
    transUnion: number;
};

export interface CustomerName {
    type: CustomerType;
    companyName?: string;
    firstName?: string;
    lastName?: string;
    middleName?: string;
    suffix?: string;
    title?: string;
}

export interface CustomerEmployment {
    employerName: string;
    occupation?: string;
}

export interface CustomerInsurance {
    agentName?: string;
    agentPhone?: string;
    companyName?: string;
    policyNo?: string;
    other?: string;
}

export interface CustomerPartyId {
    type: 'NationalID' | 'DriversLicense';
    value: string;
    expirationDate?: string;
    issuingState?: string;
}

/** 一周的星期几 */
export enum DayOfTheWeek {
    /** 星期日 */
    Sunday = 0,
    /** 星期一 */
    Monday = 1,
    /** 星期二 */
    Tuesday = 2,
    /** 星期三 */
    Wednesday = 3,
    /** 星期四 */
    Thursday = 4,
    /** 星期五 */
    Friday = 5,
    /** 星期六 */
    Saturday = 6
}
export interface Customer {
    id?: string;
    groupId?: string;
    type: CustomerType;
    name: CustomerName;
    names?: CustomerName[];
    gender?: 'M' | 'F';
    dob?: string;
    isSalesCustomer?: boolean;
    isServiceCustomer?: boolean;
    assigningPartyId?: CustomerPartyId;
    partyIds?: CustomerPartyId[];
    contactInfo: ContactInfo;
    employment?: CustomerEmployment;
    insurance?: CustomerInsurance;
}

export interface ContactInfoValue {
    value: string;
    ext?: string;
    description?: 'Mobile' | 'Home' | 'Work' | 'Pager';
    preferred?: boolean;
}

export interface Address {
    country?: string;
    state?: string;
    city?: string;
    county?: string;
    street?: string;
    mailing?: string;
    zip?: string;
}

export enum DayOfWeek {
    Sunday = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6
}

export interface ContactInfo {
    phoneNumber: ContactInfoValue;
    phoneNumbers?: ContactInfoValue[];
    email?: ContactInfoValue;
    emails?: ContactInfoValue[];
    fax?: ContactInfoValue;
    address?: Address;
    preferredContactDay?: DayOfWeek[];
    preferredContactHour?: number[];
    addresses?: { description?: string; address: Address }[];
    doNotContact?: boolean;
}

export type CustomerCreateInput = Customer;
export type CustomerUpdateInput = Omit<Customer, 'isSalesCustomer' | 'isServiceCustomer'>;
export interface CustomerDataObject extends Customer {
    id: string;
}

// user

export enum AccountStatus {
    Confirmed,
    ForceChangePassword,
    Blocked = -1
}
export interface UserPermission {
    page: string;
    permissions: string[];
}

export interface User {
    id: string;
    dealer: string;
    /** User's email address, also is the sign in username. */
    email: string;
    /** User's first name (given name). */
    firstName: string;
    /** User's last name (family name). */
    lastName: string;
    /** User's telephone number, must be stored in E.164 standard. */
    phone?: string;
    /** User's picture address. */
    picture?: string;
}
export interface UserDataObject extends User {
    /** User account status */
    accountStatus: AccountStatus;
    /** Dealers the user belongs to */
    dealers: string[];
    /** User roles */
    roles: string[];
    /** Pages to access, populated from roles */
    pages: string[];
    /** Access permissions, populated from roles */
    permissions: string[];
    /** User last sign in time, in unix time. */
    lastSignInAt?: number;
    /** User last sign in ip address. */
    lastSignInIp?: string;
    _dataStatus: number;
    _createAt?: number;
    _updateAt?: number;
}

// team

export enum TeamType {
    Sales,
    Service,
    Technician
}

export interface Team {
    id: string;
    type: TeamType;
    name: string;
    members: UserDataObject[];
    enabled: boolean;
}

export interface LeadLastComment {
    id: string;
    comment: string;
}

export interface CallContact {
    id: string;
    lead: string;
    salesperson: string;
    initiationMethod?: string;
    initiationAt?: number;
    scheduledAt?: number;
    connectedToAgentAt?: number;
    enqueueAt?: number;
    lastUpdateAt?: number;
    disconnectAt?: number;
    recording?: Attachment;
}

export interface LeadActivityClosePhoneInput {
    id?: string;
    prospectLevel?: number;
    leadDefeated?: boolean;
    comment?: string;
    nextContact?: number;
}

export interface LeadActivityCreatePhoneInput {
    prospectLevel?: number;
    leadDefeated?: boolean;
    comment?: string;
    nextContact?: number;
}

interface Attachment {
    id: string;
    key: string;
    fileName: string;
    mimeType: string;
    size: number;
}

export interface SMSLog {
    id: string;
    lead: string;
    salesperson: string;
    from?: string;
    to?: string;
    message: string;
    direction: Direction;
    sendAt: number;
}

export enum CallResult {
    /** The called party answered the call and was connected to the caller. */
    Answered,
    /** Received a busy signal when trying to connect to the called party. */
    Busy,
    /** The called party did not pick up before the timeout period passed. */
    NoAnswer,
    /** The call was canceled via the REST API before it was answered. */
    Canceled,
    /** Unable to route to the given phone number. This is frequently caused by dialing a properly formatted but non-existent phone number. */
    Failed = -1
}

export interface CallLog {
    id: string;
    dealer: string;
    from: string;
    to: string;
    result: CallResult;
    createAt: number;
    duration: number; // second
    direction: Direction;
    recording?: Attachment;
    lead?: string;
    salesperson?: string;
}
