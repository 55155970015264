import { Select, Space } from 'antd';
import { useEffect } from 'react';

import { gql } from '@apollo/client';

import { TeamDataObject } from '../../shop/team/models';
import { Loading } from '../components';
import { useSearch } from '../hooks';
import { Team, TeamType } from '../lead/models';

interface Props {
    type?: TeamType;
    members?: string;
    value?: Team;
    onChange?: (value: TeamDataObject | null) => void;
    onCallBack?: (teams: TeamDataObject[]) => void;
}

export const SEARCH_TEAMS = gql`
    query SearchTeams($filter: TeamSearchInput) {
        searchTeams(filter: $filter, page: 1, pageSize: 50) {
            total
            data {
                id
                type
                name
                enabled
                members {
                    id
                    firstName
                    lastName
                    email
                    accountStatus
                }
                notAvailable {
                    id
                    firstName
                    lastName
                    email
                }
                _updateAt
            }
        }
    }
`;
interface Filter {
    _search?: string;
    type?: TeamType;
    enabled?: boolean;
    _noSearch?: boolean;
    members?: string;
}

const TeamSearcher = ({ type, members, value, onChange, onCallBack }: Props) => {
    const filter: Filter = { _noSearch: true, type: type, members: members, enabled: true };
    const { loading, data } = useSearch<Filter, TeamDataObject>(SEARCH_TEAMS, filter, [], -1);

    const onChangeSelect = (value: string) => {
        const team = data.find(team => team.id === value) ?? null;
        if (typeof onChange === 'function') onChange(team);
    };

    useEffect(() => {
        if (data == null || (Array.isArray(data) && data.length == 0)) return;
        if (typeof onCallBack === 'function') onCallBack(data as TeamDataObject[]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <Select
            placeholder="Select team"
            value={value?.id ?? undefined}
            notFoundContent={loading ? <Loading text="Searching..." /> : null}
            onChange={onChangeSelect}
            defaultActiveFirstOption={false}
            filterOption={false}
            style={{ width: '100%' }}
            allowClear
        >
            {Array.isArray(data) &&
                data.map(team => (
                    <Select.Option key={team.id} value={team.id}>
                        <Space>
                            <strong>{team.name}</strong>
                        </Space>
                    </Select.Option>
                ))}
        </Select>
    );
};

export default TeamSearcher;
