import { Spin } from 'antd';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
`;

const WrapperSmall = styled.div`
    position: relative;
    width: 100%;
    text-align: center;
    top: 50%;
`;

interface Props {
    text?: string;
    fullscreen?: boolean;
}

const Loading: React.FC<Props> = props => {
    const fullscreen = props.fullscreen === true;
    const text = typeof props.text === 'string' ? props.text : 'Loading...';
    const content = (
        <React.Fragment>
            <Spin size="large" />
            <div>{text}</div>
        </React.Fragment>
    );
    return fullscreen ? <Wrapper>{content}</Wrapper> : <WrapperSmall>{content}</WrapperSmall>;
};

export default React.memo(Loading);
