import { Button, Card, Radio, Space, Spin } from 'antd';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import { uniq } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';

import { UserContext } from '../../../contexts';
import { TeamType } from '../../crm/lead/models';
import UserSearcherSelect from '../../service/appointment/components/UserSearcherSelect';
import { BusinessType, LeadReportDataObject, LeadStatistics, SEARCH_LEAD_REPORTS } from '../models';
import styles from '../styles.module.scss';
import HomeChart from '../HomeChart';

dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

const AdviserLeadChart = () => {
    const user = useContext(UserContext);
    const isSalesManager = useMemo(() => user != null && (user.roles.includes('admin') || user.roles.includes('salesmanager')), [user]);
    const isSales = useMemo(() => user != null && (user.roles.includes('admin') || user.pages.includes('crm/lead')), [user]);

    const daySpan = 30,
        weekSpan = 20,
        monthSpan = 20;
    const daily = 'daily',
        weekly = 'weekly',
        monthly = 'monthly';
    const [reportType, setReportType] = useState<string>(daily);
    const [leadStatistics, setLeadStatistics] = useState<LeadStatistics[]>([]);

    const [searchLeadReports, { loading: leadReportsLoading, called, refetch: refetchLeadReports }] = useLazyQuery(SEARCH_LEAD_REPORTS, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
    });

    //选中某一天
    const [dailyDateReport, setDailyDateReport] = useState<dayjs.Dayjs>(dayjs());
    const [weeklyDateReport, setWeeklyDateReport] = useState<dayjs.Dayjs>(dayjs());
    const [monthlyDateReport, setMonthlyDateReport] = useState<dayjs.Dayjs>(dayjs());

    const [selectAdvisor, setSelectAdvisor] = useState<string>();

    //返回不同维度，x 轴的显示集合
    const getYearMonths = useCallback(() => {
        const day =
            reportType === daily
                ? dailyDateReport
                : reportType === weekly
                ? weeklyDateReport
                : reportType === monthly
                ? monthlyDateReport
                : dailyDateReport;
        if (day == null) return;
        const number = reportType === daily ? daySpan : reportType === weekly ? weekSpan : reportType === monthly ? monthSpan : 0;
        const method: any = reportType === daily ? 'day' : reportType === weekly ? 'week' : reportType === monthly ? 'month' : '';
        const results: dayjs.Dayjs[] = [];

        for (let i = 0; i < number; i++) {
            const d = dayjs(day).subtract(i, method);
            results.push(d);
        }
        return results;
    }, [dailyDateReport, monthlyDateReport, reportType, weeklyDateReport]);

    const buildLeadStatistics = useCallback(
        async (_yearMonths: dayjs.Dayjs[], userId: any, reportType: any) => {
            const _leadStatistics: LeadStatistics[] = [];
            const variables = {
                filter: {
                    _noSearch: true,
                    type: reportType,
                    assignee: userId
                }
            };

            const years = uniq(_yearMonths.map(o => o.year()));
            const weeks = uniq(_yearMonths.map(o => o.week()));
            const months = uniq(_yearMonths.map(o => o.month() + 1));
            if (reportType === daily) {
                variables.filter = Object.assign({}, variables.filter, { year: years, month: months });
            } else if (reportType === weekly) {
                variables.filter = Object.assign({}, variables.filter, { year: years, week: weeks });
            } else if (reportType === monthly) {
                variables.filter = Object.assign({}, variables.filter, { year: years, month: months });
            }

            const results = _yearMonths as dayjs.Dayjs[];
            const result = called ? await refetchLeadReports(variables) : await searchLeadReports({ variables });
            const leads = (result.data?.searchLeadReports?.data ?? []) as LeadReportDataObject[];
            results.forEach(item => {
                let result: LeadReportDataObject[] = [];
                let label = '';
                if (reportType === daily) {
                    result = leads.filter(l => l.year === item.year() && l.month === item.month() + 1 && l.date === item.date());
                    label = item.format('L');
                } else if (reportType === weekly) {
                    result = leads.filter(l => l.year === item.year() && l.week === item.week());
                    label = item.format('L');
                } else if (reportType === monthly) {
                    result = leads.filter(l => l.year === item.year() && l.month === item.month() + 1);
                    label = item.format('MMM/YYYY');
                }

                _leadStatistics.push({
                    timeLabel: label,
                    time: item,
                    new: result.length > 0 ? result[0].new ?? 0 : 0,
                    close: result.length > 0 ? result[0].closed ?? 0 : 0,
                    won: result.length > 0 ? result[0].won ?? 0 : 0,
                    assigned: result.length > 0 ? result[0].assigned ?? 0 : 0,
                    contacted: result.length > 0 ? result[0].contacted ?? 0 : 0
                });
            });
            setLeadStatistics(_leadStatistics);
        },
        [called, refetchLeadReports, searchLeadReports]
    );

    useEffect(() => {
        const _yearMonths = getYearMonths();
        let userId = undefined;
        if (isSalesManager) userId = selectAdvisor;
        if (isSales && !isSalesManager) userId = user?.id;
        buildLeadStatistics(_yearMonths || [], userId, reportType);
    }, [buildLeadStatistics, getYearMonths, reportType, user?.id, selectAdvisor, isSalesManager, isSales]);

    const previous = () => {
        if (reportType === daily) {
            const previousDay = dailyDateReport.subtract(daySpan, 'day');
            setDailyDateReport(previousDay);
        } else if (reportType === weekly) {
            const previousDay = weeklyDateReport.subtract(weekSpan, 'week');
            setWeeklyDateReport(previousDay);
        } else if (reportType === monthly) {
            const previousDay = monthlyDateReport.subtract(monthSpan, 'month');
            setMonthlyDateReport(previousDay);
        }
    };

    const next = () => {
        if (reportType === daily) {
            const nextDay = dailyDateReport.add(daySpan, 'day');
            setDailyDateReport(nextDay);
        } else if (reportType === weekly) {
            const nextDay = weeklyDateReport.add(weekSpan, 'week');
            setWeeklyDateReport(nextDay);
        } else if (reportType === monthly) {
            const previousDay = monthlyDateReport.add(monthSpan, 'month');
            setMonthlyDateReport(previousDay);
        }
    };

    const checkDisabled = () => {
        if (reportType === daily) {
            return dailyDateReport.format('L') === dayjs().format('L');
        } else if (reportType === weekly) {
            return weeklyDateReport.format('L') === dayjs().format('L');
        } else if (reportType === monthly) {
            return monthlyDateReport.format('L') === dayjs().format('L');
        }
    };

    const userSelectOnChange = (value: string | string[]) => {
        if (Array.isArray(value)) return;
        setSelectAdvisor(value);
    };

    const tabBarExtraContent = (
        <Space>
            <Radio.Group value={reportType} onChange={e => setReportType(e.target.value)}>
                <Radio value={daily}>Daily</Radio>
                <Radio value={weekly}>Weekly</Radio>
                <Radio value={monthly}>Monthly</Radio>
            </Radio.Group>
            {isSalesManager && (
                <div style={{ width: 200 }}>
                    <UserSearcherSelect onChange={userSelectOnChange} showSearch={true} type={TeamType.Sales} />
                </div>
            )}
            <Button type="primary" icon={<ArrowLeftOutlined />} onClick={previous}></Button>
            <Button type="primary" icon={<ArrowRightOutlined />} onClick={next} disabled={checkDisabled()}></Button>
        </Space>
    );

    return (
        <>
            {isSalesManager && (
                <Card size="small" title="All Leads" className={styles.tabcard} extra={tabBarExtraContent} style={{ padding: 20 }}>
                    <Spin spinning={leadReportsLoading}>
                        <HomeChart type={BusinessType.Lead} leadStatisticss={leadStatistics} reportType={reportType} />
                    </Spin>
                </Card>
            )}
            {isSales && !isSalesManager && (
                <Card size="small" title="My Leads" className={styles.tabcard} extra={tabBarExtraContent} style={{ padding: 20 }}>
                    <Spin spinning={leadReportsLoading}>
                        <HomeChart type={BusinessType.Lead} leadStatisticss={leadStatistics} reportType={reportType} />
                        {/* <div style={{ marginTop: 20 }}>
                            Improve contact rate click{' '}
                            <a style={{ color: '#2a7fff' }} onClick={() => setActiveKey('noContact')}>
                                me
                            </a>
                        </div> */}
                    </Spin>
                </Card>
            )}
        </>
    );
};

export default AdviserLeadChart;
