import { Alert, Col, Modal, Radio, Row, Spin, Tooltip } from 'antd';
import defaultLocale from 'antd/lib/calendar/locale/en_US';
import dayjs from 'dayjs';
import groupBy from 'lodash/groupBy';
import sumBy from 'lodash/sumBy';
import toPairs from 'lodash/toPairs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Calendar } from '../../components';
import { SimpleBarAny as SimpleBar } from '../../components/SimpleBarAny';
import { useFilters, useGet, usePaging, useSearchData } from '../../hooks';
import { Holiday, HolidayType, ServiceAppointment, Setting } from '../../models';

interface Props {
    visible: boolean;
    value: dayjs.Dayjs;
    onCancel: () => void;
    onOk: (time: dayjs.Dayjs) => void;
}

interface TimeButtonProps {
    time: dayjs.Dayjs;
    current: number;
    capacity: number;
    color?: string;
}

const locale = Object.assign({}, defaultLocale, {
    dateFormat: 'MM/DD/YYYY',
    dateTimeFormat: 'lll'
});

const TimeButton: React.FC<TimeButtonProps> = props => {
    const color = props.color ?? '#ccc';
    let available = props.capacity - props.current;
    available = available < 0 ? 0 : available;
    const progress = available === 0 ? 100 : props.capacity === 0 ? 0 : Math.ceil(Math.abs((props.current / props.capacity) * 100));
    const background = `linear-gradient(to right, ${color}, ${color} ${progress}%, transparent ${progress}%)`;
    return (
        <Tooltip placement="left" title={available > 0 ? `Available: ${available}` : 'Full'}>
            <Radio.Button
                style={{ width: '100%', height: 40, lineHeight: '40px', textAlign: 'center', background }}
                value={props.time.hour() * 60 + props.time.minute()}
                disabled={available === 0}
            >
                {props.time.format('h:mm a')}
            </Radio.Button>
        </Tooltip>
    );
};

const ScheduleCalendar: React.FC<Props> = ({ visible, value, onCancel, onOk }) => {
    const [time, setTime] = useState(value);
    const [paging] = usePaging(0);
    const [filters1] = useFilters(undefined, { type: HolidayType.General });
    const [filters2] = useFilters(undefined, { type: HolidayType.Specific, year: new Date().getFullYear() });
    const [filters4, setFilters4] = useFilters(undefined, {
        scheduleTime: [time.startOf('day'), time.startOf('day').add(1, 'day')]
    });
    const [result1, isWorking1] = useSearchData<Holiday>('holiday', filters1, paging);
    const [result2, isWorking2] = useSearchData<Holiday>('holiday', filters2, paging);
    const [result3, isWorking3] = useGet<Setting>('setting');
    const [result4, isWorking4] = useSearchData<ServiceAppointment>('serviceAppointment', filters4, paging);
    const isWorking = useMemo(() => isWorking1 || isWorking2 || isWorking3 || isWorking4, [isWorking1, isWorking2, isWorking3, isWorking4]);

    const disabledDate = useCallback(
        (value: dayjs.Dayjs) => {
            const today = dayjs().startOf('day');
            if (value < today || value > today.add(6, 'month')) return true;
            const day = result1.data.find(day => day.day === value.day());
            if (day != null && day.isOpen !== true) return true;
            if (
                result2.data.find(
                    day => day.year === value.year() && day.month === value.month() && day.date === value.date() && day.isOpen !== true
                ) != null
            )
                return true;
            return false;
        },
        [result1.data, result2.data]
    );

    const dateCellRender = useCallback(
        (value: dayjs.Dayjs) => {
            const holiday = result2.data.find(day => day.year === value.year() && day.month === value.month() && day.date === value.date());
            return holiday == null ? null : (
                <div style={{ height: '100%' }}>
                    <div
                        style={{
                            color: holiday.isOpen === true ? 'inherit' : '#bfbfbf',
                            fontSize: '90%',
                            position: 'absolute',
                            bottom: 5
                        }}
                    >
                        {holiday.notes}
                    </div>
                </div>
            );
        },
        [result2.data]
    );

    const scheduled = useMemo(
        () =>
            toPairs(
                groupBy(result4.data, holiday => {
                    const time = dayjs(holiday.scheduledTime);
                    return time.hour() * 60 + time.minute();
                })
            ).map(p => [Number(p[0]), p[1].length]),
        [result4.data]
    );

    useEffect(() => setTime(value), [value]);
    const selectedDate = time.startOf('day').valueOf();
    useEffect(() => setFilters4('scheduleTime', [dayjs(selectedDate), dayjs(selectedDate).add(1, 'day')]), [selectedDate, setFilters4]);

    const onChange = (value: dayjs.Dayjs) => setTime(value);

    const timeButtons: JSX.Element[] = [];
    if (!disabledDate(time)) {
        let start = 0,
            end = 0;
        const span = result3?.serviceAppointment?.span ?? 60;
        const holiday = result2.data.find(
            day => day.year === time.year() && day.month === time.month() && day.date === time.date() && day.isOpen === true
        );
        if (holiday != null) {
            start = holiday.openHour ?? 0;
            end = holiday.closeHour ?? 0;
        } else {
            const day = result1.data.find(day => day.day === time.day() && day.isOpen === true);
            if (day != null) {
                start = day.openHour ?? 0;
                end = day.closeHour ?? 0;
            }
        }
        if (start > 0 && end > 0)
            for (let i = start; i <= end - span; i += span) {
                const current = sumBy(
                    scheduled.filter(d => d[0] >= i && d[0] < i + span),
                    d => d[1]
                );
                timeButtons.push(
                    <Col key={i} span={8} onDoubleClick={() => onOk(time)}>
                        <TimeButton
                            time={dayjs().startOf('day').add(i, 'minute')}
                            current={current}
                            capacity={result3?.serviceAppointment?.capacity ?? 0}
                        />
                    </Col>
                );
            }
    }

    return (
        <Modal
            className="form"
            title="Choose an appointment time"
            confirmLoading={isWorking}
            visible={visible}
            onCancel={onCancel}
            onOk={() => onOk(time)}
            width={1200}
            okButtonProps={{ disabled: disabledDate(time) }}
            cancelButtonProps={{ type: 'link' }}
            centered
        >
            <Spin spinning={isWorking}>
                <Row gutter={4} style={{ backgroundColor: 'white' }}>
                    <Col span={16}>
                        <Calendar
                            locale={locale}
                            value={time}
                            disabledDate={disabledDate}
                            onChange={onChange}
                            dateCellRender={dateCellRender}
                        />
                    </Col>
                    <Col span={8} style={{ padding: '12px 0' }}>
                        <div style={{ padding: '0 12px 4px 12px' }}>
                            <Alert
                                style={{ textAlign: 'center' }}
                                message={disabledDate(time) ? 'No Date Selected' : time.format('dddd, MMMM D h:mm a')}
                            />
                        </div>
                        <SimpleBar style={{ padding: '0 12px', height: 750, overflowX: 'hidden' }}>
                            <Radio.Group
                                value={time.hour() * 60 + time.minute()}
                                onChange={e => setTime(time => time.startOf('day').minute(e.target.value))}
                            >
                                <Row gutter={[4, 4]}>{isWorking ? null : timeButtons}</Row>
                            </Radio.Group>
                        </SimpleBar>
                    </Col>
                </Row>
            </Spin>
        </Modal>
    );
};

export default React.memo(ScheduleCalendar);
