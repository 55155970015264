/**
 * 系统中常用的枚举结构
 * @packageDocumentation
 */

/** 一周的星期几 */
export enum DayOfTheWeek {
    /** 星期日 */
    Sunday = 0,
    /** 星期一 */
    Monday = 1,
    /** 星期二 */
    Tuesday = 2,
    /** 星期三 */
    Wednesday = 3,
    /** 星期四 */
    Thursday = 4,
    /** 星期五 */
    Friday = 5,
    /** 星期六 */
    Saturday = 6
}

export enum NotifyMethod {
    Email = 0x1,
    Text = 0x2
}
