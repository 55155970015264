import { Col, Row } from 'antd';
import { useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';

import { Setting } from '../../admin/setting/model';
import { LeadContactStatus } from '../../crm/lead/models';
import { Group } from '../models';
import styles from '../styles.module.scss';
import LeadHistoryModal from './LeadHistoryModal';
import Task from './Task';

interface Props {
    groups: Group[];
    settings: Setting[];
}

const grid = 8;

const getListStyle = () => ({
    padding: grid,
    width: 250,
    borderRadius: '3px'
});

const getGroupStyle = {
    background: 'rgb(235, 236, 240)',
    padding: 8,
    margin: `5px 5px 0 5px`,
    borderRadius: '3px'
    // padding: grid,
    //height: 500
};

// #b7eb8f  #389e0d  #ffa39e  #cf1322
const getRateStyle = (isStandard: boolean) => ({
    color: isStandard ? '#389e0d' : '#cf1322'
});

const TaskList = ({ groups, settings }: Props) => {
    const [commentVisible, setCommentVisible] = useState<boolean>(false);
    const [leadId, setLeadId] = useState<string>('');
    const calcRate = (group: Group) => {
        if (!Array.isArray(group.tasks)) return 0;
        const sum = group.tasks.length;
        const count = group.tasks.filter(task => task.contactStatus === LeadContactStatus.Success).length;
        if (sum === 0 || count === 0) return 0;

        const _completeRate = ((count / sum) * 100).toFixed(0);
        return _completeRate;
    };

    return (
        <>
            {Array.isArray(groups) &&
                groups.map(group => (
                    <Droppable key={group.id} droppableId={group.id} isDropDisabled={group.id === 'UnassignedId'}>
                        {provided => (
                            <div ref={provided.innerRef} style={getListStyle()} {...provided.droppableProps}>
                                <div style={getGroupStyle}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
                                        <div className={styles.title}>
                                            <strong>{group.title}</strong>{' '}
                                        </div>
                                        <div
                                            className={styles.rate}
                                            style={getRateStyle(Number(calcRate(group)) >= Number(settings[0]?.value || 0.8) * 100)}
                                        >
                                            <strong> {calcRate(group) + '%'} </strong>
                                        </div>
                                    </div>

                                    {group.tasks.map((task, i) => (
                                        <Row key={i} gutter={8}>
                                            <Col span={24}>
                                                <Task
                                                    task={task}
                                                    index={i}
                                                    handleHistoryVisible={() => {
                                                        setLeadId(task.id);
                                                        setCommentVisible(true);
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    ))}
                                </div>
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                ))}
            <LeadHistoryModal id={leadId} visible={commentVisible} onCancel={() => setCommentVisible(false)} />
        </>
    );
};

export default TaskList;
