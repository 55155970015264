import { Avatar, AvatarProps, Tooltip } from 'antd';
import { isEmpty } from 'lodash';

/**
 * 返回的是个数组 [string, string] ，
 * 第一个是背景色，第二个是前景色，
 * 前景色拿来显示名字缩写，主要是黑色调和白色调两种，
 * 用于跟背景色形成反差，避免暗色调背景上显示黑色文字
 * @param string
 * @returns [backgroundColor, color]
 */
const stringToColor = (string: string) => {
    let hash = 0;
    let i;
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) hash = string.charCodeAt(i) + ((hash << 5) - hash);
    let color = '#';
    const colors: number[] = [];
    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        const v = `00${value.toString(16)}`.substr(-2);
        colors.push(parseInt(v, 16));
        color += v;
    }
    /* eslint-enable no-bitwise */
    const contrast = (Math.round(colors[0] * 299) + Math.round(colors[1] * 587) + Math.round(colors[2] * 114)) / 1000;
    return [color, contrast >= 150 ? '#111' : '#eee'];
};

interface Props {
    firstName: string;
    lastName: string;
    author?: string;
}

export default function UserAvatar(props: AvatarProps & Props) {
    const { firstName, lastName, author, ...avatarProps } = props;
    let _firstName = firstName || '';
    let _lastName = lastName || '';
    if (author != null && !isEmpty(author)) {
        const authors = author?.split(' ');
        if (Array.isArray(authors) && authors.length === 1) {
            _firstName = authors[0];
            _lastName = '';
        } else if (Array.isArray(authors) && authors.length > 1) {
            _firstName = authors[0];
            _lastName = authors[1];
        }
    }
    const [backgroundColor, color] = stringToColor(_firstName + _lastName);
    const _style =
        (_firstName == null || isEmpty(_firstName)) && (_lastName == null || isEmpty(_lastName))
            ? {}
            : {
                  color: color,
                  backgroundColor: backgroundColor
              };
    return (
        <Tooltip title={`${_firstName} ${_lastName}`}>
            <Avatar style={_style} {...avatarProps}>
                {_firstName?.substring(0, 1) + _lastName?.substring(0, 1)}
            </Avatar>
        </Tooltip>
    );
}
