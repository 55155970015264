import { createHttpLink, ApolloClient, FieldPolicy, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

interface SearchArgs {
    page: number;
    pageSize: number;
}

const withAuth = setContext(
    (_, prevCtx) =>
        new Promise((resolve, reject) => {
            const done = (token: string) =>
                resolve({
                    token,
                    headers: {
                        ...prevCtx.headers,
                        authorization: token
                    }
                });
            import('./cognito').then(({ getAccessToken }) => {
                getAccessToken()
                    .then(token =>
                        typeof token === 'string' ? done(token) : reject(new Error('Your login session has expired.'))
                    )
                    .catch(err => reject(err));
            });
        })
);

const createSearchFieldPolicy = (): FieldPolicy => {
    return {
        keyArgs: ['dealer', 'filter', 'sort'],
        read: (existing, { args }) => {
            if (existing == null) return;
            const { page = 1, pageSize = 25 } = args as SearchArgs;
            const start = (page - 1) * pageSize;
            const data = existing.data.slice(start, start + pageSize);
            return Object.assign({}, existing, { data });
        },
        merge: (existing, incoming, options) => {
            if (existing == null) return { ...incoming };
            const { page = 1, pageSize = 25 } = options.args as SearchArgs;
            const start = (page - 1) * pageSize;
            const data = existing.data.slice(0);
            for (let i = 0; i < incoming.data.length; i++) data[start + i] = incoming.data[i];
            return Object.assign({}, existing, { total: incoming.total, data });
        }
    };
};

export const cache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                searchUsers: createSearchFieldPolicy()
            }
        },
        CustomerName: {
            fields: {
                name: {
                    read: (_, { readField }) => {
                        switch (readField('type')) {
                            case 0:
                                let middleName = readField('middleName') ?? '';
                                if (typeof middleName === 'string' && middleName.length > 0)
                                    middleName = ` ${middleName[0].toUpperCase()}`;
                                return `${readField('lastName')}, ${readField('firstName')}${middleName}`;
                            case 1:
                                return readField('companyName');
                            default:
                                return '';
                        }
                    }
                }
            }
        },
        User: {
            fields: {
                fullName: { read: (_, { readField }) => `${readField('firstName')} ${readField('lastName')}` }
            }
        }
    }
});

export const apolloClient = new ApolloClient({
    link: withAuth.concat(createHttpLink({ uri: process.env.REACT_APP_GRAPHQL_ENDPOINT })),
    cache
});
