import { gql } from '@apollo/client';

export const GET_TASK_REMINDER = gql`
    query GetTaskReminder($id: ID!) {
        getTaskReminder(id: $id) {
            id
            type
            status
            title
            scheduleTime
            allDay
            year
            month
            week
            date
            description
            businessType
            businessId
            businessTitle
        }
    }
`;

export const CREATE_TASK_REMINDER = gql`
    mutation CreateTaskReminder($input: TaskReminderCreateInput!) {
        createTaskReminder(input: $input) {
            __typename
            id
            type
            status
            title
            scheduleTime
            allDay
            year
            month
            week
            date
            description
            businessType
            businessId
            businessTitle
        }
    }
`;

export const UPDATE_TASK_REMINDER = gql`
    mutation UpdateTaskReminder($id: ID!, $input: TaskReminderUpdateInput!) {
        updateTaskReminder(id: $id, input: $input) {
            __typename
            id
            type
            status
            title
            scheduleTime
            allDay
            year
            month
            week
            date
            description
            businessType
            businessId
            businessTitle
        }
    }
`;

export const SEARCH_TASK_REMINDERS = gql`
    query SearchTaskReminders($filter: TaskReminderSearchInput, $sort: [String], $page: Int, $pageSize: Int) {
        searchTaskReminders(filter: $filter, sort: $sort, page: $page, pageSize: $pageSize) {
            total
            data {
                id
                type
                status
                title
                scheduleTime
                allDay
                year
                month
                week
                date
                description
                businessType
                businessId
                businessTitle
            }
        }
    }
`;

export const DELETE_TASK_REMINDER = gql`
    mutation DeleteTaskReminder($id: ID!) {
        deleteTaskReminder(id: $id)
    }
`;

export const MARK_COMPLETED_TASK_REMINDER = gql`
    mutation MarkCompletedTaskReminder($id: ID!) {
        markCompletedTaskReminder(id: $id) {
            __typename
            id
            type
            status
            title
            scheduleTime
            allDay
            year
            month
            week
            date
            description
            businessType
            businessId
            businessTitle
        }
    }
`;

export const MARK_UNCOMPLETED_TASK_REMINDER = gql`
    mutation MarkUncompletedTaskReminder($id: ID!) {
        markUncompletedTaskReminder(id: $id) {
            __typename
            id
            type
            status
            title
            scheduleTime
            allDay
            year
            month
            week
            date
            description
            businessType
            businessId
            businessTitle
        }
    }
`;
