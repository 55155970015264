import { Button, PageHeader, Space, Spin } from 'antd';
import React, { useEffect, useRef, useState } from 'react';

import { DownCircleOutlined, UpCircleOutlined } from '@ant-design/icons';

import { SimpleBarAny as SimpleBar } from '../../../components/SimpleBarAny';
import { sessionStore } from '../../../services';
import { ActionContainer, HeadContainer } from './containers';

export interface PageProps {
    title: React.ReactNode;
    loading?: boolean;
    headContent?: React.ReactNode;
    extraContent?: React.ReactNode;
    collapsable?: boolean;
}

const menuKey = 'default';

const getStoreCollapsed = (key: string) => {
    const collapsed = sessionStore.get('page.collapsed');
    if (collapsed == null) return false;
    return collapsed[key] === true;
};

const setStoreCollapsed = (key: string, value: boolean) => {
    const collapsed: { [key: string]: true } = sessionStore.get('page.collapsed') ?? {};
    if (value === true) collapsed[key] = true;
    else delete collapsed[key];
    sessionStore.set('page.collapsed', collapsed);
};

const Page: React.FC<React.PropsWithChildren<PageProps>> = props => {
    const headRef = useRef(null);
    const [scrollHeight, setScrollHeight] = useState(0);
    const [collapsed, setCollapsed] = useState(typeof menuKey === 'string' ? getStoreCollapsed(menuKey) : false);
    const [timestamp, setTimestamp] = useState(Date.now());
    useEffect(() => {
        const head = headRef?.current as any;
        const onResize = () => {
            if (head == null) return;
            let totalHeight = head.offsetHeight;
            const header = document.getElementById('shell-header');
            if (header != null) totalHeight += header.offsetHeight;
            const vh = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
            setScrollHeight(vh - totalHeight);
        };
        onResize();
        window.addEventListener('resize', onResize, false);
        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [headRef, timestamp]);

    const collapseButton =
        props.collapsable === true ? (
            <ActionContainer>
                <Button
                    size="small"
                    type="primary"
                    shape="circle"
                    icon={collapsed ? <DownCircleOutlined /> : <UpCircleOutlined />}
                    style={{ border: 0, backgroundColor: 'transparent' }}
                    onClick={() => {
                        setCollapsed(collapsed => {
                            const result = !collapsed;
                            if (typeof menuKey === 'string') setStoreCollapsed(menuKey, result);
                            return result;
                        });
                        setTimestamp(Date.now());
                    }}
                />
            </ActionContainer>
        ) : null;

    const extra =
        props.extraContent == null ? (
            collapseButton
        ) : (
            <Space>
                {props.extraContent}
                {collapseButton}
            </Space>
        );

    return (
        <Spin spinning={props.loading === true} size="large">
            <HeadContainer ref={headRef}>
                <PageHeader title={props.title} extra={extra} />
                {collapsed ? null : props.headContent}
            </HeadContainer>
            <SimpleBar style={{ height: scrollHeight }}>{props.children}</SimpleBar>
        </Spin>
    );
};

export default React.memo(Page);
