import { Select, Space } from 'antd';
import debounce from 'lodash/debounce';
import React, { useCallback, useMemo, useState } from 'react';

import { CustomerName, Loading } from '../../../components';
import { useFilters, usePaging, useSearchData } from '../../../hooks';
import { Employee } from '../../../models';

interface Props {
    employee?: Employee;
    job?: string;
    roleName?: string;
    value?: string | string[];
    onChange?: (value: string | string[]) => void;
}

const useEmployeeSearcher = (job?: string, roleName?: string): [Employee[], boolean, (value: string) => void] => {
    const [filters, setFilter] = useFilters(undefined, { job, roleName });
    const [paging] = usePaging(30);
    const [result, isWorking] = useSearchData<Employee>('employee', filters, paging);
    const employees = useMemo(() => (isWorking ? [] : result.data), [result, isWorking]);
    const setKeyword = useCallback(
        (value: string) => {
            value = value.trim();
            setFilter('$keyword', value === '' ? undefined : value);
        },
        [setFilter]
    );
    return [employees, isWorking, setKeyword];
};
const EmployeeSearcher: React.FC<Props> = React.forwardRef<any, Props>((props, ref) => {
    const [employees, isWorking, setKeyword] = useEmployeeSearcher(props.job, props.roleName);
    const [employeeId, setEmployeesId] = useState<string | string[]>();
    //标记是不是搜索查询
    const [isSearched, setIsSearched] = useState(false);
    const currentEmployees = useMemo(() => {
        if (isSearched) return employees;
        if (props.employee)
            if (employees.find(item => item.id === props.employee?.id) === undefined)
                return [props.employee, ...employees];
        return employees;
    }, [isSearched, employees, props.employee]);
    const onChange = (value: string | string[]) => {
        setEmployeesId(value);
        if (typeof props.onChange === 'function') props.onChange(value);
    };
    const onSearch = (value: string) => {
        setKeyword(value);
        setIsSearched(true);
    };
    useMemo(() => {
        setEmployeesId(props.value || undefined);
    }, [props.value]);
    return (
        <Select
            ref={ref}
            placeholder="Search employee by name, phone, email..."
            value={employeeId}
            notFoundContent={isWorking ? <Loading text="Searching..." /> : null}
            onSearch={debounce(onSearch, 500)}
            onChange={onChange}
            defaultActiveFirstOption={false}
            filterOption={false}
            showSearch
            mode={props.roleName === 'salesPerson' ? 'multiple' : undefined}
        >
            {currentEmployees.map(employee => (
                <Select.Option key={employee.id} value={employee.id}>
                    <Space>
                        <strong>
                            <CustomerName name={employee.name} />
                        </strong>
                        <span>{employee.gender}</span>
                        <span>{employee.contactInfo?.cellNumber}</span>
                        {/* <span>{employee.contactInfo?.email}</span> */}
                    </Space>
                </Select.Option>
            ))}
        </Select>
    );
});

EmployeeSearcher.displayName = 'EmployeeSearcher';

export default React.memo(EmployeeSearcher);
