import { message, Button, Col, Form, Input, Modal, Radio, Row, Space, Spin } from 'antd';
import { Store } from 'antd/lib/form/interface';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';

import { CalendarOutlined, PlusOutlined } from '@ant-design/icons';

import { EnumSelect } from '../../../components';
import { SimpleBarAny as SimpleBar } from '../../../components/SimpleBarAny';
import ScheduleCalendar from '../../../dms/appointments/ScheduleCalendar';
import {
    CustomerCategory,
    ServiceAppointment,
    ServiceAppointmentInput,
    ServiceAppointmentStatus,
    ServiceAppointmentType,
    Vehicle,
    VehicleInput
} from '../../../models';
import { createCustomer, createVehicle, getAppointmentByVehicleId, getVehiclesByCustomer, searchVehiclesByVin } from '../../apis';
import { Customer, CustomerInput } from '../../customers/types';
import { WindowContainer } from '../components';
import { CustomerSearcher, EmployeeSearcher, UnappointmentedVehicleSearcher } from '../searchers';
import CustomerEditor from './CustomerEditor';
import ServiceVehicleEditor from './ServiceVehicleEditor';

const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px'
};

interface Props {
    visible: boolean;
    onCancel: () => void;
    onSave: (data: ServiceAppointmentInput) => Promise<void>;
}

const emptyData: ServiceAppointmentInput & {
    customer?: Customer;
    vehicle?: Vehicle;
} = {
    customerId: '',
    vehicleId: '',
    serviceAdvisorId: '',
    operations: [],
    mileage: 0,
    scheduledTime: '',
    type: ServiceAppointmentType.Inbound,
    customer: undefined,
    vehicle: undefined
};

const NewAppointmentEditor: React.FC<Props> = props => {
    const [scheduledTime, setScheduledTime] = useState<dayjs.Dayjs>(dayjs());

    const [calendarVisible, setCalendarVisible] = useState(false);
    // 表单控制属性
    const [isWorking, setIsWorking] = useState(false);
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    //车辆预约警告信息显示控制
    const [isLock, setIsLock] = useState(false);
    const onOk = async () => {
        if (isLock) {
            return message.error({
                content: 'This vehicle has already appointmented',
                duration: 2
            });
        }
        try {
            const values = (await form.validateFields()) as ServiceAppointment;
            setIsWorking(true);
            const data = Object.assign({}, emptyData, values);
            data.customerId = data.customer?.id || '';
            data.vehicleId = data.vehicle?.id || data.vehicleId || '';
            data.scheduledTime = dayjs(scheduledTime).toISOString();
            delete data.customer;
            delete data.vehicle;
            await props.onSave(data);
        } catch (err) {
            console.error(err);
        } finally {
            setIsWorking(false);
        }
    };
    const onCancel = () => {
        setIsLock(false);
        if (!isWorking) props.onCancel();
    };
    useEffect(() => {
        if (props.visible) {
            form.resetFields();
            setVehicleData([]);
            setVehicleLoading(false);
        }
    }, [form, props.visible]);
    const [vehicleLoading, setVehicleLoading] = useState(false);
    const [timestamp, setTimeStamp] = useState<number>();
    // 新增客户相关状态
    const [customerEditorVisible, setCustomerEditorVisible] = useState(false);
    const onCustomerSave = async (data: CustomerInput) => {
        const customer = await createCustomer(data);
        form.setFieldsValue({ customer });
        setTimeStamp(Date.now());
        setCustomerEditorVisible(false);
    };
    // 新增车辆相关状态
    const [vehicleEditorVisible, setVehicleEditorVisible] = useState(false);
    const onVehicleSave = async (data: VehicleInput) => {
        let res;
        let vehicle;
        if (vehiclesData && vehiclesData.length > 0) {
            res = await searchVehiclesByVin(data.vin);
            if (res && res.data?.length > 0) {
                setVehicleData([...vehiclesData, ...(res.data as Vehicle[])]);
            } else {
                vehicle = await createVehicle(data);
                setVehicleData([...vehiclesData, vehicle as Vehicle]);
            }
        } else {
            vehicle = await createVehicle(data);
            form.setFieldsValue({ vehicle });
            setTimeStamp(Date.now());
        }

        setVehicleEditorVisible(false);
    };
    const [vehiclesData, setVehicleData] = useState<Vehicle[]>([]);
    const checkVehicleData = useMemo(() => {
        return Array.isArray(vehiclesData) && vehiclesData.length > 0;
    }, [vehiclesData]);
    //车辆列表UI
    const vehiclesElement = useMemo(() => {
        form.setFieldsValue({ vehicleId: undefined });
        if (Array.isArray(vehiclesData) && vehiclesData.length > 0)
            return vehiclesData.map(item => (
                <Radio style={radioStyle} value={item.id} key={item.id}>
                    <Space>
                        <strong>{item.vin}</strong>
                        <span>{item.modelCode}</span>
                        <span>{item.modelYear}</span>
                        <span>{item.model?.fullName}</span>
                    </Space>
                </Radio>
            ));
        return null;
    }, [vehiclesData, form]);
    //检测车辆是否被预约
    const checkVehicleAppointed = (id: string) => {
        (async () => {
            setIsLock(false);
            setIsLoading(true);
            const result: any = await getAppointmentByVehicleId(id);
            setIsLoading(false);
            if (Array.isArray(result.data) && result.data.length > 0) {
                try {
                    result.data.forEach((element: ServiceAppointment) => {
                        if (
                            element.appointmentStatus === ServiceAppointmentStatus.New ||
                            element.appointmentStatus === ServiceAppointmentStatus.Confirmed
                        ) {
                            throw new Error('ending');
                        } else {
                            setIsLock(false);
                        }
                    });
                } catch (e) {
                    if (e.message === 'ending') {
                        setIsLock(true);
                        message.error({
                            content: 'This vehicle has already appointmented',
                            duration: 2
                        });
                    }
                }
            }
        })();
    };
    //字段值更新
    const onValuesChange = (changedValues: Store) => {
        if (changedValues.customer != null)
            (async () => {
                try {
                    setVehicleLoading(true);
                    const vehicles = await getVehiclesByCustomer(changedValues.customer.id);
                    setIsLock(false);
                    setVehicleData(vehicles?.vehicles as Vehicle[]);
                    if (form.getFieldValue('vehicle') != null) checkVehicleAppointed(form.getFieldValue('vehicle').id);
                } finally {
                    setVehicleLoading(false);
                }
            })();
        if (changedValues.vehicle != null) checkVehicleAppointed(changedValues.vehicle.id);
        if (changedValues.vehicleId != null) {
            form.setFieldsValue({ vehicle: undefined });
            const vehicleIdItem = changedValues.vehicleId.value;
            checkVehicleAppointed(vehicleIdItem);
        }
    };
    return (
        <Modal
            title="New Appointment"
            confirmLoading={isWorking}
            visible={props.visible}
            onCancel={onCancel}
            onOk={onOk}
            width={600}
            className="form"
            okText="Submit"
            okButtonProps={{ disabled: isLoading }}
            cancelButtonProps={{ type: 'link' }}
            maskClosable={false}
            //forceRender
        >
            <SimpleBar style={{ maxHeight: 600 }}>
                <WindowContainer>
                    <Form form={form} initialValues={emptyData} labelCol={{ span: 6 }} onValuesChange={onValuesChange}>
                        <Form.Item label="Date" name="scheduledTime" rules={[{ required: true }]}>
                            <Input
                                suffix={<CalendarOutlined />}
                                placeholder="choose the appointment time"
                                onClick={() => setCalendarVisible(true)}
                            />
                        </Form.Item>

                        <Form.Item name="serviceAdvisorId" label="Advisor" rules={[{ required: true }]}>
                            <EmployeeSearcher job="Service" />
                        </Form.Item>
                        <Row>
                            <Col span={23}>
                                <Form.Item name="customer" label="Customer" rules={[{ required: true }]}>
                                    <CustomerSearcher category={CustomerCategory.Retention} timestamp={timestamp} />
                                </Form.Item>
                            </Col>
                            <Col span={1}>
                                <Button type="link" icon={<PlusOutlined />} onClick={() => setCustomerEditorVisible(true)} />
                                <CustomerEditor
                                    category={CustomerCategory.Retention}
                                    visible={customerEditorVisible}
                                    onCancel={() => setCustomerEditorVisible(false)}
                                    onSave={onCustomerSave}
                                />
                            </Col>

                            {checkVehicleData ? (
                                <Col span={24}>
                                    <Spin spinning={vehicleLoading}>
                                        <Row style={{ marginBottom: '24px' }}>
                                            <Col span={23}>
                                                <Form.Item
                                                    name="vehicleId"
                                                    label="Vehicle"
                                                    style={{ marginBottom: 0 }}
                                                    rules={[{ required: true }]}
                                                >
                                                    <Radio.Group style={{ width: '100%' }}>{vehiclesElement}</Radio.Group>
                                                </Form.Item>
                                            </Col>
                                            <Col span={1}>
                                                <Button type="link" icon={<PlusOutlined />} onClick={() => setVehicleEditorVisible(true)} />
                                            </Col>
                                        </Row>
                                    </Spin>
                                </Col>
                            ) : (
                                <Col span={24}>
                                    <Spin spinning={vehicleLoading}>
                                        <Row>
                                            <Col span={23}>
                                                <Form.Item name="vehicle" label="Vehicle" rules={[{ required: true }]}>
                                                    <UnappointmentedVehicleSearcher timestamp={timestamp} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={1}>
                                                <Button type="link" icon={<PlusOutlined />} onClick={() => setVehicleEditorVisible(true)} />
                                            </Col>
                                        </Row>
                                    </Spin>
                                </Col>
                            )}
                        </Row>
                        <Form.Item name="type" label="Type" rules={[{ required: true }]}>
                            <EnumSelect enum={ServiceAppointmentType} />
                        </Form.Item>
                    </Form>
                    <ServiceVehicleEditor
                        visible={vehicleEditorVisible}
                        onCancel={() => setVehicleEditorVisible(false)}
                        onSave={onVehicleSave}
                    />
                    <ScheduleCalendar
                        visible={calendarVisible}
                        value={scheduledTime}
                        onCancel={() => setCalendarVisible(false)}
                        onOk={time => {
                            setScheduledTime(time);
                            setCalendarVisible(false);
                            form.setFieldsValue({ scheduledTime: dayjs(time).format('MM/DD/YYYY h:mm a') });
                        }}
                    />
                </WindowContainer>
            </SimpleBar>
        </Modal>
    );
};

export default React.memo(NewAppointmentEditor);
