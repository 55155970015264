import dayjs from 'dayjs';

import { gql } from '@apollo/client';

import {
    Customer,
    Direction,
    LeadActivity,
    LeadContactStatus,
    LeadContactType,
    LeadDataObject,
    LeadProspectLevel,
    LeadStatus
} from '../crm/lead/models';

export enum BusinessType {
    Lead,
    Appointment
}
export interface TimelineData {
    id: string;
    type: BusinessType;
    scheduleTime: string;
    time: number;
    title: string;
    content: string;
    contactType?: LeadContactType;
}

export interface LeadStatistics {
    timeLabel: string;
    time: dayjs.Dayjs;
    new: number;
    close: number;
    won: number;
    assigned: number;
    contacted: number;
}
export interface ServiceStatistics {
    timeLabel: string;
    time: dayjs.Dayjs;
    appointments: number;
    repairOrders: number;
    appointmentInRepairOrders: number;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const leadStatisticss: LeadStatistics[] = [
    {
        time: dayjs('2022-03-30'),
        timeLabel: '2022-03-30',
        new: 25,
        close: 33,
        won: 23,
        assigned: 5,
        contacted: 2
    },
    {
        time: dayjs('2022-03-25'),
        timeLabel: '2022-03-25',
        new: 10,
        close: 12,
        won: 8,
        assigned: 5,
        contacted: 2
    },
    {
        time: dayjs('2022-03-26'),
        timeLabel: '2022-03-26',
        new: 8,
        close: 60,
        won: 20,
        assigned: 5,
        contacted: 2
    },
    {
        time: dayjs('2022-03-28'),
        timeLabel: '2022-03-28',
        new: 25,
        close: 3,
        won: 1,
        assigned: 5,
        contacted: 2
    },
    {
        time: dayjs('2022-03-29'),
        timeLabel: '2022-03-29',
        new: 32,
        close: 19,
        won: 11,
        assigned: 5,
        contacted: 2
    },

    {
        time: dayjs('2022-03-31'),
        timeLabel: '2022-03-31',
        new: 50,
        close: 20,
        won: 8,
        assigned: 5,
        contacted: 2
    },
    {
        time: dayjs('2022-03-27'),
        timeLabel: '2022-03-27',
        new: 12,
        close: 12,
        won: 6,
        assigned: 5,
        contacted: 2
    }
];

export interface LeadReportDataObject {
    id: string;
    type: 'record' | 'daily' | 'weekly' | 'monthly' | 'yearly';
    assignee?: string;
    // daily, weekly, monthly
    year?: number;
    month?: number;
    week?: number;
    date?: number;
    new?: number;
    closed?: number;
    won?: number;
    assigned?: number;
    contacted?: number;
}

export interface RepairOrderReportDataObject {
    id: string;
    type: 'record' | 'daily' | 'weekly' | 'monthly' | 'yearly';
    serviceAdvisor?: string;
    // daily, weekly, monthly
    year?: number;
    month?: number;
    week?: number;
    date?: number;
    appointments?: number;
    repairOrders?: number;
    appedRepairOrders?: number;
}

export interface SearchPageArguments {
    page: number;
    pageSize: number;
}

export interface SearchArguments<TFilter> {
    filter?: TFilter;
    sort?: string[];
}

export interface TaskActivity {
    id: string;
    status: LeadStatus;
    source: string;
    summary: string;
    scheduleTime?: string;
    lastContactAt?: number;
    prospectLevel?: LeadProspectLevel;
    loading: boolean;
    contactStatus?: LeadContactStatus;
    createAt: number;
    customer: Customer;
    activities: LeadActivity[];
}

export interface Group {
    id: string;
    title: string;
    tasks: TaskActivity[];
}

export enum InboxType {
    Email,
    SMS
}

export interface Inbox {
    id: string;
    type: InboxType;
    from: string;
    to: string;
    direction: Direction;
    message: string;
    sendAt: number;
    unread: boolean;
    lead?: LeadDataObject;
}

export interface InboxDataObject extends Inbox {
    _dataStatus: number;
    _createAt?: number;
    _updateAt?: number;
}

export const SEARCH_REPAIRORDERS = gql`
    query searchRepairOrders($filter: RepairOrderSearchInput, $sort: [String], $page: Int, $pageSize: Int) {
        searchRepairOrders(filter: $filter, sort: $sort, page: $page, pageSize: $pageSize) {
            total
            data {
                id
                customer {
                    id
                    type
                    name {
                        lastName
                        firstName
                        companyName
                    }
                }
                vehicle {
                    vin
                    year
                    mileage
                    model {
                        make
                        name
                    }
                }
                team {
                    name
                }
                laborAmount
                partsAmount
                appointment
                status
                _createAt
                _updateAt
            }
        }
    }
`;

export const SEARCH_LEAD_REPORTS = gql`
    query searchLeadReports($filter: LeadReportSearchInput) {
        searchLeadReports(filter: $filter, sort: [], page: 1, pageSize: -1) {
            total
            data {
                id
                type
                assignee
                year
                month
                week
                date
                new
                closed
                won
                assigned
                contacted
                isTotal
            }
        }
    }
`;

export const SEARCH_REPAIRORDER_REPORTS = gql`
    query SearchRepairOrderReports($filter: RepairOrderReportSearchInput) {
        searchRepairOrderReports(filter: $filter, sort: [], page: 1, pageSize: -1) {
            total
            data {
                id
                type
                serviceAdvisor
                year
                month
                week
                date
                appointments
                repairOrders
                appedRepairOrders
            }
        }
    }
`;
