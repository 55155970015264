import type { Customer, CustomerInput } from './Customer';
import type { Employee } from './Employee';
import type { Entity } from './Entity';

export enum LeadSourceType {
    Unknown = 0,
    WalkIn = 1,
    Website = 2,
    CallCenter = 3,
    Dealer = 4,
    DigitalAd = 5,
    Exhibition = 6,
    App = 7
}

export enum LeadStatus {
    New = 0,
    Assigned = 1,
    Working = 2,
    Appointment = 3,
    Defeated = 4,
    Sold = 5
}

export enum LeadProspectLevel {
    High = 0,
    Neutral = 1,
    Low = 2
}

export enum LeadActivityStatus {
    Scheduled = 0,
    Completed = 1,
    Abandoned = -1
}

export enum LeadContactType {
    Phone = 0,
    Mail = 1,
    Text = 2,
    Appointment = 3
}

export interface LeadTradeIn {
    year?: number;
    make?: string;
    model?: string;
    mileage?: number;
    vin?: string;
}

export interface LeadOpportunity {
    model?: string;
    color?: string;
    testDrived?: boolean;
    demonstrated?: boolean;
    lookingForUsed?: boolean;
    prospectLevel?: LeadProspectLevel;
    comment?: string;
}

/** 跟进活动 */
export interface LeadActivity {
    id?: string;
    name: string;
    /** 活动状态 */
    activityStatus: LeadActivityStatus;
    /** 联系方式 */
    contactType: LeadContactType;
    automatic?: boolean;
    /** 任务时间 */
    taskTime?: string;
    /** 内容 */
    comment?: string;
    /** 完成时间 */
    completeTime?: string;
    /** 完成员工id */
    completeById?: string;
    /** 完成员工 */
    completeBy?: Employee;
    /** 预约时间 */
    appointmentTime?: string;
    /** 实际到店时间 */
    actualVisitTime?: string;
    type?: string;
    /** 潜在机会 */
    opportunity?: LeadOpportunity;
}

/** 线索输入数据 */
export interface LeadInput {
    /** 客户数据 */
    customerInput: CustomerInput;
    /** 线索来源类型 */
    sourceType: LeadSourceType;
    /** 线索详情 */
    sourceDetails?: string;
    /** 分配人id */
    assignedSalespersonId?: string;
    /** 原因 */
    reason?: string;
    /** 潜在机会 */
    opportunity?: LeadOpportunity;
    /** 二手车 */
    tradeIn?: LeadTradeIn[];
    /** 跟进活动 */
    activities?: LeadActivity[];
}

export interface Lead extends Entity, LeadInput {
    /** 线索状态 */
    leadStatus: LeadStatus;
    /** 分配员工 */
    assignedSalesperson?: Employee;
    /** 分配时间 */
    assignedTime?: string;
    /** 客户 */
    customer: Customer;
}
