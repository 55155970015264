import { gql } from '@apollo/client';

export const SEARCH_USERS = gql`
    query SearchUsers($filter: UserSearchInput, $sort: [String], $page: Int, $pageSize: Int) {
        searchUsers(filter: $filter, sort: $sort, page: $page, pageSize: $pageSize) {
            total
            data {
                id
                firstName
                lastName
                email
                phone
                accountStatus
            }
        }
    }
`;

export const ABANDON_TEAM = gql`
    mutation DeleteTeam($id: ID!) {
        deleteTeam(id: $id)
    }
`;
