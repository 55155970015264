import { message, notification, Button, Col, Form, Input, Modal, Popconfirm, Row, Space, Spin } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { isEmpty, isNil, pickBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import { CloseOutlined, DeleteOutlined, EditOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';

import { DatePicker } from '../components';
import { useCreate, useDelete, useUpdate } from '../hooks';
import {
    CREATE_TASK_REMINDER,
    DELETE_TASK_REMINDER,
    MARK_COMPLETED_TASK_REMINDER,
    MARK_UNCOMPLETED_TASK_REMINDER,
    UPDATE_TASK_REMINDER
} from './graphql';
import { TaskReminderBusinessType, TaskReminderCreateInput, TaskReminderObject, TaskReminderStatus, TaskReminderType } from './models';
import styles from './styles.module.scss';

interface Props {
    mode: 'New' | 'Edit' | 'Read';
    taskReminder?: TaskReminderObject;
    taskRemindersPerDay: TaskReminderObject[];
    index: number;
    selectorVisible: boolean;
    selectValue: Dayjs;
    onCancel: () => void;
    callback: (taskReminder: TaskReminderObject) => void;
}

const TaskReminderModal = ({ mode, taskReminder, taskRemindersPerDay, index, selectorVisible, selectValue, onCancel, callback }: Props) => {
    const [form] = Form.useForm();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [createTaskReminder] = useCreate(CREATE_TASK_REMINDER);
    const [updateTaskReminder] = useUpdate(UPDATE_TASK_REMINDER);
    const [deleteTaskReminder] = useDelete(DELETE_TASK_REMINDER);
    const [markCompletedTaskReminder] = useDelete(MARK_COMPLETED_TASK_REMINDER);
    const [markUncompletedTaskReminder] = useDelete(MARK_UNCOMPLETED_TASK_REMINDER);
    const [currentTaskReminder, setCurrentTaskReminder] = useState<TaskReminderObject>();
    const [currentIndex, setCurrentIndex] = useState<number>(index);
    const [formMode, setFormMode] = useState<string>(mode);

    useEffect(() => {
        const _scheduleTime = selectValue != null ? selectValue : null;
        if (mode === 'New') {
            form.resetFields();
            setCurrentTaskReminder(undefined);
            form.setFieldsValue({ type: TaskReminderType.Task, scheduleTime: _scheduleTime });
        } else if (['Edit', 'Read'].includes(mode) && taskReminder?.id != null) {
            form.setFieldsValue(Object.assign({}, taskReminder, { scheduleTime: dayjs(taskReminder.scheduleTime) }));
            setCurrentTaskReminder(taskReminder);
            // setCurrentIndex(taskRemindersPerDay.findIndex(task => task.id === taskReminder?.id));
            // setCurrentIndex(index);
        }
        setFormMode(mode);
    }, [form, mode, selectValue, taskReminder, selectorVisible, index]);

    useEffect(() => {
        if (selectorVisible) setCurrentIndex(index);
    }, [index, selectorVisible]);

    useEffect(() => {
        if (currentIndex === -1) return;
        const _taskReminder = taskRemindersPerDay[currentIndex];
        setCurrentTaskReminder(_taskReminder);
        form.setFieldsValue(Object.assign({}, _taskReminder, { scheduleTime: dayjs(_taskReminder.scheduleTime) }));
    }, [currentIndex, form, taskRemindersPerDay]);

    const onClose = () => {
        reset();
        if (typeof onCancel === 'function') onCancel();
    };

    const onSave = async () => {
        try {
            setIsSubmitting(true);
            let values = null;
            let input = null;
            let result = null;
            let msg = '';

            if (formMode === 'New') {
                msg = 'Task created';
                values = await form.validateFields();
                input = Object.assign(
                    {},
                    pickBy(values, v => !isNil(v) && v !== ''),
                    { allDay: false, type: TaskReminderType.Task, timezone: dayjs.tz.guess(), scheduleTime: values.scheduleTime.valueOf() } // #TODO 暂未实现
                ) as TaskReminderCreateInput;

                if (input.type === TaskReminderType.Reminder) msg = 'Reminder created on ' + dayjs(input.scheduleTime).format('MMM D.');
                result = await createTaskReminder(input);
            } else if (formMode === 'Edit') {
                msg = 'Task updated';
                values = await form.validateFields();
                input = Object.assign(
                    {},
                    pickBy(values, v => !isNil(v) && v !== ''),
                    { allDay: false, timezone: dayjs.tz.guess(), scheduleTime: values.scheduleTime.valueOf() } // #TODO 暂未实现
                ) as TaskReminderCreateInput;

                if (currentTaskReminder?.type === TaskReminderType.Reminder) msg = 'Reminder updated';
                result = await updateTaskReminder(currentTaskReminder?.id as string, input);
            } else if (formMode === 'Read') {
                if (currentTaskReminder == null) return;
                if (currentTaskReminder?.status === TaskReminderStatus.InProgress) {
                    msg = 'Task completed';
                    if (currentTaskReminder.type === TaskReminderType.Reminder) msg = 'Reminder marked done.';
                    result = await markCompletedTaskReminder(currentTaskReminder.id);
                } else {
                    msg = 'Task uncompleted';
                    result = await markUncompletedTaskReminder(currentTaskReminder.id);
                }
            }
            message.success(msg);
            if (typeof callback === 'function') result && callback(result);
            if (typeof onCancel === 'function') onCancel();
            reset();
        } catch (error) {
            if (error?.errorFields && Array.isArray(error.errorFields)) {
            } else {
                notification.error({ duration: 0, message: 'Something went wrong', description: error.message });
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    const reset = () => {
        form.resetFields();
        setCurrentTaskReminder(undefined);
        setCurrentIndex(-1);
    };

    const onDelete = async () => {
        if (currentTaskReminder == null) return;

        try {
            setIsSubmitting(true);
            const result = await deleteTaskReminder(currentTaskReminder.id);
            const msg = currentTaskReminder.type === TaskReminderType.Task ? 'Task deleted' : 'Reminder deleted';
            message.success(msg);
            if (typeof callback === 'function') result && callback(result);
            if (typeof onCancel === 'function') onCancel();
            reset();
        } catch (error) {
            if (error?.errorFields && Array.isArray(error.errorFields)) {
            } else {
                notification.error({ duration: 0, message: 'Something went wrong', description: error.message });
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    const previous = () => {
        const index = currentIndex - 1;
        setCurrentIndex(index < 0 ? 0 : index);
    };

    const next = () => {
        const index = currentIndex + 1;
        setCurrentIndex(index === taskRemindersPerDay.length ? taskRemindersPerDay.length - 1 : index);
    };

    const goEdit = () => {
        setFormMode('Edit');
    };

    const buildOkText = useCallback(() => {
        let okText = 'Save';
        if (formMode === 'Edit') okText = 'Save';
        if (
            formMode === 'Read' &&
            currentTaskReminder?.type === TaskReminderType.Task &&
            currentTaskReminder.status === TaskReminderStatus.InProgress
        )
            okText = 'Mark Completed';
        if (
            formMode === 'Read' &&
            currentTaskReminder?.type === TaskReminderType.Task &&
            currentTaskReminder.status === TaskReminderStatus.Completed
        )
            okText = 'Reset as Uncompleted';
        if (formMode === 'Read' && currentTaskReminder?.type === TaskReminderType.Reminder) okText = 'Mark as done';
        return okText;
    }, [formMode, currentTaskReminder]);

    const customModalHeader = () => {
        let title = 'Task';
        if (formMode !== 'New' && currentTaskReminder?.type === TaskReminderType.Task) title = 'Task';
        if (formMode !== 'New' && currentTaskReminder?.type === TaskReminderType.Reminder) title = 'Reminder';
        return (
            <Row>
                <Col span={12}>{title}</Col>
                <Col span={6}></Col>
                <Col span={6}>
                    {formMode !== 'New' ? (
                        <Space>
                            <div style={{ marginLeft: 50, zIndex: 99 }}>
                                {formMode === 'Read' ? (
                                    <span className={styles.antModalClose} style={{ marginRight: 8 }}>
                                        <EditOutlined onClick={() => goEdit()} />
                                    </span>
                                ) : (
                                    //TODO 进入编辑模式时 隐藏编辑图标，目前的处理方式不太好，待后续优化
                                    <span style={{ marginRight: 8 }}>
                                        <EditOutlined style={{ opacity: 0 }} />
                                    </span>
                                )}

                                <span className={styles.antModalClose} style={{ marginRight: 12 }}>
                                    <Popconfirm
                                        style={{ zIndex: 9999 }}
                                        placement="right"
                                        title={`Delete this ${currentTaskReminder?.type === TaskReminderType.Task ? 'task' : 'reminder'}?`}
                                        okText="Yes"
                                        cancelText="No"
                                        onConfirm={onDelete}
                                    >
                                        <DeleteOutlined />
                                    </Popconfirm>
                                </span>

                                <span className={styles.antModalClose}>
                                    <CloseOutlined onClick={onClose} />
                                </span>
                            </div>
                        </Space>
                    ) : (
                        <div style={{ marginLeft: 102, zIndex: 99 }}>
                            <span className={styles.antModalClose}>
                                <CloseOutlined
                                    onClick={() => {
                                        if (typeof onCancel === 'function') onCancel();
                                    }}
                                />
                            </span>
                        </div>
                    )}
                </Col>
            </Row>
        );
    };

    const customModalFooter = () => {
        return (
            <Row>
                <Col span={12}>
                    {/* {taskRemindersPerDay.length > 1 && (
                        <Space size={20} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <Button type="primary" icon={<ArrowLeftOutlined />} onClick={previous} disabled={currentIndex === 0}></Button>
                            <Button
                                type="primary"
                                icon={<ArrowRightOutlined />}
                                onClick={next}
                                disabled={currentIndex === taskRemindersPerDay.length - 1}
                            ></Button>
                        </Space>
                    )} */}
                </Col>
                <Col span={12}>
                    <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {buildOkText() === 'Save' ? (
                            <>
                                <Button onClick={() => setFormMode('Read')}>Cancel</Button>
                                <Button type="primary" onClick={onSave}>
                                    {buildOkText()}
                                </Button>
                            </>
                        ) : (
                            <Button onClick={onSave}>{buildOkText()}</Button>
                        )}
                    </Space>
                </Col>
            </Row>
        );
    };

    const buildNewForm = () => {
        return (
            <>
                <Row>
                    <Col span={24}>
                        <Form.Item label="Title" name="title" rules={[{ required: true }]}>
                            <Input placeholder="title" />
                        </Form.Item>
                    </Col>
                </Row>
                {/* {mode === 'New' && (
                    <Row>
                        <Col span={24}>
                            <Form.Item label="Type" name="type">
                                <Radio.Group>
                                    {Object.keys(TaskReminderType)
                                        .map(key => Number(key))
                                        .filter(value => !isNaN(value))
                                        .map(value => (
                                            <Radio key={value} value={Number(value)}>
                                                {TaskReminderType[Number(value)]}
                                            </Radio>
                                        ))}
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                )} */}

                <Row>
                    <Col span={24}>
                        <Form.Item label="Schedule" name="scheduleTime">
                            <DatePicker format="M/D/YYYY h:mm a" minuteStep={15} showTime={true} allowClear={false} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item noStyle shouldUpdate={(prev, current) => prev.type !== current.type}>
                            {({ getFieldValue }) =>
                                getFieldValue('type') === TaskReminderType.Task ? (
                                    <Form.Item name="description" label="Description">
                                        <Input.TextArea rows={3} />
                                    </Form.Item>
                                ) : null
                            }
                        </Form.Item>
                    </Col>
                </Row>
            </>
        );
    };

    const buildReadForm = () => {
        if (currentTaskReminder == null) return null;
        let url = '/crm/lead';
        if (currentTaskReminder?.businessType === TaskReminderBusinessType.ServiceAppointment) url = '/service/appointment';
        if (currentTaskReminder?.businessType === TaskReminderBusinessType.RepairOrder) url = '/service/repairOrder';
        return (
            <>
                <Row>
                    <Col span={24}>
                        <Form.Item label="Title" rules={[{ required: true }]}>
                            {currentTaskReminder.title}
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        {/* Sat, Sep. 10 2022 */}
                        <Form.Item label="Schedule">{dayjs(currentTaskReminder.scheduleTime).format('ddd, MMM. D YYYY h:mm A')}</Form.Item>
                    </Col>
                </Row>
                {currentTaskReminder.type === TaskReminderType.Task && (
                    <Row>
                        <Col span={24}>
                            <Form.Item label="Description">{currentTaskReminder.description}</Form.Item>
                        </Col>
                    </Row>
                )}
                {currentTaskReminder.businessId != null && currentTaskReminder.businessType != null && (
                    <Row>
                        <Col span={24}>
                            <Form.Item label="Source">
                                <a
                                    style={{ color: '#2a7fff' }}
                                    target="_blank"
                                    href={`${url}/${currentTaskReminder.businessId.split('#activity#')[0]}`}
                                    rel="noopener noreferrer"
                                >
                                    {!isEmpty(currentTaskReminder.businessTitle) ? currentTaskReminder.businessTitle : 'Lead Details'}
                                </a>
                            </Form.Item>
                        </Col>
                    </Row>
                )}
            </>
        );
    };

    return (
        <>
            <Modal
                title={currentTaskReminder?.businessId != null ? 'Task' : customModalHeader()}
                visible={selectorVisible}
                closable={currentTaskReminder?.businessId != null ? true : false}
                onOk={onSave}
                onCancel={onClose}
                cancelButtonProps={{ style: { display: 'none' } }}
                // okText={buildOkText()}
                style={{ minHeight: '500px' }}
                destroyOnClose={true}
                footer={currentTaskReminder?.businessId != null ? false : customModalFooter()}
                centered
            >
                <Spin spinning={isSubmitting}>
                    <Form form={form} labelCol={{ span: 5 }}>
                        {formMode === 'Read' ? (
                            <>
                                {currentIndex > 0 && (
                                    <a>
                                        <div
                                            className={styles.leftPrevious}
                                            style={{
                                                position: 'absolute',
                                                left: 0,
                                                marginLeft: -20,
                                                height: '100%',
                                                display: 'flex',
                                                flexDirection: 'column'
                                            }}
                                            onClick={previous}
                                        >
                                            <div style={{ height: 60 }}></div>
                                            <LeftOutlined />
                                        </div>
                                    </a>
                                )}

                                {buildReadForm()}
                                {!(currentIndex === taskRemindersPerDay.length - 1) && (
                                    <a>
                                        <div
                                            className={styles.rightNext}
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                right: 0,
                                                marginRight: -20,
                                                height: '100%',
                                                display: 'flex',
                                                flexDirection: 'column'
                                            }}
                                            onClick={next}
                                        >
                                            <div style={{ height: 60 }}></div>
                                            <RightOutlined />
                                        </div>
                                    </a>
                                )}
                            </>
                        ) : (
                            <Col span={24}> {buildNewForm()}</Col>
                        )}
                    </Form>
                </Spin>
            </Modal>
        </>
    );
};

export default TaskReminderModal;
