import React from 'react';
import { useParams } from 'react-router-dom';

const Search = React.lazy(() => import('./SearchCustomer'));
const NewCustomer = React.lazy(() => import('./NewCustomer'));
const EditCustomer = React.lazy(() => import('./EditCustomer'));
const VehicleAdditionalCharge = () => {
    const { id } = useParams();
    if (id == null) return <Search />;
    if (id === 'new') return <NewCustomer />;
    return <EditCustomer id={id} />;
};

export default VehicleAdditionalCharge;
