import type { ObjectBase } from './Entity';

export enum ServiceOperationType {
    Maintenance = 0,
    Repair = 1
}

export enum ServiceOperationCategory {
    MaintenanceOilChange = 0,
    MaintenanceSynthetic = 1,
    MaintenanceConventional = 2,
    RepairDiagnostics = 3,
    RepairEngineRepairs = 4,
    RepairBrakes = 5,
    RepairACSystem = 6,
    RepairPowerSteering = 7,
    RepairSuspension = 8,
    RepairElectronicItems = 9,
    RepairEngineTransMajor = 10,
    RepairInteriorTrimMould = 11,
    RecommendedOEM = 12,
    RecommendedDealer = 13
}

/** 配件 */
export interface ServiceOperationPart {
    /** 配件编码 */
    code: string;
    /** 数量 */
    quantity: number;
    /** 价格 */
    salePrice: number;
    /** 描述 */
    description?: string;
}

export interface ServiceOperation extends ObjectBase {
    itemId?: string;
    code: string;
    description?: string;
    /** 类型 */
    type?: ServiceOperationType;
    /** 分类 */
    category?: ServiceOperationCategory;
    /** 工时 */
    hours: number;
    /** 配件费用 */
    partsAmount: number;
    /** 人工费用 */
    laborAmount: number;
    /** 成本 */
    costRate: number;
    /** 配件 */
    parts?: Array<ServiceOperationPart>;
}
