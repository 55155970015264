import React from 'react';
import { useParams } from 'react-router-dom';

const Search = React.lazy(() => import('./SearchVehicleAdditionalCharge'));
const NewVehicleAdditionalCharge = React.lazy(() => import('./NewVehicleAdditionalCharge'));
const EditVehicleAdditionalCharge = React.lazy(() => import('./EditVehicleAdditionalCharge'));
const VehicleAdditionalCharge = () => {
    const { id } = useParams();
    if (id == null) return <Search />;
    if (id === 'new') return <NewVehicleAdditionalCharge />;
    return <EditVehicleAdditionalCharge id={id} />;
};

export default VehicleAdditionalCharge;
