import type { DealAdditional } from './Deal';
import type { Entity } from './Entity';

export enum FrontBackFlag {
    Front = 'F',
    Back = 'B'
}

export enum HardSoftFlag {
    Hard = 'H',
    Soft = 'S'
}

/** 车辆的附加销售项目，只读型基础数据 */
export interface VehicleAccessoryInput
    extends Omit<
        DealAdditional,
        | 'category'
        | 'contractCompanyName'
        | 'contractDeductible'
        | 'contractId'
        | 'contractRate'
        | 'contractDeductible'
        | 'contractRateTable'
        | 'contractTermMileage'
        | 'term'
        | 'texasGapFlag'
        | 'totalContractAmount'
        | 'contractType'
        | 'contractTypeCode'
    > {
    /** 维修时可以看到 */
    serviceVisible: boolean;
}

export interface VehicleAccessory extends VehicleAccessoryInput, Entity {}
