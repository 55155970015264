import { Form, Input, Modal, Radio, Switch } from 'antd';
import { isNil, pickBy } from 'lodash';
import { useEffect, useState } from 'react';

import { WindowContainer } from '../../components';
import { LeadCloseInput, LeadStatus } from '../models';

interface Props {
    visible: boolean;
    id: string;
    onCancel: () => void;
    onSave: (id: string, data: LeadCloseInput) => Promise<void>;
}

const CloseLead = ({ visible, id, onCancel, onSave }: Props) => {
    const [isWorking, setIsWorking] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        if (visible) form.resetFields();
    }, [form, visible]);

    const cancel = () => {
        if (!isWorking) onCancel();
    };
    const onOk = async () => {
        try {
            setIsWorking(true);
            const values = (await form.validateFields()) as LeadCloseInput;
            const result = pickBy(values, v => !isNil(v)) as LeadCloseInput;
            result.lost = form.getFieldValue('lost') === LeadStatus.Lost ? true : false;
            await onSave(id, result);
        } finally {
            // form.resetFields();
            setIsWorking(false);
        }
    };
    return (
        <Modal
            title="Close Lead"
            confirmLoading={isWorking}
            visible={visible}
            onCancel={cancel}
            onOk={onOk}
            width={500}
            className="form"
            okText="Submit"
            cancelButtonProps={{ type: 'link' }}
            maskClosable={false}
            forceRender
        >
            <WindowContainer>
                <Form form={form} labelCol={{ span: 6 }}>
                    <Form.Item
                        name="lost"
                        label="Result"
                        rules={[{ required: true, message: 'Result is required' }]}
                        initialValue={LeadStatus.Converted}
                    >
                        <Radio.Group buttonStyle="solid">
                            <Radio.Button value={LeadStatus.Converted}>{LeadStatus[LeadStatus.Converted]}</Radio.Button>
                            <Radio.Button value={LeadStatus.Lost}>{LeadStatus[LeadStatus.Lost]}</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item noStyle shouldUpdate>
                        {({ getFieldValue }) =>
                            getFieldValue('lost') === LeadStatus.Lost ? (
                                <Form.Item name="reason" label="Reason" rules={[{ required: true, whitespace: true }]}>
                                    <Input.TextArea rows={3} />
                                </Form.Item>
                            ) : null
                        }
                    </Form.Item>
                    <Form.Item noStyle shouldUpdate>
                        {({ getFieldValue }) =>
                            getFieldValue('lost') === LeadStatus.Converted ? (
                                <Form.Item name={['createDeal']} label="Create deal" valuePropName="checked">
                                    <Switch />
                                </Form.Item>
                            ) : null
                        }
                    </Form.Item>
                </Form>
            </WindowContainer>
        </Modal>
    );
};

export default CloseLead;
