import { Col, Form, Input, InputNumber, Modal, Row, Switch } from 'antd';
import React, { useEffect, useState } from 'react';

import { EnumSelect } from '../../../components';
import { DealAdditionalProfitTo, FrontBackFlag, HardSoftFlag, VehicleAccessory, VehicleAccessoryInput } from '../../../models';
import { WindowContainer } from '../components';

interface Props {
    visible: boolean;
    data?: Readonly<VehicleAccessory>;
    onCancel: () => void;
    onSave: (data: VehicleAccessoryInput, id?: string) => Promise<void>;
}

const emptyData: VehicleAccessoryInput = {
    code: '',
    cost: 0,
    retail: 0,
    residual: 0,
    profitTo: DealAdditionalProfitTo.Gross,
    taxable: false,
    financed: false,
    serviceVisible: false,
    frontBackFlag: FrontBackFlag.Front,
    hardSoftFlag: HardSoftFlag.Hard,
    residualTableFlag: false,
    disclosureType: '',
    dmvFlag: false,
    tax1Flag: false,
    tax2Flag: false,
    tax3Flag: false,
    tax4Flag: false
};

const VehicleAccessoryEditor: React.FC<Props> = props => {
    const id = props.data?.id;
    const initialValues = props.data || emptyData;

    const [isWorking, setIsWorking] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        if (props.visible) form.resetFields();
    }, [form, initialValues, props.visible]);

    const onCancel = () => {
        if (!isWorking) props.onCancel();
    };
    const onOk = async () => {
        try {
            setIsWorking(true);
            const values = (await form.validateFields()) as VehicleAccessoryInput;
            //console.log(values);
            await props.onSave(values, id);
        } catch (err) {
            console.error(err);
        } finally {
            setIsWorking(false);
        }
    };

    return (
        <Modal
            title={`${id == null ? 'New' : 'Edit'} Accessory`}
            confirmLoading={isWorking}
            visible={props.visible}
            onCancel={onCancel}
            onOk={onOk}
            width={600}
            className="form"
            okText="Submit"
            cancelButtonProps={{ type: 'link' }}
            maskClosable={false}
            forceRender
        >
            <WindowContainer>
                <Form form={form} initialValues={initialValues} labelCol={{ span: 6 }}>
                    <Form.Item name="code" label="Code" rules={[{ required: true, whitespace: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="description" label="Description">
                        <Input />
                    </Form.Item>
                    <Row gutter={4}>
                        <Col span={12}>
                            <Form.Item
                                name="cost"
                                label="Cost"
                                rules={[{ type: 'number', required: true, min: 0 }]}
                                labelCol={{ span: 12 }}
                            >
                                <InputNumber style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="retail"
                                label="Retail"
                                rules={[{ type: 'number', required: true, min: 0 }]}
                                labelCol={{ span: 12 }}
                            >
                                <InputNumber style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="residual"
                                label="Residual"
                                rules={[{ required: true }]}
                                labelCol={{ span: 12 }}
                            >
                                <InputNumber style={{ width: '100%' }} min={0} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="profitTo" label="Profit to" rules={[{ required: true }]}>
                                <EnumSelect enum={DealAdditionalProfitTo} />
                            </Form.Item>
                        </Col>
                        <Col span={10}>
                            <Form.Item name="taxable" label="Taxable" valuePropName="checked" labelCol={{ span: 15 }}>
                                <Switch />
                            </Form.Item>
                        </Col>
                        <Col span={7}>
                            <Form.Item name="financed" label="Financed" valuePropName="checked" labelCol={{ span: 14 }}>
                                <Switch />
                            </Form.Item>
                        </Col>
                        <Col span={7}>
                            <Form.Item
                                name="serviceVisible"
                                label="Service"
                                valuePropName="checked"
                                labelCol={{ span: 14 }}
                            >
                                <Switch />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="frontBackFlag" label="Front back" labelCol={{ span: 12 }}>
                                <EnumSelect enum={FrontBackFlag} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="hardSoftFlag" label="Hard soft" labelCol={{ span: 12 }}>
                                <EnumSelect enum={HardSoftFlag} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="disclosureType" label="Disclosure" labelCol={{ span: 6 }}>
                                <Input style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={10}>
                            <Form.Item
                                name="residualTableFlag"
                                label="Residual table"
                                valuePropName="checked"
                                labelCol={{ span: 15 }}
                                initialValue={false}
                            >
                                <Switch />
                            </Form.Item>
                        </Col>
                        <Col span={7}>
                            <Form.Item
                                name="dmvFlag"
                                label="Dmv flag"
                                valuePropName="checked"
                                labelCol={{ span: 14 }}
                                initialValue={false}
                            >
                                <Switch />
                            </Form.Item>
                        </Col>
                        <Col span={7}>
                            <Form.Item
                                name="tax1Flag"
                                label="tax1Flag"
                                valuePropName="checked"
                                initialValue={false}
                                labelCol={{ span: 14 }}
                            >
                                <Switch />
                            </Form.Item>
                        </Col>
                        <Col span={10}>
                            <Form.Item
                                name="tax2Flag"
                                label="tax2Flag"
                                valuePropName="checked"
                                labelCol={{ span: 15 }}
                                initialValue={false}
                            >
                                <Switch />
                            </Form.Item>
                        </Col>
                        <Col span={7}>
                            <Form.Item
                                name="tax3Flag"
                                label="tax3Flag"
                                valuePropName="checked"
                                labelCol={{ span: 14 }}
                                initialValue={false}
                            >
                                <Switch />
                            </Form.Item>
                        </Col>
                        <Col span={7}>
                            <Form.Item
                                name="tax4Flag"
                                label="tax4Flag"
                                valuePropName="checked"
                                initialValue={false}
                                labelCol={{ span: 14 }}
                            >
                                <Switch />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item name="notes" label="Notes">
                        <Input.TextArea rows={3} />
                    </Form.Item>
                </Form>
            </WindowContainer>
        </Modal>
    );
};

export default React.memo(VehicleAccessoryEditor);
