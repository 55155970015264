import React from 'react';
import { useParams } from 'react-router-dom';

const Search = React.lazy(() => import('./SearchTeam'));
const New = React.lazy(() => import('./NewTeam'));
const Edit = React.lazy(() => import('./EditTeam'));

const Team = () => {
    const { id } = useParams();

    if (id == null) return <Search />;
    if (id === 'new') return <New />;

    return <Edit id={id} />;
};

export default Team;
