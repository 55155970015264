import { Input, InputProps } from 'antd';
import currency from 'currency.js';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';

const money = (value?: currency.Any) => currency(value || 0, { precision: 8 });

export default function InputPercent(props: InputProps & { onValueChange?: (value?: string) => void }) {
    const { value, onChange, onValueChange, ...rest } = props;
    const [controlledValue, setControlledValue] = useState<InputProps['value']>();

    useEffect(() => {
        setControlledValue(controlledValue => {
            if (isEmpty(value) || isNaN(Number(value))) return '0';
            const newValue = money(value?.toString()).multiply(100).value.toString();
            return controlledValue === newValue ? controlledValue : newValue;
        });
    }, [value]);

    const onInputChange: InputProps['onChange'] = e => {
        const target = e.currentTarget;
        setControlledValue(target.value);
        if (typeof onValueChange === 'function' && target.validity.valid && !isNaN(target.valueAsNumber)) {
            const newValue = money(target.value).divide(100).value.toString();
            if (newValue !== value) onValueChange(newValue);
        }
        if (typeof onChange === 'function') onChange(e);
    };

    return <Input step={0.01} min={0} {...rest} type="number" suffix="%" value={controlledValue} onChange={onInputChange} />;
}
