import { Select, Space } from 'antd';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { CustomerName, Loading } from '../../../components';
import { useFilters, usePaging, useSearchData } from '../../../hooks';
import { Customer } from '../../customers/types';

interface Props {
    timestamp?: number;
    value?: Customer;
    onChange?: (value: Customer) => void;
    category?: number;
}

const useCustomerSearch = (timestamp?: number, category?: number): [Customer[], boolean, (value: string) => void] => {
    const [filters, setFilter] = useFilters(undefined, { category });
    const [paging] = usePaging(30);
    const [result, isWorking, reload] = useSearchData<Customer>('customer', filters, paging);
    const customers = useMemo(() => (isWorking ? [] : result.data), [result, isWorking]);
    const setKeyword = useCallback(
        (value: string) => {
            value = value.trim();
            setFilter('$keyword', value === '' ? undefined : value);
        },
        [setFilter]
    );
    useEffect(() => {
        if (timestamp !== undefined) reload();
    }, [timestamp, reload]);
    return [customers, isWorking, setKeyword];
};

const CustomerSearcher: React.FC<Props> = React.forwardRef<any, Props>((props, ref) => {
    const [customers, isWorking, setKeyword] = useCustomerSearch(props.timestamp, props.category);
    const [customerId, setCustomerId] = useState<string>();
    const onChange = (value: string) => {
        setCustomerId(value);
        const customer = customers.find(customer => customer.id === value);
        if (customer != null && typeof props.onChange === 'function') props.onChange(customer);
    };
    useMemo(() => {
        setCustomerId(props.value?.id);
    }, [props.value]);

    return (
        <Select
            ref={ref}
            placeholder="Search customer by name, phone, email..."
            value={customerId}
            notFoundContent={isWorking ? <Loading text="Searching..." /> : null}
            onSearch={debounce(setKeyword, 500)}
            onChange={onChange}
            defaultActiveFirstOption={false}
            filterOption={false}
            showSearch
        >
            {customers.map(customer => (
                <Select.Option key={customer.id} value={customer.id}>
                    <Space>
                        <strong>
                            <CustomerName name={customer.name} />
                        </strong>
                        <span>{customer.contactInfo?.cellNumber}</span>
                        <span>{customer.contactInfo?.email}</span>
                    </Space>
                </Select.Option>
            ))}
        </Select>
    );
});

CustomerSearcher.displayName = 'CustomerSearcher';

export default React.memo(CustomerSearcher);
