import type { TablePaginationConfig } from 'antd';

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useImmer } from 'use-immer';

import { useLazyQuery, useMutation, ApolloError, ApolloQueryResult, DocumentNode } from '@apollo/client';

type UseCreate<TInput, TResult> = [(input: TInput) => Promise<any>, boolean, TResult | null | undefined];

type UseUpdate<TInput, TResult> = [
    update: (id: string, input: TInput) => Promise<any>,
    loading: boolean,
    result?: TResult | null,
    error?: ApolloError
];

type UseDelete<TResult> = [del: (id: string) => Promise<any>, loading: boolean, result?: TResult | null, error?: ApolloError];

interface UseSearch<TFilter, TEntity> {
    loading: boolean;
    page: number;
    pageSize: number;
    total: number;
    data: TEntity[];
    pagination: TablePaginationConfig;
    setFilter: <T extends keyof TFilter>(field: T, value: TFilter[T]) => void;
    setSort: (sort: string[]) => void;
    setPage: (page: number) => void;
    setPageSize: (page: number) => void;
    refresh: () => Promise<void>;
}

interface SearchArguments<TFilter> {
    filter?: TFilter;
    sort?: string[];
}

interface SearchPageArguments {
    page: number;
    pageSize: number;
}

export function useGqlGet<T>(
    document: DocumentNode,
    id: string
): [T | undefined, boolean, (variables?: Partial<{ id: string }> | undefined) => Promise<ApolloQueryResult<any>>] {
    const [fetchData, { called, loading, data, refetch }] = useLazyQuery(document, {
        variables: { id },
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache'
    });
    useEffect(() => {
        if (called) {
            if (refetch) refetch({ id });
        } else fetchData({ variables: { id } });
    }, [id, called, fetchData, refetch]);

    const result = (() => {
        if (data) {
            const keys = Object.keys(data);
            if (keys.length > 0) return data[keys[0]];
        }
    })();

    return [result, loading, refetch];
}

export function useSearch<TFilter extends Record<string, any> = any, TEntity = Record<string, any>>(
    document: DocumentNode,
    initialFilter?: TFilter,
    initialSort?: string[],
    initialPageSize = 25
): UseSearch<TFilter, TEntity> {
    const [result, setResult] = useState<[number, any[]]>([0, []]);
    const [args, setArgs] = useImmer<SearchArguments<TFilter>>({ filter: initialFilter, sort: initialSort });
    const [pageArgs, setPageArgs] = useImmer<SearchPageArguments>({ page: 1, pageSize: initialPageSize });
    const [fetchData, { called, loading, data, variables, refetch, fetchMore }] = useLazyQuery(document, {
        variables: Object.assign({ filter: {} }, args, pageArgs),
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
        notifyOnNetworkStatusChange: true
    });

    const dataToResult = (data: Record<string, any>) => {
        if (data) {
            const keys = Object.keys(data);
            if (keys.length > 0 && data[keys[0]]) setResult([data[keys[0]].total, data[keys[0]].data]);
        } else setResult([0, []]);
    };

    useEffect(() => {
        dataToResult(data);
    }, [data]);

    useEffect(() => {
        if (called) {
            if (typeof refetch === 'function') refetch(Object.assign({}, variables, args));
            return;
        }
        (async () => {
            const result = await fetchData({ variables: Object.assign({}, variables, args) });
            dataToResult(result.data);
        })();
    }, [called, args, variables, fetchData, refetch]);

    useEffect(() => {
        (async () => {
            if (typeof fetchMore === 'function') {
                const result = await fetchMore({ variables: Object.assign({}, variables, args, pageArgs) });
                dataToResult(result.data);
            }
        })();
    }, [pageArgs, variables, args, fetchMore]);

    const setFilter = useCallback(
        <T extends keyof TFilter>(field: T, value: TFilter[T]) => {
            setArgs(variables => {
                if (variables.filter == null) variables.filter = { [field]: value } as any;
                else (variables.filter as TFilter)[field] = value;
            });
        },
        [setArgs]
    );

    const setSort = useCallback(
        (sort: string[]) => {
            setArgs(variables => {
                variables.sort = Array.isArray(sort) && sort.length > 0 ? sort : undefined;
            });
        },
        [setArgs]
    );

    const setPage = useCallback(
        (page: number) => {
            setPageArgs(params => {
                params.page = page;
            });
        },
        [setPageArgs]
    );

    const setPageSize = useCallback(
        (pageSize: number) => {
            setPageArgs(params => {
                params.pageSize = pageSize;
            });
        },
        [setPageArgs]
    );

    const refresh = useCallback(async () => {
        if (typeof refetch === 'function') await refetch();
    }, [refetch]);

    const total = result[0];
    const pagination = useMemo<TablePaginationConfig>(() => {
        const onChange = (page: number, pageSize: number) => {
            setPage(page);
            setPageSize(pageSize);
        };
        return {
            current: pageArgs.page,
            pageSize: pageArgs.pageSize,
            total,
            onChange,
            onShowSizeChange: onChange
        };
    }, [pageArgs.page, pageArgs.pageSize, total, setPage, setPageSize]);

    return {
        loading,
        page: pageArgs.page,
        pageSize: pageArgs.pageSize,
        total,
        data: result[1],
        pagination,
        setFilter,
        setSort,
        setPage,
        setPageSize,
        refresh
    };
}

export function useCreate<TInput = Record<string, any>, TResult = any>(document: DocumentNode): UseCreate<TInput, TResult> {
    const [mutateFunction, { data, loading }] = useMutation(document);

    const mutate = useCallback(
        async (input: TInput) => {
            const result = await mutateFunction({ variables: { input } });
            if (result?.data != null) return result.data[Object.keys(result.data)[0]];
        },
        [mutateFunction]
    );

    const result = (() => {
        if (data) {
            const keys = Object.keys(data);
            if (keys.length > 0) return data[keys[0]];
        }
    })();

    return [mutate, loading, result];
}

export function useUpdate<TInput = Record<string, any>, TResult = any>(document: DocumentNode): UseUpdate<TInput, TResult> {
    const [mutateFunction, { data, loading, error }] = useMutation(document);

    const mutate = useCallback(
        async (id: string, input: TInput) => {
            const result = await mutateFunction({ variables: { id, input } });
            if (result?.data != null) return result.data[Object.keys(result.data)[0]];
        },
        [mutateFunction]
    );

    const result = (() => {
        if (data) {
            const keys = Object.keys(data);
            if (keys.length > 0) return data[keys[0]];
        }
    })();

    return [mutate, loading, result, error];
}

export function useDelete<TResult = any>(document: DocumentNode): UseDelete<TResult> {
    const [mutateFunction, { data, loading, error }] = useMutation(document);

    const mutate = useCallback(
        async (id: string) => {
            const result = await mutateFunction({ variables: { id } });
            if (result?.data != null) return result.data[Object.keys(result.data)[0]];
        },
        [mutateFunction]
    );

    const result = (() => {
        if (data) {
            const keys = Object.keys(data);
            if (keys.length > 0) return data[keys[0]];
        }
    })();

    return [mutate, loading, result, error];
}
