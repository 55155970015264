import produce from 'immer';
import set from 'lodash/set';
import { useCallback, useEffect, useState } from 'react';

import { sessionStore } from '../services';
import { Filters } from '../types';

export interface SetFilter {
    (field: string | string[], value: any): void;
}

export default function useFilters(uniqueId?: string, initialValue?: Filters): [Filters, SetFilter, () => void] {
    const [filters, setFilters] = useState<Filters>(
        (uniqueId != null && sessionStore.get(uniqueId)) || initialValue || {}
    );
    const setFilter = useCallback<SetFilter>((field, value) => {
        setFilters(filters =>
            produce(filters, draft => {
                set(draft, field, value);
            })
        );
    }, []);
    const onReset = useCallback(() => {
        setFilters(initialValue || {});
    }, [initialValue]);

    useEffect(() => {
        if (uniqueId != null) sessionStore.set(uniqueId, filters);
    }, [uniqueId, filters]);

    return [filters, setFilter, onReset];
}
