import { Select, Space } from 'antd';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useMemo } from 'react';

import { Loading } from '../../../components';
import { useFilters, usePaging, useSearchData } from '../../../hooks';
import { Vehicle, VehicleInputSource, VehicleVmsStatus } from '../../../models';

interface Props {
    timestamp?: number;
    vmsStatus?: VehicleVmsStatus;
    customerId?: string;
    value?: Vehicle;
    onChange?: (value: Vehicle) => void;
    inputSource?: VehicleInputSource;
}

const useVehicleSearch = (
    vmsStatus?: VehicleVmsStatus,
    timestamp?: number,
    inputSource?: VehicleInputSource
): [Vehicle[], boolean, (value: string) => void] => {
    const [filters, setFilter] = useFilters(undefined, { vmsStatus, inputSource });
    const [paging] = usePaging(30);
    const [result, isWorking, reload] = useSearchData<Vehicle>('vehicle', filters, paging);
    const vehicles = useMemo(() => (isWorking ? [] : result.data), [result, isWorking]);
    const setKeyword = useCallback(
        (value: string) => {
            value = value.trim();
            setFilter('$keyword', value === '' ? undefined : value);
        },
        [setFilter]
    );
    useEffect(() => {
        if (timestamp !== undefined) reload();
    }, [timestamp, reload]);
    return [vehicles, isWorking, setKeyword];
};

const VehicleSearcher: React.FC<Props> = React.forwardRef<any, Props>((props, ref) => {
    const [vehicles, isWorking, setKeyword] = useVehicleSearch(props.vmsStatus, props.timestamp, props.inputSource);
    const onChange = (value: string) => {
        const vehicle = vehicles.find(vehicle => vehicle.id === value);
        if (vehicle != null && typeof props.onChange === 'function') props.onChange(vehicle);
    };
    return (
        <Select
            ref={ref}
            placeholder="Search vehicle by vin, model..."
            value={props.value?.id}
            notFoundContent={isWorking ? <Loading text="Searching..." /> : null}
            onSearch={debounce(setKeyword, 500)}
            onChange={onChange}
            defaultActiveFirstOption={false}
            filterOption={false}
            showSearch
        >
            {vehicles.map(vehicle => (
                <Select.Option key={vehicle.id} value={vehicle.id}>
                    <Space>
                        <strong>{vehicle.vin}</strong>
                        <span>{vehicle.modelYear}</span>
                        <span>{vehicle.model?.fullName}</span>
                    </Space>
                </Select.Option>
            ))}
        </Select>
    );
});

VehicleSearcher.displayName = 'VehicleSearcher';

export default React.memo(VehicleSearcher);
