import { Button, Card, Col, Row, Space } from 'antd';
import { parsePhoneNumber } from 'libphonenumber-js';
import { useRef } from 'react';

import { MessageTwoTone, UserOutlined } from '@ant-design/icons';

import { SimpleBarAny as SimpleBar } from '../../../../components/SimpleBarAny';
import { Customer, CustomerType } from '../models';
import styles from '../styles.module.scss';

interface Props {
    customer?: Customer;
    setSmsModalVisible?: (isVisible: boolean) => void;
    setCallModalVisible?: (isVisible: boolean) => void;
    setEmailModalVisible?: (isVisible: boolean) => void;
}

export const parsePhoneNumberCustom = (value: string) => {
    try {
        const phoneNumber = parsePhoneNumber(value, 'US').formatNational();
        return phoneNumber;
    } catch (error) {
        // console.error(error);
        return value;
    }
};

export const displayCustomer = (customer?: Customer) =>
    customer &&
    (customer.name == null
        ? ''
        : customer.type === CustomerType.Person
        ? `${customer.name.lastName ?? ''}, ${customer.name.firstName ?? ''}`
        : customer.name.companyName ?? '');

const CustomerCard = ({ customer, setSmsModalVisible, setCallModalVisible, setEmailModalVisible }: Props) => {
    const title =
        customer && customer.name != null
            ? customer.name?.type === 1
                ? `${customer.name.companyName ?? ''} (Business)`
                : `${customer.name.lastName ?? ''}, ${customer.name.firstName ?? ''}`
            : 'Customer';

    const getPhoneNumber = (type?: string) => {
        if (customer?.contactInfo == null) return null;
        if (type == null) return customer.contactInfo.phoneNumber ? parsePhoneNumberCustom(customer.contactInfo.phoneNumber.value) : null;
        if (!Array.isArray(customer.contactInfo.phoneNumbers)) return null;
        let phoneNumber = customer.contactInfo.phoneNumbers.find(phoneNumber => phoneNumber.description === type);

        if ('Mobile' === type && phoneNumber == null)
            phoneNumber = customer.contactInfo.phoneNumbers.find(
                phoneNumber =>
                    phoneNumber.description == null ||
                    phoneNumber.description == undefined ||
                    !['Mobile', 'Home', 'Work', 'Pager'].includes(phoneNumber.description)
            );

        return phoneNumber ? parsePhoneNumberCustom(phoneNumber.value) : null;
    };

    const getEmail = () => {
        return customer?.contactInfo?.email?.value ?? null;
    };

    const getAddress = () => {
        if (customer?.contactInfo?.address == null) return null;
        const address = customer?.contactInfo?.address;
        const addresses: string[] = [];
        if (address.mailing || address.street) addresses.push(address.mailing ?? address.street ?? '');
        if (address.zip) addresses.push(address.zip);
        if (address.state && address.city) addresses.push(`${address.city}, ${address.state}`);
        else if (address.city) addresses.push(address.city);
        else if (address.state) addresses.push(address.state);
        if (address.country) addresses.push(address.country);
        return addresses.join(' ');
    };
    const simpleBarRef = useRef<SimpleBar>(null);

    return (
        <Card
            size="small"
            title={
                <Space>
                    <UserOutlined />
                    {title}
                </Space>
            }
            className={styles.card}
        >
            <SimpleBar ref={simpleBarRef} style={{ height: 130 }}>
                {customer ? (
                    customer.type === 1 ? (
                        <Row gutter={[0, 9]}>
                            <Col xxl={12} xl={24}>
                                <span>Contact #: </span>
                                <span>
                                    <Button
                                        type="link"
                                        size="small"
                                        style={{ padding: 0 }}
                                        onClick={() => {
                                            if (typeof setCallModalVisible === 'function') setCallModalVisible(true);
                                        }}
                                    >
                                        <span style={{ color: '#2a7fff' }}>{getPhoneNumber()} </span>
                                    </Button>
                                    <Button
                                        type="link"
                                        size="small"
                                        style={{ padding: 0, marginLeft: 5 }}
                                        onClick={() => {
                                            if (typeof setSmsModalVisible === 'function') setSmsModalVisible(true);
                                        }}
                                    >
                                        <MessageTwoTone twoToneColor="#52c41a" />
                                    </Button>
                                </span>
                            </Col>
                            <Col xxl={12} xl={24}>
                                <span>Email: </span>
                                <span>
                                    {getEmail() && (
                                        <Button
                                            type="link"
                                            size="small"
                                            style={{ padding: 0 }}
                                            onClick={() => {
                                                if (typeof setEmailModalVisible === 'function') setEmailModalVisible(true);
                                            }}
                                        >
                                            <span style={{ color: '#2a7fff' }}>{getEmail()}</span>
                                        </Button>
                                    )}
                                </span>
                            </Col>
                            <Col span={24}>
                                <span>Address: </span>
                                <span>{getAddress()}</span>
                            </Col>
                        </Row>
                    ) : (
                        <Row gutter={[0, 9]}>
                            <Col xxl={12} xl={16}>
                                <div style={{ display: 'flex' }}>
                                    <div>Phone #:</div>
                                    <div style={{ marginLeft: 3 }}>
                                        <Button
                                            type="link"
                                            size="small"
                                            style={{ padding: 0 }}
                                            onClick={() => {
                                                if (typeof setCallModalVisible === 'function') setCallModalVisible(true);
                                            }}
                                        >
                                            <span style={{ color: '#2a7fff' }}>{getPhoneNumber('Mobile')} (Mobile)</span>
                                        </Button>
                                        <Button
                                            type="link"
                                            size="small"
                                            style={{ padding: 0, marginLeft: 5 }}
                                            onClick={() => {
                                                if (typeof setSmsModalVisible === 'function') setSmsModalVisible(true);
                                            }}
                                        >
                                            <MessageTwoTone twoToneColor="#52c41a" />
                                        </Button>
                                        {getPhoneNumber('Work') != null && <div>{getPhoneNumber('Work')} (Work)</div>}
                                        {getPhoneNumber('Work') != null && <div>{getPhoneNumber('Home')} (Home)</div>}
                                    </div>
                                </div>
                            </Col>
                            <Col xxl={12} xl={8}>
                                <span>Gender: </span>
                                <span>{customer.gender}</span>
                            </Col>
                            <Col xxl={12} xl={16}>
                                <span>Email: </span>
                                <span>
                                    {getEmail() && (
                                        <Button
                                            type="link"
                                            size="small"
                                            style={{ padding: 0 }}
                                            onClick={() => {
                                                if (typeof setEmailModalVisible === 'function') setEmailModalVisible(true);
                                            }}
                                        >
                                            <span style={{ color: '#2a7fff' }}>{getEmail()}</span>
                                        </Button>
                                    )}
                                </span>
                            </Col>
                            <Col xxl={12} xl={8}>
                                <span>Birthday: </span>
                                <span>{customer.dob ? customer.dob.split('T')[0] : ''}</span>
                            </Col>
                            <Col span={24}>
                                <span>Address: </span>
                                <span>{getAddress()}</span>
                            </Col>
                        </Row>
                    )
                ) : null}
            </SimpleBar>
        </Card>
    );
};

export default CustomerCard;
