import { Select } from 'antd';
import debounce from 'lodash/debounce';
import React, { useCallback, useMemo } from 'react';

import { Loading } from '../../../components';
import { useFilters, usePaging, useSearchData } from '../../../hooks';
import { VehicleModel } from '../../../models';

interface Props {
    value?: VehicleModel;
    onChange?: (value: VehicleModel) => void;
}

const useVehicleModelSearch = (): [VehicleModel[], boolean, (value: string) => void] => {
    const [filters, setFilter] = useFilters();
    const [paging] = usePaging(30);
    const [result, isWorking] = useSearchData<VehicleModel>('vehicleModel', filters, paging);
    const vehicleModels = useMemo(() => (isWorking ? [] : result.data), [result, isWorking]);
    const setKeyword = useCallback(
        (value: string) => {
            value = value.trim();
            setFilter('$keyword', value === '' ? undefined : value);
        },
        [setFilter]
    );
    return [vehicleModels, isWorking, setKeyword];
};

const VehicleModelSearcher: React.FC<Props> = React.forwardRef<any, Props>((props, ref) => {
    const [vehicleModels, isWorking, setKeyword] = useVehicleModelSearch();
    const onChange = (value: string) => {
        const vehicleModel = vehicleModels.find(vehicleModel => vehicleModel.code === value);
        if (vehicleModel != null && typeof props.onChange === 'function') props.onChange(vehicleModel);
    };
    return (
        <Select
            ref={ref}
            placeholder="Search model by code, name..."
            defaultValue={props.value?.code}
            notFoundContent={isWorking ? <Loading text="Searching..." /> : null}
            onSearch={debounce(setKeyword, 500)}
            onChange={onChange}
            defaultActiveFirstOption={false}
            filterOption={false}
            showSearch
        >
            {vehicleModels.map(vehicleModel => (
                <Select.Option key={vehicleModel.code} value={vehicleModel.code}>
                    <strong>{vehicleModel.fullName || vehicleModel.name}</strong>
                    {` (${vehicleModel.makeName})`}
                </Select.Option>
            ))}
        </Select>
    );
});

VehicleModelSearcher.displayName = 'VehicleModelSearcher';

export default React.memo(VehicleModelSearcher);
