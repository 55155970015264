import { Select, Space } from 'antd';
import debounce from 'lodash/debounce';
import React, { useCallback, useMemo } from 'react';

import { Loading } from '../../../components';
import { useFilters, usePaging, useSearchData } from '../../../hooks';
import { WorkTeam } from '../../../models';

interface Props {
    value?: WorkTeam;
    onChange?: (value: WorkTeam) => void;
}

const useWorkTeamSearch = (): [WorkTeam[], boolean, (value: string) => void] => {
    const [filters, setFilter] = useFilters();
    const [paging] = usePaging(30);
    const [result, isWorking] = useSearchData<WorkTeam>('workTeam', filters, paging);
    const workTeams = useMemo(() => (isWorking ? [] : result.data), [result, isWorking]);
    const setKeyword = useCallback(
        (value: string) => {
            value = value.trim();
            setFilter('$keyword', value === '' ? undefined : value);
        },
        [setFilter]
    );
    return [workTeams, isWorking, setKeyword];
};

const WorkTeamSearcher: React.FC<Props> = React.forwardRef<any, Props>((props, ref) => {
    const [workTeams, isWorking, setKeyword] = useWorkTeamSearch();
    const onChange = (value: string) => {
        const workTeam = workTeams.find(workTeam => workTeam.id === value);

        if (workTeam != null && typeof props.onChange === 'function') props.onChange(workTeam);
    };
    return (
        <Select
            ref={ref}
            placeholder="Search workTeam by code,name..."
            notFoundContent={isWorking ? <Loading text="Searching..." /> : null}
            onSearch={debounce(setKeyword, 500)}
            onChange={onChange}
            defaultActiveFirstOption={false}
            filterOption={false}
            showSearch
        >
            {workTeams.map(workTeam => (
                <Select.Option key={workTeam.id} value={workTeam.id}>
                    <Space>{workTeam.name}</Space>
                </Select.Option>
            ))}
        </Select>
    );
});

WorkTeamSearcher.displayName = 'WorkTeamSearcher';

export default React.memo(WorkTeamSearcher);
