import { Select, Space } from 'antd';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useMemo } from 'react';

import { Loading } from '../../../components';
import { useFilters, usePaging, useSearchData } from '../../../hooks';
import { Vehicle } from '../../../models';

interface Props {
    timestamp?: number;
    value?: Vehicle;
    onChange?: (value: Vehicle) => void;
}

const useVehicleSearch = (
    timestamp?: number
): [Vehicle[], boolean, (value: string) => void] => {
    const [filters, setFilter] = useFilters(undefined);
    const [paging] = usePaging(0);
    const [result, isWorking, reload] = useSearchData<Vehicle>('vehicles/unAppoint', filters, paging);
    const vehicles = useMemo(() => (isWorking ? [] : result), [result, isWorking]) as Vehicle[];
    const setKeyword = useCallback(
        (value: string) => {
            value = value.trim();
            setFilter('$keyword', value === '' ? undefined : value);
        },
        [setFilter]
    );
    useEffect(() => {
        if (timestamp !== undefined) reload();
    }, [timestamp, reload]);
    return [vehicles, isWorking, setKeyword];
};

const UnappointmentedVehicleSearcher: React.FC<Props> = React.forwardRef<any, Props>((props, ref) => {
    const [vehicles, isWorking, setKeyword] = useVehicleSearch(props.timestamp);
    const onChange = (value: string) => {
        const vehicle = vehicles.find(vehicle => vehicle.id === value);
        if (vehicle != null && typeof props.onChange === 'function') props.onChange(vehicle);
    };
    return (
        <Select
            ref={ref}
            placeholder="Search vehicle by vin, model..."
            value={props.value?.id}
            notFoundContent={isWorking ? <Loading text="Searching..." /> : null}
            onSearch={debounce(setKeyword, 500)}
            onChange={onChange}
            defaultActiveFirstOption={false}
            filterOption={false}
            showSearch
        >
            {Array.isArray(vehicles) && vehicles.map(vehicle => (
                <Select.Option key={vehicle.id} value={vehicle.id}>
                    <Space>
                        <strong>{vehicle.vin}</strong>
                        <span>{vehicle.modelYear}</span>
                        <span>{vehicle.model?.fullName}</span>
                    </Space>
                </Select.Option>
            ))}
        </Select>
    );
});

UnappointmentedVehicleSearcher.displayName = 'UnappointmentedVehicleSearcher';

export default React.memo(UnappointmentedVehicleSearcher);
