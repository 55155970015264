import { Checkbox } from 'antd';
import { CheckboxGroupProps } from 'antd/lib/checkbox';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import React from 'react';

interface Props {
    enum: Record<string, unknown>;
    flag?: boolean;
    value?: number | Array<CheckboxValueType>;
    onChange?: (checkedValue: number | Array<CheckboxValueType>) => void;
}

const EnumCheckboxGroup: React.FC<CheckboxGroupProps & Props> = props => {
    const keys = Object.keys(props.enum);
    const values = keys.map(key => Number(key)).filter(key => !isNaN(key));
    if (props.flag === true && values.length === 0) return <span>String enum is not supported in flag mode</span>;

    const checkBoxGroupProps: CheckboxGroupProps = {
        value: props.value,
        onChange: props.onChange
    };

    if (props.flag === true) {
        checkBoxGroupProps.value =
            typeof props.value === 'number'
                ? values
                      .filter(value => ((props.value as number) & value) === value)
                      .map((value: any) => (props.enum as any)[value])
                : [];
        checkBoxGroupProps.onChange = (values: any) => {
            if (typeof props.onChange === 'function' && Array.isArray(values))
                props.onChange(
                    values.map((value: any) => (props.enum as any)[value]).reduce((result, value) => result | value, 0)
                );
        };
    } else {
        checkBoxGroupProps.value = props.value && props.value.map((value: any) => (props.enum as any)[value]);
        checkBoxGroupProps.onChange = (values: any) => {
            if (typeof props.onChange === 'function' && Array.isArray(values))
                props.onChange(values.map((value: any) => (props.enum as any)[value]));
        };
    }

    const options = keys.filter(key => isNaN(Number(key))).sort();
    return <Checkbox.Group {...checkBoxGroupProps} options={options} />;
};

EnumCheckboxGroup.displayName = 'EnumCheckboxGroup';

export default React.memo(EnumCheckboxGroup);
