/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { withSentryReactRouterV6Routing } from '@sentry/react';

import { NotFound } from './components';
import { UserContext } from './contexts';
import Role from './pages/admin/role';
import Setting from './pages/admin/setting';
import User from './pages/admin/user';
import LeadContactPlanner from './pages/crm/board';
import Lead from './pages/crm/lead';
import Customer from './pages/desking/customer';
import Deal from './pages/desking/deal';
import Home from './pages/home';
import Appointment from './pages/service/appointment';
import ServiceCustomer from './pages/service/customer';
import ServiceOperation from './pages/service/operation';
import RepairOrder from './pages/service/repairorder';
import ServicePackage from './pages/service/servicepackage';
import FinancialInstitute from './pages/shop/financialInstitute';
import Team from './pages/shop/team';
import InventoryVehicle from './pages/vehicles/inventory';
import VehicleAdditional from './pages/vehicles/vehicleAdditional';
import AddCharge from './pages/vehicles/vehicleAdditionalCharge';
import Shell from './shell/Main';

const routes = [
    ['crm/lead', Lead, [':id', ['lead'], ['assign'], ['merge']]],
    ['crm/planner', LeadContactPlanner, [':id']],
    ['vehicle/inventory', InventoryVehicle, [':id']],
    ['vehicle/accessory', VehicleAdditional, [':id']],
    ['desking/customer', Customer, [':id']],
    ['desking/deal', Deal, [':id', ['forms']]],
    ['service/customer', ServiceCustomer, [':id']],
    ['service/appointment', Appointment, [':id']],
    ['service/repairOrder', RepairOrder, [':id', ['view']]],
    ['admin/user', User, [':id', ['assign']]],
    ['admin/role', Role, [':id']],
    ['admin/setting', Setting],
    ['service/operation', ServiceOperation, [':id']],
    ['service/package', ServicePackage, [':id']],
    ['vehicle/additionalCharge', AddCharge, [':id']],
    ['shop/team', Team, [':id']],
    ['shop/financialInstitute', FinancialInstitute, [':id']]
];

const createRoute = (routes: any[], component?: any) => {
    const path = routes[0];
    if (typeof path !== 'string') throw new Error('Invalid path');

    const element = routes[1] == null || Array.isArray(routes[1]) ? component : routes[1];
    if (element == null) throw new Error('Missing component');

    const props = { key: path, path, element: React.createElement(element, null) };
    const children: any[] = Array.isArray(routes[1])
        ? routes.slice(1).map(r => createRoute(r, component))
        : routes.slice(2).map(r => createRoute(r, routes[1]));
    return children.length > 0 ? React.createElement(Route, props, children) : React.createElement(Route, props);
};

const SentryRoutes = withSentryReactRouterV6Routing(Routes);

export default function AppRoutes() {
    const user = useContext(UserContext);

    useEffect(() => {
        document.title = user?.dealerName != null ? `${user.dealerName} - Nebula Pro` : 'Nebula Pro';
    }, [user?.dealerName]);

    return user == null ? null : (
        <BrowserRouter>
            <SentryRoutes>
                <Route path="/" element={<Shell />}>
                    <Route index element={<Home />} />
                    {user.pages.map(path => {
                        const route = routes.find(r => r[0] === path);
                        return route == null ? null : createRoute(route);
                    })}
                    <Route path="*" element={<NotFound />} />
                </Route>
            </SentryRoutes>
        </BrowserRouter>
    );
}
