import { Tag } from 'antd';
import React from 'react';

type Colors =
    | 'pink'
    | 'red'
    | 'yellow'
    | 'orange'
    | 'cyan'
    | 'green'
    | 'blue'
    | 'purple'
    | 'geekblue'
    | 'magenta'
    | 'volcano'
    | 'gold'
    | 'lime';

interface Props {
    enum: { [key: string]: string | number; [key: number]: string };
    value: number;
    colors: Colors[];
}

const StatusTag: React.FC<Props> = props => {
    const { value, colors } = props;
    let color: Colors | undefined = undefined;
    if (Array.isArray(colors) && colors.length > 0) {
        const values = Object.keys(props.enum)
            .map(key => Number(key))
            .filter(key => !isNaN(key))
            .sort();
        const index = values.indexOf(value);
        if (index > -1) color = colors[index % colors.length];
    }
    return <Tag color={color}>{props.enum[value]}</Tag>;
};

export default React.memo(StatusTag);
