import { Select, Space } from 'antd';
import dayjs from 'dayjs';
import debounce from 'lodash/debounce';
import React, { useCallback, useMemo } from 'react';

import { CustomerName, Loading } from '../../../components';
import { useFilters, usePaging, useSearchData } from '../../../hooks';
import { ServiceAppointment, ServiceAppointmentStatus } from '../../../models';

interface Props {
    date?: Date;
    value?: ServiceAppointment;
    onChange?: (value?: ServiceAppointment) => void;
}

const useAppointmentSearch = (date: dayjs.Dayjs): [ServiceAppointment[], boolean, (value: string) => void] => {
    const [filters, setFilter] = useFilters(undefined, {
        appointmentStatus: ServiceAppointmentStatus.Arrived,
        scheduleTimeFrom: date.startOf('day')
        //scheduleTime: [date.startOf('day'), date.startOf('year')]
    });
    const [paging] = usePaging(30);
    const [result, isWorking] = useSearchData<ServiceAppointment>('serviceAppointment', filters, paging, [
        'customer',
        'vehicle'
    ]);
    const appointments = useMemo(() => (isWorking ? [] : result.data), [result, isWorking]);
    const setKeyword = useCallback(
        (value: string) => {
            value = value.trim();
            setFilter('$keyword', value === '' ? undefined : value);
        },
        [setFilter]
    );
    return [appointments, isWorking, setKeyword];
};

const ServiceAppointmentSearcher: React.FC<Props> = React.forwardRef<any, Props>((props, ref) => {
    const [appointments, isWorking, setKeyword] = useAppointmentSearch(dayjs(props.date) ?? dayjs());
    const onChange = (value: string) => {
        if (typeof props.onChange !== 'function') return;
        if (typeof value !== 'string') props.onChange();
        else {
            const appointment = appointments.find(vehicle => vehicle.id === value);
            props.onChange(appointment);
        }
    };
    return (
        <Select
            ref={ref}
            placeholder="Search appointment..."
            defaultValue={props.value?.id}
            notFoundContent={isWorking ? <Loading text="Searching..." /> : null}
            onSearch={debounce(setKeyword, 500)}
            onChange={onChange}
            defaultActiveFirstOption={false}
            filterOption={false}
            showSearch
            allowClear
        >
            {appointments.map(appointment => (
                <Select.Option key={appointment.id} value={appointment.id}>
                    <Space>
                        <strong>
                            {appointment.customer ? <CustomerName name={appointment.customer?.name} /> : null}
                        </strong>
                        <span>{appointment.vehicle?.vin}</span>
                    </Space>
                </Select.Option>
            ))}
        </Select>
    );
});

ServiceAppointmentSearcher.displayName = 'ServiceAppointmentSearcher';

export default React.memo(ServiceAppointmentSearcher);
