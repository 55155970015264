import dayjs from 'dayjs';
import { ceil, max } from 'lodash';

import { DualAxes } from '@ant-design/plots';

interface Props {
    labelRateName: string;
    data: any[];
    lineData: any[];
    type: string;
}

const DualAxesChart = ({ data, lineData, type }: Props) => {
    // const uvBillData = [
    //     {
    //         category: 'new',
    //         time: '2022-03-25',
    //         value: 10,
    //         type: 'New'
    //     },
    //     {
    //         category: 'new',
    //         time: '2022-03-26',
    //         value: 8,
    //         type: 'New'
    //     },
    //     {
    //         category: 'new',
    //         time: '2022-03-27',
    //         value: 12,
    //         type: 'New'
    //     },
    //     {
    //         category: 'new',
    //         time: '2022-03-28',
    //         value: 25,
    //         type: 'New'
    //     },
    //     {
    //         category: 'new',
    //         time: '2022-03-29',
    //         value: 32,
    //         type: 'New'
    //     },
    //     {
    //         category: 'new',
    //         time: '2022-03-30',
    //         value: 25,
    //         type: 'New'
    //     },
    //     {
    //         category: 'new',
    //         time: '2022-03-31',
    //         value: 50,
    //         type: 'New'
    //     },

    //     {
    //         category: 'InProgress',
    //         time: '2022-03-25',
    //         value: 12,
    //         type: 'Close'
    //     },
    //     {
    //         category: 'InProgress',
    //         time: '2022-03-26',
    //         value: 60,
    //         type: 'Close'
    //     },
    //     {
    //         category: 'InProgress',
    //         time: '2022-03-27',
    //         value: 12,
    //         type: 'Close'
    //     },
    //     {
    //         category: 'InProgress',
    //         time: '2022-03-28',
    //         value: 3,
    //         type: 'Close'
    //     },
    //     {
    //         category: 'InProgress',
    //         time: '2022-03-29',
    //         value: 19,
    //         type: 'Close'
    //     },
    //     {
    //         category: 'InProgress',
    //         time: '2022-03-30',
    //         value: 33,
    //         type: 'Close'
    //     },
    //     {
    //         category: 'InProgress',
    //         time: '2022-03-31',
    //         value: 20,
    //         type: 'Close'
    //     },

    //     {
    //         category: 'InProgress',
    //         time: '2022-03-25',
    //         value: 8,
    //         type: 'Won'
    //     },
    //     {
    //         category: 'InProgress',
    //         time: '2022-03-26',
    //         value: 20,
    //         type: 'Won'
    //     },
    //     {
    //         category: 'InProgress',
    //         time: '2022-03-27',
    //         value: 6,
    //         type: 'Won'
    //     },
    //     {
    //         category: 'InProgress',
    //         time: '2022-03-28',
    //         value: 1,
    //         type: 'Won'
    //     },
    //     {
    //         category: 'InProgress',
    //         time: '2022-03-29',
    //         value: 11,
    //         type: 'Won'
    //     },
    //     {
    //         category: 'InProgress',
    //         time: '2022-03-30',
    //         value: 23,
    //         type: 'Won'
    //     },
    //     {
    //         category: 'InProgress',
    //         time: '2022-03-31',
    //         value: 8,
    //         type: 'Won'
    //     }
    // ];
    // const transformData = [
    //     {
    //         time: '2022-03-25',
    //         count: 5
    //     },
    //     {
    //         time: '2022-03-26',
    //         count: 7
    //     },
    //     {
    //         time: '2022-03-27',
    //         count: 2
    //     },
    //     {
    //         time: '2022-03-28',
    //         count: 33
    //     },
    //     {
    //         time: '2022-03-29',
    //         count: 50
    //     },
    //     {
    //         time: '2022-03-30',
    //         count: 22
    //     },
    //     {
    //         time: '2022-03-31',
    //         count: 12
    //     }
    // ];

    const getMax = (data: any[]) => {
        if (Array.isArray(data)) {
            const values = data.map(item => item.value);
            const max = Math.max(...values);
            return ceil(max * 1.5);
        }
        return 100;
    };
    //右边的百分比的线，它的区间是 50
    const percentageInterval = 50;
    const getMaxCount = (lineData: any[]) => {
        const lines = lineData.filter(l => l.name === 'Contact rate' && l.count >= 100);
        // console.log(lines.map(l => l.count));
        if (Array.isArray(lines) && lines.length === 0) return 100;
        // console.log(lines);
        const _maxCount = max(lines.map(l => l.count));
        // const _maxCount = 133;
        const span = Math.ceil(_maxCount / percentageInterval);
        const _max = span * percentageInterval;

        // console.log(_maxCount / percentageInterval);
        // console.log(Math.ceil(_maxCount / percentageInterval));
        // console.log(span * percentageInterval);

        return _max === _maxCount ? _max + percentageInterval : _max;
        // return 200;
    };

    //用来将普通数字转为序号形式的方法
    const ordinal = (n: number) => {
        const s = ['th', 'st', 'nd', 'rd'];
        const v = n % 100;
        return n + (s[(v - 20) % 10] || s[v] || s[0]);
    };
    /**
     *
     * @param value  MM/DD/YYYY | MM/DD/YYYY | MMM/YYYY
     * @returns MM/DD/YYYY | 18th week of 2022 | MMM/YYYY
     */
    const formatterTimeX = (value: string) => {
        switch (type) {
            case 'daily':
                return value;
                break;
            case 'weekly':
                // console.log(dayjs(value).format('L'));
                // console.log(dayjs(value).week());
                return `${ordinal(dayjs(value).endOf('week').week())} week of ${dayjs(value).endOf('week').year()}`;
                // return value;
                break;
            case 'monthly':
                return value;
                break;
            default:
                return value;
        }
    };
    /**
     *
     * @param value MM/DD/YYYY
     * @returns MM/DD/YYYY | MM/DD/YYYY-  MM/DD/YYYY | MMM/YYYY
     */
    const formatterTitle = (value: any) => {
        switch (type) {
            case 'daily':
                return value;
                break;
            case 'weekly':
                if (typeof value === 'string') {
                    const day = dayjs(value);
                    return `${day.startOf('week').format('L')} - ${day.endOf('week').format('L')}`;
                }
                return value;
                break;
            case 'monthly':
                return value;
                break;
            default:
                return value;
        }
    };
    // console.log(data);
    // console.log(lineData);

    const config = {
        height: 500,
        animation: false,
        data: [data, lineData],
        xField: 'time',
        yField: Array.isArray(lineData) && lineData.length > 0 ? ['value', 'count'] : ['value'],
        tooltip: {
            title: (value: any) => {
                return formatterTitle(value);
            },
            customItems: (originalItems: any[]) => {
                // process originalItems,
                return originalItems.filter(item => item.name !== 'Contact qualified rate');
            }
        },
        legend: {
            itemName: {
                formatter: (text: string, item: any) => {
                    if ('Contact qualified rate' == item.value) {
                        item.marker.style.lineDash = [1, 4];
                        // return null;
                    } else {
                        return item.value;
                    }
                }
            }
        },
        xAxis: {
            label: {
                autoRotate: true,
                // autoHide: false,
                // autoEllipsis: false,
                //(text: string, item: ListItem, index: number) => any
                formatter: (text: string) => {
                    return formatterTimeX(text);
                }
            }
        },
        meta: {
            count: {
                formatter: (v: any) => {
                    return v + '%';
                },
                mix: 0,
                max: getMaxCount(lineData)
            },
            // time: {
            //     formatter: (value: string) => {
            //         return formatterTimeX(value);
            //     }
            // },
            value: {
                min: 0,
                max: getMax(data)
            }
        },

        geometryOptions: [
            {
                geometry: 'column',
                isStack: false,
                isGroup: true,
                seriesField: 'type'
                // groupField: 'category'
                // color: ['cyan', 'yellow', 'green']
                // color array
            },
            {
                geometry: 'line',
                // isStack: true,
                seriesField: 'name',
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                lineStyle: (data: any) => {
                    if (data.name === 'Contact qualified rate') {
                        return {
                            lineDash: [1, 4],
                            opacity: 1
                        };
                    }
                }

                // smooth: true
            }
        ]
    };
    return <DualAxes {...config} />;
};
export default DualAxesChart;
