import type { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import type { User } from './types';

import 'simplebar/dist/simplebar.min.css';
import './index.less';

import { ConfigProvider } from 'antd';
import { pick } from 'lodash';
import LZString from 'lz-string';
import React from 'react';
import ReactDOM from 'react-dom';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

import { gql, ApolloProvider } from '@apollo/client';
// import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import { Loading } from './components';
import { UserContext } from './contexts';
import validateMessages from './messages';
import { apolloClient } from './services/apollo';
import { getSession } from './services/cognito';
import { sessionStore } from './services/store';
import AppRoutes from './AppRoutes';
import Login from './Login';

const isDev = process.env.REACT_APP_DEVELOPMENT != null;

// Sentry.init({
//     dsn: process.env.REACT_APP_SENTRY_DSN,
//     environment: isDev ? 'development' : 'production',
//     release: process.env.CI_COMMIT_SHA,
//     tracesSampleRate: isDev ? 1 : 0.5,
//     autoSessionTracking: true,
//     integrations: [
//         new BrowserTracing({
//             routingInstrumentation: Sentry.reactRouterV6Instrumentation(
//                 React.useEffect,
//                 useLocation,
//                 useNavigationType,
//                 createRoutesFromChildren,
//                 matchRoutes
//             )
//         })
//     ]
// });

const SignIn = gql`
    mutation SignIn {
        signIn {
            dealerName
            roles
            pages
            permissions
            callcenter
            dealerShortName
        }
    }
`;

const getUser = async (): Promise<User | undefined> => {
    // throw new Error('Test error');
    let result: [CognitoUserSession, CognitoUser] | null;
    try {
        result = await getSession();
        if (result == null) return;
    } catch (err) {
        return err;
    }

    const storeId = `user.${result[1].getUsername()}`;
    let user = sessionStore.get(storeId);
    if (typeof user === 'string' && result[0].isValid()) {
        user = LZString.decompressFromBase64(user);
        if (user != null) return JSON.parse(user);
    }
    sessionStore.remove(storeId);

    user = (await apolloClient.mutate({ mutation: SignIn })).data.signIn;
    delete user.__typename;

    const payload = result[0].getIdToken().payload;
    const isSuperAdmin = payload['custom:isSuperAdmin'] === 'true';
    user = {
        id: payload['sub'],
        email: payload['email'],
        dealer: payload['nebulapro:dealer'],
        dealers: payload['nebulapro:dealers']?.split(',') ?? [],
        firstName: payload['given_name'],
        lastName: payload['family_name'],
        phoneNumber: payload['phone_number'],
        picture: payload['picture'],
        isAdmin: isSuperAdmin || user.roles.includes('admin'),
        isSuperAdmin,
        ...user
    };

    const pageData = await import('./shell/pages.json');
    const pages: any[] = user.isAdmin
        ? pageData.pages
        : user.pages.map((pageId: string) => pageData.pages.find(page => page.id === pageId));
    user.pages = pages
        .map(page => {
            const group = page && pageData.groups.find(group => group.id === page.id[0]);
            return group && `${group.path}/${page.path}`;
        })
        .filter(url => url != null);

    sessionStore.set(storeId, LZString.compressToBase64(JSON.stringify(user)));
    //Sentry.setUser(pick(user, 'id', 'email'));

    return user;
};

const showLogin = (err?: Error) => {
    ReactDOM.render(<Login err={err} />, document.getElementById('root'));
};

const showShell = async (user: User) => {
    //const SentryAny = Sentry as any;
    ReactDOM.render(
        //<SentryAny.ErrorBoundary fallback={<p>An error has occurred</p>} showDialog={false}>
        <UserContext.Provider value={user}>
            <ApolloProvider client={apolloClient}>
                <ConfigProvider form={{ validateMessages }}>
                    <AppRoutes />
                </ConfigProvider>
            </ApolloProvider>
        </UserContext.Provider>,
        // </SentryAny.ErrorBoundary>
        document.getElementById('root')
    );
};

ReactDOM.render(<Loading text="Signing in..." fullscreen />, document.getElementById('root'));

(async () => {
    const user = await getUser();
    if (user == null || user instanceof Error) showLogin();
    else showShell(user);
})();
