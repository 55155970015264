import { Select, Space } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';

import { CustomerName, Loading } from '../../../components';
import { useFilters, usePaging, useSearchData } from '../../../hooks';
import { Employee } from '../../../models';

interface Props {
    employee?: Employee;
    job?: string;
    roleName?: string;
    value?: Employee[];
    onChange?: (value: Employee[]) => void;
}

const useEmployeeSearcher = (job?: string, roleName?: string): [Employee[], boolean, (value: string) => void] => {
    const [filters, setFilter] = useFilters(undefined, { job, roleName });
    const [paging] = usePaging(0);
    const [result, isWorking] = useSearchData<Employee>('employee', filters, paging);
    const employees = useMemo(() => (isWorking ? [] : result.data), [result, isWorking]);
    const setKeyword = useCallback(
        (value: string) => {
            value = value.trim();
            setFilter('$keyword', value === '' ? undefined : value);
        },
        [setFilter]
    );
    return [employees, isWorking, setKeyword];
};

const EmployeeSearcher: React.FC<Props> = React.forwardRef<any, Props>((props, ref) => {
    const [employees, isWorking] = useEmployeeSearcher(props.job, props.roleName);
    const [employeeId, setEmployeesId] = useState<string[]>();

    const onChange = (value: string[]) => {
        setEmployeesId(value);
        const employee = employees.filter(item1 => value.find(item2 => item2 === item1.id));
        if (employee != null && typeof props.onChange === 'function') props.onChange(employee);
    };

    useMemo(() => {
        setEmployeesId(props.value?.map(item => item.id));
    }, [props.value]);
    return (
        <Select
            ref={ref}
            placeholder="Search employee by name, phone, email..."
            value={employeeId}
            notFoundContent={isWorking ? <Loading text="Searching..." /> : null}
            onChange={onChange}
            defaultActiveFirstOption={false}
            filterOption={false}
            mode="multiple"
        >
            {employees.map(employee => (
                <Select.Option key={employee.id} value={employee.id}>
                    <Space>
                        <strong>
                            <CustomerName name={employee.name} />
                        </strong>
                        <span>{employee.gender}</span>
                        <span>{employee.contactInfo?.cellNumber}</span>
                    </Space>
                </Select.Option>
            ))}
        </Select>
    );
});

EmployeeSearcher.displayName = 'EmployeeSearcher';

export default React.memo(EmployeeSearcher);
