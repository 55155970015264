import { message, Form, Input, Modal } from 'antd';
import { useEffect, useState } from 'react';

import { sessionStore } from '../services';
import { changePassword, signOut } from '../services/cognito';
import { User } from '../types';
import styles from '../Login.module.scss';

interface Props {
    visible: boolean;
    user: User | undefined;
    onClose: () => void;
}

interface PasswordRuleProps {
    currentPassword: string;
    password: string;
    confirmPassword: string;
    onValidChange: (valid: boolean) => void;
}
//
// You must provide a valid current password Current password is required
const Rules = [
    // 'Current password is required',
    'Be at least 8 characters long',
    'Contain at least one number',
    'Contain at least one uppercase letter',
    'Contain at least one lowercase letter',
    'Password confirmation doesn\'t match Password'
];
const PasswordRule = ({ currentPassword, password, confirmPassword, onValidChange }: PasswordRuleProps) => {
    const [ruleChecks, setRuleChecks] = useState(Rules.map(() => false));

    useEffect(() => {
        setRuleChecks([
            // currentPassword.length > 0,
            password.length >= 8,
            password.match(/\d/) != null,
            password.match(/[A-Z]/) != null,
            password.match(/[a-z]/) != null,
            password.length > 0 && password === confirmPassword
        ]);

    }, [confirmPassword, currentPassword, password]);

    useEffect(() => {
        onValidChange(ruleChecks.every(check => check === true));
    }, [ruleChecks, onValidChange]);

    return (
        <ul style={password !== '' ? { listStyle: 'none', paddingLeft: 24 } : undefined}>
            {Rules.map((rule, i) => (
                <li key={i} className={password !== ''  ? (ruleChecks[i] ? styles.valid : styles.invalid) : undefined}>
                    {rule}
                </li>
            ))}
        </ul>
    );
};


const Password = ({ visible, user , onClose }: Props) => {
    const [isBusy, setIsBusy] = useState(false);
    const [currentPassword, setCurrentPassword] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordValid, setPasswordValid] = useState(false);

    const getUser = user != null && user != undefined;

    const onOk = async () => {
        try {
            if(currentPassword && password && user != null && user != undefined){
                setIsBusy(true);
                await changePassword(user.email, currentPassword, password);
                await signOut();
                // message.success('Password changed, please login with the new password.')
                sessionStore.remove(`user.${user?.id}`);
                location.reload();
                onCloseModal();
            }
        } catch (err) {
            message.error(err.message, 3);
        } finally {
            setIsBusy(false);
        }
    };

    const onCloseModal = ()=>{
        setCurrentPassword('');
        setPassword('');
        setConfirmPassword('');
        if(typeof onClose === 'function' ) onClose();
    }

    return (
        <Modal
            title="Edit Password"
            okText={'Save password'}
            okButtonProps={{ loading: isBusy, disabled: getUser && (!passwordValid || (currentPassword.length == 0)) }}
            cancelButtonProps={{ style: { display: 'none' } }}
            onOk={onOk}
            onCancel={onCloseModal}
            keyboard={false}
            maskClosable={false}
            destroyOnClose={true}
            visible={visible}
            centered
        >
            <Form labelCol={{ span: 9 }}>
                {/* <p>After a successful password update, you will be redirected to the login page where you can log in with your new password</p> */}
                <Form.Item label="Current password" name="currentPassword" required>
                    <Input.Password value={currentPassword} onChange={(e: any) => setCurrentPassword(e.currentTarget.value)} />
                </Form.Item>
                <Form.Item label="New password" name="newPassword" required>
                    <Input.Password value={password} onChange={(e: any) => setPassword(e.currentTarget.value)} />
                </Form.Item>
                <Form.Item label="Password confirmation" name="confirmPassword" required>
                    <Input.Password value={confirmPassword} onChange={(e: any) => setConfirmPassword(e.currentTarget.value)} />
                </Form.Item>
                <PasswordRule currentPassword={currentPassword} password={password} confirmPassword={confirmPassword} onValidChange={setPasswordValid} />
            </Form>
        </Modal>
    );
};

export default Password;
