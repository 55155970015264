import type { Customer } from './Customer';
import type { Employee } from './Employee';
import type { Entity } from './Entity';
import type { Vehicle } from './Vehicle';
import type { FrontBackFlag, HardSoftFlag } from './VehicleAccessory';
import type { ContractType, ContractTypeCode } from './VehicleWarranty';

export enum DealStatus {
    New = 0,
    Closed = 1
}

export enum DealType {
    Lease = 0,
    Retail = 1
}

export enum DealAdditionalCategory {
    Accessory = 0,
    Warranty = 1
}

export enum DealAdditionalProfitTo {
    Gross = 0,
    Finance = 1
}

export interface DealAdditional {
    /** 分类 */
    category: DealAdditionalCategory;
    /** 编号 */
    code: string;
    /** 描述 */
    description?: string;
    /** 成本价格 */
    cost: number;
    /** 零售价格 */
    retail: number;
    /** Lease残值 */
    residual?: number;
    /** 利润目标 */
    profitTo?: DealAdditionalProfitTo;
    /** 是否可上税 */
    taxable: boolean;
    /** 是否可贷款 */
    financed: boolean;
    /** 维修时可以看到 */
    serviceVisible: boolean;
    /** 备注 */
    notes?: string;

    /** Warranty/Products */
    contractCompanyName?: string;
    contractDeductible?: number;
    contractId?: string;
    contractRate?: number;
    contractRateTable?: string;
    contractTermMileage?: number;
    term?: number;
    texasGapFlag?: boolean;
    totalContractAmount?: number;
    contractType?: ContractType;
    contractTypeCode?: ContractTypeCode;
    /** Warranty/Products */

    /** Accessory  getHelpWeOweOptionSetup fields */

    /** Front or back flag. A code used for assigning commissions.*/
    frontBackFlag?: FrontBackFlag;
    /** Hard or soft flag. A code used to categorize lease deals.*/
    hardSoftFlag?: HardSoftFlag;
    /**
     * Residual table flag. Indicates if the residual amount is a flat
        amount or is calculated from a table based on the lease term.
     */
    residualTableFlag?: boolean;
    /** Hard or soft flag. A code used to categorize lease deals.*/
    disclosureType?: string;
    /** Indicates if the We Owe should be included when calculating the base DMV fee.*/
    dmvFlag?: boolean;
    /** Indicates if the We Owe should be included in the Tax 1 total.*/
    tax1Flag?: boolean;
    /** Indicates if the We Owe should be included in the Tax 2 total.*/
    tax2Flag?: boolean;
    /** Indicates if the We Owe should be included in the Tax 3 total.*/
    tax3Flag?: boolean;
    /** Indicates if the We Owe should be included in the Tax 4 total.*/
    tax4Flag?: boolean;

    /** Accessory */
}

export interface DealTradeIn {
    vin?: string;
    make?: string;
    model?: string;
    cylinders?: string;
    bodyType?: string;
    year?: string;
    mileage?: number;
    licensePlate?: string;
    vehicleInfo?: string;
    accidents?: boolean;
    damages?: boolean;
    majorProblems?: boolean;
    tradeInValue?: number;
    adjustment?: number;
    deductions?: number;
    actualValue?: number;
    tradeValue?: number;
    dealPayoff?: number;
    tradeEquity?: number;
    tradeUA?: number;
    useAsCapReduction?: boolean;
    isLeaseReturn?: boolean;
}

export type SinglePay = {
    payment: number;
    monthlyBase: number;
    monthlyTax: number;
    monthlyPay: number;
    customerCash: number;
    salesTax: number;
    sellRate: number;
};

export type SecurityDeposit = {
    monthlyDeposit: number;
    months: number;
    sellRate: number;
};

export interface DealLeaseInput {
    instituteCode?: string;
    term: number;
    buyRate: number;
    sellRate: number;
    milesPerYear: number;
    residualFactor: number;
    mfgRebate: number;
    customerCash: number;
    acquisitionFee: number;
    acquisitionFeeFinanced: boolean;
    participation: number;
    flatReserve: number;
    isSinglePay: boolean;
    securityDepositMonths: number;
    singlePay?: SinglePay;
    securityDeposit?: SecurityDeposit;
}

export interface DealRetailInput {
    instituteCode?: string;
    term: number;
    buyApr: number;
    sellApr: number;
    mfgRebate: number;
    cashDeposit: number;
    reserve?: number;
    flatReserve: number;
    participation: number;
}

/** 销售订单输入数据 */
export interface DealInput {
    type: DealType;
    date: string;
    customer?: Customer;
    vehicle?: Vehicle;
    cashSalePrice: number;
    tax: number;
    dmvFee: number;
    dmvFeeFinanced: boolean;
    tireFee: number;
    tireFeeFinanced: boolean;
    documentFee: number;
    documentFeeFinanced: boolean;
    firstPayIn: number;
    incentive: number;
    lease: DealLeaseInput;
    retail: DealRetailInput;
    additionals: DealAdditional[];
    tradeIns: DealTradeIn[];
    reserve: number;
    tierName?: string;
    cobuyer?: Customer;
    closingManagerId?: string;
    fiManagerId?: string;
    salesManagerId?: string;
    salesPersonsId?: string[];
}

export interface DealLease extends DealLeaseInput {
    residual: number;
    dealerFunds: number;
    capitalCostReduction: number;
    adjustedCapitalCost: number;
    salesTax: number;
    monthlyBase: number;
    monthlyPay: number;
    monthlyTax: number;
    totalPay: number;
    driveOff: number;
}

export interface DealRetail extends DealRetailInput {
    totalCashDown: number;
    amountFinanced: number;
    stateTax: number;
    monthlyPay: number;
    totalPay: number;
}

/** 销售订单实体数据 */
export interface Deal extends Entity, DealInput {
    externalId?: string;
    code?: string;
    customerId?: string;
    vehicleId?: string;
    lease: DealLease;
    retail: DealRetail;
    grossProfit: number;
    netProfit: number;
    accessoryTotal: number;
    accessoryNonFinancedTotal: number;
    accessoryTaxTotal: number;
    accessoryNonTaxTotal: number;
    accessoryResidual: number;
    accessoryGrossProfit: number;
    accessoryFinancedProfit: number;
    warrantyTotal: number;
    warrantyProfitTotal: number;
    tradeTotal: number;
    tradeUATotal: number;
    dealStatus: DealStatus;
    closingManager?: Employee;
    fiManager?: Employee;
    salesManager?: Employee;
    salesPersons?: Employee[];
}
