import { Select } from 'antd';
import debounce from 'lodash/debounce';
import uniqBy from 'lodash/uniqBy';
import React, { useCallback, useMemo } from 'react';

import { Loading } from '../../../components';
import { useFilters, usePaging, useSearchData } from '../../../hooks';
import { FinancialInstitute } from '../../../models';

interface Props {
    type?: number;
    value?: FinancialInstitute;
    onChange?: (value: FinancialInstitute | string) => void;
    codeOnly?: boolean;
    disabled?: boolean;
}

const useFinancialInstituteSearch = (type?: number): [FinancialInstitute[], boolean, (value: string) => void] => {
    const [filters, setFilter] = useFilters(undefined, { type });
    const [paging] = usePaging(30);
    const [result, isWorking] = useSearchData<FinancialInstitute>('financialInstitute', filters, paging);
    const financialInstitutes = useMemo(() => (isWorking ? [] : uniqBy(result.data, 'code')), [result, isWorking]);
    const setKeyword = useCallback(
        (value: string) => {
            value = value.trim();
            setFilter('$keyword', value === '' ? undefined : value);
        },
        [setFilter]
    );
    return [financialInstitutes, isWorking, setKeyword];
};

const FinancialInstituteSearcher: React.FC<Props> = React.forwardRef<any, Props>((props, ref) => {
    const [financialInstitutes, isWorking, setKeyword] = useFinancialInstituteSearch(props.type);
    const onChange = (value: string) => {
        if (props.codeOnly === true) {
            if (typeof props.onChange === 'function') props.onChange(value);
        } else {
            const financialInstitute = financialInstitutes.find(
                financialInstitute => financialInstitute.code === value
            );
            if (financialInstitute != null && typeof props.onChange === 'function') props.onChange(financialInstitute);
        }
    };
    return (
        <Select
            ref={ref}
            placeholder="Search financial institute by code, name..."
            defaultValue={props.value?.code}
            notFoundContent={isWorking ? <Loading text="Searching..." /> : null}
            onSearch={debounce(setKeyword, 500)}
            onChange={onChange}
            defaultActiveFirstOption={false}
            filterOption={false}
            showSearch
            disabled={props.disabled}
        >
            {financialInstitutes.map(financialInstitute => (
                <Select.Option key={financialInstitute.code} value={financialInstitute.code}>
                    <strong>{financialInstitute.bankName}</strong>
                    {` (${financialInstitute.code})`}
                </Select.Option>
            ))}
        </Select>
    );
});

FinancialInstituteSearcher.displayName = 'FinancialInstituteSearcher';

export default React.memo(FinancialInstituteSearcher);
