import type { PageData } from './types';

import { Menu } from 'antd';
import { uniq } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import styled from 'styled-components';

import { CarOutlined, FormOutlined, HomeOutlined, PhoneOutlined, ShopOutlined, TeamOutlined, ToolOutlined } from '@ant-design/icons';

import { UserContext } from '../contexts';

const SEP = '/';

const Icons: Record<string, JSX.Element> = {
    Car: <CarOutlined />,
    Form: <FormOutlined />,
    Phone: <PhoneOutlined />,
    Shop: <ShopOutlined />,
    Team: <TeamOutlined />,
    Tool: <ToolOutlined />
};

const SubMenu = styled(Menu.SubMenu)`
    user-select: none;
    .ant-menu-inline.ant-menu-sub {
        background-color: #2b2f56cc;
        box-shadow: none;
    }
`;
const MenuAny = Menu as any;
const MenuItem = styled(MenuAny.Item)`
    user-select: none;
    &.ant-menu-item-selected {
        background-color: #957e0e !important;
    }
`;

const ShellMenu = () => {
    const user = useContext(UserContext);
    const location = useLocation();
    const [openKeys, setOpenKeys] = useState<string[]>();
    const [pageData, setPageData] = useState<PageData>();

    const keys = (user?.pages.map(page => SEP + page) ?? []).concat(SEP);
    const currentKey = keys.includes(location.pathname)
        ? location.pathname
        : keys.find(key => location.pathname.startsWith(key + SEP)) ?? SEP;

    useEffect(() => {
        import('./pages.json').then(pageData => setPageData(pageData));
    }, []);

    return (
        <Menu
            style={{ backgroundColor: 'transparent' }}
            theme="dark"
            mode="inline"
            defaultSelectedKeys={[SEP]}
            selectedKeys={[currentKey]}
            openKeys={openKeys ?? [currentKey?.split(SEP)[1]]}
            onOpenChange={setOpenKeys}
        >
            <MenuItem key="/">
                <>
                    <HomeOutlined />
                    &nbsp;&nbsp;
                    <Link to="/">Home</Link>
                </>
            </MenuItem>
            {pageData == null || user == null
                ? null
                : uniq(user.pages.map(page => page.split(SEP)[0]))
                      .map(path => pageData.groups.find(group => group.mobileOnly !== true && group.path === path && group.hidden !== true))
                      .map(group =>
                          group == null ? null : (
                              <SubMenu
                                  key={group.path}
                                  title={
                                      <span>
                                          {Icons[group.icon]}&nbsp;&nbsp;{group.name}
                                      </span>
                                  }
                              >
                                  {pageData.pages
                                      .filter(page => page.id.startsWith(group.id) && page.hidden !== true)
                                      .map(page => {
                                          const path = `${group.path}/${page.path}`;
                                          return user.pages.includes(path) ? (
                                              <MenuItem key={SEP + path}>
                                                  <Link to={SEP + path}>{page.name}</Link>
                                              </MenuItem>
                                          ) : null;
                                      })}
                              </SubMenu>
                          )
                      )}
        </Menu>
    );
};

export default ShellMenu;
