import React, { useContext, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { UserContext } from '../contexts';

const Search = React.lazy(() => import('./SearchDeal'));
const New = React.lazy(() => import('./new'));
const Edit = React.lazy(() => import('./edit'));
const FormTemplate = React.lazy(() => import('./edit/FormTemplate'));
const SalespersonDeal = React.lazy(() => import('./edit/SalespersonDeal'));

const Deal = () => {
    const user = useContext(UserContext);
    const isSalesManager = useMemo(() => user != null && (user.roles.includes('admin') || user.roles.includes('salesmanager')), [user]);

    const { id } = useParams();
    const { pathname } = useLocation();
    if (id == null) return <Search />;
    if (id.toLowerCase() === 'new') return <New />;
    if (pathname.toLowerCase().endsWith('forms')) return <FormTemplate id={id} />;
    return isSalesManager ? <Edit id={id} /> : <SalespersonDeal id={id} />;
};

export default Deal;
