import currency from 'currency.js';
import React from 'react';
import styled from 'styled-components';

const MoneyText = styled.span`
    color: #ffae00;
    font-size: 22px;
    & > span {
        font-size: 80%;
    }
`;

interface Props {
    value: number | string;
}

const BigMoney: React.FC<Props & React.HTMLAttributes<HTMLSpanElement>> = props => {
    const { value } = props;
    const str = typeof value === 'string' ? value : currency(value).format();
    const index = str.indexOf('.');
    if (index === -1) return <MoneyText>{str}</MoneyText>;
    return (
        <MoneyText {...props}>
            {str.substring(0, index)}
            <span>{str.substr(index)}</span>
        </MoneyText>
    );
};

export default React.memo(BigMoney);
