import { message, Button, Col, Form, Modal, Radio, Row, Space, Spin } from 'antd';
import { Store } from 'antd/lib/form/interface';
import React, { useEffect, useMemo, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';

import { CustomerName } from '../../../components';
import { SimpleBarAny as SimpleBar } from '../../../components/SimpleBarAny';
import {
    CustomerCategory,
    ServiceAppointment,
    ServiceConsultation,
    ServiceConsultationInput,
    Vehicle,
    VehicleInput,
    VehicleInputSource
} from '../../../models';
import { createCustomer, createVehicle, getConsultationByAppointment, getVehiclesByCustomer, searchVehiclesByVin } from '../../apis';
import { Customer, CustomerInput } from '../../customers/types';
import { WindowContainer } from '../components';
import { AppointmentSearcher, CustomerSearcher, EmployeeSearcher, VehicleSearcher } from '../searchers';
import CustomerEditor from './CustomerEditor';
import ServiceVehicleEditor from './ServiceVehicleEditor';

const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px'
};
interface Props {
    visible: boolean;
    onCancel: () => void;
    onSave: (data: ServiceConsultationInput) => Promise<void>;
}

const emptyData: ServiceConsultationInput & {
    customer?: Customer | null;
    vehicle?: Vehicle | null;
    appointment?: ServiceAppointment | null;
} = {
    customerId: '',
    vehicleId: '',
    serviceAdvisorId: '',
    operations: [],
    mileage: 0,
    laborAmount: 0,
    laborAmountDiscount: 1,
    partsAmount: 0,
    partsAmountDiscount: 1,
    taxRate: 0,
    shopCharges: 0,
    customer: null,
    vehicle: null,
    appointment: null
};

const NewConsultationEditor: React.FC<Props> = props => {
    // 表单控制属性
    const [isWorking, setIsWorking] = useState(false);
    const [form] = Form.useForm();
    const [vehicleLoading, setVehicleLoading] = useState(false);
    const onOk = async () => {
        try {
            const values = (await form.validateFields()) as ServiceConsultation;
            setIsWorking(true);
            const data = Object.assign({}, emptyData, values);
            if (data.appointment != null) {
                const appointment = data.appointment;
                data.appointmentId = appointment.id;
                data.customerId = appointment.customerId;
                data.vehicleId = appointment.vehicleId;
                data.mileage = appointment.mileage ?? 0;
                data.notification = appointment.notification;
                if (Array.isArray(appointment.operations))
                    data.operations = appointment.operations.map(op => ({
                        code: op.code,
                        operationType: op.operationType,
                        operationDescription: op.operationDescription,
                        operationHours: op.operation?.hours || 0,
                        laborType: '',
                        partsAmount: op.operation?.partsAmount || 0,
                        laborAmount: op.operation?.laborAmount || 0,
                        quantity: 1,
                        parts: []
                    }));
            } else {
                data.customerId = data.customer?.id || '';
                data.vehicleId = data.vehicle?.id || data.vehicleId || '';
            }
            //data.serviceAdvisorId = data.serviceAdvisor?.id || '';
            delete data.appointment;
            delete data.customer;
            delete data.vehicle;
            // console.log(JSON.stringify(data, null, 2));
            await props.onSave(data);
        } catch (err) {
            console.error(err);
        } finally {
            setIsWorking(false);
        }
    };
    const onCancel = () => {
        if (!isWorking) props.onCancel();
    };
    useEffect(() => {
        if (props.visible) {
            form.resetFields();
            setVehicleLoading(false);
            setVehicleData([]);
        }
    }, [form, props.visible]);

    const [timestamp, setTimeStamp] = useState<number>();

    //检测此预约单是否已创建报价单
    const [isDisabled, setDisabled] = useState(false);
    const checkIsAppointmented = async (appointmentId: string) => {
        setDisabled(true);
        const res: any = await getConsultationByAppointment(appointmentId);
        if (Array.isArray(res.data) && res.data.length > 0) {
            message.error({
                content: 'This consultation already exists',
                duration: 2
            });
        } else {
            setDisabled(false);
        }
    };
    // 新增客户相关状态
    const [customerEditorVisible, setCustomerEditorVisible] = useState(false);
    const onCustomerSave = async (data: CustomerInput) => {
        const customer = await createCustomer(data);
        form.setFieldsValue({ customer });
        setTimeStamp(Date.now());
        setCustomerEditorVisible(false);
    };
    const [vehiclesData, setVehicleData] = useState<Vehicle[]>([]);
    // 新增车辆相关状态
    const [vehicleEditorVisible, setVehicleEditorVisible] = useState(false);
    const onVehicleSave = async (data: VehicleInput) => {
        let res;
        let vehicle;
        if (vehiclesData && vehiclesData.length > 0) {
            res = await searchVehiclesByVin(data.vin);
            if (res && res.data?.length > 0) {
                setVehicleData([...vehiclesData, ...(res.data as Vehicle[])]);
            } else {
                vehicle = await createVehicle(data);
                setVehicleData([...vehiclesData, vehicle as Vehicle]);
            }
        } else {
            vehicle = await createVehicle(data);
            form.setFieldsValue({ vehicle });
            setTimeStamp(Date.now());
        }

        setVehicleEditorVisible(false);
    };

    //客户关联车辆的ui
    const vehiclesElement = useMemo(
        () =>
            Array.isArray(vehiclesData) &&
            vehiclesData.map(item => (
                <Radio style={radioStyle} value={item.id} key={item.id}>
                    <Space>
                        <strong>{item.vin}</strong>
                        <span>{item.modelCode}</span>
                        <span>{item.modelYear}</span>
                        <span>{item.model?.fullName}</span>
                    </Space>
                </Radio>
            )),
        [vehiclesData]
    );
    //字段值更新回调
    const onValuesChange = (changedValues: Store) => {
        if (changedValues.customer != null)
            (async () => {
                try {
                    setVehicleLoading(true);
                    const vehicles = await getVehiclesByCustomer(changedValues.customer.id);
                    setVehicleData(vehicles?.vehicles as Vehicle[]);
                } finally {
                    setVehicleLoading(false);
                }
            })();
        if (changedValues.vehicleId != null) form.setFieldsValue({ vehicle: undefined });

        const appointment = changedValues.appointment;
        if (appointment != null) {
            form.setFieldsValue({
                serviceAdvisorId: appointment.serviceAdvisorId
            });
            checkIsAppointmented(appointment.id);
        } else {
            setDisabled(false);
        }
    };
    return (
        <Modal
            title="New Consultation"
            confirmLoading={isWorking}
            visible={props.visible}
            onCancel={onCancel}
            onOk={onOk}
            width={600}
            className="form"
            okText="Submit"
            cancelButtonProps={{ type: 'link' }}
            maskClosable={false}
            okButtonProps={{ disabled: isDisabled }}
            //forceRender
        >
            <SimpleBar style={{ maxHeight: 600 }}>
                <WindowContainer>
                    <Form form={form} initialValues={emptyData} labelCol={{ span: 6 }} onValuesChange={onValuesChange}>
                        <Form.Item name="appointment" label="Appointment">
                            <AppointmentSearcher date={new Date()} />
                        </Form.Item>
                        <Form.Item name="serviceAdvisorId" label="Advisor" rules={[{ required: true }]}>
                            <EmployeeSearcher job="Service" />
                        </Form.Item>
                        <Form.Item noStyle shouldUpdate={(prev, curr) => prev.appointment !== curr.appointment}>
                            {({ getFieldValue }) => {
                                const appointment = getFieldValue('appointment');
                                if (appointment == null)
                                    return (
                                        <Row>
                                            <Col span={23}>
                                                <Form.Item name="customer" label="Customer" rules={[{ required: true }]}>
                                                    <CustomerSearcher category={CustomerCategory.Retention} timestamp={timestamp} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={1}>
                                                <Button
                                                    type="link"
                                                    icon={<PlusOutlined />}
                                                    onClick={() => setCustomerEditorVisible(true)}
                                                />
                                                <CustomerEditor
                                                    category={CustomerCategory.Retention}
                                                    visible={customerEditorVisible}
                                                    onCancel={() => setCustomerEditorVisible(false)}
                                                    onSave={onCustomerSave}
                                                />
                                            </Col>

                                            {Array.isArray(vehiclesData) && vehiclesData.length > 0 ? (
                                                <Col span={24}>
                                                    <Spin spinning={vehicleLoading}>
                                                        <Row style={{ marginBottom: '24px' }}>
                                                            <Col span={23}>
                                                                <Form.Item
                                                                    name="vehicleId"
                                                                    label="Vehicle"
                                                                    style={{ marginBottom: 0 }}
                                                                    rules={[{ required: true }]}
                                                                >
                                                                    <Radio.Group style={{ width: '100%' }}>{vehiclesElement}</Radio.Group>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={1}>
                                                                <Button
                                                                    type="link"
                                                                    icon={<PlusOutlined />}
                                                                    onClick={() => setVehicleEditorVisible(true)}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Spin>
                                                </Col>
                                            ) : (
                                                <Col span={24}>
                                                    <Spin spinning={vehicleLoading}>
                                                        <Row>
                                                            <Col span={23}>
                                                                <Form.Item name="vehicle" label="Vehicle" rules={[{ required: true }]}>
                                                                    <VehicleSearcher
                                                                        timestamp={timestamp}
                                                                        inputSource={VehicleInputSource.Service}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={1}>
                                                                <Button
                                                                    type="link"
                                                                    icon={<PlusOutlined />}
                                                                    onClick={() => setVehicleEditorVisible(true)}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Spin>
                                                </Col>
                                            )}
                                        </Row>
                                    );
                                return (
                                    <>
                                        <Form.Item label="Customer">
                                            <CustomerName name={appointment.customer?.name} />
                                        </Form.Item>
                                        <Form.Item label="Vehicle">
                                            <span>{appointment.vehicle?.vin}</span>
                                        </Form.Item>
                                    </>
                                );
                            }}
                        </Form.Item>
                        <Form.Item name="technicianId" label="Technician" rules={[{ required: true }]}>
                            <EmployeeSearcher job="Technician" />
                        </Form.Item>
                    </Form>
                    <ServiceVehicleEditor
                        visible={vehicleEditorVisible}
                        onCancel={() => setVehicleEditorVisible(false)}
                        onSave={onVehicleSave}
                    />
                </WindowContainer>
            </SimpleBar>
        </Modal>
    );
};

export default React.memo(NewConsultationEditor);
