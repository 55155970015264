import type { Entity } from './Entity';

/** 附件类型 */
export enum AttachmentType {
    Image = 0,
    Video = 1,
    Other = 2
}

/** 附件输入数据 */
export interface AttachmentInput {
    type: AttachmentType;
    key: string;
    url: string;
    date: string;
    fileName: string;
    mimeType: string;
}

export interface Attachment extends Entity, AttachmentInput {}
