import type { Customer } from './Customer';
import type { Dispatch } from './Dispatch';
import type { Employee } from './Employee';
import type { Entity } from './Entity';
import type { ServiceOperation, ServiceOperationType } from './ServiceOperation';
import type { Notification } from './ValueObjects';
import type { Vehicle } from './Vehicle';

/** 服务预约的来源类型 */
export enum ServiceAppointmentType {
    /** 主动呼出 */
    Outbound = 0,
    /** 被动呼入 */
    Inbound = 1
}

/** 客户在维修保养期间所使用的出行方式 */
export enum ServiceAppointmentTransportation {
    WaitingRoom = 0,
    Loaner = 1,
    Shuttle = 2,
    Rental = 3
}

/** 预约单状态 */
export enum ServiceAppointmentStatus {
    /** 新建 */
    New = 0,
    /** 已和客户确认 */
    Confirmed = 1,
    /** 客户已到店 */
    Arrived = 2,
    /** 客户已取消 */
    Canceled = 3
}

/** 维修项目的类型 */
export enum ServiceAppointmentOperationType {
    /** 系统自动推荐 */
    Recommend = 0,
    /** 客户要求 */
    CustomerDemand = 1
}

/** 预约单上的维修项目 */
export interface ServiceAppointmentOperation {
    /** 预约单维修项目的类型 */
    type?: ServiceAppointmentOperationType;
    /** 维修项目的编号 */
    code: string;
    /** 维修项目原始数据，客户端使用 */
    operation?: ServiceOperation;
    /** 维修项目的类型，冗余数据 */
    operationType?: ServiceOperationType;
    /** 维修项目的描述，冗余数据 */
    operationDescription?: string;
}

export interface ServiceAppointmentInput {
    /** 外部系统ID */
    externalId?: string;
    type: ServiceAppointmentType;
    customerId: string;
    vehicleId: string;
    serviceAdvisorId: string;
    /** 客户预约来店时间 */
    scheduledTime: string;
    /** 客户车辆当前的里程数 */
    mileage?: number;
    /** 维修保养项目清单 */
    operations: Array<ServiceAppointmentOperation>;
    transportation?: ServiceAppointmentTransportation;
    notification?: Notification;
    notes?: string;
    dispatch?: Dispatch;
}

export interface ServiceAppointment extends Entity, ServiceAppointmentInput {
    /** 预约单编号 */
    code: string;
    /** 预约单状态 */
    appointmentStatus: ServiceAppointmentStatus;
    /** 客户确认时间 */
    confirmTime?: string;
    /** 客户到店时间 */
    arrivalTime?: string;
    /** 客户取消时间 */
    cancelTime?: string;
    customer?: Customer;
    vehicle?: Vehicle;
    /** 服务顾问 */
    serviceAdvisor?: Employee;
}
