import { Badge, Button, Card, Col, Divider, Empty, Row, Select, Space, Spin, Timeline } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { orderBy } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import omitDeep from 'omit-deep-lodash';
import { useEffect, useMemo, useRef, useState } from 'react';

import { LeftOutlined, MenuOutlined, PlusOutlined, ReloadOutlined, RightOutlined } from '@ant-design/icons';

import { SimpleBarAny as SimpleBar } from '../../../components/SimpleBarAny';
import { useSearch } from '../../../hooks';
import { Calendar } from '../components';
import { SEARCH_TASK_REMINDERS } from './graphql';
import CheckmarkIcon from './images/checkmark.png';
import ReminderIcon from './images/reminder.png';
import SalesFunne from './images/sales-funne.png';
import { TaskReminderObject, TaskReminderStatus, TaskReminderType } from './models';
import styles from './styles.module.scss';
import TaskReminderModal from './TaskReminderModal';

dayjs.extend(localeData);
dayjs.extend(utc);
dayjs.extend(timezone);

interface Props {
    activeKey: string;
    userId: string;
}

interface Filter {
    _noSearch?: boolean;
    year?: number;
    month?: number;
    user?: string;
}

const MyCalendar = ({ activeKey, userId }: Props) => {
    const [today, setToday] = useState<dayjs.Dayjs>(dayjs());
    const [taskMode, setTaskMode] = useState<'New' | 'Edit' | 'Read'>('New');
    const filter: Filter = {
        _noSearch: true,
        year: today.year(),
        month: today.month() + 1,
        user: userId
    };
    const { loading, data, setFilter, refresh } = useSearch<Filter, TaskReminderObject>(SEARCH_TASK_REMINDERS, filter, [], -1);
    const [tasks, setTasks] = useState<TaskReminderObject[]>([]);
    const [taskReminderModalVisible, setTaskReminderModalVisible] = useState(false);
    const [selectedTaskReminder, setSelectedTaskReminder] = useState<TaskReminderObject>();
    const [taskRemindersPerDay, setTaskRemindersPerDay] = useState<TaskReminderObject[]>([]);
    const [taskReminderIndex, setTaskReminderIndex] = useState<number>(-1);

    const [calendarCol, setCalendarCol] = useState(24);
    const [timelineCol, setTimeline] = useState(0);
    const [fold, setFold] = useState(false);

    useEffect(() => {
        if (activeKey === 'calendar') refresh();
    }, [activeKey, refresh]);

    useEffect(() => {
        if (fold) {
            setCalendarCol(24);
            setTimeline(0);
        } else {
            setCalendarCol(18);
            setTimeline(6);
        }
    }, [fold]);

    useEffect(() => {
        if (data == null) return;
        setTasks(omitDeep(data, '__typename') as TaskReminderObject[]);
    }, [data]);

    useEffect(() => {
        setFilter('year', today.year());
        setFilter('month', today.month() + 1);
    }, [setFilter, today]);

    const getListData = (value: Dayjs) => {
        if (tasks == null) return [];
        const listData = tasks.filter(task => task.year === value.year() && task.month === value.month() + 1 && task.date === value.date());
        const result: TaskReminderObject[] = [];
        listData.forEach(task => {
            if (task.type === TaskReminderType.Reminder && task.status === TaskReminderStatus.Completed) return;
            result.push(task);
        });

        return result;
    };

    const onClickTaskItem = (taskReminder: TaskReminderObject) => {
        const result = orderBy(getListData(dayjs(taskReminder.scheduleTime)), ['scheduleTime'], ['asc']);
        setTaskMode('Read');
        setSelectedTaskReminder(taskReminder);
        setTaskRemindersPerDay(result);
        setTaskReminderIndex(result.findIndex(task => task.id === taskReminder?.id));
        setTaskReminderModalVisible(true);
    };

    const getIcon = (taskReminder: TaskReminderObject) => {
        if (taskReminder.type === TaskReminderType.Reminder) return <img src={ReminderIcon} width={12} />;
        if (taskReminder.type === TaskReminderType.Task && taskReminder.businessId != null) return <img src={SalesFunne} width={12} />;
        return <img src={CheckmarkIcon} width={12} />;
    };

    const dateCellRender = (value: Dayjs) => {
        const listData = orderBy(getListData(value), ['scheduleTime'], ['asc']);
        // <Badge color={item.type === TaskReminderType.Task ? 'rgb(66, 133, 244)' : 'rgb(63, 81, 181)'} />
        return (
            <ul className={styles.events}>
                {listData.map(item => (
                    <li key={item.id} className={styles.taskItem} onClick={() => onClickTaskItem(item)}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Badge
                                color={
                                    item.type === TaskReminderType.Task && item.status === TaskReminderStatus.Completed
                                        ? 'green'
                                        : 'rgb(66, 133, 244)'
                                }
                            />

                            <span style={{ marginRight: 3 }}>{getIcon(item)}</span>
                            <span
                                style={{ marginRight: 3, fontWeight: 330 }}
                                className={item.status === TaskReminderStatus.Completed ? styles.taskCompleted : ''}
                            >
                                {dayjs(item.scheduleTime).format('LT')}
                            </span>
                            <span
                                className={item.status === TaskReminderStatus.InProgress ? styles.title : styles.taskCompleted}
                                style={{ marginRight: 3, minWidth: 0, textOverflow: 'ellipsis', overflow: 'hidden', cursor: 'pointer' }}
                            >
                                {item.title}
                            </span>
                            {/* </Space> */}
                        </div>
                    </li>
                ))}
            </ul>
        );
    };

    const buildTimeline = () => {
        const taskList = orderBy(getListData(today), ['scheduleTime'], ['asc']);
        if (taskList.length === 0) return <Empty />;
        //rgb(63, 81, 181)
        return (
            <>
                {taskList.map(item => (
                    <Timeline.Item
                        key={item.id}
                        color={
                            item.type === TaskReminderType.Task && item.status === TaskReminderStatus.Completed
                                ? 'green'
                                : 'rgb(66, 133, 244)'
                        }
                    >
                        <div style={{ display: 'flex', alignItems: 'baseline' }}>
                            {/* <Space size={0}> */}
                            {/* <Badge color={item.type === TaskReminderType.Task ? 'rgb(66, 133, 244)' : 'rgb(63, 81, 181)'} /> */}

                            <span style={{ marginRight: 3 }}>
                                {/* {item.type === TaskReminderType.Task ? (
                                    <img src={CheckmarkIcon} width={12} />
                                ) : (
                                    <img src={ReminderIcon} width={12} />
                                    
                                )} */}
                                {getIcon(item)}
                            </span>

                            <span
                                style={{ marginRight: 3, fontWeight: 330, whiteSpace: 'nowrap' }}
                                className={item.status === TaskReminderStatus.Completed ? styles.taskCompleted : ''}
                            >
                                {dayjs(item.scheduleTime).format('LT')}
                            </span>

                            <span
                                onClick={() => onClickTaskItem(item)}
                                className={item.status === TaskReminderStatus.InProgress ? styles.title : styles.taskCompleted}
                                style={{ marginRight: 3, minWidth: 0, textOverflow: 'ellipsis', overflow: 'hidden', cursor: 'pointer' }}
                            >
                                {item.title}
                            </span>
                            {/* </Space> */}
                        </div>
                    </Timeline.Item>
                ))}
            </>
        );
    };

    const buildYearMonthData = (value: Dayjs) => {
        const start = 0;
        const end = 12;
        const yearSpan = 10;
        const monthOptions = [];
        // console.log('======' + value.format('h:mm A'));
        const current = value.clone();
        const localeData = value.localeData();

        const months = [];
        for (let i = 0; i < 12; i++) {
            current.month(i);
            // console.log(current.month(i).format('L'));
            months.push(localeData.monthsShort(current.month(i)));
        }

        for (let i = start; i < end; i++) {
            monthOptions.push(
                <Select.Option key={i} value={i} className="month-item">
                    {months[i]}
                </Select.Option>
            );
        }

        const year = value.year();
        const month = value.month();
        const options = [];
        for (let i = year - yearSpan; i < year + yearSpan; i += 1) {
            options.push(
                <Select.Option key={i} value={i} className="year-item">
                    {i}
                </Select.Option>
            );
        }
        return { year, yearOptions: options, month, monthOptions };
    };

    const buildYearMonthSelect = (value: Dayjs, onChange: (date: Dayjs) => void) => {
        const { year, yearOptions, month, monthOptions } = buildYearMonthData(value);
        return (
            <>
                <Select
                    dropdownMatchSelectWidth={false}
                    className="my-year-select"
                    value={year}
                    onChange={newYear => {
                        const now = value.clone().year(newYear);
                        onChange(now);
                    }}
                >
                    {yearOptions}
                </Select>
                <Select
                    dropdownMatchSelectWidth={false}
                    value={month}
                    onChange={newMonth => {
                        const now = value.clone().month(newMonth);
                        onChange(now);
                    }}
                >
                    {monthOptions}
                </Select>
            </>
        );
    };

    const customMainRightHeader = () => {
        // const { year, yearOptions, month, monthOptions } = buildYearMonthData(value);

        return (
            // <div style={{ padding: 8>
            // 组件原始的样式 2022 Sep  Month Year
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Row gutter={8}>
                    {/* <Col>
                        <Select
                            dropdownMatchSelectWidth={false}
                            className="my-year-select"
                            value={year}
                            onChange={newYear => {
                                const now = value.clone().year(newYear);
                                onChange(now);
                            }}
                        >
                            {yearOptions}
                        </Select>
                    </Col>
                    <Col>
                        <Select
                            dropdownMatchSelectWidth={false}
                            value={month}
                            onChange={newMonth => {
                                const now = value.clone().month(newMonth);
                                onChange(now);
                            }}
                        >
                            {monthOptions}
                        </Select>
                    </Col> */}
                    <Col>
                        <Button icon={<MenuOutlined />} onClick={() => setFold(!fold)}></Button>
                    </Col>
                    <Col>
                        {/* <Radio.Group>
                            <Radio.Button value="month">Month</Radio.Button>
                            <Radio.Button value="year">Year</Radio.Button>
                        </Radio.Group> */}
                        {/* <Button>Add Task</Button> */}
                        <Button type="primary" icon={<PlusOutlined />} onClick={() => onNew()}>
                            New
                        </Button>
                    </Col>
                </Row>
            </div>
        );
    };

    const customHeaderNoShow = () => {
        return (
            <>
                {' '}
                <div style={{ padding: '12px 0' }}></div>
            </>
        );
    };

    const onSelect = (value: Dayjs) => {
        // setFold(false);
        setToday(value);
    };

    const onNew = () => {
        setTaskMode('New');
        setSelectedTaskReminder(undefined);
        setTaskRemindersPerDay([]);
        setTaskReminderIndex(-1);
        setTaskReminderModalVisible(true);
    };

    const onCallback = (taskReminder: TaskReminderObject | string) => {
        let _tasks = cloneDeep(tasks);

        if (typeof taskReminder === 'string') {
            _tasks = _tasks.filter(t => t.id !== taskReminder);
        } else {
            const index = _tasks.findIndex(t => t.id === taskReminder.id);
            if (index === -1) {
                _tasks.push(taskReminder);
            } else {
                _tasks[index] = taskReminder;
            }
        }

        setTasks(_tasks);
    };

    const simpleBarRef = useRef<SimpleBar>(null);
    const height = useMemo(() => Math.max(document.documentElement.clientHeight, window.innerHeight ?? 0) - 200, []);

    return (
        <>
            <Card className={styles.tabcard} bodyStyle={{ paddingTop: 20, paddingBottom: 0 }}>
                <Spin spinning={loading}>
                    <Row>
                        <Col span={16}>
                            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                <Row gutter={8}>
                                    <Col>
                                        <Space>
                                            <Button
                                                style={{ marginRight: 20 }}
                                                icon={<LeftOutlined />}
                                                onClick={() => setToday(today.subtract(1, 'month'))}
                                            ></Button>
                                            {buildYearMonthSelect(today, setToday)}
                                            <Button
                                                style={{ marginLeft: 20 }}
                                                icon={<RightOutlined />}
                                                onClick={() => setToday(today.add(1, 'month'))}
                                            ></Button>
                                            <Button
                                                icon={<ReloadOutlined />}
                                                style={{ marginLeft: 20 }}
                                                onClick={() => {
                                                    setToday(dayjs());
                                                    refresh();
                                                }}
                                            >
                                                Today
                                            </Button>
                                            {/* <span style={{ marginLeft: 30, fontSize: 22, lineHeight: '24px' }}>{`${today
                                                .localeData()
                                                .months(today)} ${today.year()}`}</span> */}
                                        </Space>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col span={8}>{customMainRightHeader()}</Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Divider style={{ marginTop: 15, marginBottom: 0 }}></Divider>
                        </Col>
                    </Row>

                    {/* <div style={{ height: height - 40 }}> */}
                    <Row>
                        <Col span={calendarCol}>
                            <SimpleBar ref={simpleBarRef} style={{ height }}>
                                <Calendar
                                    headerRender={customHeaderNoShow}
                                    dateCellRender={dateCellRender}
                                    onSelect={onSelect}
                                    fullscreen={true}
                                    value={today}
                                />
                            </SimpleBar>
                        </Col>
                        <Col span={timelineCol} style={{ borderRight: '1px solid rgba(0, 0, 0, 0.06)' }}>
                            <SimpleBar ref={simpleBarRef} style={{ height }}>
                                <div>
                                    <Row>
                                        <Col span={24} style={{ textAlign: 'center', padding: 5, fontSize: 16, lineHeight: '24px' }}>
                                            {today.format('ddd, MMM. D YYYY')}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Timeline style={{ marginTop: 16, marginLeft: 18 }}>{buildTimeline()}</Timeline>
                                        </Col>
                                    </Row>
                                </div>
                            </SimpleBar>
                        </Col>
                    </Row>
                    {/* </div> */}
                </Spin>

                <TaskReminderModal
                    index={taskReminderIndex}
                    taskRemindersPerDay={taskRemindersPerDay}
                    mode={taskMode}
                    taskReminder={selectedTaskReminder}
                    selectorVisible={taskReminderModalVisible}
                    selectValue={today}
                    onCancel={() => setTaskReminderModalVisible(false)}
                    callback={onCallback}
                />
            </Card>
        </>
    );
};

export default MyCalendar;
