import styled from 'styled-components';

export const Container = styled.div`
    padding: 10px;
`;

export const CenterContainer = styled.div`
    position: relative;
    text-align: center;

    & > * {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -10%;
        margin-top: -10%;
    }
`;

export const HeadContainer = styled.div`
    background-color: #2e2e2e;

    .ant-page-header {
        background-color: transparent;
    }

    .ant-page-header-heading-title {
        color: #fefefe;
        font-size: 22px;
        font-weight: normal;
    }

    .ant-breadcrumb,
    .ant-breadcrumb a,
    .ant-breadcrumb-link,
    .ant-breadcrumb-separator {
        color: #fefefe;
    }

    .ant-breadcrumb + .ant-page-header-heading {
        margin-top: 2px;
    }

    .ant-card {
        color: #b2b2b2;
        background: #3d3d3d;
    }

    .ant-card-head-title {
        color: #ffffff;
        font-size: 18px;
    }

    .ant-card-bordered {
        border-color: #4a4a4a;
    }

    .ant-card-head {
        border-bottom-color: #4a4a4a;
        border-radius: 0;
        padding: 0 7px;
    }

    .ant-empty {
        padding: 10px 0;
    }

    .ant-empty-image {
        height: initial;
        margin-bottom: 0;
    }

    .ant-empty-description {
        color: #6e6e6e;
    }

    .ant-descriptions-item {
        padding-bottom: 6px;
    }

    .ant-descriptions-item-label {
        font-weight: 550;
        color: white;
    }

    .ant-descriptions-item-content {
        font-weight: 350;
        color: #b2b2b2;
    }

    .ant-radio-wrapper {
        color: #b2b2b2;
        margin-right: 4px;
    }
`;

export const FilterContainer = styled.div`
    padding: 0 20px 20px 20px;

    .ant-btn.reload {
        border-radius: 0;
        color: black;
        font-size: 16px;
        background-color: #ffd300;
        border: none;
        margin-right: 4px;
    }

    .ant-checkbox-wrapper span {
        color: #fefefe;
        line-height: 32px;
    }
`;

export const MainContainer = styled.div`
    height: 100%;
    margin: 10px 20px;
    overflow-x: hidden;

    .ant-table {
        background-color: transparent;
        border: 0;
        border-radius: 0;
    }

    .ant-table-column-title {
        font-weight: bold;
    }

    .ant-table-row {
        font-weight: 500;
    }

    .ant-table-row .ant-btn-link {
        color: #2a7fff;
    }

    .ant-table-fixed-left table,
    .ant-table-fixed-right table {
        background-color: transparent;
    }

    .ant-table-thead > tr > th {
        border-bottom: 3px solid #3d6f9f !important;
        background-color: transparent !important;
    }

    .ant-table-placeholder {
        background-color: transparent;
    }

    .align-right {
        text-align: right;
    }

    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: #ffd300;
    }

    .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
    .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
    .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
    .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
        background-color: #ededed;
    }
`;

export const BorderContainer = styled.div`
    border: 1px solid #cdcdcd;
    padding: 10px;
`;

export const WindowContainer = styled.div`
    padding: 20px;
    background-color: #fff;
    overflow: hidden;
`;

export const ButtonContainer = styled.div`
    margin: 20px 0 0 10px;
`;

export const ActionContainer = styled.div`
    .ant-btn {
        color: #f8f8f8;
        background-color: #3c3c3c;
        border-color: #4f4f4f;
    }

    .ant-btn:hover:not(:disabled) {
        border-color: #ffd300;
        color: #ffd300;
    }

    .ant-btn:disabled {
        color: #757575;
    }

    .ant-btn:disabled:hover {
        background-color: #3c3c3c;
        border-color: #4f4f4f;
    }
`;
