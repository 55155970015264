import type { SelectProps, SelectValue } from 'antd/lib/select';
import { Select } from 'antd';
import React from 'react';

interface Props {
    enum: { [key: string]: string | number; [key: number]: string };
}

const EnumSelect: React.FC<Props & SelectProps<SelectValue>> = React.forwardRef<any, Props & SelectProps<SelectValue>>(
    (props, ref) => {
        const { enum: enumObj, ...selectProps } = props;
        const keys = Object.keys(enumObj);
        const numericKeys = keys.map(key => Number(key)).filter(key => !isNaN(key));

        if (numericKeys.length === 0)
            return (
                <Select ref={ref} {...selectProps}>
                    {keys.map((key: string) => (
                        <Select.Option key={key} value={enumObj[key]}>
                            {key}
                        </Select.Option>
                    ))}
                </Select>
            );
        else {
            const options: JSX.Element[] = [];
            [(key: number) => key >= 0, (key: number) => key < 0].forEach(filter => {
                numericKeys
                    .filter(filter)
                    .sort()
                    .forEach(key => {
                        options.push(
                            <Select.Option key={key} value={key}>
                                {enumObj[key]}
                            </Select.Option>
                        );
                    });
            });
            return (
                <Select ref={ref} {...selectProps}>
                    {options}
                </Select>
            );
        }
    }
);

EnumSelect.displayName = 'EnumSelect';

export default React.memo(EnumSelect);
