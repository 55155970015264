import { Form, Input, Modal } from 'antd';
import React, { useEffect } from 'react';

import { EnumSelect } from '../../../components';
import { Address } from '../../../models';
import { WindowContainer } from '../components';
import { UnitedStates } from '../enums';

interface Props {
    visible: boolean;
    data?: Address;
    onCancel: () => void;
    onChange?: (data: Address) => void;
}

const emptyData: Address = {};
const filterOption = (input: string, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

const AddressEditor: React.FC<Props> = props => {
    const initialValues = props.data || emptyData;
    // 表单控制属性
    const [form] = Form.useForm();
    const onOk = async () => {
        try {
            const values = (await form.validateFields()) as Address;
            props.onChange && props.onChange(values);
        } catch (err) {
            console.error(err);
        }
    };
    useEffect(() => {
        if (!props.visible) return;
        form.resetFields();
    }, [form, initialValues, props.visible]);
    return (
        <Modal
            title="Edit Address Info"
            visible={props.visible}
            onCancel={props.onCancel}
            onOk={onOk}
            width={500}
            className="form"
            cancelButtonProps={{ type: 'link' }}
            mask={false}
            style={{ top: 200 }}
        >
            <WindowContainer>
                <Form form={form} initialValues={initialValues} labelCol={{ span: 6 }}>
                    <Form.Item name="street" label="Street 1" rules={[{ type: 'string', required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="street2" label="Street 2">
                        <Input />
                    </Form.Item>
                    <Form.Item name="city" label="City" rules={[{ type: 'string', required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="county" label="County" rules={[{ type: 'string' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="state" label="State" rules={[{ type: 'string', required: true }]}>
                        <EnumSelect enum={UnitedStates} filterOption={filterOption} showSearch />
                    </Form.Item>
                    <Form.Item
                        name="zip"
                        label="Zip"
                        rules={[
                            { required: true },
                            {
                                pattern: /(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/,
                                message: 'Invalid format. e.g. : 99850,99801-1234'
                            }
                        ]}
                    >
                        <Input style={{ width: 120 }} />
                    </Form.Item>
                    <Form.Item name="country" label="Country" rules={[{ type: 'string' }]}>
                        <Input />
                    </Form.Item>
                </Form>
            </WindowContainer>
        </Modal>
    );
};

export default React.memo(AddressEditor);
