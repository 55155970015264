import { Button, Col, Descriptions, Divider, Form, Input, Modal, Radio, Row, Select, Switch } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { Store } from 'antd/lib/form/interface';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';

import { CloseOutlined, DownOutlined, EditOutlined, UpOutlined } from '@ant-design/icons';

import { DatePicker, EnumSelect } from '../../../components';
import { SimpleBarAny as SimpleBar } from '../../../components/SimpleBarAny';
import { Address, CustomerCategory, CustomerType, LeadTradeIn } from '../../../models';
import { searchCustomer } from '../../apis';
import { Customer } from '../../customers/types';
import { Lead, LeadInput, LeadSourceType } from '../../leads/types';
import LeadCustomer from '../../leads/Lead.Customer';
import { WindowContainer } from '../components';
import AddressEditor from './AddressEditor';
import TreadInEditor from './TreadInEditor';

interface Props {
    visible: boolean;
    data?: Lead;
    onCancel: () => void;
    onSave: (data: LeadInput, id?: string) => Promise<void>;
    //creditID?: string;
}
//手机号码正则
const numReg = /^[2-9]\d{2}[2-9](?!11)\d{6}$/;

const emptyData: LeadInput = {
    sourceType: LeadSourceType.WalkIn,
    customerInput: {
        category: CustomerCategory.Potential,
        type: CustomerType.Individual,
        name: {
            firstName: '',
            lastName: ''
        }
    }
};

const OutLineSaleLeadEditor: React.FC<Props> = props => {
    const id = props.data?.id;
    const initialValues = props.data || emptyData;
    // 表单控制属性
    const [isWorking, setIsWorking] = useState(false);
    const [form] = Form.useForm();
    //form.setFieldsValue({ customerInput: { type: 0 } });
    const onOk = async () => {
        try {
            setIsWorking(true);
            const values = (await form.validateFields()) as LeadInput;
            if (values.customerInput) values.customerInput.dob = form.getFieldValue('dob');
            values.tradeIn = form.getFieldValue('tradeIn');
            //console.log(JSON.stringify(values, null, 2));
            values.activities = props.data?.activities;
            // if (props.creditID) {
            //     if (
            //         !(
            //             values.customerInput.name &&
            //             values.customerInput.ssn &&
            //             values.customerInput.contactInfo &&
            //             values.customerInput.contactInfo.homeAddress &&
            //             values.customerInput.contactInfo.homeAddress.street &&
            //             values.customerInput.contactInfo.homeAddress.city &&
            //             values.customerInput.contactInfo.homeAddress.state &&
            //             values.customerInput.contactInfo.homeAddress.zip
            //         )
            //     ) {
            //         message.error('To view credit, you need to fill in your name, home address, and ssn');
            //         return;
            //     }
            // }
            await props.onSave(values, id);
        } catch (err) {
            console.error(err);
        } finally {
            setIsWorking(false);
        }
    };
    const onCancel = () => {
        if (!isWorking) props.onCancel();
    };
    useEffect(() => {
        if (props.visible) {
            form.resetFields();
            setTreadInList(form.getFieldValue('tradeIn'));
            setTreadInVisible(false);
        }
    }, [form, initialValues, props.visible]);
    /**已有客户数据相关 */
    const [customerVisible, setCustomerVisible] = useState(false);
    const [customerData, setCustomerData] = useState<Customer>();
    //根据电话号码查询客户是否已经存在
    const cellNumberBlur = async (val: string) => {
        if (!numReg.test(val)) return setCustomerData(undefined);
        const res = await searchCustomer(val);
        if (res && res.data.length > 0) setCustomerData(res.data[0] as Customer);
        else setCustomerData(undefined);
    };
    //TreadIn编辑对话框
    const [treadInVisible, setTreadInVisible] = useState(false);
    const [treadInEditVisible, setTreadInEditVisible] = useState(false);
    const [treadInData, setTreadInData] = useState<LeadTradeIn>();
    const [treadInIndex, setTreadInIndex] = useState<number>();
    const [treadInList, setTreadInList] = useState<LeadTradeIn[]>([]);
    const onTreadInChange = (tradeIn: LeadTradeIn) => {
        let list = form.getFieldValue('tradeIn') || [];
        if (treadInIndex !== undefined) {
            list = list.filter((l: LeadTradeIn, i: number) => i !== treadInIndex);
        }
        list.push(tradeIn);
        setTreadInList(list);
        form.setFieldsValue({ tradeIn: list });
        setTreadInVisible(true);
        setTreadInEditVisible(false);
    };
    // 地址编辑对话框
    const [addrEditorVisible, setAddrEditorVisible] = useState(false);
    const [addressData, setAddressData] = useState<Address>();
    const [addressPath, setAddressPath] = useState<string | string[]>();
    // eslint-disable-next-line react/display-name
    const createAddressRenderer = (path: string | string[]) => (form: FormInstance) => {
        const address = form.getFieldValue(path);
        return (
            <Form.Item name={path} noStyle>
                <Button
                    type="link"
                    size="small"
                    style={{ padding: 0 }}
                    onClick={() => {
                        setAddressPath(path);
                        setAddressData(address);
                        setAddrEditorVisible(true);
                    }}
                >
                    Edit
                </Button>
            </Form.Item>
        );
    };
    const onAddressChange = (address: Address) => {
        if (typeof addressPath === 'string') form.setFieldsValue({ [addressPath]: address });
        else if (Array.isArray(addressPath)) {
            let value: any;
            addressPath.reverse().forEach((path, i) => {
                if (i === 0) value = { [path]: address };
                else value = { [path]: value };
            });
            if (value != null) form.setFieldsValue(value);
        }
        setAddrEditorVisible(false);
    };
    const onFieldsChange = (changedFields: any[]) => {
        const typeChanged = changedFields.some(field => field.name.length === 2 && field.name[1] === 'type');
        const nameChanged = changedFields.some(field => field.name.length === 2 && field.name[1] === 'name');
        if (typeChanged && !nameChanged) {
            const type = changedFields.find(field => field.name.length === 2 && field.name[1] === 'type').value;
            if (type === CustomerType.Individual && typeof form.getFieldValue(['name', 'firstName']) !== 'string')
                form.setFieldsValue({ customerInput: { name: { firstName: '', lastName: '' } } });
            else if (type === CustomerType.Company && typeof form.getFieldValue('name') !== 'string')
                form.setFieldsValue({ customerInput: { name: '' } });
        }
    };
    //opportunity相关
    const opportunityItems = (
        <Row gutter={4}>
            <Col span={12}>
                <Form.Item name={['opportunity', 'model']} label="Model" labelCol={{ span: 10 }} rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item name={['opportunity', 'color']} label="Color" labelCol={{ span: 10 }}>
                    <Input />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item name={['opportunity', 'testDrived']} label="Test drived" valuePropName="checked" labelCol={{ span: 10 }}>
                    <Switch />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item name={['opportunity', 'demonstrated']} label="Demonstrated" valuePropName="checked" labelCol={{ span: 10 }}>
                    <Switch />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    name={['opportunity', 'lookingForUsed']}
                    label="Looking for used"
                    valuePropName="checked"
                    labelCol={{ span: 10 }}
                >
                    <Switch />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item name={['opportunity', 'prospectLevel']} label="Prospect level" labelCol={{ span: 10 }}>
                    <Select allowClear={true}>
                        <Select.Option value={0}>High</Select.Option>
                        <Select.Option value={1}>Neutral</Select.Option>
                        <Select.Option value={2}>Low</Select.Option>
                    </Select>
                </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item name={['opportunity', 'comment']} label="Comments" labelCol={{ span: 5 }}>
                    <Input.TextArea rows={3} />
                </Form.Item>
            </Col>
        </Row>
    );

    //lead相关
    const leadItems = (
        <Row gutter={4}>
            <Col span={12}>
                <Form.Item name="sourceType" label="Source" labelCol={{ span: 10 }}>
                    <EnumSelect enum={LeadSourceType} />
                </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item name="sourceDetails" label="Details" labelCol={{ span: 5 }}>
                    <Input />
                </Form.Item>
            </Col>
        </Row>
    );

    //tread In相关
    const treadInItems = (
        <Row gutter={4}>
            <Col span={24}>
                <Form.Item label="Trade-in" shouldUpdate>
                    {({ getFieldValue }) => (
                        <>
                            <Button
                                type="link"
                                size="small"
                                style={{ padding: 0 }}
                                onClick={() => {
                                    setTreadInVisible(!treadInVisible);
                                }}
                            >
                                {getFieldValue('tradeIn') ? getFieldValue('tradeIn').length : 0} items
                                {treadInVisible ? <UpOutlined /> : <DownOutlined />}
                            </Button>
                            <Button
                                type="link"
                                size="small"
                                style={{ padding: 0, float: 'right' }}
                                onClick={() => {
                                    setTreadInIndex(undefined);
                                    setTreadInEditVisible(true);
                                    setTreadInData(undefined);
                                }}
                            >
                                Add new
                            </Button>
                        </>
                    )}
                </Form.Item>
            </Col>
            <Col span={19} offset={5}>
                {treadInVisible &&
                    treadInList &&
                    treadInList.map((t: LeadTradeIn, i: number) => (
                        <>
                            <Descriptions key={new Date().toString()} column={2}>
                                <Descriptions.Item label="Year">{t.year}</Descriptions.Item>
                                <Descriptions.Item label="Make">{t.make}</Descriptions.Item>
                                <Descriptions.Item label="Model">{t.model}</Descriptions.Item>
                                <Descriptions.Item label="Mileage">{t.mileage}</Descriptions.Item>
                                <Descriptions.Item label="VIN">{t.vin}</Descriptions.Item>
                                <Descriptions.Item label="Action">
                                    <Button
                                        size="small"
                                        type="link"
                                        onClick={() => {
                                            setTreadInData(t);
                                            setTreadInEditVisible(true);
                                            setTreadInIndex(i);
                                        }}
                                    >
                                        <EditOutlined />
                                    </Button>
                                    <Button
                                        size="small"
                                        type="link"
                                        onClick={() => {
                                            let list = form.getFieldValue('tradeIn') || [];
                                            list = list.filter((l: LeadTradeIn, index: number) => i !== index);
                                            setTreadInList(list);
                                            form.setFieldsValue({ tradeIn: list });
                                        }}
                                    >
                                        <CloseOutlined />
                                    </Button>
                                </Descriptions.Item>
                            </Descriptions>
                            <Divider />
                        </>
                    ))}
            </Col>
        </Row>
    );
    const onValuesChange = (changedValues: Store) => {
        if (changedValues.customerInput?.contactInfo?.cellNumber != null) setCustomerData(undefined);
    };
    //customer相关
    const companyItems = (
        <Row gutter={4}>
            <Col span={24}>
                <Form.Item name={['customerInput', 'name']} label="Name" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    name={['customerInput', 'contactInfo', 'cellNumber']}
                    label="Phone"
                    labelCol={{ span: 10 }}
                    rules={[{ required: true }, { pattern: /^[2-9]\d{2}[2-9](?!11)\d{6}$/, message: 'Incorrect number format.' }]}
                >
                    <Input />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item name={['customerInput', 'contactInfo', 'workNumber']} label="Alt. phone" labelCol={{ span: 10 }}>
                    <Input />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    rules={[{ required: true, type: 'email' }]}
                    name={['customerInput', 'contactInfo', 'email']}
                    label="Email"
                    labelCol={{ span: 10 }}
                >
                    <Input />
                </Form.Item>
            </Col>

            <Col span={12}>
                <Form.Item
                    label="Address"
                    labelCol={{ span: 10 }}
                    shouldUpdate={(prev, next) => prev.contactInfo && prev.contactInfo.workAddress !== next.contactInfo.workAddress}
                >
                    {createAddressRenderer(['customerInput', 'contactInfo', 'workAddress'])}
                </Form.Item>
            </Col>
        </Row>
    );
    const individualItems = (
        <Row gutter={4}>
            <Col span={12}>
                <Form.Item
                    extra={
                        customerData ? (
                            <div>
                                The number already exists.
                                <Button type="link" size="small" onClick={() => setCustomerVisible(true)}>
                                    Use
                                </Button>
                            </div>
                        ) : null
                    }
                    style={{ marginBottom: '6px' }}
                    name={['customerInput', 'contactInfo', 'cellNumber']}
                    label="Cell number"
                    labelCol={{ span: 10 }}
                    rules={[{ required: true }, { pattern: numReg, message: 'Incorrect number format.' }]}
                >
                    <Input onBlur={e => cellNumberBlur(e.target.value)} />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    rules={[{ required: true, type: 'email' }]}
                    name={['customerInput', 'contactInfo', 'email']}
                    label="Email"
                    labelCol={{ span: 10 }}
                >
                    <Input />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    name={['customerInput', 'name', 'firstName']}
                    label="First name"
                    rules={[{ required: true, whitespace: true }]}
                    labelCol={{ span: 10 }}
                >
                    <Input />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    name={['customerInput', 'name', 'lastName']}
                    label="Last name"
                    rules={[{ required: true, whitespace: true }]}
                    labelCol={{ span: 10 }}
                >
                    <Input />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item name={['customerInput', 'name', 'middleName']} label="Mid. name" labelCol={{ span: 10 }}>
                    <Input />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item name={['customerInput', 'name', 'title']} label="Title" labelCol={{ span: 10 }}>
                    <Input />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item name={['customerInput', 'name', 'prefix']} label="Prefix" labelCol={{ span: 10 }}>
                    <Input />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item name={['customerInput', 'name', 'suffix']} label="Suffix" labelCol={{ span: 10 }}>
                    <Input />
                </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item name={['customerInput', 'gender']} label="Gender">
                    <Radio.Group>
                        <Radio value="M">Male</Radio>
                        <Radio value="F">Female</Radio>
                        <Radio value="X">Other</Radio>
                    </Radio.Group>
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item label="Birth date" labelCol={{ span: 10 }} shouldUpdate>
                    {({ getFieldValue, setFieldsValue }) => (
                        <DatePicker
                            format="MM/DD/YYYY"
                            value={getFieldValue('customerInput').dob ? dayjs(getFieldValue('customerInput').dob) : undefined}
                            onChange={(date: any) => {
                                setFieldsValue({
                                    dob: date
                                });
                            }}
                        />
                    )}
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    name={['customerInput', 'ssn']}
                    label="SSN"
                    labelCol={{ span: 10 }}
                    rules={[
                        {
                            pattern: /^(?!000)(?!666)[0-8][0-9]{2}[ -](?!00)[0-9]{2}[ -](?!0000)[0-9]{4}$/,
                            message: 'Invalid SSN format.'
                        }
                    ]}
                >
                    <Input />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item name={['customerInput', 'contactInfo', 'homeNumber']} label="Home phone" labelCol={{ span: 10 }}>
                    <Input />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    label="Home address"
                    labelCol={{ span: 10 }}
                    shouldUpdate={(prev, next) =>
                        prev.contactInfo && prev.customerInput.contactInfo.homeAddress !== next.customerInput.contactInfo.homeAddress
                    }
                >
                    {createAddressRenderer(['customerInput', 'contactInfo', 'homeAddress'])}
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item name={['customerInput', 'contactInfo', 'workNumber']} label="Work phone" labelCol={{ span: 10 }}>
                    <Input />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    label="Work address"
                    labelCol={{ span: 10 }}
                    shouldUpdate={(prev, next) =>
                        prev.contactInfo && prev.customerInput.contactInfo.workAddress !== next.customerInput.contactInfo.workAddress
                    }
                >
                    {createAddressRenderer(['customerInput', 'contactInfo', 'workAddress'])}
                </Form.Item>
            </Col>
        </Row>
    );
    const customerOk = (checked: boolean) => {
        setCustomerVisible(false);
        if (!checked && !customerData) return;
        form.setFieldsValue({
            customerInput: customerData
        });
    };
    return (
        <Modal
            title={`${id == null ? 'New' : 'Edit'} Lead`}
            confirmLoading={isWorking}
            visible={props.visible}
            onCancel={onCancel}
            onOk={onOk}
            width={600}
            className="form"
            okText="Submit"
            cancelButtonProps={{ type: 'link' }}
            maskClosable={false}
            forceRender
        >
            <SimpleBar style={{ maxHeight: 600 }}>
                <WindowContainer>
                    <Form
                        form={form}
                        initialValues={initialValues}
                        labelCol={{ span: 5 }}
                        onFieldsChange={onFieldsChange}
                        onValuesChange={onValuesChange}
                    >
                        <Divider orientation="left">Lead</Divider>
                        {leadItems}
                        <Divider orientation="left">Customer</Divider>
                        <Form.Item name={['customerInput', 'type']} label="Type" rules={[{ required: true }]}>
                            <EnumSelect enum={CustomerType} />
                        </Form.Item>
                        <Form.Item noStyle shouldUpdate={(prev, current) => prev.customerInput !== current.customerInput}>
                            {({ getFieldValue }) =>
                                getFieldValue(['customerInput', 'type']) === CustomerType.Company ? companyItems : individualItems
                            }
                        </Form.Item>
                        <Divider orientation="left">Opportunity</Divider>
                        {opportunityItems}
                        <Divider orientation="left">Trade-in</Divider>
                        {treadInItems}
                    </Form>
                    <AddressEditor
                        visible={addrEditorVisible}
                        onCancel={() => setAddrEditorVisible(false)}
                        data={addressData}
                        onChange={onAddressChange}
                    />
                    <TreadInEditor
                        visible={treadInEditVisible}
                        onCancel={() => setTreadInEditVisible(false)}
                        data={treadInData}
                        onChange={onTreadInChange}
                    />
                </WindowContainer>
                <LeadCustomer visible={customerVisible} onCancel={() => setCustomerVisible(false)} data={customerData} onOk={customerOk} />
            </SimpleBar>
        </Modal>
    );
};

export default React.memo(OutLineSaleLeadEditor);
