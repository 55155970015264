import { Col, Form, Modal, Radio, Row, Table } from 'antd';
import currency from 'currency.js';
import React, { useEffect, useMemo, useState } from 'react';

import { FormInput } from '../../../components';
import { useFilters, usePaging, useSearchData } from '../../../hooks';
import { ServiceOperation, ServiceOperationType } from '../../../models';
import { Container, MainContainer, WindowContainer } from '../components';

import type { TableRowSelection } from 'antd/lib/table/interface';

interface Props {
    visible: boolean;
    single?: boolean;
    data?: ServiceOperation[];
    onCancel: () => void;
    onChange?: (data: ServiceOperation[]) => void;
}

const ServiceOperationSelector: React.FC<Props> = ({ visible, single, data, onChange, onCancel }) => {
    const [filters, setFilter] = useFilters();
    const [paging, pagination, setTotal] = usePaging(50, true);
    const [result, isWorking] = useSearchData<ServiceOperation>('serviceOperation', filters, paging);
    useEffect(() => {
        setTotal(result.total);
    }, [setTotal, result.total]);

    // 选择项
    const [selectedRows, setSelectedRows] = useState<ServiceOperation[]>([]);
    const rowSelection = useMemo(
        (): TableRowSelection<ServiceOperation> => ({
            columnWidth: 40,
            type: single === true ? 'radio' : 'checkbox',
            selectedRowKeys: selectedRows.map(charge => charge.code),
            onChange: (_: any, selectedRows: ServiceOperation[]) => setSelectedRows(selectedRows)
        }),
        [selectedRows, single]
    );
    // 本地过滤
    const [dataFilter, setDataFilter] = useState<'all' | 'selected'>('all');
    const filteredData = useMemo(() => {
        switch (dataFilter) {
            case 'selected':
                const codes = selectedRows.map(entry => entry.code);
                return result.data.filter(entry => codes.indexOf(entry.code) > -1);
        }
        return result.data;
    }, [dataFilter, result.data, selectedRows]);
    // data属性更改后自动更新选择项目
    useEffect(() => {
        setSelectedRows(
            data != null && Array.isArray(data)
                ? result.data.filter(item1 => data.find(item2 => item2.code === item1.code) != null)
                : []
        );
    }, [data, result.data]);

    return (
        <Modal
            title="Select Service Operation"
            confirmLoading={isWorking}
            visible={visible}
            onCancel={onCancel}
            onOk={() => typeof onChange === 'function' && onChange(selectedRows)}
            width={800}
            className="form"
            cancelButtonProps={{ type: 'link' }}
            style={{ top: 200 }}
        >
            <WindowContainer>
                <Container>
                    <Row gutter={8}>
                        <Col span={10}>
                            <FormInput
                                placeholder="Search operations..."
                                allowClear
                                onEnterPress={setFilter}
                                data={filters}
                                field="$keyword"
                            />
                        </Col>
                        <Col span={14}>
                            <Form.Item label="Filter" labelCol={{ span: 4 }}>
                                <Radio.Group
                                    value={dataFilter}
                                    buttonStyle="solid"
                                    onChange={e => setDataFilter(e.target.value)}
                                >
                                    <Radio.Button value="all">All</Radio.Button>
                                    <Radio.Button value="selected">Selected</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                </Container>
                <MainContainer style={{ margin: 0 }}>
                    <Table
                        rowKey="code"
                        size="small"
                        dataSource={filteredData}
                        pagination={pagination}
                        rowSelection={rowSelection}
                        scroll={{ y: 200 }}
                        loading={isWorking}
                    >
                        <Table.Column<ServiceOperation>
                            title="Type"
                            width={80}
                            render={(_, record) =>
                                record.type != null ? ServiceOperationType[record.type] : record.type
                            }
                        />
                        <Table.Column title="Code" dataIndex="code" width={80} />
                        <Table.Column title="Description" dataIndex="description" />
                        <Table.Column title="Hours" dataIndex="hours" width={60} />
                        <Table.Column<ServiceOperation>
                            title="Parts"
                            width={70}
                            render={(_, record) => currency(record.partsAmount || 0).format()}
                        />
                        <Table.Column<ServiceOperation>
                            title="Labor"
                            width={70}
                            render={(_, record) => currency(record.laborAmount || 0).format()}
                        />
                    </Table>
                </MainContainer>
            </WindowContainer>
        </Modal>
    );
};

ServiceOperationSelector.displayName = 'ServiceOperationSelector';

export default React.memo(ServiceOperationSelector);
