import { AuthenticationDetails, CognitoUser, CognitoUserAttribute, CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js';

interface AuthenticateResult {
    errorMessage?: string;
    newPasswordRequired?: Record<string, string>;
}

interface ChallengeSession {
    user: CognitoUser;
    data: any;
}

let challengeSession: ChallengeSession | undefined;

const userPool = new CognitoUserPool({
    UserPoolId: process.env.REACT_APP_USER_POOL_ID as string,
    ClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID as string
});

export function getSession(): Promise<[CognitoUserSession, CognitoUser] | null> {
    return new Promise((resolve, reject) => {
        const user = userPool.getCurrentUser();
        if (user == null) {
            resolve(null);
            return;
        }

        user.getSession((err: any, session: CognitoUserSession) => {
            if (err != null) reject(err);
            else if (session == null) resolve(null);
            else resolve([session, user]);
        });
    });
}

export function getAccessToken(): Promise<string | null> {
    return new Promise((resolve, reject) => {
        const checkExpiration = (user: CognitoUser, session: CognitoUserSession, refresh = true) => {
            const idToken = session.getIdToken();
            if (idToken.getExpiration() - Math.ceil(Date.now() / 1000) > 55) {
                resolve(idToken.getJwtToken());
                return;
            }
            if (!refresh) {
                resolve(null);
                return;
            }

            const dealer = idToken.payload['nebulapro:dealer'];
            if (typeof dealer !== 'string') {
                resolve(null);
                return;
            }

            const refreshToken = session.getRefreshToken();
            user.refreshSession(
                refreshToken,
                (err: any, session: CognitoUserSession) => {
                    if (err != null || session == null) {
                        user.signOut(() => resolve(null));
                    } else checkExpiration(user, session, false);
                },
                { dealer }
            );
        };

        getSession()
            .then(result => {
                if (result == null) resolve(null);
                else checkExpiration(result[1], result[0]);
            })
            .catch(err => reject(err));
    });
}

export function getUserAttributes(): Promise<Record<string, string> | null> {
    return new Promise((resolve, reject) => {
        getSession()
            .then(result => {
                if (result == null) resolve(null);
                else
                    result[1].getUserAttributes((err: any, result?: CognitoUserAttribute[]) => {
                        if (err != null) {
                            reject(err);
                            return;
                        }
                        if (Array.isArray(result)) resolve(result.reduce((acc, cur) => ({ ...acc, [cur.getName()]: cur.getValue() }), {}));
                        else resolve(null);
                    });
            })
            .catch(err => reject(err));
    });
}

export async function isLoggedIn(): Promise<boolean> {
    try {
        return (await getSession()) != null;
    } catch {
        return false;
    }
}

export function signOut(): Promise<void> {
    return new Promise(resolve => {
        const user = userPool.getCurrentUser();
        if (user == null) resolve();
        else user.signOut(() => resolve());
    });
}

export function authenticate(userName: string, password: string, dealer?: string): Promise<AuthenticateResult> {
    const details = new AuthenticationDetails({
        Username: userName,
        Password: password,
        ClientMetadata: typeof dealer === 'string' ? { dealer } : undefined
    });
    const cognitoUser = new CognitoUser({ Username: details.getUsername(), Pool: userPool });
    return new Promise((resolve, reject) => {
        try {
            cognitoUser.authenticateUser(details, {
                onSuccess: () => {
                    challengeSession = undefined;
                    resolve({});
                },
                onFailure: err => {
                    challengeSession = undefined;
                    if (err.name === 'NotAuthorizedException') resolve({ errorMessage: err.message });
                    else reject(err);
                },
                newPasswordRequired: userAttributes => {
                    delete userAttributes.email_verified;
                    delete userAttributes.phone_number_verified;
                    challengeSession = { user: cognitoUser, data: userAttributes };
                    resolve({ newPasswordRequired: { ...userAttributes } });
                }
            });
        } catch (err) {
            reject(err);
        }
    });
}

export function completeNewPassword(newPassword: string, dealer?: string): Promise<AuthenticateResult> {
    return new Promise((resolve, reject) => {
        try {
            if (challengeSession == null) {
                reject(Error('Challenge session not set'));
                return;
            }

            const { user } = challengeSession;
            user.completeNewPasswordChallenge(
                newPassword,
                {},
                {
                    onSuccess: () => {
                        challengeSession = undefined;
                        resolve({});
                    },
                    onFailure: err => {
                        challengeSession = undefined;
                        if (err.name === 'NotAuthorizedException') resolve({ errorMessage: err.message });
                        else reject(err);
                    }
                },
                typeof dealer === 'string' ? { dealer } : undefined
            );
        } catch (err) {
            reject(err);
        }
    });
}

export function forgotPassword(userName: string) {
    return new Promise((resolve, reject) => {
        try {
            const cognitoUser = new CognitoUser({ Username: userName, Pool: userPool });
            cognitoUser.forgotPassword({ onSuccess: resolve, onFailure: reject, inputVerificationCode: () => resolve(cognitoUser) });
        } catch (err) {
            reject(err);
        }
    });
}

export function completeForgotPassword(user: CognitoUser, code: string, newPassword: string) {
    return new Promise((onSuccess, onFailure) => {
        try {
            user.confirmPassword(code, newPassword, { onSuccess, onFailure });
        } catch (err) {
            onFailure(err);
        }
    });
}

export function changePassword(userName: string, oldPassword: string, newPassword: string) {
    return new Promise<void>(async (resolve, reject) => {
        try {
            const result: [CognitoUserSession, CognitoUser] | null = await getSession();
            if (result == null) return;
            const cognitoUser = result[1];
            cognitoUser.changePassword(oldPassword, newPassword, (err, result) => {
                if (err != null) reject(err);
                else if (result === 'SUCCESS') resolve();
                else reject(new Error('Unknown error'));
            });
        } catch (err) {
            reject(err);
        }
    });
}
