import { Empty } from 'antd';
import _ from 'lodash';
import { useEffect, useState } from 'react';

import { LeadDataObject } from '../crm/lead/models';
import { ServiceAppointmentDataObject } from '../service/appointment/models';
import { BusinessType, LeadStatistics, ServiceStatistics } from './models';
import DualAxesChart from './DualAxesChart';

interface Props {
    type: BusinessType;
    leads?: LeadDataObject[];
    appointments?: ServiceAppointmentDataObject;
    leadStatisticss?: LeadStatistics[];
    serviceStatistics?: ServiceStatistics[];
    reportType: string;
}

const HomeChart = ({ type, leadStatisticss, serviceStatistics, reportType }: Props) => {
    const lableRateName = type === BusinessType.Lead ? 'Contact rate' : 'Reservation rate';
    const [data, setData] = useState<any[]>([]);
    const [lineData, setLineData] = useState<any[]>([]);

    useEffect(() => {
        if (leadStatisticss == null) return;
        // console.log(leadStatisticss);
        const results: any[] = [];
        const lineResults: any[] = [];
        const leadsSort = _.sortBy(leadStatisticss, l => l.time);

        leadsSort.map(item => {
            results.push({
                category: 'New',
                time: item.timeLabel,
                value: item.new,
                type: 'New'
            });
        });

        leadsSort.map(item => {
            results.push({
                category: 'InProgress',
                time: item.timeLabel,
                value: item.assigned,
                type: 'Assigned'
            });
        });

        leadsSort.map(item => {
            results.push({
                category: 'InProgress',
                time: item.timeLabel,
                value: item.contacted,
                type: 'Contacted'
            });
            lineResults.push({
                time: item.timeLabel,
                count: item.assigned !== 0 ? _.round((item.contacted / item.assigned) * 100) : 0,
                name: 'Contact rate'
            });
            lineResults.push({
                time: item.timeLabel,
                count: 75,
                name: 'Contact qualified rate'
            });
        });
        // leadsSort.map(item => {
        //     lineResults.push({
        //         time: item.timeLabel,
        //         count: 75,
        //         name: 'Contact rate standard'
        //     });
        // });
        // console.log(lineResults);
        setData(results);
        setLineData(lineResults);
    }, [leadStatisticss]);

    useEffect(() => {
        if (serviceStatistics == null) return;
        // console.log(serviceStatistics);
        const results: any[] = [];
        const lineResults: any[] = [];
        const appointmentSort = _.sortBy(serviceStatistics, l => l.time);
        appointmentSort.map(item => {
            results.push({
                category: 'New',
                time: item.timeLabel,
                value: item.appointments,
                type: 'Appointment'
            });
        });

        appointmentSort.map(item => {
            results.push({
                category: 'RO',
                time: item.timeLabel,
                value: item.repairOrders,
                type: 'RepairOrder'
            });
        });

        appointmentSort.map(item => {
            results.push({
                category: 'RO',
                time: item.timeLabel,
                value: item.appointmentInRepairOrders,
                type: 'Reserved repair order'
            });
            lineResults.push({
                time: item.timeLabel,
                count: item.repairOrders !== 0 ? _.round((item.appointmentInRepairOrders / item.repairOrders) * 100) : 0,
                name: 'Reservation rate'
            });
        });
        // console.log(lineResults);
        setData(results);
        setLineData(lineResults);
    }, [serviceStatistics]);

    return data.length > 0 ? (
        <DualAxesChart data={data} lineData={lineData} labelRateName={lableRateName} type={reportType} />
    ) : (
        <Empty></Empty>
    );
};
export default HomeChart;
