import { message, Alert, Button, Form, Input, Modal } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';

import background from './images/login-background.jpg';
import { authenticate, completeForgotPassword, completeNewPassword, forgotPassword } from './services/cognito';
import styles from './Login.module.scss';

interface LoginHookState {
    isBusy: boolean;
    newPasswordRequired?: boolean;
    error?: string;
}

interface LoginHookResult extends LoginHookState {
    authenticate: (userName: string, password: string) => void;
    setNewPassword: (newPassword: string) => void;
}

interface ForgotPasswordProps {
    visible: boolean;
    onClose: () => void;
}

interface PasswordRuleProps {
    password: string;
    onValidChange: (valid: boolean) => void;
}

function useLogin(): LoginHookResult {
    const [state, setState] = useState<LoginHookState>({ isBusy: false });
    const [credential, setCredential] = useState<{ userName: string; password: string }>();
    const [userAttributes, setUserAttributes] = useState<Record<string, string>>();
    const [newPassword, setNewPassword] = useState<string>();

    useEffect(() => {
        (async () => {
            if (state.isBusy || credential == null) return;
            try {
                setState(state => ({ ...state, isBusy: true, error: undefined }));
                const result = await authenticate(credential.userName, credential.password);
                if (typeof result.errorMessage === 'string') setState(state => ({ ...state, error: result.errorMessage, isBusy: false }));
                else if (result.newPasswordRequired != null) {
                    setUserAttributes(result.newPasswordRequired);
                    setState(state => ({ ...state, newPasswordRequired: true, error: undefined, isBusy: false }));
                } else location.reload();
            } catch (err) {
                setState(state => ({ ...state, error: err.message, isBusy: false }));
            } finally {
                setCredential(undefined);
            }
        })();
    }, [state.isBusy, credential]);

    useEffect(() => {
        (async () => {
            if (!state.isBusy && typeof newPassword === 'string' && newPassword !== '' && userAttributes != null) {
                try {
                    setState(state => ({ ...state, isBusy: true, error: undefined }));
                    const result = await completeNewPassword(newPassword);
                    if (typeof result.errorMessage === 'string')
                        setState(state => ({ ...state, error: result.errorMessage, isBusy: false }));
                    else location.reload();
                } catch (err) {
                    setState(state => ({ ...state, error: err.message, isBusy: false }));
                } finally {
                    setNewPassword(undefined);
                }
            }
        })();
    }, [newPassword, userAttributes, state.isBusy]);

    return useMemo<LoginHookResult>(
        () => ({
            ...state,
            authenticate: (userName: string, password: string) => setCredential({ userName, password }),
            setNewPassword
        }),
        [state]
    );
}

const Rules = [
    'Be at least 8 characters long',
    'Contain at least one number',
    'Contain at least one uppercase letter',
    'Contain at least one lowercase letter'
];
const PasswordRule = ({ password, onValidChange }: PasswordRuleProps) => {
    const [ruleChecks, setRuleChecks] = useState(Rules.map(() => false));

    useEffect(() => {
        setRuleChecks([
            password.length >= 8,
            password.match(/\d/) != null,
            password.match(/[A-Z]/) != null,
            password.match(/[a-z]/) != null
        ]);
    }, [password]);

    useEffect(() => {
        onValidChange(ruleChecks.every(check => check === true));
    }, [ruleChecks, onValidChange]);

    return (
        <ul style={password !== '' ? { listStyle: 'none', paddingLeft: 24 } : undefined}>
            {Rules.map((rule, i) => (
                <li key={i} className={password !== '' ? (ruleChecks[i] ? styles.valid : styles.invalid) : undefined}>
                    {rule}
                </li>
            ))}
        </ul>
    );
};

const ForgotPassword = ({ visible, onClose }: ForgotPasswordProps) => {
    const [cognitoUser, setCognitoUser] = useState<any>();
    const [userName, setUserName] = useState('');
    const [error, setError] = useState<string>();
    const [isBusy, setIsBusy] = useState(false);
    const [code, setCode] = useState('');
    const [password, setPassword] = useState('');
    const [passwordValid, setPasswordValid] = useState(false);

    const codeSent = cognitoUser != null;
    const onOk = async () => {
        try {
            if (codeSent) {
                setIsBusy(true);
                await completeForgotPassword(cognitoUser, code, password);
                message.success('Password changed, please login with the new password.', 10);
                onClose();
            } else if (userName.trim() !== '') {
                if (/^[a-z0-9]+@[a-z]+\.[a-z]{2,3}$/i.test(userName) !== true) {
                    setError('Invalid email address');
                    return;
                }
                setError(undefined);
                setIsBusy(true);
                setCognitoUser(await forgotPassword(userName));
                message.success('Verification code sent, please check your email.', 10);
            }
        } catch (err) {
            message.error(err.message);
        } finally {
            setIsBusy(false);
        }
    };

    return (
        <Modal
            title="Forgot Password"
            okText={codeSent ? 'Confirm Password' : 'Send Verification'}
            okButtonProps={{ loading: isBusy, disabled: codeSent && !passwordValid }}
            cancelButtonProps={{ style: { display: 'none' } }}
            onOk={onOk}
            onCancel={onClose}
            keyboard={false}
            maskClosable={false}
            visible={visible}
            centered
        >
            <Form labelCol={{ span: 8 }}>
                <Form.Item label="Your Email" help={error} validateStatus={error && 'error'} required>
                    <Input type="email" value={userName} onChange={(e: any) => setUserName(e.currentTarget.value)} readOnly={codeSent} />
                </Form.Item>
                {codeSent ? (
                    <>
                        <Form.Item label="Verification Code" style={{ width: '100%' }} required>
                            <Input type="number" value={code} onChange={(e: any) => setCode(e.currentTarget.value)} />
                        </Form.Item>
                        <Form.Item label="New Password" name="newPassword" required>
                            <Input.Password value={password} onChange={(e: any) => setPassword(e.currentTarget.value)} />
                        </Form.Item>
                        <PasswordRule password={password} onValidChange={setPasswordValid} />
                    </>
                ) : null}
            </Form>
        </Modal>
    );
};

const Login: React.FC<{ err?: Error }> = ({ err }) => {
    const login = useLogin();
    const [newPassword, setNewPassword] = useState('');
    const [passwordValid, setPasswordValid] = useState(false);
    const [forgotPasswordVisible, setForgotPasswordVisible] = useState(false);

    return (
        <main id="login" className={styles.container}>
            <header className={styles.header} style={{ backgroundImage: `url(${background})` }} />
            <section className={styles.content}>
                <h3>Welcome to Nebula Pro</h3>
                <Form
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 17 }}
                    initialValues={{}}
                    onFinish={values => login.authenticate(values.userName, values.password)}
                    autoComplete="off"
                    style={{ width: 450 }}
                >
                    <Form.Item label="Email" name="userName" rules={[{ required: true, message: 'Email is required' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Password" name="password" rules={[{ required: true, message: 'Password is required' }]}>
                        <Input.Password />
                    </Form.Item>
                    {typeof login.error === 'string' || err != null ? (
                        <Form.Item label=" " colon={false}>
                            <Alert
                                message={login.error ?? (err == null ? '' : 'Something went wrong, please try again in a few seconds.')}
                                type="error"
                                showIcon
                            />
                        </Form.Item>
                    ) : null}
                    <Form.Item wrapperCol={{ offset: 7, span: 17 }}>
                        <div style={{ display: 'flex' }}>
                            <div style={{ flexGrow: 1 }}>
                                <Button type="primary" htmlType="submit" disabled={login.isBusy}>
                                    Sign in
                                </Button>
                            </div>
                            <div>
                                <Button
                                    type="link"
                                    htmlType="button"
                                    size="small"
                                    disabled={login.isBusy}
                                    onClick={() => setForgotPasswordVisible(true)}
                                >
                                    Forgot password
                                </Button>
                            </div>
                        </div>
                    </Form.Item>
                </Form>
            </section>
            <Modal
                title="New Password Required"
                okText="Submit"
                okButtonProps={{ disabled: !passwordValid }}
                cancelButtonProps={{ style: { display: 'none' } }}
                onOk={() => login.setNewPassword(newPassword)}
                confirmLoading={login.isBusy}
                visible={login.newPasswordRequired}
                closable={false}
                centered
            >
                <Form layout="inline" onValuesChange={({ newPassword }) => setNewPassword(newPassword)}>
                    <p>You need to change your password to proceed.</p>
                    <p>The new password must...</p>
                    <PasswordRule password={newPassword} onValidChange={setPasswordValid} />
                    <Form.Item label="New Password" name="newPassword" required>
                        <Input.Password />
                    </Form.Item>
                </Form>
            </Modal>
            <ForgotPassword visible={forgotPasswordVisible} onClose={() => setForgotPasswordVisible(false)} />
        </main>
    );
};

export default Login;
