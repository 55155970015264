import { message } from 'antd';
import dayjs from 'dayjs';
import isDate from 'lodash/isDate';
import pluralize from 'pluralize';
import wretch from 'wretch';

import { Filters, SearchParams, SearchResult } from '../types';
import { getAccessToken } from './cognito';

const API_SITE = '';
const API_ROOT = `${API_SITE}/api/`;

const api = wretch()
    .defaults({ headers: { 'Content-Type': 'application/json', Accept: 'application/json' } })
    .catcher(404, async (err, request) => {
        if (request._options.method === 'GET') return null;
        throw err;
    })
    .catcher(403, async () => {
        message.error({ content: 'Access Denied', duration: 8 });
    });

const getUrl = (resource: string) => `${API_ROOT}${pluralize(resource)}`;

const filtersToQuery = (filters?: Filters) => {
    if (filters == null) return {};

    const convertValue = (value: any) => {
        switch (typeof value) {
            case 'string':
            case 'number':
                return value;
            case 'boolean':
                return value === true ? 1 : 0;
            case 'bigint':
                return value.toString();
            case 'object':
                if (isDate(value) || dayjs.isDayjs(value)) return value.toISOString();
                break;
        }
    };

    const result: {
        [key: string]: string | number;
    } = {};

    Object.keys(filters).forEach(key => {
        const value = convertValue(filters[key]);
        if (value != null) result[key] = value;
    });

    return result;
};

export async function request<T, TResult>(
    method: 'get' | 'post' | 'put' | 'patch' | 'delete',
    resource: string,
    paths?: string[],
    query?: { [key: string]: string | number },
    data?: T
): Promise<TResult | null> {
    try {
        const token = await getAccessToken();
        if (token == null) {
            message.error({ content: 'Session timed out, please re-sign in', duration: 8 });
            return null;
        }

        let url = getUrl(resource);
        if (paths != null && Array.isArray(paths) && paths.length > 0) url += `/${paths.join('/')}`;
        let req = api.url(url).auth(`Bearer ${token}`);
        if (query != null) req = req.query(query);
        if (data != null) req = req.json(data as any);
        return await req[method]().json<TResult>();
    } catch (err) {
        let content;
        try {
            const res = JSON.parse(err.message);
            content = res.error ?? res.message;
        } catch {
            content = err.message;
        }
        if (method === 'delete' && content.indexOf('Unexpected end of JSON input') >= 0) return null;
        message.error({ content: `API request failed: ${content}`, duration: 8 });
        return null;
    }
}

export async function searchData<T>(resource: string, params: SearchParams): Promise<SearchResult<T> | null> {
    const query = filtersToQuery(params.filters);
    if (params.paging != null) {
        if (typeof params.paging.page === 'number') query.$page = params.paging.page;
        if (typeof params.paging.pageSize === 'number') query.$pageSize = params.paging.pageSize;
    }
    if (params.includes != null && Array.isArray(params.includes)) query.$includes = params.includes.join(',');
    return await request<T, SearchResult<T>>('get', resource, [], query);
}

export async function getData<T>(resource: string, id: string, includes?: string[]): Promise<T | null> {
    const query = (includes != null && Array.isArray(includes) && { $includes: includes.join(',') }) || undefined;
    return await request<any, T>('get', resource, [id], query);
}

export async function createData<T, TResult>(resource: string, data: T): Promise<TResult | null> {
    return await request<T, TResult>('post', resource, [], undefined, data);
}

export async function updateData<T, TResult>(resource: string, id: string, data: T): Promise<TResult | null> {
    return await request<T, TResult>('patch', resource, [id], undefined, data);
}

export async function deleteData(resource: string, id: string): Promise<any> {
    return await request('delete', resource, [id]);
}
