import { Select, Space } from 'antd';
import { flattenDeep, uniq } from 'lodash';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';

import { AccountStatus, User } from '../../../admin/user/models';
import { SEARCH_TEAMS } from '../../../crm/board/TeamSearcher';
import { SEARCH_USERS } from '../../../crm/lead/graphql';
import { TeamType } from '../../../crm/lead/models';
import { TeamDataObject } from '../../../shop/team/models';
import { Loading } from '../../components';
import { useSearch } from '../../hooks';

interface Props {
    user?: Partial<User>;
    job?: string;
    roleName?: string;
    value?: string | string[];
    onChange?: (value: string | string[]) => void;
    onChangeUser?: (value: User) => void;
    placeholder?: string;
    type?: TeamType;
    allUser?: boolean;
    showSearch?: boolean;
    showArrow?: boolean;
    disabled?: boolean;
    name?: string;
}

const useUserSearcher = (): [User[], boolean, (value: string) => void] => {
    const { data, loading, refetch } = useQuery(SEARCH_USERS, {
        variables: { filter: { _search: '', _noSearch: true, accountStatus: AccountStatus.Confirmed }, page: 1, pageSize: -1 }
    });

    const users = useMemo(() => (loading ? [] : data?.searchUsers?.data ?? []), [data, loading]);
    const setKeyword = useCallback(
        (value: string) => {
            value = value.trim();
            refetch({ filter: { _search: value, _noSearch: true, accountStatus: AccountStatus.Confirmed }, page: 1, pageSize: -1 });
        },
        [refetch]
    );
    return [users, loading, setKeyword];
};

interface Filter {
    _search?: string;
    type?: TeamType;
    enabled?: boolean;
    _noSearch?: boolean;
    members?: string;
}

const UserSearcherSelect = ({
    user,
    value,
    onChange,
    onChangeUser,
    placeholder,
    type,
    allUser,
    showSearch,
    showArrow = true,
    disabled = false,
    name
}: Props) => {
    const filter: Filter = { _noSearch: true, enabled: true, type: type };
    const { data } = useSearch<Filter, TeamDataObject>(SEARCH_TEAMS, filter, [], -1);
    const [users, loading, setKeyword] = useUserSearcher();
    const [userId, setUserId] = useState<string | string[]>();
    //标记是不是搜索查询
    const [isSearched, setIsSearched] = useState(false);
    const currentUsers = useMemo(() => {
        const members = uniq(flattenDeep(data.map(team => team.members.map(user => user.id))));
        let _users = [];
        if (allUser) {
            _users = users;
        } else {
            _users = users.filter(user => members.includes(user.id)).sort((a, b) => a.firstName.localeCompare(b.firstName));
        }

        if (isSearched) return _users;
        if (user && _users) {
            if (_users.find(item => item.id === user?.id) === undefined) {
                return [user, ..._users];
            }
        }
        return _users;
    }, [allUser, data, isSearched, user, users]);

    const onChangeSelect = (value: string | string[]) => {
        setUserId(value);
        if (typeof onChange === 'function') onChange(value);
        if (typeof onChangeUser === 'function') onChangeUser(currentUsers.find(user => user.id === value) as User);
    };
    const onSearch = (value: string) => {
        setKeyword(value);
        setIsSearched(true);
    };
    useMemo(() => {
        setUserId(value);
    }, [value]);

    useEffect(() => {
        if (data == null) return;
    }, [data]);

    if (Array.isArray(currentUsers) && currentUsers.length > 0) {
        return (
            <Select
                loading={loading}
                placeholder={placeholder ?? 'Search user by name'}
                value={(userId != null && currentUsers.findIndex(u => u.id === userId) === -1 ? name : userId) ?? undefined}
                notFoundContent={loading ? <Loading text="Searching..." /> : null}
                onSearch={showSearch ? debounce(onSearch, 500) : undefined}
                onChange={onChangeSelect}
                defaultActiveFirstOption={false}
                filterOption={false}
                style={{ width: '100%' }}
                showArrow={showArrow}
                allowClear
                showSearch={showSearch}
                disabled={disabled}
            >
                {Array.isArray(currentUsers) &&
                    currentUsers.map(user => (
                        <Select.Option key={user?.id} value={user?.id}>
                            <Space>
                                <strong>{`${user?.firstName} ${user?.lastName}`}</strong>
                            </Space>
                        </Select.Option>
                    ))}
            </Select>
        );
    } else {
        // 在请求服务端user时，显示空 select , 避免显示 user id
        return (
            <Select
                loading={loading}
                placeholder={placeholder ?? 'Search user by name'}
                notFoundContent={loading ? <Loading text="Searching..." /> : null}
                onSearch={showSearch ? debounce(onSearch, 500) : undefined}
                onChange={onChangeSelect}
                defaultActiveFirstOption={false}
                filterOption={false}
                style={{ width: '100%' }}
                showArrow={showArrow}
                allowClear
                showSearch={showSearch}
            ></Select>
        );
    }
};

export default UserSearcherSelect;
